<template>
    <ion-app :scroll-events="true" class="permission">
        <ion-page>
            <div class="header-step">
                <HeaderApp />
            </div>

            <ion-content class="ion-padding permission-content">
                <div class="content">
                    <div class="load-blue" v-if="!showContent"></div>

                    <div class="container ion-padding" v-if="showContent && (errors.error_404 || errors.error_general)">
                        <div class="box-error">
                            <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                            <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                            <div class="cod-error">{{ getMyLanguage("box-error",errors.error_msg) }}</div>
                        </div>
                    </div>

                    <div class="container" v-if="showContent && (!errors.error_404 && !errors.error_general && !errors.error_blockPurchase)">
                        <h2 class="title-secondary">{{getMyLanguage("compliance","compliance.company.title")}}</h2>
                        
                        <div class="box-compliance">
                            <div class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg." + dataAccount.backOfficeStatus)'></div>

                            <!-- PERSONAL INFORMATION -->
                            <div class="box-registration" v-for="(listPartner, index) in dataAccount.partners" :key="index">
                                <div class="formBox">
                                    <h3 class="subtitle">{{getMyLanguage("compliance","compliance.company.title-partner")}}</h3>
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <label class="formFullName">
                                                {{getMyLanguage("compliance","compliance.lbl.fullName")}}
                                                <span class="form formDisabled">{{listPartner.fullName}}</span>
                                            </label>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="formYourEmail">
                                                {{getMyLanguage("compliance","compliance.lbl.email")}}
                                                <span class="form formDisabled">{{listPartner.email}}</span>
                                            </label>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="formPhone">
                                                {{getMyLanguage("compliance","compliance.lbl.phone")}}
                                                <span class="form formDisabled">{{listPartner.phoneNumber}}</span>
                                            </label>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="formResidenceCountry">
                                                {{getMyLanguage("compliance","compliance.lbl.country")}}
                                                <span class="form formDisabled">
                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${dataAccount.countryFlag}/flags`" class="flag" v-if="dataAccount.countryFlag != false && dataAccount.countryFlag != null">
                                                    {{listPartner.country}}
                                                </span>
                                            </label>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="formPersonDOB">
                                                {{getMyLanguage("compliance","compliance.lbl.dob")}}
                                                <span class="form formDisabled">{{dateTime(listPartner.dob)}}</span>
                                            </label>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="formTaxNumber" :class="{required: errorsValidate.taxNumber}">
                                                {{getMyLanguage("compliance","compliance.lbl.taxNumber")}}
                                                <span class="form formDisabled">{{listPartner.taxNumber}}</span>
                                            </label>
                                        </div>
                                        
                                        <div class="col-12 col-lg-6">
                                            <label class="formStatusKYC" :class="{active: listPartner.kyc, pending: !listPartner.kyc}">
                                                {{getMyLanguage("compliance","compliance.lbl.kyc")}}
                                                <span class="form formDisabled statusKYC" v-if="listPartner.kyc">{{getMyLanguage("compliance","compliance.lbl.kyc.active")}}</span>
                                                <span class="form formDisabled statusKYC" v-if="!listPartner.kyc">{{getMyLanguage("compliance","compliance.lbl.kyc.pending")}}</span>
                                                
                                                <small v-if="!dataAccount.partnersValidated" v-html='getMyLanguage("compliance","compliance.msg.kyc.incomplete")'></small>
                                                <small v-if="dataAccount.partnersValidated" v-html='getMyLanguage("compliance","compliance.msg.kyc.incomplete")'></small>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- COMPANY DATA -->
                            <div class="box-registration address-compliance">
                                <div class="formBox">

                                    <h3 class="subtitle">{{getMyLanguage("compliance","compliance.company.title")}}</h3>

                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <label class="noIcon">
                                                {{getMyLanguage("compliance","compliance.lbl.companyName")}}
                                                <input type="text" class="form" :class="{error: errorsValidate.fullName}" v-model="dataCompliance.fullName">
                                            </label>
                                        </div>
                                        
                                        <div class="col-12 col-lg-6">
                                            <label class="noIcon">
                                                {{getMyLanguage("compliance","compliance.lbl.email")}}
                                                <span class="form formDisabled">{{dataAccount.company.email}}</span>
                                            </label>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="noIcon">
                                                {{getMyLanguage("compliance","compliance.lbl.country")}}
                                                <span class="form formDisabled">{{dataAccount.company.countryName}}</span>
                                            </label>
                                        </div>
                                        
                                        <div class="col-12 col-lg-6">
                                            <label class="noIcon">
                                                {{getMyLanguage("compliance","compliance.lbl.phone")}}
                                                <span class="form formDisabled">{{dataAccount.company.phoneNumber}}</span>
                                            </label>
                                        </div>
                                        
                                        <div class="col-12 col-lg-6">
                                            <label class="noIcon">
                                                {{getMyLanguage("compliance","compliance.lbl.taxNumberCompany")}}
                                                <input type="text" class="form" :class="{error: errorsValidate.taxNumber}" v-model="dataCompliance.taxNumber">
                                            </label>
                                        </div>

                                        <div class="col-12">
                                            <label class="observations-data noIcon">
                                                {{getMyLanguage("compliance","compliance.company.observations")}}
                                                <span class="cont">{{dataCompliance.observations.length}}/250</span>
                                                <textarea class="form" v-model="dataCompliance.observations" maxlength="250" :placeholder='getMyLanguage("compliance","compliance.placeholder.additional-address")'></textarea>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- DOCUMENTS -->
                            <div class="box-registration box-documents">
                                <div id="Docs"></div>
                                <div class="formBox">

                                    <h3 class="subtitle">{{getMyLanguage("compliance","compliance.company.title-docs")}}</h3>
                                    <p class="obs-mandatory">{{getMyLanguage("compliance","compliance.company.obs-mandatory")}}</p>

                                    <div class="list-docs" v-for="(listDocs, index) in dataAccount.documents" :key="index">
                                        <div class="doc" v-if='listDocs.accountDocumentMandatory || listDocs.attachmentType != "none"' :class="listDocs.accountDocumentStatusExtra">
                                            <div class="doc-inf" :class="{center: listDocs.attachment === null}">
                                                <div class="doc-type-status">
                                                    <div class="doc-name">
                                                        <small :class="{mandatory: listDocs.accountDocumentMandatory}">{{ getMyLanguage("compliance","compliance.document-type." + listDocs.accountDocumentTypeCode) }}</small>
                                                        <span class="status" :class="listDocs.accountDocumentStatusExtra">{{ getMyLanguage("compliance","compliance.document-status." + listDocs.accountDocumentStatusExtra) }}</span>
                                                    </div>
                                                    
                                                    <a :href="`${VUE_APP_INTERNATIONAL_API}api/v1/sales/compliance/kyc/document/${listDocs.accountDocumentId}`" target="_blank">{{listDocs.fileName}}</a>
                                                </div>
                                                

                                                <!-- <a class="view"></a> -->
                                            </div>
                                            <div class="doc-observations" v-if="listDocs.observations != null">
                                                {{listDocs.observations}}
                                            </div>
                                        </div>
                                    </div>


                                    <!-- UPLOAD DOCS -->
                                    <div class="upload-docs" v-if='(dataAccount.backOfficeStatus === "None" || (dataAccount.backOfficeStatus === "Pending" && !dataAccount.documentsValidated))'>
                                        <p><strong>{{getMyLanguage("compliance","compliance.company.upload-docs")}}</strong></p>

                                        <select class="form" v-model="dataSendDoc.accountDocumentTypeCode">
                                            <option value="" selected disabled hidden>{{getMyLanguage("compliance","compliance.company.selectDocumentType")}}</option>
                                            <option v-for='(listDocumentType,index) in dataAccount.documents.filter(typeCode => typeCode.accountDocumentStatus != "Accepted")' :key="index" :value="listDocumentType.accountDocumentTypeCode">{{ getMyLanguage("compliance","compliance.document-type." + listDocumentType.accountDocumentTypeCode) }}</option>
                                        </select>

                                        <label for="LblUploadFile" class="btn-upload" :class='uploadFile.selected'>
                                            <span class="send" v-if="!uploadFile.selected">{{getMyLanguage("client-space","instructions.receipt.upload.select-file")}}</span>
                                            <span class="selected" v-if="uploadFile.selected">{{getMyLanguage("client-space","instructions.receipt.upload.selected-file")}}</span>

                                            <input type="file" id="LblUploadFile" ref="file" @change="uploadDocument()">
                                        </label>

                                        <div class="observations-upload noIcon">
                                            <label>
                                                {{getMyLanguage("compliance","compliance.company.observations")}}
                                                <span class="cont">{{dataSendDoc.Observations.length}}/150</span>
                                            </label>
                                            <textarea class="form" v-model="dataSendDoc.Observations" maxlength="150" :placeholder='getMyLanguage("compliance","compliance.placeholder.additional-address")'></textarea>
                                        </div>

                                        <div class="box-error" v-if="showContent && errorsPost.upload != false">
                                            <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                            <!-- <p>{{ getMyLanguage("box-error", errorsPost.dataCompliance) }}</p> -->
                                            <div class="cod-error">
                                                Cód.: {{errorsPost.upload}}
                                            </div>
                                        </div>

                                        <div class="btn-primary btn-disable" v-if='uploadFile.selected != "fileSelected" || dataSendDoc.accountDocumentTypeCode === ""'>{{getMyLanguage("client-space","instructions.receipt.send")}}</div>
                                        <div class="btn-primary" v-if='uploadFile.selected === "fileSelected" && !errorsPost.upload && !uploadFile.loadUpload && dataSendDoc.accountDocumentTypeCode != ""' @click="uploadDoc()">{{getMyLanguage("client-space","instructions.receipt.send")}}</div>
                                        <div class="btn-primary load" v-if="uploadFile.loadUpload"></div>
                                    </div>
                            
                                    <!-- MSG DOCS -->
                                    <div class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg." + dataAccount.backOfficeStatus)'></div>
                                    <!-- <div v-if='dataAccount.backOfficeStatus != "Accepted" && !dataAccount.documentsValidated' class="msg" :class="dataAccount.backOfficeStatus" v-html='getMyLanguage("compliance","compliance.msg." + dataAccount.backOfficeStatus)'></div>
                                    <div v-if='dataAccount.backOfficeStatus === "Pending" && dataAccount.documentsValidated' class="msg Review" v-html='getMyLanguage("compliance","compliance.msg.waitingAccept")'></div> -->
                                </div>
                            </div>



                            <!-- SEND COMPLIANCE -->
                            <!-- <div class="send-compliance" id="#review" v-if='dataAccount.backOfficeStatus === "None" || dataAccount.backOfficeStatus != "Pending"'> -->
                            <div class="send-compliance" id="#review" v-if='dataAccount.documentsValidated'>
                                <div class="accepts">
                                    <div class="form-group-check">
                                        <input type="checkbox" id="acceptTermsLG" class="form-check" v-model="accept.terms">
                                        <label for="acceptTermsLG" class="form-check-label"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms") }}</small></label>
                                        <a :href="`https://dne.international/${flagSelected}/terms-and-conditions`" target="_blank" class="lnk-terms-group-check">{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms.lnk") }}</a>
                                    </div>
                                    
                                    <div class="form-group-check">
                                        <input type="checkbox" id="acceptNotify" class="form-check" v-model="accept.notify">
                                        <label for="acceptNotify" class="form-check-label"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-notify") }}</small></label>
                                    </div>
                                    
                                    <div class="form-group-check">
                                        <input type="checkbox" id="acceptGDPR" class="form-check" v-model="accept.gdpr">
                                        <label for="acceptGDPR" class="form-check-label"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-gdpr") }}</small></label>
                                    </div>
                                </div>

                                <div class="row" v-if="errorsValidate.fullName || errorsValidate.taxNumber">
                                    <div class="col-12">
                                        <div class="errorsForm">
                                            {{getMyLanguage("compliance","compliance.errorValidateForm")}}
                                        </div>
                                    </div>
                                </div>

                                <div class="box-error" v-if="showContent && errorsPost.dataCompliance != false">
                                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                    <p>{{ getMyLanguage("box-error", errorsPost.dataCompliance) }}</p>
                                    <div class="cod-error">
                                        Cód.: {{errorsPost.dataCompliance}}
                                    </div>
                                </div>

                                <div class="msg-extra" v-if="(!dataAccount.partnersValidated || !dataAccount.documentsValidated) && accept.terms && accept.notify && accept.gdpr">
                                    <p v-if="!dataAccount.partnersValidated"><small>*** {{getMyLanguage("compliance","compliance.msg.kyc.incomplete")}}</small></p>
                                    <p v-if="!dataAccount.documentsValidated"><small>*** {{getMyLanguage("compliance","compliance.msg." + dataAccount.backOfficeStatus)}}</small></p>
                                </div>

                                <div id="btnRegister" v-if="(!dataAccount.partnersValidated || !dataAccount.documentsValidated) || !accept.terms || !accept.notify || !accept.gdpr" class="btn-primary btn-disable">{{ getMyLanguage("compliance", "compliance.btn-send-review") }}</div>
                                <div id="btnRegister" v-if="(dataAccount.partnersValidated && dataAccount.documentsValidated) && accept.terms && accept.notify && accept.gdpr && !load" @click="validateForm()" class="btn-primary">{{ getMyLanguage("compliance", "compliance.btn-send-review") }}</div>

                                <div class="btn-primary load" v-if="load"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </ion-content>
            <FooterApp />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import HeaderApp from '@/components/dneplatform/HeaderApp.vue';
    import FooterApp from '@/components/dneplatform/FooterApp.vue';

    import moment from 'moment';

    // import apiPlatform from '@/config/apiPlatform.js';
    import apiInternational from '@/config/apiInternational.js';

    import { handleError, error404, errorGeneral, errorMsg, errorBlockPurchase } from '@/services/errorHandler.js'

    export default {
        components: {
            IonApp,IonPage, IonContent,
            HeaderApp,
            FooterApp
        },
        data(){
            return{
                VUE_APP_INTERNATIONAL_API: process.env.VUE_APP_INTERNATIONAL_API,

                flagSelected: {},
                showContent: false,
                load: false,
                dataAccount: [],
                dataDocuments: [],
                
                errors: {
                    errorPost: false,
                    error_404: false,
                    error_general: false,
                    error_blockPurchase: false,
                    error_msg: ""
                },
                
                errorsGet: {
                    dataAccount: false
                },
                errorsPost: {
                    dataCompliance: false,
                    upload: false
                },

                dataSendDoc: {
                    accountDocumentTypeCode: '',
                    Observations: ''
                },
                hasPending: '',
                
                // dataCountry: [],
                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryFlag: false,

                accept: {
                    terms: false,
                    notify: false,
                    gdpr: false
                },

                dataCompliance: {},

                uploadFile: {
                    File: null,
                    selected: false,
                    documentType: "1",
                    loadUpload: false
                },

                modalNoConfirm: {
                    modal: false,
                    load: false,
                    send: false
                },
                noConfirm: {
                    error: "Address error",
                    msg: ""
                },

                errorsValidate: {
                    taxNumber: false,
                    taxNumberIssuerCountry: false
                }
            }
        },    
        
        
        async mounted(){
            this.flagUrl();

            await apiInternational.get('/api/v1/international/compliance/company')
            .then(response => {
                this.dataAccount = response.data

                this.dataCompliance.fullName = this.dataAccount.company.fullName
                this.dataCompliance.taxNumber = this.dataAccount.company.taxNumber
                this.dataCompliance.observations = this.dataAccount.company.observations

                // this.hasPending = this.dataModal.documents.filter(typeCode => typeCode.accountDocumentStatusExtra != "Accepted")

                // if ( this.hasPending.length ){
                //     this.dataSendDoc.accountDocumentTypeCode = this.dataAccount.documents.filter(typeCode => typeCode.accountDocumentStatusExtra != "Accepted")[0].accountDocumentTypeCode
                // }
            })
            .catch(error => {
                setTimeout(() => {
                    this.showContent = true
                }, 500);

                handleError(error, this.flagSelected);
                
                this.errors.error_404 = error404
                this.errors.error_general = errorGeneral
                this.errors.error_blockPurchase = errorBlockPurchase
                this.errors.error_msg = errorMsg
            })

            
            // const resumeClientCountry = await apiPlatform.get('api/v1/platform/country')
            // this.dataCountry = resumeClientCountry.data

            this.showContent = true
        },


        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            dateTime(value) {
                return moment(value).locale(this.flagSelected).format('ll');
            },

            validateForm(){
                if ( this.dataCompliance.fullName === null || this.dataCompliance.fullName === "" ){
                    this.errorsValidate.fullName = true
                } else{
                    this.errorsValidate.fullName = false
                }
                
                if ( this.dataCompliance.taxNumber === null || this.dataCompliance.taxNumber === "" ){
                    this.errorsValidate.taxNumber = true
                } else{
                    this.errorsValidate.taxNumber = false
                }
                
                if ( this.errorsValidate.fullName === false && this.errorsValidate.taxNumber === false ) {
                    this.register()
                }
            },

            register(){
                this.load = true
                const compliance = this.dataCompliance;

                apiInternational.post('/api/v1/international/compliance/company', compliance)
                .then(response => {
                    this.registerId = response.data.id;

                    this.toastSuccess()

                    setTimeout(() => {
                        this.load = false
                    }, 3000);

                    // this.$toast.success(this.getMyLanguage("compliance","compliance.toast.saveData"));
                    // setTimeout(this.$toast.clear, 6000)

                    // this.dataAccount = response.data
                    // window.location.href = '#top'
                })
                .catch(error => {
                    // this.errorMessage = error.message;

                    // this.errorsGet.dataAccount = error.response.data
                    this.errorsGet.dataAccount = error.response.status

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            },

            uploadDocument() {
                this.uploadFile.File = this.$refs.file.files[0];
                console.log(this.uploadFile)

                if ( this.uploadFile.File != null ){
                    this.uploadFile.selected = "fileSelected"
                }
            },

            uploadDoc(){
                this.uploadFile.loadUpload = true
                const formData = new FormData();
                formData.append('File', this.uploadFile.File);
                formData.append('AccountDocumentTypeId', this.dataSendDoc.accountDocumentTypeId);
                formData.append('Type', this.dataSendDoc.accountDocumentTypeCode);
                formData.append('Observations', this.dataSendDoc.Observations);
                const token = localStorage.getItem('accessToken');
                const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}` };
                apiInternational.post("/api/v1/international/compliance/company/document", formData, { headers })
                .then(response => {
                    console.log(response)
                    // console.clear()
                    this.dataAccount = response.data

                    this.uploadFile.File = null
                    this.uploadFile.selected = false
                    this.uploadFile.loadUpload = false
                    this.dataSendDoc.Observations = ""

                    this.toastUploadSuccess()
                    // this.$toast.success(this.getMyLanguage("compliance","compliance.toast.sendDoc"));
                    // setTimeout(this.$toast.clear, 6000)

                    if ( this.dataAccount.documentsValidated ){
                        // window.location.href = `/${this.flagSelected}/account/compliance-company/#Docs`

                        this.uploadFile.loadUpload = false
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.errorsPost.upload = "Upload"
                    
                    // setTimeout(() => {
                    //     this.load = false
                    // }, 500);

                    setTimeout(() => {
                        this.errorsPost.upload = false
                        this.uploadFile.loadUpload = false
                    }, 10000);
                })
            },


            async toastSuccess() {
                const toast = await toastController
                    .create({
                    message: this.getMyLanguage("compliance","compliance.toast.saveData"),
                    color: "success",
                    duration: 2000
                    })
                return toast.present();
            },
            
            async toastUploadSuccess() {
                const toast = await toastController
                    .create({
                    message: this.getMyLanguage("compliance","compliance.toast.sendDoc"),
                    color: "success",
                    duration: 2000
                    })
                return toast.present();
            },

        },
    }
</script>


<style scoped>
    .msg{padding: 16px; margin-bottom: 20px; background: url('~@/assets/images/bg-25.svg') no-repeat; background-size: cover; border-radius: 12px; text-align: center; font-size: 13px; font-family: var(--font-primary-semibold);}
    .msg.Review{position: relative; z-index: 20; background: url('~@/assets/images/bg-waiting.svg') no-repeat; background-size: cover; color: var(--color-text-secondary);}
    .msg.Rejected{position: relative; z-index: 20; background: url('~@/assets/images/bg-red.png') no-repeat; background-size: cover; color: var(--color-text-tertiary);}
    .msg.None, .msg.Pending{position: relative; z-index: 20; background: url('~@/assets/images/bg-pending.svg') no-repeat; background-size: cover; color: var(--color-text-tertiary);}
    .msg.Accepted{position: relative; z-index: 20; background: url('~@/assets/images/bg-green.png') no-repeat; background-size: cover; color: var(--color-text-tertiary);}
    /* .msg.msg-success{background: rgba(74,174,87,0.1); color: var(--color-primary);} */
    .msg p{margin: 0;}
    .msg h3{font-size: 28px;}

    .box-registration{width: 100%; border-radius: 12px; background: var(--color-text-tertiary); margin-top: 30px;}
    .box-registration label{font-size: 13px;}
    .box-registration .headerBox{background: var(--color-secondary); padding: 15px 10px; text-align: center; position: relative;}
    .box-registration .headerBox p{color: var(--color-text-tertiary); font-family: var(--font-primary-semibold); margin: 0;}
    .box-registration .headerBox p span{opacity: .8;}
    .box-registration .headerBox:after{content: ''; display: block; position: absolute; left: 50%; bottom: -10px; transform: translateX(-50%); width: 0; height: 0; border-left: 15px solid transparent; border-right: 15px solid transparent; border-top: 10px solid var(--color-secondary);}

    .cont{position: absolute; right: 12px; top: 16px; font-size: 10px;}
    
    .accepts{margin: 40px 0 30px 0;}
    .accepts .form-group-check{display: flex; align-items: center;}
    .accepts .lnk-terms-group-check{text-decoration: underline; color: var(--color-primary); font-size: 11px; line-height: 16px; padding-left: 4px; font-weight: 600;}
    .accepts + .btn-primary{margin-bottom: 50px;}

    .formBox{padding: 20px 15px;}
    .formBox h3{font-size: 16px; color: var(--color-primary); margin: 0 0 20px 0;}
    .formBox h3.subtitle{display: block; padding-bottom: 7px; position: relative;}
    .formBox h3.subtitle:after{content: ''; display: block; width: 240px; height: 4px; background: #eee; position: absolute; left: 0; bottom: 0;}
    .formBox label:not(.form-check-label), .formBox .autocomplete{position: relative; margin-bottom: 10px; display: block;}
    .formBox label:not(.form-check-label) span, .formBox .autocomplete span{display: block; padding-left: 40px;}
    .formBox label:not(.form-check-label) span:before, .formBox .autocomplete span:before{content: ''; display: block; width: 16px; height: 16px; background-size: 16px auto; position: absolute; left: 12px;}
    .formBox label.noIcon .form{padding-left: 15px;}
    .formBox label.noIcon span:before{display: none;}
    .formBox label span img{width: 30px; margin-right: 10px;}
    .formBox label:not(.noIcon) span img{margin-left: -30px; position: relative; z-index: 10;}
    .formBox input, .formBox select{margin-bottom: 0;}
    .formBox .formDisabled{border-color: #eee; color: #999; cursor: not-allowed; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; display: flex !important; align-items: center; overflow: hidden;}
    .formBox .formDisabled span{line-height: 14px; padding-left: 0 !important;}
    .formBox .formDisabled small{font-size: 12px; line-height: 12px;}
    
    .formBox .formFullName .form:before{background: url(~@/assets/images/icons/form-register-fullname.svg) no-repeat center;}
    .formBox .formYourEmail .form:before{background: url(~@/assets/images/icons/form-register-your-email.svg) no-repeat center;}
    .formBox .formResidenceCountry .form:before{background: url(~@/assets/images/icons/form-register-residence-country.svg) no-repeat center;}
    .formBox .formPhone .form:before{background: url(~@/assets/images/icons/form-register-phone.svg) no-repeat center;}

    .formBox .formIdNumber span:first-child{padding-left: 0;}
    .formBox .formIdNumber .form{padding-left: 40px;}
    .formBox .formIdNumber .form:before{background: url(~@/assets/images/icons/form-register-idnumber.svg) no-repeat center;}
    
    .formBox .formExpirationDate .form{padding-left: 40px;}
    .formBox .formExpirationDate .form:before{background: url(~@/assets/images/icons/form-register-expiration.svg) no-repeat center;}
    
    .formBox .formCountry .form{padding-left: 40px;}
    .formBox .formCountry .form:before{background: url(~@/assets/images/icons/form-register-residence-country.svg) no-repeat center;}
    
    .formBox .formPersonDOB .form{padding-left: 40px;}
    .formBox .formPersonDOB .form:before{background: url(~@/assets/images/icons/form-register-dob.svg) no-repeat center;}
    
    .formBox .formTaxNumber .form{padding-left: 40px; border: 1px solid #ccc;}
    .formBox .formTaxNumber:before{content: ''; display: block; position: absolute; z-index: 10; bottom: 12px; left: 12px; width: 16px; height: 16px; background: url(~@/assets/images/icons/form-register-taxnumber.svg) no-repeat center; background-size: 16px auto;}

    .formBox .formStatusKYC .form{padding-left: 40px; border: 1px solid #ccc;}
    .formBox .formStatusKYC:before{content: ''; display: block; position: absolute; z-index: 10; top: 34px; left: 12px; width: 16px; height: 16px;}
    
    .formBox .formStatusKYC.pending span,
    .formBox .formStatusKYC.pending small{color: var(--color-pending);}
    .formBox .formStatusKYC.pending:before{background: url(~@/assets/images/icons/form-register-status-kyc-pending.svg) no-repeat center; background-size: 16px auto;}
    
    .formBox .formStatusKYC.active span,
    .formBox .formStatusKYC.active small{color: var(--color-secondary);}
    .formBox .formStatusKYC.active:before{background: url(~@/assets/images/icons/form-register-status-kyc-active.svg) no-repeat center; background-size: 16px auto;}

    .formBox .formStatusKYC small{display: block; margin-top: -10px;}

    .formBox .formIssuerCountry .form{padding-left: 40px; border: 1px solid #ccc;}
    .formBox .formIssuerCountry:before{content: ''; display: block; position: absolute; z-index: 10; bottom: 12px; left: 12px; width: 16px; height: 16px; background: url(~@/assets/images/icons/form-register-residence-country.svg) no-repeat center; background-size: 16px auto;}
    
    .formBox .formGender .form{padding-left: 40px;}
    .formBox .formGender .form:before{background: url(~@/assets/images/icons/form-register-gender.svg) no-repeat center;}

    .formBox .formAddress .form{padding: .75rem 1rem .75rem 40px; line-height: 22px; height: 72px; margin-bottom: 0;}
    .formBox .formAddress .form:before{background: url(~@/assets/images/icons/form-register-address.svg) no-repeat center; top: 43px !important; transform: translateY(0) !important; height: 33px !important;}
    
    .formBox .d-flex{justify-content: space-between; align-items: center;}
    .formBox .btn-primary{padding: 0 20px;}

    .btn-cancel-compliance{display: flex; justify-content: center; width: 100%;}
    .btn-cancel-compliance span{display: inline-block; font-size: 14px; color: var(--color-cancel); margin: 30px auto 50px; padding: 0 20px; height: 40px; line-height: 40px; border-radius: 12px; background: rgba(0,0,0,0.05); cursor: pointer; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}


    .additional-address-data, .observations-data{padding: 12px; margin-bottom: 0 !important; background: rgba(235, 103, 51 ,0.1); border-radius: 12px; margin-top: 30px;}
    .additional-address-data .cont, .observations-data .cont{position: absolute; right: 12px; top: 12px;}
    .additional-address-data .form, .observations-data .form{padding: 8px 12px !important; margin: 10px 0 0 0; line-height: 18px; height: 72px;}

    @media (max-width: 360px){
        .additional-address-data .cont, .observations-data .cont{display: none;}
    }


    .box-documents{margin-bottom: 50px; position: relative;}
    .box-documents #Docs{position: absolute; top: -100px;}
    .box-documents .obs-mandatory{margin: -15px 0 35px 0; font-size: 75%; color: var(--color-pending);}
    .box-documents .upload-docs{display: flex; flex-direction: column; align-items: center; padding: 32px 16px; margin-top: 50px; border-radius: 16px; background: #eee; border: 1px solid #ddd;}
    .box-documents .upload-docs .form{max-width: 400px; margin-bottom: 20px;}
    .box-documents .upload-docs .observations-upload{width: 100%; max-width: 600px; padding: 12px; margin-bottom: 20px !important; background: rgba(235, 103, 51 ,0.1); border-radius: 12px; margin-top: 30px;}
    .box-documents .upload-docs .observations-upload label{margin-bottom: 0;}
    .box-documents .upload-docs .observations-upload .cont{position: absolute; right: 12px; top: 1px;}
    .box-documents .upload-docs .observations-upload .form{width: 100%; max-width: 100%; height: 72px; padding: .75rem 1rem; margin: 10px 0 0 0; line-height: 16px;}
    .box-documents .upload-docs .btn-primary{width: auto; min-width: 150px; margin-top: 0 !important;}
    .box-documents .upload-docs .send, .box-documents .upload-docs .selected{padding-left: 0;}
    .box-documents .list-docs p{text-align: center; margin-bottom: 20px}
    
    .box-documents .list-docs .doc{-webkit-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -moz-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); border-radius: 12px; background: var(--color-text-tertiary); margin-bottom: 10px; padding: 12px; position: relative; border: 1px solid #fff;}
    .box-documents .list-docs .doc.Rejected .doc-inf .doc-type-status .doc-name a{color: var(--color-cancel);}
    .box-documents .list-docs .doc.NotLoaded .doc-inf .doc-type-status .doc-name a{color: var(--color-waiting);}
    .box-documents .list-docs .doc.Loaded .doc-inf .doc-type-status .doc-name a{color: var(--color-pending);}
    .box-documents .list-docs .doc.Accepted .doc-inf .doc-type-status .doc-name a{color: var(--color-secondary);}
    
    .box-documents .list-docs .doc .doc-inf.center{align-items: center;}
    .box-documents .list-docs .doc .doc-inf .doc-type-status{width: 100%;}
    .box-documents .list-docs .doc .doc-inf .doc-type-status .doc-name{display: flex; justify-content: space-between;}
    .box-documents .list-docs .doc .doc-inf .doc-type-status .doc-name small{display: flex; color: #666; line-height: 16px; position: relative;}
    .box-documents .list-docs .doc .doc-inf .doc-type-status .doc-name small:before{content: ''; display: none; position: absolute; left: -8px; top: -2px; width: 16px; height: 16px; background: url("~@/assets/images/icons/mandatory.svg") no-repeat left center; background-size: 6px auto;}
    .box-documents .list-docs .doc .doc-inf .doc-type-status .doc-name small.mandatory:before{display: block;}

    .box-documents .list-docs .doc .doc-inf .doc-type-status .status{display: block; white-space: nowrap; padding: 0 8px; font-size: 10px; line-height: 22px; height: 22px; color: var(--color-text-tertiary); border-radius: 6px;}
    .box-documents .list-docs .doc .doc-inf .doc-type-status .status.Rejected{background-color: var(--color-cancel);}
    .box-documents .list-docs .doc .doc-inf .doc-type-status .status.NotLoaded{background-color: var(--color-waiting); color: #555;}
    .box-documents .list-docs .doc .doc-inf .doc-type-status .status.Loaded{background-color: var(--color-pending);}
    .box-documents .list-docs .doc .doc-inf .doc-type-status .status.Accepted{background-color: var(--color-secondary);}
    
    .box-documents .list-docs .doc .doc-inf .doc-type-status a{color: var(--color-secondary); font-weight: 600; font-size: 14px;}
    
    .box-documents .list-docs .doc .doc-observations{font-size: 90%; margin-top: 5px; padding: 5px 8px; color: #777; background: #f5f5f5;}
    .box-documents .list-docs .doc .view{width: 30px; height: 24px; margin-left: 10px; background: url('~@/assets/images/icons/view.svg') no-repeat center; background-size: auto 24px; cursor: pointer;}
    .box-documents .msg{margin: 40px 0 0 0;}

    @media (max-width: 360px){
        .box-documents .upload-docs .observations-upload .cont{display: none;}
    }

    .msg-extra{margin: 0 0 30px 0; background: #eee; border: 1px solid var(--color-pending); padding: 10px 15px; border-radius: 12px;}
    .msg-extra p{font-size: 18px; line-height: 20px; font-weight: 600; text-align: center;}
    .msg-extra p:last-child{margin-bottom: 0;}
    .msg-extra + .btn-primary{margin-bottom: 50px;}

    .btn-primary.load{background: url('~@/assets/images/icons/loading-secondary.svg') no-repeat center transparent; background-size: auto 100%;}
    .btn-primary.load:after{display: none;}
</style>