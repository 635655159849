<template>
    <ion-app :scroll-events="true" class="token">
        <ion-page>
            <div class="header-step">
                <HeaderApp />
            </div>

            <ion-content class="token-content">
                <div class="ion-padding" v-if="showContent && errors.error_blockPurchase">
                    <h2 class="title-primary">DNE <span>T</span>oken</h2>
                    <div class="box-error">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error",errors.error_msg) }}</p>
                    </div>
                </div>

                <div class="ion-padding" v-if="showContent && (errors.error_404 || errors.error_general)">
                    <div class="box-error">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                        <div class="cod-error">{{ getMyLanguage("box-error",errors.error_msg) }}</div>
                    </div>
                </div>

                <div class="load-blue" v-if="!showContent"></div>

                <div class="content" v-if="showContent && (!errors.error_404 && !errors.error_general && !errors.error_blockPurchase)">

                    <div class="no-has-quota phases" v-if="showContent && dataTokenSale.quota.available < dataTokenSale.quota.min_amount">
                        <h2 class="title-primary">DNE <span>T</span>oken</h2>
                        
                        <div class="box-success">
                            <p>{{getMyLanguage("token","purchase-limit-01")}}</p>
                            <p>{{getMyLanguage("token","purchase-limit-02")}}</p>
                        </div>

                        <Phases />
                    </div>

                    <!-- PHASES -->
                    <div class="ion-padding phases" v-if="showContent && dataProfile.account_status != 'Verified'">
                        <h1 class="title-primary">DNE <span>T</span>oken</h1>
                        <h3 class="alert-phase">{{ getMyLanguage("token", "token.purchase.msg-phases") }}</h3>

                        <Phases />
                    </div>

                    <div class="phase-current" v-if="showContent && dataProfile.account_status != 'Verified'">
                        <h4>{{dataTokenSale.phase.phase_name}}</h4>
                        <p><strong>{{dataTokenSale.phase.product_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}} <span v-if="this.dataTokenSale.phase.phase_discount > 0">({{this.dataTokenSale.phase.phase_discount}}% OFF)</span><span v-if="this.dataTokenSale.phase.phase_bonus > 0"> + {{dataTokenSale.phase.phase_bonus}}% {{dataTranslation.phaseBonus}}</span></strong></p>
                        <div class="btn-showMore" @click="this.modalPhase = true"></div>
                    </div>

                    <div class="box kyc-pending" v-if="showContent && dataProfile.account_status != 'Verified'">
                        <p><strong>{{ getMyLanguage("token", "token.kycPending.alert") }}</strong></p>
                        <div class="kyc">
                            <img src="@/assets/images/icons/kyc-pending.svg">
                            <div>
                                <h6>{{ getMyLanguage("token", "token.kycPending.title") }}</h6>
                                <p>{{ getMyLanguage("token", "token.kycPending.msg") }}</p>
                                <a href="#" target="_blank" class="btn-secondary">{{ getMyLanguage("token", "token.kycPending.btn") }}</a>
                            </div>
                        </div>
                    </div>



                    <!-- BUY -->                
                    <div class="ion-padding" v-if="showContent && (dataTokenSale.quota.available >= dataTokenSale.quota.min_amount) && contentShow.buyPayment && dataProfile.account_status === 'Verified'">
                        <h1 class="title-primary">DNE <span>T</span>oken</h1>
                        <div class="phase-current">
                            <h4>{{dataTokenSale.phase.phase_name}}</h4>
                            <p><strong>{{dataTokenSale.phase.purchase_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}} <span v-if="dataTokenSale.phase.phase_discount > 0">({{dataTokenSale.phase.phase_discount}}% OFF)</span><span v-if="dataTokenSale.phase.phase_bonus > 0"> + {{dataTokenSale.phase.phase_bonus}}% {{dataTranslation.phaseBonus}}</span></strong></p>
                            <div class="btn-showMore" @click="modalPhase = true"></div>
                        </div>

                        <div class="box buy-content">
                            <img src="@/assets/images/client-space/dne-coin.png" alt="DNE Token" class="dne-coins">
                            <h6>{{ getMyLanguage("token", "token.buyContent.title") }}</h6>

                            <div class="quantityBuy">
                                <div class="quantity">
                                    <div class="tooltip tooltip-less">{{ getMyLanguage("token", "token.buyContent.tooltip.min") }} 1 {{ getMyLanguage("token", "token.buyContent.tooltip.package") }}</div>
                                    <div class="tooltip tooltip-more">{{ getMyLanguage("token", "token.buyContent.tooltip.max") }} 10 {{ getMyLanguage("token", "token.buyContent.tooltip.packages") }}</div>

                                    <div class="btnQtd btn-less" :class="{disable: (dataPurchase.quantity === dataTokenSale.quota.min_amount)}" v-if="disableQuantity === false" @click="upSummary(dataPurchase.quantity --)" @touchstart="quantityLess()" @touchend="quantityStop()" @mousedown="quantityLess()" @mouseup="quantityStop()" @mouseout="quantityStop()">-</div>
                                    <div class="form" v-text="dataPurchase.quantity"></div>
                                    <div class="btnQtd btn-more" :class="{disable: (dataPurchase.quantity === dataTokenSale.quota.available)}" v-if="disableQuantity === false" @click="upSummary(dataPurchase.quantity ++)" @touchstart="quantityMore()" @touchend="quantityStop()" @mousedown="quantityMore()" @mouseup="quantityStop()" @mouseout="quantityStop()">+</div>
                                </div>
                            </div>

                            <div class="quantityBuyRange">
                                <div class="purchased-packages">
                                    {{getMyLanguage("token","token.purchased-packages")}}
                                    {{dataTokenSale.quota.used}}
                                </div>
                                <input type="range" :min="dataTokenSale.quota.min_amount" :max="dataTokenSale.quota.available" step="1" v-model="dataPurchase.quantity" @touchend="upSummary(dataPurchase.quantity)"  @mouseup="upSummary(dataPurchase.quantity)">
                                <div class="quantity-min-max">
                                    <span class="minRange">{{dataTokenSale.quota.min_amount}}</span>
                                    <span class="maxRange">{{dataTokenSale.quota.available}}</span>
                                </div>
                            </div>

                            <div class="alert-lost-token anime" v-if="(dataPurchase.quantity > (dataTokenSale.quota.available - dataTokenSale.quota.min_amount)) && dataPurchase.quantity != dataTokenSale.quota.available">
                                <p>
                                    <strong>{{dataTokenSale.quota.available - dataPurchase.quantity}} </strong>
                                    {{getMyLanguage("token","token.purchase.lost-token")}}
                                </p>
                            </div>

                            <div class="summary-mobile">
                                <table class="summary-mobile-cont">
                                    <tr>
                                        <td><p><small>{{ getMyLanguage("token", "token.purchase.order-summary.quantity-tokens") }}:</small></p></td>
                                        <td class="text-right"><strong class="asset-dne">{{summary.quantityTokenString}}</strong></td>
                                    </tr>
                                    
                                    <tr class="calculated-value promotion-price" v-if="dataTokenSale.phase.phase_bonus > 0">
                                        <td><p><small>{{ getMyLanguage("token", "token.purchase.order-summary.token-bonus-stake") }}:</small></p></td>
                                        <td class="text-right">
                                            <strong>
                                                <span class="asset-dne">+ {{dataPurchase.quantity * dataTokenSale.phase.purchase_bonus}}</span>
                                                <small>({{ getMyLanguage("token", "token.purchase.order-summary.total") }}:&nbsp;{{((dataPurchase.quantity * dataTokenSale.phase.purchase_bonus) + summary.quantityToken)}}&nbsp;{{ getMyLanguage("token", "token.purchase.order-summary.tokens") }})</small>
                                            </strong>
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <td><p><small>{{ getMyLanguage("token", "token.purchase.order-summary.original-price") }}:</small></p></td>
                                        <td class="text-right"><strong>{{summary.originalPriceString}}</strong></td>
                                    </tr>
                                    
                                    <tr class="promotion-price">
                                        <td><p><small>{{ getMyLanguage("token", "token.purchase.order-summary.promotion-price") }}:</small></p></td>
                                        <td class="text-right">
                                            <strong>
                                                <span>{{summary.priceFinalString}}</span>
                                                <small>({{ getMyLanguage("token", "token.purchase.order-summary.with-discount") }}{{this.dataTokenSale.phase.phase_discount}}{{ getMyLanguage("token", "token.purchase.order-summary.percent-off") }})</small>
                                            </strong>
                                        </td>
                                    </tr>
                                    
                                    <tr v-if='methodPay === "crypto"' class="calculated-value">
                                        <td class="no-border"><p class="value-ticker"><small>TETHER USDT:</small></p></td>
                                        <td class="no-border text-right">
                                            <strong>
                                                <span class="tether">{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}}</span>
                                                <small>({{summary.priceFinalString}} x {{ticker.toLocaleString("pt-PT", {style:"currency", currency:"USD"})}})</small>
                                            </strong>
                                        </td>
                                    </tr>

                                    <tr v-if='methodPay === "swapix"' class="calculated-value">
                                        <td class="no-border"><p class="value-ticker"><small>SWAPIX USDT:</small></p></td>
                                        <td class="no-border text-right">
                                            <strong>
                                                <span>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}}</span>
                                                <small>({{summary.priceFinalString}} x {{tickerPix.toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}})</small>
                                            </strong>
                                        </td>
                                    </tr>

                                    <tr v-if='methodPay === "crypto" || methodPay === "swapix"' class="quote-updates">
                                        <td colspan="2">
                                            <em>{{getMyLanguage("client-space","quote-updates")}}</em>
                                            <strong v-if="quoteTimer >= 60"> {{quoteTimerMinutes}} <span v-if="quoteTimerMinutes > 1">{{getMyLanguage("client-space","quote-updates.minutes")}}</span><span v-if="quoteTimerMinutes == 1">{{getMyLanguage("client-space","quote-updates.minute")}}</span></strong>
                                            <strong v-if="quoteTimer < 60"> {{quoteTimer}} {{getMyLanguage("client-space","quote-updates.seconds")}}</strong>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>


                        <!-- TOKEN PAYMENT -->
                        <div class="buy-payment" v-if="contentShow.buyPayment && dataProfile.account_status === 'Verified'">
                            <form>
                                <div class="buy-payment-cont" v-if="dataTokenSale.payment_types.length > 1">
                                    <h3 class="title-secondary">{{getMyLanguage("tokenPayment","tokenPayment.purchase.form.select-payment-method")}}</h3>

                                    <!-- SELECT PAYMENT TYPE -->
                                    <ul class="select-method-payment">
                                        <li v-for="(listPaymentType,index) in dataTokenSale.payment_types" :key="index" :class="[{hide: !listPaymentType.enabled},listPaymentType.paymentTypeCode]">
                                            <div class="select-payment-type" v-if="listPaymentType.enabled" :class='{active: methodPay === listPaymentType.paymentTypeCode}' @click='(methodPay = listPaymentType.paymentTypeCode),(ticker = listPaymentType.ticker),(dataPurchase.paymentTypeId = listPaymentType.paymentTypeId)'>
                                                <span v-if='listPaymentType.paymentTypeCode != "credit_card"'>{{listPaymentType.name}}</span>
                                                <span v-if='listPaymentType.paymentTypeCode === "credit_card"'>{{ getMyLanguage("client-space","instructions.paymentType.Credit Card") }}</span>
                                            </div>
                                        </li>
                                    </ul>


                                    <!-- CREDIT CARD -->
                                    <div class="method-pay-creditcard" v-if='methodPay === "credit_card"'>
                                        <div class="alert-card" v-html='getMyLanguage("client-space","client-space.purchase.form.credit-card.warning")'></div>

                                        <label>
                                            {{ getMyLanguage("tokenPayment", "tokenPayment.label.cardHolder") }}
                                            <input type="text" class="form" :class="{error: validateCard.cardHolder == false}" id="cardholder" v-model="dataPurchase.cardHolder" required>
                                        </label>
                                        
                                        <label>
                                            {{ getMyLanguage("tokenPayment", "tokenPayment.label.cardNumber") }}
                                            <input type="text" class="form" :class="{error: validateCard.cardNumber == false}" id="card-number" v-mask='"#### #### #### ####"' v-model="dataPurchase.cardNumber" placeholder="____ ____ ____ ____" required>
                                        </label>

                                        <div class="expiry-cvv">                            
                                            <label class="expiry">
                                                {{ getMyLanguage("tokenPayment", "tokenPayment.label.expiry") }}
                                                <input type="text" class="form" :class="{error: validateCard.expiry == false}" id="expiry" v-mask='"##/##"' v-model="dataPurchase.expiry" placeholder="__/__" required>
                                            </label>
                                            
                                            <label class="cvv">
                                                {{ getMyLanguage("tokenPayment", "tokenPayment.label.cvv") }}
                                                <input type="text" class="form" :class="{error: validateCard.cvv == false}" id="cvv" v-mask='"###"' v-model="dataPurchase.cvv" placeholder="___" required>
                                            </label>
                                        </div>
                                    </div>



                                    <!-- CHECKS -->
                                    <div class="method-pay-animation">
                                        
                                        <!-- CHECKS SWAPIX -->
                                        <div class="checks-suapix" v-if='methodPay === "swapix"'>
                                            <div class="form-group-check">
                                                <input type="checkbox" id="swapixCheck01" class="form-check" v-model="accepts.swapix01">
                                                <label for="swapixCheck01" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-swapix01") }}</small></label>
                                            </div>

                                            <div class="form-group-check">
                                                <input type="checkbox" id="swapixCheck02" class="form-check" v-model="accepts.swapix02">
                                                <label for="swapixCheck02" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-swapix02") }}</small></label>
                                            </div>
                                            
                                            <div class="form-group-check">
                                                <input type="checkbox" id="swapixCheck03" class="form-check" v-model="accepts.swapix03">
                                                <label for="swapixCheck03" class="form-check-label"><small>{{ getMyLanguage("client-space", "client-space.alert-swapix03") }}</small></label>
                                            </div>
                                        </div>

                                        <div class="form-group-check">
                                            <input type="checkbox" id="acceptNotify" class="form-check" v-model="accepts.Conditions">
                                            
                                            <label for="acceptNotify" class="form-check-label">
                                                <small>{{ getMyLanguage("tokenPayment", "tokenPayment.alert-reserve.accept") }}</small>
                                            </label>
                                        </div>

                                        <div class="form-group-check">
                                            <input type="checkbox" id="acceptTerms" class="form-check" v-model="accepts.Terms">
                                           
                                            <label for="acceptTerms" class="form-check-label">
                                                <small>
                                                    {{ getMyLanguage("tokenPayment", "client-space.confirm-accordance-terms") }}
                                                    </small>
                                            </label>
                                            
                                            <a :href="`${VUE_APP_INTERNATIONAL_URL}en/terms-and-conditions`" target="_blank" class="btn-learn" v-html='getMyLanguage("register","activate.read.terms")'></a>
                                        </div>


                                        <!-- OPEN MODAL CONFIRM -->
                                        <div v-if='methodPay != "swapix"'>
                                            <input type="hidden" v-model="dataPurchase.phaseProductId">

                                            <div class="btn-primary disabled" v-if='(methodPay != "crypto" && methodPay != "swapix") && (accepts.Conditions != true || accepts.Terms != true)'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{summary.priceFinalString}}</small></div>
                                            <div @click="openModalConfirm()" expand="block" class="btn-primary" v-if='(methodPay != "crypto" && methodPay != "swapix") && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{summary.priceFinalString}}</small></div>

                                            <div class="btn-primary btn-pay-crypto disabled" v-if='(methodPay === "crypto" || methodPay === "swapix") && (accepts.Conditions != true || accepts.Terms != true)'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT <small>({{summary.priceFinalString}})</small></small></div>
                                            <div @click="openModalConfirm()" expand="block" class="btn-primary btn-pay-crypto" v-if='(methodPay === "crypto" || methodPay === "swapix") && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT <small>({{summary.priceFinalString}})</small></small></div>
                                        </div>
                                        
                                        <div v-if='methodPay === "swapix"'>
                                            <div class="btn-primary btn-pay-crypto disabled" v-if='accepts.swapix01 != true || accepts.swapix02 != true || accepts.swapix03 != true || accepts.Conditions != true || accepts.Terms != true'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} (PIX)</small></div>
                                            <div @click="openModalConfirm()" expand="block" class="btn-primary btn-pay-crypto" v-if='accepts.swapix01 && accepts.swapix02 && accepts.swapix03 && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} (PIX)</small></div>
                                        </div>



                                        <!-- <div class="btn-primary disabled" v-if='(methodPay != "crypto" && methodPay != "swapix") && !stepBuy.load && (accepts.Conditions != true || accepts.Terms != true)'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{summary.priceFinalString}}</small></div>
                                        <div @click="openModalConfirm()" expand="block" class="btn-primary" v-if='(methodPay != "crypto" && methodPay != "swapix") && !stepBuy.load && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{summary.priceFinalString}}</small></div>

                                        <div class="btn-primary btn-pay-crypto disabled" v-if='(methodPay === "crypto" || methodPay === "swapix") && !stepBuy.load && (accepts.Conditions != true || accepts.Terms != true)'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT <small>({{summary.priceFinalString}})</small></small></div>
                                        <div @click="openModalConfirm()" expand="block" class="btn-primary btn-pay-crypto" v-if='(methodPay === "crypto" || methodPay === "swapix") && !stepBuy.load && accepts.Conditions && accepts.Terms'>{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} <small>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT <small>({{summary.priceFinalString}})</small></small></div> -->
                                    </div>


                                    <!-- PAYMENT CONDITIONS -->
                                    <div class="msg-payment-condition box">
                                        <div v-for="(listConditions,index) in dataTokenSale.payment_types" :key="index">
                                            <div v-if='methodPay === listConditions.paymentTypeCode' v-html='getMyLanguage("client-space",listConditions.paymentTypeCode + ".text")'></div>
                                        </div>
                                    </div>

                                    <div class="alert-reserve" v-html='getMyLanguage("tokenPayment", "tokenPayment.alert-reserve")'></div>
                                </div>
                            </form>


                            <div class="btn-center">
                                <a :href="`https://cdn.dneplatform.com/docs/WhitePaper-${flagSelected}.pdf`" target="_blank" class="btn-whitepaper">
                                    <span>Whitepaper</span>
                                    <small>PDF Download</small>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </ion-content>

            <FooterApp />


            <!-- MODAL CONFIRM -->
            <div class="modal modal-confirm" v-if="modalConfirm">
                <div class="box cont-modal" v-if="!threeDSecurity">
                    <div class="close-modal" @click="modalConfirm = false"></div>
                
                    <div class="scroll-vertical" ref="contract">
                        <h2 class="txt-center">PRE-SALE AGREEMENT<br>OF THE DNE TOKENS<small>(the "Agreement")</small></h2>
                            
                        <h3>Between</h3>
                        <p><strong>DNE International Sagl</strong>, (hereinafter the "Company"), a Limited Liability Company (registration number CHE-409.547.100) with registered office in headquartered in Via Cantonale 11, 6900 Lugano -  Switzerland (hereinafter referred as <strong>"Company"</strong>) and <strong>{{dataProfile.profile_full_name}}</strong> (hereinafter referred as to the <strong>"Buyer"</strong>)</p>
                        <p>(Company and Buyer hereinafter referred together as to the <strong>"Parties"</strong> and each a <strong>"Party"</strong>)</p>

                        <h3>Preamble</h3>
                        <p><strong>WHEREAS</strong>, the Company is offering payment tokens (hereinafter called <strong>"DNE Token"</strong>) both for pre-sale and subsequent token sale. The purpose of the DNE Token shall be to act as a mean of payment in the DNE Ecosystem. This <strong>"DNE Pre-Sale"</strong> shall take place between Sep 1st 2023 and October 31st 2023. The <strong>"ICO Token Sale"</strong> shall subsequently occur within the month of November 2023 (the first day of the ICO Token Sale hereinafter referred to as <strong>"ICO Starting Day"</strong>).</p>
                        <p><strong>WHEREAS</strong>, the Buyer with signing this Agreement declares that she/he has read carefully this Agreement before participating to the DNE Pre-sale;</p>
                        <p><strong>NOW THEREFORE</strong>, in consideration of the foregoing, the Parties hereto agree as follows:</p>

                        <h3>Project</h3>
                        <p>The "DNE" project arises in a context in which the "new economy" is increasingly a reality to endure. The emergence of new disruptive business models (rapid evolution and disruption) makes companies and professions obsolete very quickly.</p>
                        <p>The DNE Concept translates into connecting the world, leveraging the transformations caused by the "new economy", through the creation of a Global Ecosystem. The DNE Token will be accessible to everyone and the system enabler.</p>
                        <p>DNE Exchange, DNE Pay and DNE Market are entities of the DNE Ecosystem that integrate, reinforce, consolidate and, through the partnership with the Bank, link the cryptocurrency world to the financial world, putting an end to the separation of both worlds.</p>
                        <p>The company's purpose is to advise, promote and provide services in the field of innovative technologies with a focus on blockchain technologies and tokenomics, as well as the concept of decentralization. The company believes in Crypto technology, therefore decided to develop decentralized technology and started to implement the blockchain technology in a platform to create an users-network with the DNE Token instead of the others FIAT money.</p>
                        <p>The DNE Token is a payment token created on the Stellar Blockchain (Blockchain Stellar).</p>

                        <h3>Purpose of the Company</h3>
                        <p>The DNE Ecosystem aims to create a community of buyers, sellers and partners, at a Global level, creating and implementing a Digital Market solution, where the DNE Token will be the digital asset that drives the ecosystem.</p>

                        <h3>Pre-sale phase’s Conditions</h3>
                        <p>The Buyer expressly acknowledges, represents and warrants that she/he has carefully reviewed the terms of this Agreement and the white paper of DNE Token and fully understands all risks, costs and benefits associated with the purchase of DNE Token in the DNE Pre-Sale.</p>
                        <p>The Buyer purchasing DNE Token shall be aware and accepts that the proceeds collected in the DNE Pre-Sale phase will be used by the Company for financing and developing the DNE Ecosystem. The buyer is also informed and aware that the proceeds from the DNE Pre-sale could be used for financing and implementing the Initial Coin Offering (ICO) procedure, which will raise money for developing the DNE Ecosystem and she/he will receive the DNE Token only at the ICO Starting Day.</p>

                        <h3>Token Purchase and price</h3>
                        <p>The DNE Token is a digital unit. One lot of DNE TOKEN consists of 180 token units, at a price of €0,50 per unit.</p>
                        <p>During the DNE Pre-sale there will be available for sale the total amount of 30,000 Lots of 180 units of DNE Token, priced at €90 per Lot, with a 40% discount, corresponding to €54 per lot, as compensation for keeping lots in stake for 18 months.</p>
                        <p>Only Company’s users can buy DNE Tokens, under the following rights and conditions (i) DNE Pre-sale starts from September 1st 2023, which will last 60 days (sixty days); (ii) the DNE User will have the right to purchase the DNE Token with a 40% discount, with the obligation to keep the respective tokens for a period of 18 months vested, counting from the date of signature of this agreement referred to herein; (iii) the discount cannot be accumulated with others.</p>
                        <p>The minimum purchase order for each DNE User is 1.800 tokens, corresponding to 10 (ten) lots and a cumulative maximum of 250 (two hundred and fifty) lots, corresponding to 45.000 tokens for each purchasing person, or 500 (five hundred) lots for each entity, corresponding to 90.000 tokens.</p>
                        <p>The Buyer hereby declares to purchase {{dataPurchase.quantity}} lot of DNE Token at the price of € 90 per single lot for a total amount which is equal to € {{dataTokenSale.phase.purchase_price}} (the "Purchase Price").</p>
                        <p>The DNE Token purchase shall be facilitated in such a way, that the Purchase Price shall be deposited in and delivered to an Stellar compatible wallet (the "Wallet").</p>
                        <p>The Buyer also agrees to pay the Purchase Price in Euro ("€") to be deposited at Company Wallet address ___________ (the "Wallet") to be managed by the Company, within [Date]. Digital wallets will be made available at the start of the ICO.</p>
                        <p>The Buyer is aware that in order to be eligible to participate in the DNE Pre-sale, she/he must have a stellar blockchain compatible wallet that supports the Stellar token standard in order to receive any DNE Token.</p>
                        
                        <h3>Knowledge required</h3>
                        <p>The Buyer wanting to purchase DNE Token during the DNE Pre-Sale shall ensure that she/he understands and has significant experience of cryptocurrencies, blockchain technologies and services, and that she/he fully understands the risks associated with DNE Token, the DNE Pre-Sale as well as the mechanism related to the use of cryptocurrencies (incl. storage).</p>
                        <p>The Buyer is aware of the fact that she/he may suffer substantial losses with regard to the DNE Token Purchase up to the loss of the full Purchase Price.</p>

                        <h3>Risks</h3>
                        <p>Acquiring DNE Token involves various risks, in particular but not limited to the fact that the Company may not be able to launch its operations and develop its platform due to technical and/or business difficulties or any other difficulties which is not foreseen for the Company at the time of the DNE Pre-sale. Therefore, and prior to acquiring DNE Token, the Buyer should carefully consider the risks, costs, and benefits of acquiring DNE Token within the DNE Pre-Sale, and, if necessary, obtain independent advice in this regard. The Buyer who is not in the position to accept nor to understand the risks associated with the DNE Pre-Sale (incl. the risks related to the non- development of Company network and operations) should not acquire DNE Token, at this stage or later.</p>
                        <p>The Buyer declares and accepts that he/she understands that he/she is the sole responsible for the custody of the DNE Tokens received during a DNE Pre-sale. The DNE Token will be sent on the wallet indicated to the Company by the Buyer. The Buyer will assume all risks associated with the self-custody of the DNE Token.</p>
                        <p>The Company will in no way be responsible for any loss of the DNE Token deposited in the wallets indicated by the Buyer.</p>

                        <h3>Important Disclaimer</h3>
                        <p>By transferring XLM, the native cryptocurrency of the Stellar blockchain on which the DNE Ecosystem is deployed and/or receiving DNE Token in the DNE Pre-Sale, no form of partnership, joint venture or any similar relationship between Buyer and the Company and/or other individuals or entities involved with the deployment of the Company Platform and Project is established/ or created.</p>
                        <p>The Buyer understands and accepts that for the purpose of the development and execution of the business the Company receives the full amount of Euro raised in both the DNE Pre-Sale and the ICO Token Sale (the "ICO Proceeds"). The ICO Proceeds may be used to cover expenses, charges and other costs that may arise by the Company and/or its subcontractors as part of the development and execution of the Company Platform and the DNE Pre-Sale as well as the ICO Token Sale as such. It remains at Company’s sole discretion to decide how to allocate the ICO Proceeds.</p>
                        <p>This Agreement shall not and cannot be considered as an invitation to enter into an investment. It does not constitute or relate in any way nor should it be considered as an offering of securities in any jurisdiction. This Agreement does not include or contain any information or indication that might be considered as a recommendation or that might be used to base any investment decision. DNE Token is a payment token issued by the company and utilized on the DNE Ecosystem and is not intended to be used for any speculative purposes.</p>
                        <p>The Company will be an operative entity managing the DNE Ecosystem and DNE Token is only the payment token of the DNE Ecosystem. Therefore, the company is a financial intermediary according to Swiss Law and therefore subject to the Swiss Anti-Money Laundering Act.</p>
                        <p>Regulatory authorities are carefully scrutinizing businesses and operations associated with cryptocurrencies in the world. In that respect, regulatory measures, investigations or actions may affect the DNE Pre-Sale, Company business and even limit or prevent it from developing its operations in the future. The Buyer undertaking to acquire DNE Token must be aware that the Company business model may change or need to be modified and such modification may be made in the sole discretion of the Company. In such a case, the Buyer undertaking to acquire DNE Token acknowledges and understands that neither DNE Token nor any of its affiliates shall be held liable for any direct or indirect loss or damages caused by such changes.</p>
                        <p>The company will use its best efforts to launch its operations and develop the DNE Ecosystem. The Buyer undertaking to acquire DNE Token acknowledges and understands that the Company does not provide any guarantee that it will manage to establish an operative platform, gain a critical mass of users and that the DNE Token will in the future in fact be able to be used to connect buyers and sellers, ensuring that each receives what was negotiated, in a practical, transparent and secure manner.</p>
                        <p>The Buyer acknowledges and understands therefore that the Company (incl. its affiliates, managers and employees) assumes no liability or responsibility for any loss or damage that would result from or relate to reliance of the capacity to use DNE Token, except in the case of intentional misconduct or gross negligence.</p>

                        <h3>Representation and Warranties</h3>
                        <p>By participating in the DNE Pre-Sale, the Buyer declares in particular, that she/he represents and warrants that she/he:</p>

                        <ul>
                            <li>is acting in her/his own name only, and will be the legal and beneficial owner of the DNE Token as well as the Purchase Price and that all payments by Buyer under this Agreement will be made only in Buyer’s name, from a digital wallet solely owned by the Buyer;</li>
                            <li>is authorized and has full power to purchase DNE Token according to the laws that apply in his jurisdiction of domicile;</li>
                            <li>in case the person is a natural person, is of a sufficient age and capacity under the applicable laws of the jurisdiction in which such Buyer resides;</li>
                            <li>lives in a jurisdiction which allows to the company to sell DNE Token through the DNE Pre-Sale event without requiring any local authorization;</li>
                            <li>is familiar with all related regulations in the specific jurisdiction in which she/he is based and that purchasing cryptographic tokens in that jurisdiction is not prohibited, restricted or subject to additional conditions of any kind;</li>
                            <li>has not acquired the amounts invested directly or indirectly derived from any activities that contravene the laws and regulations of any jurisdiction, including anti-money laundering laws and regulations</li>
                            <li>is not a U.S. citizen, resident or entity (a "U.S. Person") nor is she/he purchasing DNE Token or signing on behalf of a U.S. Person;</li>
                            <li>is not a Chinese resident nor is she/he purchasing DNE Token or signing on behalf of a Chinese resident;</li>
                            <li>is not a South Korean resident nor is she/he purchasing DNE Token or signing on behalf of a South Korean resident;</li>
                            <li>is not acting for the purpose of speculative investment;</li>
                            <li>will not use DNE Token purchased during the DNE Pre-Sale for any illegal activity, including but not limited to money laundering and the financing of terrorism;</li>
                            <li>is solely responsible for determining whether the acquisition of DNE Token is appropriate for her/him;</li>
                            <li>is acquiring DNE Token for future use of the DNE Ecosystem;</li>
                            <li>
                                agrees and acknowledges that in the case the DNE Token are not construed, interpreted, classified or treated as:
                                <ul>
                                    <li>any kind of currency;</li>
                                    <li>debentures, stocks or shares issued by any person or entity;</li>
                                    <li>rights, options or derivatives in respect of such debentures, stocks or shares;</li>
                                    <li>rights under a contract for differences or under any other contract the purpose or pretended purpose of which is to secure a profit or avoid a loss;</li>
                                    <li>units in a collective investment scheme;</li>
                                    <li>units in a business trust;</li>
                                    <li>derivatives of units in a business trust; or</li>
                                    <li>any other security, class of securities or form of investment;</li>
                                    <li>understands the risks associated with the DNE Pre-Sale (incl. the risks related to the non-development of DNE Ecosystem and its operations); and has a substantial understanding of the functionality, usage, storage, transmission mechanisms and intricacies associated with cryptographic tokens or coins, and blockchain-based software ledger systems;</li>
                                    <li>understands that the value of DNE Token (if any) over time may experience extreme volatility or depreciate in full;</li>
                                    <li>understands that she/he bears the sole responsibility to determine what tax implications a purchase in the DNE Token may have for her/him and agrees not to hold the company or any other person involved in the proposed sale of the DNE Token liable for any tax liability associated with or arising therefrom.</li>
                                </ul>
                            </li>
                        </ul>

                        <h3>Limitation of Liability</h3>
                        <p>Neither the Company nor any of its officers, affiliates or agents shall be liable for any indirect, special, incidental, consequential, or exemplary damages of any kind (including, but not limited to, where related to loss of revenue, income or profits, loss of use or data, or damages for business interruption) arising out of or in any way related to the DNE Pre-sale or use of the DNE Tokens or otherwise related to these terms, regardless of the form of action, whether based in contract, tort (including, but not limited to, simple negligence, whether active, passive or imputed), or any other legal or equitable theory (even if the party has been advised of the possibility of such damages and regardless of whether such damages were foreseeable), and (ii) in no event will the aggregate liability of the Company and the Company parties (jointly), whether in contract, warranty, tort (including negligence, whether active, passive or imputed), or other theory, arising out of or relating to these terms or the use of or inability to use the DNE Token, exceed the amount you pay to the company for the DNE Token.</p>
                        <p>The limitations set forth in this Agreement shall not limit or exclude the liability for the gross negligence, fraud or intentional, willful or reckless misconduct of the Company or its representatives, nor the liability for damage from injury to life, body or health by intention or negligence of the Company or its representatives.</p>

                        <h3>Notice: Informal Dispute Resolution</h3>
                        <p>Each Party will notify the other Party in writing of any arbitrable dispute within thirty (30) days of the date it arises, so that the Parties can attempt in good faith to resolve the dispute informally. Notice to the Company shall be sent by e-mail to the email address of the Company at support@dne.international Notice to Buyer shall be by email to the email address of Buyer at {{dataProfile.profile_email}} A notice must include (i) name, postal address, email address and telephone number, (ii) a description in reasonable detail of the nature or basis of the dispute, and (iii) the specific relief that the claimant is seeking.</p>

                        <h3>Severability</h3>
                        <p>If any term, clause or provision of this Agreement is held unlawful, void or unenforceable, then that term, clause or provision will be severable from this Agreement and will not affect the validity or enforceability of any remaining part of that term, clause or provision, or any other term, clause or provision of these Terms. In the case of a clause not being valid or in the case that a regulation has not been included therein, this gap shall be replaced by a valid clause that the Parties would have been agreed upon if they would have known the gap.</p>

                        <h3>Applicable Law and Jurisdiction</h3>
                        <p>This Agreement is governed by Swiss law. Any dispute, controversy or claim arising out or in relation to this Agreement, including the validity, invalidity, breach or termination thereof, shall be resolved by the ordinary courts of Lugano.</p>

                        <p>&nbsp;</p>
                        <p>Hereby, accepted and agreed on {{ dateTimeSignature(signature.date || new Date()) }}, by and between:</p>
                        
                        <div class="signatures">
                            <div class="company">
                                <p>Company</p>

                                <div class="signature">Dne International Sagl</div>
                                <p>DNE International Sagl</p>
                                <p>{{ dateTimeSignature(signature.date || new Date()) }}</p>
                            </div>
                            
                            <div class="buyer">
                                <p>The Buyer</p>

                                <div class="signature">{{signature.fullName}}</div>
                                <p>{{dataProfile.profile_full_name}}</p>
                                <p>{{ dateTimeSignature(signature.date || new Date()) }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="box-error" v-if="showContent && errors.errorPost != false">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                        <div class="cod-error">
                            Cód.: {{errors.errorPost}}
                        </div>
                    </div>

                    <div class="confirm-purchase" v-if="showContent">
                        <label class="formName">
                            <span>{{getMyLanguage("compliance","compliance.placeholder.fullName.lbl")}}</span>
                            <input type="text" class="form" :class="{error: errorsForm.fullName == true}" v-model="signature.fullName" v-on:input="signatureName()" @keyup="validateError()" @click="scrollContract()" :placeholder='getMyLanguage("compliance","compliance.placeholder.fullName")'>
                            <small class="error" v-if="errorsForm.fullName == true">{{getMyLanguage("compliance","compliance.error.comparative")}} {{dataProfile.profile_full_name}}</small>
                        </label>

                        <div class="form-group-check" v-if='methodPay != "crypto" && methodPay != "swapix"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{summary.priceFinalString}}</strong></small></label>
                        </div>
                        
                        <div class="form-group-check" v-if='methodPay === "crypto"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{(summary.priceFinal * ticker).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} TETHER USDT</strong>&nbsp;({{summary.priceFinalString}})</small></label>
                        </div>
                        
                        <div class="form-group-check" v-if='methodPay === "swapix"'>
                            <input type="checkbox" id="acceptConfirm" class="form-check" v-model="accepts.Confirm">
                            <label for="acceptConfirm" class="form-check-label"><small>{{getMyLanguage("client-space","modal-confirm.txt01")}} &nbsp;<strong>{{summary.quantityTokenString}}</strong>&nbsp; {{getMyLanguage("client-space","modal-confirm.txt02")}} &nbsp;<strong>{{(summary.priceFinal * tickerPix).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} (PIX)</strong></small></label>
                        </div>

                        <div class="btn-primary disabled" v-if="!accepts.Confirm || errorsForm.fullName != 'validated'">{{getMyLanguage("client-space","modal-confirm.btn-confirm")}}</div>
                        <div @click="purchase()" class="btn-primary" v-if="accepts.Confirm && errorsForm.fullName == 'validated' && !stepBuy.load">{{getMyLanguage("client-space","modal-confirm.btn-confirm")}}</div>                        
                        <div class="btn-primary load" v-if="stepBuy.load"></div>
                        
                        <!-- <div class="btn-primary disabled" v-if="!accepts.Confirm">{{getMyLanguage("client-space","modal-confirm.btn-confirm")}}</div>
                        <div @click="purchase()" class="btn-primary" v-if="accepts.Confirm && !stepBuy.load">{{getMyLanguage("client-space","modal-confirm.btn-confirm")}}</div>                        
                        <div class="btn-primary load" v-if="stepBuy.load"></div> -->
                    </div>
                </div>
            </div>
            <div class="overlayModal" v-if="modalConfirm"></div>


            <!-- MODAL PHASE -->
            <div class="modal" v-if="this.modalPhase == true">
                <div @click="this.modalPhase = false" class="close-modal"></div>

                <div class="box cont-modal phase-modal">
                    <div class="scroll-vertical">
                        <h3>
                            {{dataTokenSale.phase.phase_name}}
                            <small>{{ dateTimePhase(dataTokenSale.phase.phase_start_date) }} / {{ dateTimePhase(dataTokenSale.phase.phase_end_date) }}</small>
                        </h3>
                        <div class="phase">
                            <p><span>{{dataTranslation.phaseDiscount}}:</span> <strong>{{dataTokenSale.phase.phase_discount}}%</strong></p>
                            <p><span>{{dataTranslation.phaseBonus}}:</span> <strong>{{dataTokenSale.phase.phase_bonus}}%</strong></p>
                            <p><span>{{dataTranslation.phaseMinAmount}}:</span> <strong>{{dataTokenSale.phase.phase_min_amount}} {{dataTranslation.phasePackages}}</strong></p>
                            <p v-if="dataProfile.account_type_code == 'account_type_person'"><span>{{dataTranslation.phaseMaxnAmount}}:</span> <strong>{{dataTokenSale.phase.phase_max_amount_person}} {{dataTranslation.phasePackages}}</strong></p>
                            <p v-if="dataProfile.account_type_code == 'account_type_company'"><span>{{dataTranslation.phaseMaxnAmount}}:</span> <strong>{{dataTokenSale.phase.phase_max_amount_company}} {{dataTranslation.phasePackages}}</strong></p>
                            <p><span>{{dataTranslation.phaseDateStart}}:</span> <strong>{{ dateTime(dataTokenSale.phase.phase_start_date) }}</strong></p>
                            <p><span>{{dataTranslation.phaseDateEnd}}:</span> <strong>{{ dateTime(dataTokenSale.phase.phase_end_date) }}</strong></p>
                            <p><span>{{dataTranslation.phaseTimeStake}}:</span> <strong>{{dataTokenSale.phase.phase_stake_months}} {{dataTranslation.phaseMonths}}</strong></p>
                            <p><span>{{dataTranslation.phaseMaxSelling}}:</span> <strong>{{dataTokenSale.phase.phase_max_quantity}} {{dataTranslation.phasePackages}}</strong></p>
                        </div>

                        <div class="btn-center">
                            <a class="btn-whitepaper">
                                <span>Whitepaper</span>
                                <small>PDF Download</small>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlayModal" v-if="modalPhase"></div>

        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent } from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import {loadStripe} from '@stripe/stripe-js';
    // const stripePromise = loadStripe(process.env.VUE_APP_STRIPE_PK);
    const stripePromise = loadStripe("pk_test_51LrJRRD3Dh6NvQyPiUfCZGuQ4oz4j3hV9fxgykvrTWHzcOySpdfHYPJTIOScz2vFDwDL4LM6LmBG4nsEg82QLagR007ONqwqGm");
    let cancelThreeDS = ""

    import apiInternational from '@/config/apiInternational.js';
    import { handleError, error404, errorGeneral, errorMsg, errorBlockPurchase } from '@/services/errorHandler.js'
    
    import moment from 'moment';

    import {mask} from 'vue-the-mask'

    import HeaderApp from '@/components/dneinternational/HeaderApp.vue';
    import Phases from '@/components/dneinternational/Phases.vue'
    import FooterApp from '@/components/dneinternational/FooterApp.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            HeaderApp,
            Phases,
            FooterApp
        },

        directives: {mask},

        data () {
            return {
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,

                showContent: false, 

                phaseID: "",
                urlGetPhase: "",
                
                dataProfile: "",
                dataTokenSale: [],
                dataTranslation: {},
                dataProducts: [],
                dataSummary: {},
                dataPurchase: {},
                disableQuantity: false,

                validateCard: {
                    cardHolder: null,
                    cardNumber: null,
                    expiry: null,
                    cvv: null
                },

                threeDSecurity: false,
                scaLink: "",
                redirectTo: "",

                methodPay: "sepa_swift",
                ticker: '',
                tickerPix: '',
                quoteTimer: 0,

                errorsForm: {
                    fullName: null
                },

                signature: {
                    fullName: ""
                },

                accepts: {
                    swapix01: false,
                    swapix02: false,
                    swapix03: false,
                    Conditions: false,
                    Terms: false,
                    Confirm: false
                },

                errors: {
                    errorPost: false,
                    error_404: false,
                    error_general: false,
                    error_blockPurchase: false,
                    error_msg: ""
                },

                summary: {},
                modalPhase: false,
                modalConfirm: false,

                contentShow: {
                    'accountPending': false,
                    'buyPayment': false
                },

                stepBuy: {
                    'load': false
                }
            }
        },

        async mounted () {
            this.flagUrl()

            this.dataProfile = JSON.parse(localStorage.getItem("profile"))
            
            this.accountStatusActivated();

            const urlParamsPhase = new URLSearchParams(window.location.search);
            this.phaseID = urlParamsPhase.get('phase-id')

            if ( this.phaseID ){
                this.urlGetPhase = `/api/v2/ico/token_sale/purchase/${this.phaseID}`
            } else {
                this.urlGetPhase = "/api/v2/ico/token_sale/purchase/current/"
            }
            
            // await apiInternational.get('/api/v2/ico/token_sale/purchase/6937b5ce-a7bc-433e-9e35-41b8477c4049')
            await apiInternational.get(this.urlGetPhase)
             .then(response => {
                this.dataTokenSale = response.data

                setTimeout(() => {
                    this.showContent = true
                }, 500);

                setTimeout(() => {
                    this.showWallets = true
                    this.showContent = true

                    this.ticker = this.dataTokenSale.payment_types.filter(paymentTypes => paymentTypes.paymentTypeCode === "crypto")[0].ticker
                    this.tickerPix = this.dataTokenSale.payment_types.filter(paymentTypes => paymentTypes.paymentTypeCode === "swapix")[0].ticker

                }, 1000);
                
                this.dataPurchase.phaseId = this.dataTokenSale.phase.phase_id
                this.dataPurchase.quantity = this.dataTokenSale.quota.min_amount
                this.dataPurchase.account_id = this.dataProfile.accountId
                this.dataPurchase.paymentTypeId = "732e682f-a99f-4914-b2b4-c56351b12ca6"

                this.dataPurchase.cardHolder = ""
                this.dataPurchase.cardNumber = ""
                this.dataPurchase.expiry = ""
                this.dataPurchase.cvv = ""

                this.upSummary()
                this.transPurchaseTokenString();
             })
            .catch(error => {
                setTimeout(() => {
                    this.showContent = true
                }, 500);

                handleError(error, this.flagSelected);
                
                this.errors.error_404 = error404
                this.errors.error_general = errorGeneral
                this.errors.error_blockPurchase = errorBlockPurchase
                this.errors.error_msg = errorMsg
            })


            // this.summary.quantityTokenString = this.dataTokenSale.quantity
            // this.summary.originalPriceString = this.dataTokenSale.price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})
            // this.summary.priceFinalString = this.dataTokenSale.phase.product_price.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})
            // this.summary.totalTokenString = this.dataTokenSale.totalAmount


            // const urlParams = new URLSearchParams(window.location.search);
            // const phasePurchaseIdParams = urlParams.get('phasePurchaseId');
            // const quantityParams = urlParams.get('quantity');

            // if ( urlParams != '' ){
            //     this.dataPurchase.phasePurchaseId = phasePurchaseIdParams
            //     this.dataPurchase.quantity = quantityParams
            //     this.disableQuantity = true
            // }


            // this.upSummary();

            this.quoteTimer = 130
            this.countDownQuote();
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
            
            goPayment(){
                this.stageBuy.buyContent = false
                this.stageBuy.buyPayment = true
                // window.location.href = `${this.flagSelected}/token-payment/${this.dataProfile.id}`
            },

            dateTime(value) {
                return moment(value).locale(this.flagSelected).format('ll');
            },

            dateTimePhase(value) {
                return moment(value).locale(this.flagSelected).format('DD MMM');
            },
            
            dateTimeSignature(value) {
                return moment(value).format('ll');
            },

            quantityLess(){
                this.interval = setInterval(() => {
                    setTimeout(() => {
                            this.upSummary(this.dataPurchase.quantity -= 1)
                    }, 200);
                }, 150)
            },

            quantityMore(){
                this.interval = setInterval(() => {
                    setTimeout(() => {
                            this.upSummary(this.dataPurchase.quantity += 1)
                    }, 200);
                }, 150)
            },

            quantityStop(){
                clearInterval(this.interval)
            },

            transPurchaseTokenString(){
                const phasePackage = this.getMyLanguage("purchase-token", "purchase-token.content.phase.package")
                const phaseDiscount = this.getMyLanguage("purchase-token", "purchase-token.content.phase.discount")
                const phaseBonus = this.getMyLanguage("purchase-token", "purchase-token.content.phase.bonus")
                const phaseMinAmount = this.getMyLanguage("purchase-token", "purchase-token.content.phase.minAmount")
                const phaseMaxnAmount = this.getMyLanguage("purchase-token", "purchase-token.content.phase.maxAmount")
                const phaseDateStart = this.getMyLanguage("purchase-token", "purchase-token.content.phase.dateStart")
                const phaseDateEnd = this.getMyLanguage("purchase-token", "purchase-token.content.phase.dateEnd")
                const phaseTimeStake = this.getMyLanguage("purchase-token", "purchase-token.content.phase.timeStake")
                const phaseMaxSelling = this.getMyLanguage("purchase-token", "purchase-token.content.phase.maxSelling")
                const phasePurchaseNow = this.getMyLanguage("purchase-token", "purchase-token.content.phase.purchaseNow")
                const phasePackages = this.getMyLanguage("purchase-token", "purchase-token.content.phase.packages")
                const phaseMonths = this.getMyLanguage("purchase-token", "purchase-token.content.phase.months")
                
                const digitalNewEconomy = this.getMyLanguage("purchase-token", "purchase-token.content.digital-new-economy")
                
                this.dataTranslation.phasePackage = phasePackage
                this.dataTranslation.phaseDiscount = phaseDiscount
                this.dataTranslation.phaseBonus = phaseBonus
                this.dataTranslation.phaseMinAmount = phaseMinAmount
                this.dataTranslation.phaseMaxnAmount = phaseMaxnAmount
                this.dataTranslation.phaseDateStart = phaseDateStart
                this.dataTranslation.phaseDateEnd = phaseDateEnd
                this.dataTranslation.phaseTimeStake = phaseTimeStake
                this.dataTranslation.phaseMaxSelling = phaseMaxSelling
                this.dataTranslation.phasePurchaseNow = phasePurchaseNow
                this.dataTranslation.phasePackages = phasePackages
                this.dataTranslation.phaseMonths = phaseMonths
                
                this.dataTranslation.digitalNewEconomy = digitalNewEconomy
            },

            accountStatusActivated(){
                if ( this.dataProfile.account_status === "Verified" ){
                    this.contentShow.buyPayment = true
                } else{
                    this.contentShow.accountPending = true
                }
            },

            // validateFormPay(){
            //     this.stepBuy.load = true
            //     this.purchase();
            // },

            changeCurrent(){
                setTimeout(() => {
                    this.showWallets = true
                    this.showContent = true
                    this.load = false

                    this.ticker = this.dataTokenSale.payment_types.filter(paymentTypes => paymentTypes.paymentTypeCode === "crypto")[0].ticker
                    this.tickerPix = this.dataTokenSale.payment_types.filter(paymentTypes => paymentTypes.paymentTypeCode === "swapix")[0].ticker
                }, 1000);
            },

            upSummary(){
                const price = this.dataTokenSale.phase.product_price
                const quantityTokens = this.dataTokenSale.phase.product_quantity
                const bonus = this.dataTokenSale.phase.phase_bonus
                const discount = this.dataTokenSale.phase.phase_discount
                const min = this.dataTokenSale.quota.min_amount
                const max = this.dataTokenSale.quota.available

                if ( this.dataPurchase.quantity <= min ){
                    this.dataPurchase.quantity = min
                    this.dataPurchase.quantity = min
                }
                
                if ( this.dataPurchase.quantity >= max){
                    this.dataPurchase.quantity = max
                    this.dataPurchase.quantity = max
                }

                this.summary.originalPrice = price * this.dataPurchase.quantity
                this.summary.quantityToken = quantityTokens * this.dataPurchase.quantity
                this.summary.priceFinal = (price * this.dataPurchase.quantity)-((price * this.dataPurchase.quantity)*(discount / 100))
                this.summary.totalToken = (this.dataPurchase.quantity * quantityTokens) * (1 + (bonus / 100));
                this.summary.priceFinalCrypto = (this.summary.priceFinal * this.ticker)
                
                // this.summary.originalPrice = price * this.dataPurchase.quantity
                // this.summary.quantityToken = quantityTokens * this.dataPurchase.quantity
                // this.summary.priceFinal = (price * this.dataPurchase.quantity)-((price * this.dataPurchase.quantity)*(discount / 100))
                // this.summary.totalToken = (this.dataPurchase.quantity * quantityTokens) * (1 + (bonus / 100));
              
                this.quantityPurchase()
            },

            purchase(){
                this.stepBuy.load = true
                const purchase = this.dataPurchase;

                apiInternational.post("/api/v1/ico/token_sale/purchase/", purchase)
                .then(response => {
                    if ( response.data.success ){
                        window.location.href = `/${response.data.returnUrl}`

                    } else if ( !response.data.success && response.data.status === "requires_action" && response.data.nextAction.type === "use_stripe_sdk" ){
                        
                        stripePromise.then(responseStripe => {
                            responseStripe.confirmCardPayment(response.data.nextAction.clientSecret)
                            .then(result => {
                                if ( result.error ){
                                    cancelThreeDS = result.error.type
                                    console.log(cancelThreeDS)
                                    window.location.href = `/${this.flagSelected}/international/token-payment-error?error=${result.error.type}`
                                    
                                } else if ( result.paymentIntent.status === "succeeded" ){
                                    window.location.href = `/${response.data.returnUrl}`
                                }
                            })
                        })
                        .catch(error => {
                            window.location.href = `/${this.flagSelected}/international/token-payment-error?error=${error}`
                        })
                    } else {
                        window.location.href = `/${this.flagSelected}/international/token-payment-error?error=error_3ds`
                    }
                })
                .catch(error => {
                    window.location.href = `/${this.flagSelected}/international/token-payment-error?error=${error.response.data[0]}`
                })
            },

            checkPurchaseCreditCard(){
                window.location.href = `/${this.redirectTo}`
            },

            quantityPurchase() {
                let qtdTokens = this.summary.quantityToken
                let totalTokens = this.summary.totalToken
                let priceOriginal = this.summary.originalPrice
                let priceFinal = this.summary.priceFinal
                
                qtdTokens = qtdTokens.toLocaleString()
                totalTokens = totalTokens.toLocaleString()
                priceOriginal = priceOriginal.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})
                priceFinal = priceFinal.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})
                
                this.summary.quantityTokenString = qtdTokens.toLocaleString()
                this.summary.totalTokenString = totalTokens.toLocaleString()
                this.summary.originalPriceString = priceOriginal.toLocaleString()
                this.summary.priceFinalString = priceFinal.toLocaleString()
            },

            countDownQuote() {                
                if(this.quoteTimer > 0) {
                    setTimeout(() => {
                        this.quoteTimer -= 1

                        if ( this.quoteTimer >= 60 ){
                            this.quoteTimerMinutes = this.quoteTimer / 60
                            this.quoteTimerMinutes = String(this.quoteTimerMinutes).split(".")[0]
                        }

                        this.countDownQuote()
                    }, 1000)
                } else{
                    this.changeCurrent()
                    this.dataTokenSale.payment_types[2].ticker = this.ticker
                    this.upSummary()
                    this.quoteTimer = 130
                    this.countDownQuote()
                }
            },

            openModalConfirm(){
                if ( this.methodPay === "credit_card" ){
                    if ( this.dataPurchase.cardHolder.length < 5 || this.dataPurchase.cardHolder == null ){ this.validateCard.cardHolder = false } else { this.validateCard.cardHolder = true }
                    if ( this.dataPurchase.cardNumber.length < 16 || this.dataPurchase.cardNumber == null ){ this.validateCard.cardNumber = false } else { this.validateCard.cardNumber = true }
                    if ( this.dataPurchase.expiry.length < 2 || this.dataPurchase.expiry == null ){ this.validateCard.expiry = false } else { this.validateCard.expiry = true }
                    if ( this.dataPurchase.cvv.length < 3 || this.dataPurchase.cvv == null ){ this.validateCard.cvv = false } else { this.validateCard.cvv = true }

                    if ( this.validateCard.cardHolder == true && this.validateCard.cardNumber == true && this.validateCard.expiry == true && this.validateCard.cvv == true ){
                        this.validateCard .cardHolder = true
                        this.modalConfirm = true
                    }
                } else {
                    this.modalConfirm = true
                    this.errors.errorPost = false
                }
            },

            scrollContract() {
                const contractDiv = this.$refs.contract;
                contractDiv.scrollTop = contractDiv.scrollHeight;
            },

            signatureName(){
                const nameSignature = this.signature.fullName;
                const nameSignatureLowercase = nameSignature.toLowerCase();
                const words = nameSignatureLowercase.split(' ');
                const wordsUppercase = words.map(words => words.charAt(0).toUpperCase() + words.slice(1));
                const nameSignatureFinished = wordsUppercase.join(' ');
                this.signature.fullName = nameSignatureFinished;

                this.scrollContract()
            },

            validateError(){
                if ( this.signature.fullName.toLowerCase() == this.dataProfile.profile_full_name.toLowerCase() ){
                    this.errorsForm.fullName = "validated"
                } else {
                    this.errorsForm.fullName = true
                }

                if ( this.signature.fullAddress != "" ){
                    this.errorsForm.fullAddress = false
                }
            }
        }
    }
</script>


<style scoped>
    .load-blue{margin-top: 100px;}

    .phases{margin-top: 30px; margin-bottom: 40px;}
    .alert-phase{font-size: 15px; text-align: center; color: var(--color-primary); margin-bottom: 0;}

    .phase-current{padding: 16px 16px 0 16px; background: url('~@/assets/images/bg-blue.png'); border-radius: 12px; text-align: center; position: relative; z-index: 20;}
    .phase-current h4{font-size: 20px; font-family: var(--font-primary-semibold); color: var(--color-text-tertiary); margin: 0 0 5px 0;}
    .phase-current p{font-size: 12px; font-weight: bold; color: var(--color-secondary); margin: 0;}
    .phase-current p strong span:last-child{display: block}
    .phase-current .btn-showMore{display: inline-block; width: 40px; height: 40px; background: url('~@/assets/images/icons/show-more.svg') no-repeat center; background-size: 20px auto;}

    .no-has-quota{padding: 0 16px;}
    .no-has-quota .box-success{max-width: 500px; margin: 30px auto 50px; background: url('~@/assets/images/bg-green.png') no-repeat; background-size: cover; border-radius: 12px !important; padding: 30px 16px;}
    .no-has-quota .box-success p{color: var(--color-text-tertiary); text-align: center;}
    .no-has-quota .box-success .cod-error{font-size: 60%; color: var(--color-text-tertiary); text-align: center; opacity: .4;}

    .kyc-pending{padding: 20px 16px; border-radius: 0 0 12px 12px;}
    .kyc-pending p strong{display: block; text-align: center; color: var(--color-primary); padding: 0 30px; font-size: 13px; font-weight: bold;}
    .kyc-pending .kyc{display: flex; justify-content: center; align-items: center; margin-top: 20px;}
    .kyc-pending .kyc img{width: auto; height: 82px; margin-right: 20px;}
    .kyc-pending div h6{font-size: 20px; font-family: var(--font-primary-semibold); color: var(--color-pending); margin: 0 0 5px;}
    .kyc-pending div p{font-size: 11px; color: var(--color-primary); margin: 0;}
    .kyc-pending div .btn-secondary{margin: 10px 0 0 0;}

    .buy-content .dne-coins{display: block; width: auto; height: 70px; margin: 0 auto 10px;}

    .buy-content{border-radius: 0 0 12px 12px; padding-top: 40px; padding-bottom: 20px; margin-top: -10px;}
    .buy-content h6{font-size: 14px; font-family: var(--font-primary-semibold); color: var(--color-primary); margin: 10px 0; text-align: center;}
    .buy-content .quantityBuy{text-align: center;}
    .buy-content .quantityBuy .quantity{position: relative; display: flex; align-items: center; justify-content: center; margin: 10px 0;}
    .buy-content .quantityBuy .quantity .tooltip{display: none; position: absolute; top: -33px; left: 50%; transform: translateX(-50%); background: var(--color-pending); font-size: 14px; line-height: 20px; color: var(--color-text-tertiary); padding: 5px 10px; border-radius: 10px; white-space: nowrap;}
    .buy-content .quantityBuy .quantity .tooltip:after{content: ''; display: block; position: absolute; left: 50%; bottom: -10px; transform: translateX(-50%); width: 0;  height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 10px solid var(--color-pending);}
    .buy-content .quantityBuy .quantity .btnQtd{width: 40px; height: 40px; line-height: 40px; text-align: center; background: var(--color-primary); color: var(--color-text-tertiary); font-size: 24px; cursor: pointer; transition: .3s; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
    .buy-content .quantityBuy .quantity .btnQtd.btn-less{border-radius: 12px 0 0 12px;}
    .buy-content .quantityBuy .quantity .btnQtd.btn-more{border-radius: 0 12px 12px 0;}
    .buy-content .quantityBuy .quantity .btnQtd.disable{opacity: .4;}
    .buy-content .quantityBuy .quantity .form{margin-bottom: 0; font-size: 18px; width: 80px; height: 40px; line-height: 40px; background: #eee; border-radius: 0; font-family: var(--font-primary-semibold);}

    .summary-mobile{background: #f5f5f5; padding: 2px 15px; border-radius: 12px; margin: 30px auto 0; width: 100%; max-width: 400px;}
    .summary-mobile .summary-mobile-cont{width: 100%; max-width: 360px; border: none; color: var(--color-primary); font-size: 13px;}
    .summary-mobile .summary-mobile-cont tr td{line-height: 16px; padding: 8px 0;}
    .summary-mobile .summary-mobile-cont tr td{border-bottom: 1px solid rgba(31,53,97,.2);}
    .summary-mobile .summary-mobile-cont tr:last-child td{border-bottom: none;}
    .summary-mobile .summary-mobile-cont tr td p{padding: 10px 0; margin: 0;}
    .summary-mobile .summary-mobile-cont tr td small{font-size: 11px;}
    .summary-mobile .summary-mobile-cont tr td strong{display: block; text-align: right;}
    .summary-mobile .summary-mobile-cont tr td strong small{display: block; margin-left: 5px;}
    
    .summary-mobile .summary-mobile-cont tr td .asset-dne{display: block; padding-right: 14px; background: url('~@/assets/images/icons/dne-symbol.svg') no-repeat right 2px; background-size: 12px auto;}

    .summary-mobile .summary-mobile-cont tr.calculated-value td.no-border{border-bottom: none; padding-bottom: 0;}
    .summary-mobile .summary-mobile-cont tr.calculated-value td strong{display: block;}
    .summary-mobile .summary-mobile-cont tr.calculated-value td strong small{display: block; text-align: right; font-size: 12px; line-height: 15px; color: #777;}
    .summary-mobile .summary-mobile-cont tr.calculated-value .tether{display: inline-block; padding-right: 18px; position: relative;}
    .summary-mobile .summary-mobile-cont tr.calculated-value .tether:after{content: ''; display: block; width: 16px; height: 24px; margin-left: 2px; background: url('~@/assets/images/icons/payment-type-tether.svg') no-repeat center; background-size: 12px auto; position: absolute; right: 0; top: 0;}
    .summary-mobile .summary-mobile-cont tr.promotion-price strong small{color: var(--color-secondary) !important;}
    .summary-mobile .summary-mobile-cont tr.quote-updates td{text-align: right; font-size: 11px; line-height: 13px; padding-top: 8px;}
    .summary-mobile .summary-mobile-cont tr.quote-updates td strong{color: var(--color-pending);}

    .summary-mobile .summary-mobile-cont tr.calculated-value td .tether{display: inline-block; padding-right: 12px; position: relative;}
    .summary-mobile .summary-mobile-cont tr.calculated-value td .tether:after{content: ''; display: block; width: 14px; height: 16px; margin-left: 2px; background: url('~@/assets/images/icons/payment-type-tether.svg') no-repeat center right; background-size: 9px auto; position: absolute; right: 0; top: 0;}

    .form-group-check{width: 100%; margin: 15px 0;}
    .form-check-label small{font-size: 11px;}
    .btn-learn{display: block; font-size: 11px; text-decoration: underline; color: var(--color-text-tertiary); margin-left: 32px; margin-top: -5px;}

    .btn-primary{flex-direction: column; height: 56px; margin-top: 20px;}
    .btn-primary:after{display: none;}
    .btn-primary.disabled{opacity: .5; filter: blur(1px);}

    .btn-pay-crypto{}
    .btn-pay-crypto small small{font-size: 85%;}
    
    
    /* TOKEN PAYMENT */
    .summary-pay{}
    .summary-pay h6{font-size: 20px; margin-bottom: 20px; color: var(--color-primary);}
    .summary-pay p{margin-bottom: 0;}
    .summary-pay p strong{color: var(--color-secondary);}

    .buy-payment{margin: -40px 0 30px 0;}
    .buy-payment .title-secondary{margin: 30px 0 !important; color: var(--color-secondary);}


    /* RANGE */
    .quantityBuyRange{margin: 30px auto 0; width: 100%; max-width: 400px;}
    .quantityBuyRange input{margin: 3px 0;}
    .quantityBuyRange .purchased-packages{font-size: 12px; font-family: var(--font-primary-semibold); color: var(--color-secondary);}
    .quantityBuyRange .quantity-min-max{display: flex; justify-content: space-between;}
    .quantityBuyRange .quantity-min-max span{display: block; font-size: 10px; line-height: 12px; color: var(--color-quaternary);}


    /* ALERT LOST TOKEN */
    .alert-lost-token{position: relative; border: 2px solid rgba(235,103,51,0.25);background: rgba(235,103,51,0.1); margin: 10px auto; border-radius: 100px; width: 100%; max-width: 400px;}
    .alert-lost-token p{margin: 0; font-size: 12px; text-align: center; line-height: 16px; padding: 7px 20px;}


    /* SELECT METHOD PAYMENT */
    ul.select-method-payment{display: flex; flex-wrap: wrap; justify-content: center; width: 100%; margin: 10px 0 30px 0; padding: 0;}
    ul.select-method-payment li{display: flex; align-items: flex-start; cursor: pointer; margin: 5px; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;}
    ul.select-method-payment li .select-payment-type{width: 100%; padding: 10px; border-radius: 12px; background: rgba(255,255,255,0.1); color: var(--color-text-tertiary); text-transform: uppercase; display: flex; flex-direction: column; align-items: center; justify-content: flex-end; text-align: center; font-weight: 600; position: relative;}
    ul.select-method-payment li .select-payment-type:before{content: ''; display: block; width: 50px; height: 50px; border-radius: 50%; background-size: 50% auto !important; margin-bottom: 10px;}
    ul.select-method-payment li .select-payment-type.active{color: var(--color-primary); background: var(--color-text-tertiary);}
    ul.select-method-payment li .select-payment-type.active:before{background-color: #eee !important;}
    ul.select-method-payment li.credit_card .select-payment-type:before{background: url('~@/assets/images/icons/payment-type-credit-card.svg') no-repeat center #fff;}
    ul.select-method-payment li.sepa_swift .select-payment-type:before{background: url('~@/assets/images/icons/payment-type-sepa-swift.svg') no-repeat center #fff;}
    ul.select-method-payment li.crypto .select-payment-type:before{background: url('~@/assets/images/icons/payment-type-tether.svg') no-repeat center #fff;}
    ul.select-method-payment li.swapix .select-payment-type:before{background: url('~@/assets/images/icons/payment-type-swapix.svg') no-repeat center #fff;}
    ul.select-method-payment li.hide{display: none !important;}

    .method-pay-creditcard label .form{margin-bottom: 25px;}

    @media (max-width:576px){
        ul.select-method-payment li{width: calc(50% - 10px);}
        ul.select-method-payment li .select-payment-type{font-size: 11px; line-height: 14px; min-height: 38px;}
    }
    
    @media (min-width:576px){
        ul.select-method-payment li{width: calc(25% - 10px);}
        ul.select-method-payment li .select-payment-type{font-size: 12px; line-height: 16px; min-height: 52px;}
    }

    @media (max-width: 1200px){
        ul.select-method-payment{flex-wrap: wrap;}
    }

    @media (min-width: 1200px){
        ul.select-method-payment{flex-wrap: nowrap;}
    }
                        

    /* ul.select-method-payment{display: flex; justify-content: center; width: 100%; margin: 30px 0; padding: 0;}
    ul.select-method-payment li{display: flex; align-items: center;}
    ul.select-method-payment li .select-payment-type{padding: 8px; line-height: 16px; font-size: 12px; text-align: center; border-radius: 12px; background: var(--color-quaternary); color: var(--color-text-secondary); text-transform: uppercase; margin: 5px; cursor: pointer; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; transition: .3s;}
    ul.select-method-payment li .select-payment-type.active{background: var(--color-text-tertiary); color: var(--color-primary);}
    ul.select-method-payment li .select-payment-type.active:before{background-position-y: 0;} */

    .method-pay-animation{margin-bottom: 60px;}
    .method-pay-animation .checks-suapix{padding-bottom: 15px; margin-bottom: 25px; border-bottom: 1px solid rgba(255,255,255,0.3);}
    .method-pay-animation .form-group-check{margin: 0 0 5px 0;}

    .alert-card{border: 2px solid rgba(235, 104, 52, 0.3); background: rgba(235, 104, 52, 0.2); color: #eee; border-radius: 12px; margin-bottom: 20px;}
    @media (max-width: 992px){
        .alert-card{padding: 8px 12px;}
    }

    @media (min-width: 992px){
        .alert-card{padding: 8px 10px 8px 60px; position: relative;}
        .alert-card:before{content: ''; display: block; position: absolute; left: 15px; top: 50%; transform: translateY(-50%); width: 30px; height: 30px; background: url('~@/assets/images/icons/alert.svg') no-repeat; background-size: 30px auto;}
    }

    .buy-payment-cont .error label{color: var(--color-pending);}
    .buy-payment-cont .error .form{border: 1px solid rgba(216,25,25,0.5);}

   
    form{background: var(--color-primary); border-radius: 12px; padding: 16px; margin-bottom: 40px;}
    form label{display: block; color: var(--color-text-tertiary);}
    form .form{height: 36px; line-height: 36px;}
    form .expiry-cvv{display: flex; justify-content: space-between; align-items: flex-start;}
    form .expiry-cvv .expiry{width: 50%;}
    form .expiry-cvv .cvv{width: 30%;}
    
    form .obsBuy{margin-top: 40px; padding-top: 30px; border-top: 1px solid rgba(255,255,255,0.2);}
    form .obsBuy h6{font-size: 16px; color: var(--color-text-tertiary); margin: 0 0 8px 0;}
    form .obsBuy p{font-size: 12px; color: var(--color-text-tertiary);}

    .btn-center{text-align: center;}

    /* MODAL CONFIRM */
    .modal-confirm{max-height: 85vh;}
    .modal-confirm .scroll-vertical{max-height: calc(85vh - 230px - 30px); scroll-behavior: smooth; margin-bottom: 20px;}
    .modal-confirm .scroll-vertical h3{font-size: 16px; margin: 15px 0 5px 0;}
    .modal-confirm .scroll-vertical h3:after{display: none;}
    .modal-confirm .confirm-purchase .formName{max-width: 400px; margin-bottom: 30px; font-size: 13px;}
    .modal-confirm .confirm-purchase .formName input{margin-bottom: 2px;}
    .modal-confirm .confirm-purchase .formName small.error{color: var(--color-cancel);}

    .modal-confirm .confirm-purchase .btn-primary{height: 46px;}
    .modal-confirm .btn-primary.load{background: url('~@/assets/images/icons/loading-secondary.svg') no-repeat center; background-size: auto 100%;}
    .modal-confirm .btn-primary.load:after{display: none;}
    
    /* THREE D SECURITY */
    .modal-confirm iframe{width: 100%; height: calc(100vh - 120px);}

    .modal .cont-modal h2{font-size: 18px; color: var(--color-primary); margin: 0 0 20px 0;}
    .modal .cont-modal h2 small{display: block; margin-top: 10px;}
    .modal .cont-modal h3{font-size: 16px; color: var(--color-primary); margin: 20px 0 5px 0 !important;}
    
    .modal .cont-modal ul{padding: 10px 0 0 15px;}
    .modal .cont-modal ul li{padding-left: 5px; list-style: lower-roman; font-size: 12px; color: var(--color-text-secondary)}

    .modal .cont-modal .scroll-vertical{background: #eee; padding: 8px 10px;}

    .modal .cont-modal .signatures{padding: 12px; margin-top: 30px; background: #f9f9f9; text-align: center;}
    .modal .cont-modal .signatures p{margin: 0;}
    .modal .cont-modal .signatures .signature{width: 400px; max-width: 100%; margin: 0 auto; font-family: var(--font-signature); font-size: 24px; line-height: 40px; height: 40px; font-weight: 600; color: blue; border-bottom: 2px solid #ddd; padding: 0 5px;}

    @media (max-width: 992px){
        .modal .cont-modal .signatures .company{margin-bottom: 40px;}
    }

    @media (min-width: 992px){
        .modal .cont-modal .signatures{display: flex; justify-content: space-between;}
    }


    .txt-center{text-align: center;}
    .txt-right{text-align: right;}
    .and{margin: 20px 0;}

    .box-error{padding: 10px 16px; margin: 20px 0 0 0;}
    .box-error h3{color: var(--color-waiting) !important;}

    /* MODAL */
    .phase-modal h3 small{display: block; margin-top: 5px;}
    .phase-modal h3:after{display: none;}
    .phase-modal .phase p{display: flex; justify-content: space-between; border-bottom: 1px solid #ddd; padding: 5px; margin-bottom: 0; color: var(--color-primary);}
    .phase-modal .phase p strong{color: var(--color-secondary)}
    .phase-modal .phase p:last-child{border-bottom: none;}
    .phase-modal .btn-whitepaper{margin-top: 30px;}


    @media (min-width: 576px){
        .buy-payment-cont{max-width: 400px; margin: 0 auto;}
    }
</style>

<style>
    .msg-payment-condition{padding: 10px; border-radius: 12px 12px 0 0; margin: 0; box-shadow: none;}
    .msg-payment-condition h3{margin: 0 0 10px 0; color: var(--color-primary); font-size: 16px;}

    .alert-reserve{background: #eee; margin-bottom: 20px; padding: 10px; border-radius: 0 0 12px 12px; border: 5px solid #fff;}
    .alert-reserve p{display: block; font-size: 11px; line-height: 13px; margin-bottom: 10px;}
    .alert-reserve p:last-child{margin-bottom: 0;}
</style>