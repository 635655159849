<template>
    <div class="pay">
        <div class="content-pay">

            <!-- SCAN QRCODE -->
            <div class="modal-scan" :class="{load: loadCam, validatePhone: !dataProfile.profile_phone_number_verified}" v-if="!errorsGet && scanQRCode && !dataResume && !error">
                <div class="header-scan">
                    <div class="instructions">{{getMyLanguage("dnePay","pay.msg.open-cam")}}</div>
                    <div class="close-scan" @click="scanQRCode = false"></div>
                </div>

                <qrcode-stream @decode="decode" @init="onInit"></qrcode-stream>
            </div>


            <!-- RESUME -->
            <div class="resume-qr-code" v-if='!errorsGet && !scanQRCode && dataResume && !error'>

                <div class="box box-resume">
                    <h2 class="title-secondary">{{getMyLanguage('dnePay',"resume")}}</h2>
                    
                     <!-- {
                        TransactionType: "Payment",
                        OperationType: "Payment",
                        AssetShort: "DNE",
                        CheckoutId: "as1d456as564d6a5s4d6a6s4d",
                        AccountWalletId: "65s8d4f65s4df564sd56f4s56d4f56s",
                        WalletAddress: "ggdfdfgdfgdf64g56df4g65d456f4g56df4g654d56f4g6df",
                        AccountWalletName: "Tilt DNE Market",
                        MerchantName: "Tilt Informatica",
                        Details: [
                            {
                                name: "Mouse Pad Razer",
                                value: 24.99
                            },
                            {
                                name: "HD External SSD 2TB Toshiba",
                                value: 129.99
                            },
                        ],
                        Amount: 154.98
                    }, -->

                    <table>
                        <tr>
                            <td>{{getMyLanguage("dnePay","pay.resume.partner")}}</td>
                            <td>{{dataResume.MerchantName}}</td>
                        </tr>
                        <tr>
                            <td>{{getMyLanguage("dnePay","pay.resume.operation-type")}}</td>
                            <td>{{dataResume.OperationType}}</td>
                        </tr>
                        <tr class="total">
                            <td>{{getMyLanguage("dnePay","pay.resume.total")}}</td>
                            <td class="priceDNE">{{dataResume.Amount.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</td>
                        </tr>
                    </table>

                    <div class="btn-primary getCode" @click="getCodePay()">{{getMyLanguage("wallets","modal-send-code.btn-generate")}}</div>

                    <div class="btn-cancel-tertiary" @click="lnkActivities()">{{getMyLanguage("dnePay","cancel")}}</div>
                </div>

                <div class="box box-details">
                    <h2 class="title-secondary">{{getMyLanguage("dnePay","pay.resume.details")}}</h2>

                    <div class="details">
                        <table class="items">
                            <!-- <tr v-for="(item, index) in dataResume.Details" :key="index">
                                <td class="name">{{item.name}}</td>
                                <td class="price">{{item.value.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</td>
                            </tr> -->

                            <tr>
                                <td colspan="2">{{dataResume.Details}}</td>
                            </tr>

                            <tr class="total">
                                <td>{{getMyLanguage("dnePay","pay.resume.total")}}</td>
                                <td class="price">{{dataResume.Amount.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>


            <!-- OPEN CAM -->
            <div class="box box-open-cam" v-if='!errorsGet && !scanQRCode && !dataResume && error != "noData" '>
                <h2 class="title-secondary">{{getMyLanguage('dnePay',"pay.title")}}</h2>

                <p>{{getMyLanguage('dnePay',"pay.msg.open-cam")}}</p>

                <div class="bt-scan-qr-code" @click='scanQRCode = true'>
                    <span>{{getMyLanguage("dnePay","pay.scan")}} QR Code</span>
                </div>
            </div>


            <!-- MODAL PAY -->
            <div class="modal modal-pay" v-if="modal.pay">
                <div class="modal-cont">
                    <div class="close-modal" v-if='modal.status != "success"' @click="modal.pay = false"></div>
                    <div class="close-modal" v-if='modal.status == "success"' @click="lnkActivities()"></div>
                    <div class="box">
                        <div class="scroll-modal">

                            <!-- SMS CODE -->
                            <div class="sms-code" v-if='modal.status == "getCode"'>
                                <h2 class="title-secondary">{{getMyLanguage("dnePay","pay.modal-pay.title.verification-code")}}</h2>
                                <p v-if="!modal.load">{{getMyLanguage("dnePay","pay.modal-pay.inf.verification-code")}}</p>

                                <div class="sms-confirm" v-if="!modal.load">
                                    <input type="text" class="form" inputmode="numeric" v-model="payData._2fa_token" v-mask="'######'" placeholder="______">
                                    <div class="confirm btn-disable" v-if="payData._2fa_token.length < 6">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                    <div class="confirm" v-if="payData._2fa_token.length === 6" @click="confirmCodePay()">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                </div>

                                <div class="alert" v-if="modal._2fa_tokenShow && !modal.load">{{getMyLanguage("wallets","modal-send-code.msg-sms")}} {{dataProfile.profile_phone_country_ddi}} {{dataProfile.profile_phone_number}}</div>
                                <div class="load" v-if="!modal._2fa_tokenShow && modal.load"></div>

                                <div class="resend" v-if="modal._2fa_tokenShow && !modal.load">
                                    <div class="btn-resend resend-load" v-if="!modal.btnResendSMS">{{getMyLanguage("wallets","modal-send-code.btn-resend-in")}} {{modal.countDownResendCode}}s</div>
                                    <div class="btn-resend" v-if="modal.btnResendSMS" @click="getCodePay()">{{getMyLanguage("wallets","modal-send-code.btn-resend")}}</div>
                                </div>
                            </div>

                            <div v-if='modal.load && modal.status == "validating"'>
                                <h2 class="title-secondary">{{getMyLanguage("dnePay","pay.modal-pay.title.validating-code")}}</h2>
                                <div class="load"></div>
                            </div>
                            
                            <!-- SMS CODE INVALID -->
                            <div class="box box-code-invalid result-flowPay" v-if='modal.status == "invalid"'>
                                <h2 class="title-secondary">{{getMyLanguage("dnePay","pay.modal-pay.title.invalid-code")}}</h2>

                                <div class="btn-primary btn-pay" @click='modal.status = "getCode"'>
                                   {{getMyLanguage("dnePay","pay.try-again")}}
                                </div>
                            </div>



                            <!-- SMS CODE VALIDATED -->
                            <div class="box-code-validated result-flowPay" v-if='modal.status == "validated"'>
                                <h2 class="title-secondary">{{getMyLanguage("dnePay","pay.modal-pay.title.finish-payment")}}</h2>
                                
                                <div class="resume">
                                    <div><strong>{{getMyLanguage("dnePay","pay.modal-pay.resume.partner")}}&nbsp;</strong><span>{{dataResume.MerchantName}}</span></div>
                                    <div><strong>{{getMyLanguage("dnePay","pay.modal-pay.resume.description")}}&nbsp;</strong><span>{{dataResume.Details}}</span></div>
                                    <div><strong>{{getMyLanguage("dnePay","pay.modal-pay.resume.value")}}&nbsp;</strong><span class="price">{{dataResume.Amount.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</span></div>
                                </div>

                                <div class="btn-primary btn-pay" v-if='!modal.load && modal.status == "validated"' @click="toPay()">
                                    {{getMyLanguage("dnePay","pay")}}
                                    <span>{{dataResume.Amount.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</span>
                                </div>

                                <div class="btn-primary load" v-if='modal.load && modal.status == "validated"'></div>
                            </div>
                            
                            <div class="load" v-if='modal.success == "validated" && modal.load'></div>

                            <!-- SUCCESS REGISTER PAYMENT -->
                            <div class="success result-flowPay" v-if='modal.status == "success"'>
                                <h2 class="title-secondary">{{getMyLanguage("wallets","modal-new-destiny.success.title")}}</h2>
                                <p>{{getMyLanguage("wallets","modal-new-destiny.success.msg")}}</p>

                                <div class="resume">
                                    <div><strong>{{getMyLanguage("dnePay","pay.modal-pay.resume.partner")}}&nbsp;</strong><span>{{dataResume.MerchantName}}</span></div>
                                    <div><strong>{{getMyLanguage("dnePay","pay.modal-pay.resume.description")}}&nbsp;</strong><span>{{dataResume.Details}}</span></div>
                                    <div><strong>{{getMyLanguage("dnePay","pay.modal-pay.resume.value")}}&nbsp;</strong><span class="price price-white">{{dataResume.Amount.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</span></div>
                                </div>
                            </div>

                            <!-- ERROR REGISTER PAYMENT -->
                            <div class="error result-flowPay" v-if='modal.status == "failure"'>
                                <h2 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h2>
                                <p>{{getMyLanguage("dnePay","pay.modal-pay.error-payment")}}</p>

                                <div class="btn-primary">{{getMyLanguage("dnePay","pay.try-again")}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overlayModal" v-if="modal.pay || modal.getCode"></div>


            <!-- BOX ERROR QRCODE -->
            <div class="box box-error-qrcode" v-if='!errorsGet && error == "noData"'>
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{getMyLanguage("dnePay",'pay.msg.box-error.no-data')}}</p>

                <div class="btn-primary" @click='scanQRCode = true, error = false, dataResume = ""'>{{getMyLanguage("dnePay","pay.try-again")}}</div>
            </div>
            
            
            <!-- BOX ERROR BALANCE -->
            <div class="box box-error-balance" v-if='!errorsGet && error == "noBalance"'>
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{getMyLanguage("dnePay",'pay.msg.box-error.no-balance')}}</p>
                
                <div class="btn-primary" @click='lnkToken()'>{{getMyLanguage("dnePay","pay.msg.box-error.no-balance.cta-purchase")}}</div>
            </div>


            <div class="box-error" v-if='!errorsGet && error != false && error != "noData" && error != "noBalance"'>
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{error}}</p>
            </div>


            <div class="box-error" v-if="errorsGet != false">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error","msg-error") }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";
    import { QrcodeStream } from 'qrcode-reader-vue3'
    import {mask} from 'vue-the-mask'

    import apiPay from '@/config/apiPay.js';
    import { handleError } from '@/services/errorHandler.js'

    export default {
        directives: {mask},
        components:{
            QrcodeStream
        },

        data () {
            return {
                flagSelected: {},
                dataProfile: {},

                loadCam: true,

                scanQRCode: true,
                dataResume: "",
                error: false,

                dataWallet: {},
                modal: {
                    load: false,
                    
                    pay: false,
                    status: false,
                    
                    countDownResendCode: 0,
                    btnResendSMS: false,
                    _2fa_tokenShow: false
                },
                payData: {
                    _2fa_token: '',
                    aaa: "aaa"
                },

                flowToPay: {},

                errorsPost: false,
                errorsGet: false,
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            decode(decodeString){
                this.dataResume = JSON.parse(decodeString)
                this.scanQRCode = false

                console.log(this.dataResume)

                if ( this.dataResume.TransactionType === "Payment" ){
                    this.error = false

                    if ( this.dataWallet.balance >= this.dataResume.Amount ){
                        this.flowToPay.destiny_account_wallet_id = this.dataResume.AccountWalletId
                        this.flowToPay.asset_short = "DNE"
                        this.flowToPay.quantity = this.dataResume.Amount
                        this.flowToPay.description = this.dataResume.Details
                    } else{
                        this.error = "noBalance"
                    }

                } else{
                    this.error = "noData"
                }
            },

            async onInit (promise) {
                setTimeout(() => {
                    this.loadCam = false
                }, 1000);

                setTimeout(() => {
                    if ( this.dataResume == "" && this.scanQRCode ){
                        this.scanQRCode = false
                        this.error = "noData"
                    }
                }, 30000);

                try {
                    await promise
                } catch (error) {
                    if (error.name === 'NotAllowedError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.NotAllowedError")
                    } else if (error.name === 'NotFoundError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.NotFoundError")
                    } else if (error.name === 'NotSupportedError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.NotSupportedError")
                    } else if (error.name === 'NotReadableError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.NotReadableError")
                    } else if (error.name === 'OverconstrainedError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.OverconstrainedError")
                    } else if (error.name === 'StreamApiNotSupportedError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.StreamApiNotSupportedError")
                    } else if (error.name === 'InsecureContextError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.InsecureContextError")
                    } else {
                        this.error = `ERROR: Camera error (${error.name})`;
                    }
                }
            },


            getCodePay(){
                // post para o getCode

                // then
                
                this.modal.pay = true
                this.modal.status = "getCode"
                this.modal._2fa_tokenShow = false
                this.modal.load = true
                
                setTimeout(() => {
                    this.modal._2fa_tokenShow = true
                    this.modal.load = false
                    this.modal.btnResendSMS = false
                    this.modal.countDownResendCode = 10
                    this.countDownPayData()
                }, 1000);

                
            },

            countDownPayData() {

                if(this.modal.countDownResendCode > 0) {
                    setTimeout(() => {
                        this.modal.countDownResendCode -= 1
                        this.countDownPayData()
                    }, 1000)
                } else{
                    this.modal.btnResendSMS = true
                }
            },

            confirmCodePay(){
                this.modal.load = true
                this.modal.status = "validating"

                // if ( validar código )
                setTimeout(() => {
                    this.modal.load = false
                    this.modal.status = "validated"
                }, 2000);
            },

            toPay(){
                this.modal.load = true

                apiPay.post('/api/v1/pay/tx/payment', this.flowToPay)
                .then(response => {
                    this.registerId = response.data.id
                    
                    setTimeout(() => {
                        this.modal.status = "success"                        
                        this.modal.load = false
                    }, 100);


                    // this.updateBalance()
                })
                .catch(error => {
                    this.errorsPost = error.response.status
                    this.loadPay = false
                })
            },

            async updateBalance(){
                await apiPay.get('/api/v1/pay/wallet/balances/DNE')
                .then(response => {
                    this.dataWallet = response.data.source_wallets.filter(spot => spot.account_wallet_type_code === "spot")[0]
                })
                .catch(error => {
                    this.errorsGet = error.response.status
                })
            },



            lnkToken(){
                window.location.href = `${this.flagSelected}/international/token/`
            },
            
            lnkActivities(){
                window.location.href = `${this.flagSelected}/dnepay/activities/`
            },
        },

        async mounted () {
            this.flagUrl()
            
            this.dataProfile = JSON.parse(localStorage.getItem("profile"))

            await apiPay.get('/api/v1/pay/wallet/balances/DNE')
            .then(response => {
                this.dataWallet = response.data.source_wallets.filter(spot => spot.account_wallet_type_code === "spot")[0]
                console.log(this.dataWallet)

                this.flowToPay.source_account_wallet_id = this.dataWallet.account_wallet_id
                
                setTimeout(() => {
                    this.showWallets = true
                    this.load = false
                }, 1000);
            })
            .catch(error => {
                this.errorsGet = error.response.status

                handleError(error, this.flagSelected);
            })
        }
    }
</script>

<style scoped src="@/assets/css/Balances.css" />
