<template>
    <ion-app :scroll-events="true" class="vouchers">
        <ion-page>
            <div class="header-step">
                <HeaderApp />
            </div>

            <ion-content class="vouchers">
                <div class="content">

                    <div class="ion-padding">

                        <Vouchers />

                    </div>

                </div>
            </ion-content>

            <FooterApp />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import HeaderApp from '@/components/dneinternational/HeaderApp.vue';
    import Vouchers from '@/components/dneinternational/Vouchers.vue';
    import FooterApp from '@/components/dneinternational/FooterApp.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            HeaderApp,
            Vouchers,
            FooterApp
        },

        data () {
            return {
                flagSelected: {},
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            }
        },

        async mounted () {
            this.flagUrl();
        }
    }
</script>