<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <HeaderApp />

            <ion-content class="ion-padding">
                <div class="load-green" v-if="!showContent"></div>

                <div class="box-error" v-if="showContent && (errors.error_404 || errors.error_general)">
                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                    <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                    <div class="cod-error">{{ getMyLanguage("box-error",errors.error_msg) }}</div>
                </div>

                <div class="content" v-if="showContent && (!errors.error_404 && !errors.error_general && !errors.error_blockPurchase)">
                    <div class="banner-home">
                        <div class="cont-banner">
                            <h2 class="title-primary">DNE <span>T</span>oken</h2>
                            <p>{{ getMyLanguage("home", "home.content.banner") }}</p>
                            <div @click="lnkToken()" class="btn-primary">{{ getMyLanguage("home", "home.content.banner.cta") }}</div>
                        </div>
                        <img src="@/assets/images/home/banner-dne-token-purchase.jpg">
                    </div>


                    <div class="home-content">
                        <div @click="lnkWallets()" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-dne-token.svg">
                                <h4><span>I</span>nternational</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>
                        
                        <div @click="lnkMarket()" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-market.svg">
                                <h4><span>M</span>arket</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>

                        <div @click="lnkPay()" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-pay.svg">
                                <h4><span>P</span>ay</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>
                        
                        <div @click="lnkBank()" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-bank.svg">
                                <h4>{{getMyLanguage("dne-bank","dne-bank.title")}}</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>

                         <div @click="lnkExchange()" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-exchange.svg">
                                <h4><span>E</span>xchange</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>
                    </div>

                    <div class="client-referer-invite" v-if="dataProfile.has_purchase">
                        <div class="cont">
                            <h4>{{getMyLanguage("client-space","client-space.bonus-invite-title")}}</h4>
                            <p>{{getMyLanguage("client-space","client-space.bonus-invite-txt")}}</p>
                            <div class="btn-secondary btn-url-copy" @click="copyInvite()">{{getMyLanguage("client-space","client-space.bonus-invite-cta")}}</div>
                        </div>
                    </div>
                </div>
            </ion-content>

            <FooterApp />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";
    
    import HeaderApp from '@/components/dneplatform/HeaderApp.vue';
    import FooterApp from '@/components/dneplatform/FooterApp.vue';
    
    import { Clipboard } from '@capacitor/clipboard';

    // import apiPlatform from '@/config/apiPlatform';
    // import { handleError, error404, errorGeneral, errorMsg, errorBlockPurchase } from '@/services/errorHandler.js'

    export default {
        components: {
            IonApp, IonPage, IonContent,
            HeaderApp,
            FooterApp
        },
        data () {
            return {
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                showContent: false,

                flagSelected: {},
                dataProfile: {},

                errors: {
                    errorPost: false,
                    error_404: false,
                    error_general: false,
                    error_blockPurchase: false,
                    error_msg: ""
                },
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            lnkToken(){
                window.location.href = `${this.flagSelected}/international/token/`
            },

            lnkWallets(){
                window.location.href = `${this.flagSelected}/international/activity/`
            },

            lnkExchange(){
                window.location.href = `${this.flagSelected}/dneexchange/`
            },

            lnkBank(){
                window.location.href = `${this.flagSelected}/dnebank/`
            },

            lnkMarket(){
                window.location.href = `${this.flagSelected}/dnemarket/`
            },

            lnkPay(){
                window.location.href = `${this.flagSelected}/dnepay/activities`
            },


            async copyInvite() {
                let urlCopy
                if ( localStorage.getItem("phaseCurrent") == "Private" ){
                    urlCopy = `${this.VUE_APP_PLATFORM_URL}en/account/lead/?referrer=${this.dataProfile.account_id}`
                } else {
                    urlCopy = `${this.VUE_APP_PLATFORM_URL}en/account/register/?referrer=${this.dataProfile.account_id}`
                }

                try {
                    await Clipboard.write({
                        string: urlCopy
                    });
                    
                    const toast = await toastController
                        .create({
                            message: this.getMyLanguage("receive", "modal-receive.copied"),
                            color: "secondary",
                            duration: 2000,
                            position: 'middle',
                        })
                    return toast.present();

                } catch (error) {
                    console.error(error);

                    const toast = await toastController
                        .create({
                            message: this.getMyLanguage("box-error", "msg-error"),
                            color: "danger",
                            duration: 2000,
                            position: 'middle',
                        })
                    return toast.present();
                }

                // try {
                //     if ( localStorage.getItem("phaseCurrent") == "Private" ){
                //         await navigator.clipboard.writeText(`${this.VUE_APP_PLATFORM_URL}en/account/lead/?referrer=${this.dataProfile.account_id}`);
                //     } else {
                //         await navigator.clipboard.writeText(`${this.VUE_APP_PLATFORM_URL}en/account/register/?referrer=${this.dataProfile.account_id}`);
                //     }
                
                //     const toast = await toastController
                //         .create({
                //             message: this.getMyLanguage("receive", "modal-receive.copied"),
                //             color: "secondary",
                //             duration: 2000,
                //             position: 'middle',
                //         })
                //     return toast.present();
                // }
                
                // catch($e) {
                //     window.alert(this.getMyLanguage("box-error","msg-error"))
                // }
            }
        },


        async mounted () {
            this.flagUrl();

            this.dataProfile = JSON.parse(localStorage.getItem("profile"))

            // await apiPlatform.get('/api/v1/platform/account/profile/')
            // .then(response => {
            //     this.dataProfile = response.data
            //     console.log(this.dataProfile)

            //     const aux = JSON.stringify(this.dataProfile)
            //     localStorage.setItem("profile",aux)

            //     this.showContent = true
            // })
            // .catch(error => {
            //     setTimeout(() => {
            //         this.showContent = true
            //     }, 500);

            //     handleError(error, this.flagSelected);
                
            //     this.errors.error_404 = error404
            //     this.errors.error_general = errorGeneral
            //     this.errors.error_blockPurchase = errorBlockPurchase
            //     this.errors.error_msg = errorMsg
            // })

            setTimeout(() => {
                this.showContent = true
            }, 1500);
        },
    }
</script>


<style scoped>
    ion-content{--background: none;}

    /* .banner-home{position: relative; margin-bottom: 20px; margin-top: 0; -webkit-animation: ShowContentHome .7s alternate;}
    .banner-home .cont-banner{position: absolute; left: 0; top: 43%; transform: translateY(-50%); padding-left: 24px;}
    .banner-home .cont-banner .title-primary{filter: brightness(0) invert(1); text-align: left; margin-bottom: 6px;}
    .banner-home .cont-banner p{color: var(--color-tertiary); margin-bottom: 0; width: 80%; font-size: 10px;}
    .banner-home .cont-banner .btn-primary{margin-top: 8px; width: 65%; height: 36px; font-size: 14px;}
    .banner-home .cont-banner .btn-secondary{margin-top: 6px;}
    .banner-home img{width: 100%; height: auto;}
    
    .home-content{display: flex; flex-wrap: wrap; justify-content: center; max-width: 600px; position: relative; left: 50%; transform: translateX(-50%); margin-bottom: 50px; margin-top: 0; -webkit-animation: ShowContentHome 1.4s alternate;}
    .lnk-home{display: flex; justify-content: center; align-items: center; flex-basis: 30.33%; width: 100%; border-radius: 50%; margin: 0 1.5%; position: relative; background: #ffffff; -webkit-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -moz-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -webkit-animation: showScale .4s alternate;}
    .lnk-home .size-cicle{width: 100%; height: auto;}
    .lnk-home .cont-lnk{display: flex; flex-direction: column; justify-content: center; align-items: center; position: absolute; left: 50%; top: 50%; transform: translateX(-50%) translateY(-50%);}
    .lnk-home .cont-lnk img{width: auto; height: 30px; margin-bottom: 5px;}
    .lnk-home .cont-lnk h4{margin: 0; font-size: 12px; font-weight: bold; white-space: nowrap; color: var(--color-primary);}
    .lnk-home .cont-lnk h4 span{display: inline-block; position: relative; padding-bottom: 0px; color: var(--color-secondary);}
    .lnk-home .cont-lnk h4 span:after{content: ''; display: block; min-width: 5px; width: 84%; height: 2px; position: absolute; left: 8%; bottom: 0; background: var(--color-secondary);}
    .lnk-home.lnk-home-dne{background: var(--color-secondary);}
    .lnk-home.lnk-home-dne h4{filter: brightness(0) invert(1);}


    @media (min-width: 375px) {
        .lnk-home .cont-lnk img{height: 35px;}
        .lnk-home .cont-lnk h4{font-size: 14px;}

        .banner-home .cont-banner .title-primary{margin-bottom: 12px;}
        .banner-home .cont-banner p{width: 70%;}
    }

    @media (min-width: 410px){
        .lnk-home .cont-lnk img{height: 45px;}
        .lnk-home .cont-lnk h4{font-size: 15px;}
    }

    @media (min-width: 576px){
        .lnk-home .cont-lnk img{height: 70px; margin-bottom: 10px;}
        .lnk-home .cont-lnk h4{font-size: 16px;}

        .banner-home .cont-banner{left: 50px; top: 43%; transform: translateY(-50%);}
        .banner-home .cont-banner .title-primary{margin-bottom: 20px;}
        .banner-home .cont-banner p{font-size: 16px; margin-bottom: 0; width: 60%;}
        .banner-home .cont-banner .btn-primary{margin-top: 30px; width: 40%; height: 46px; font-size: 18px;}
        .banner-home .cont-banner .btn-secondary{margin-top: 10px;}
    }
    
    @media (min-width: 660px){
        .lnk-home .cont-lnk h4{font-size: 18px;}
    }

    .phases{margin-top: 60px; margin-bottom: 40px;} */

    
    /* CLIENT REFERER INVITE */
    /* .client-referer-invite{padding: 20px; margin-bottom: 30px; margin-top: 0; border-radius: 16px; background: url('~@/assets/images/bg-blue.png') no-repeat left bottom; background-size: cover; -webkit-animation: ShowContentHome 2.1s alternate;}
    .client-referer-invite .cont{position: relative; z-index: 20;}
    .client-referer-invite .cont h4{font-size: 16px; line-height: 18px; font-family: var(--font-primary-semibold); color: var(--color-secondary); margin-bottom: 5px;}
    .client-referer-invite .cont p{font-size: 12px; line-height: 16px; color: #ccc; margin-bottom: 5px;}
    .client-referer-invite .cont .btn-url-copy{color: var(--color-text-tertiary)}
    .client-referer-invite .cont .btn-url-copy:before{background: var(--color-secondary);}
    .client-referer-invite .cont .btn-url-copy:after{background: url('~@/assets/images/icons/copy-white.svg') no-repeat;} */
    
    /* @keyframes ShowContentHome {
        0% {margin-top: 15px;}
        100% {margin-top: 0;}
    } */


    /* FOOTER */
    .col-lnks.lnks-left{justify-content: left; padding-left: 20px;}
    .col-lnks.lnks-right{justify-content: right; padding-right: 20px;}
</style>