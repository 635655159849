<template>
    <div class="modal modal-login" v-if="dataSource.modal">
        <div class="modal-cont">
            <div class="close-modal" @click="closeModal()"></div>
            <div class="form-login ion-padding" v-if="!loginRemember.show">
                <ion-row>
                    <ion-col class="header">
                        <h3>{{ getMyLanguage("login", "login.hello.msg") }}</h3>
                    </ion-col>
                    <!-- <ion-col size="auto" class="selectLang">
                        <SelectLanguage />
                    </ion-col> -->
                </ion-row>

                <form>
                    <ion-list>
                        <!-- EMAIL LOGIN -->
                        <ion-item lines="none">
                            <input v-model="sign.username" type="email" imputemode="email" @keyup.enter="validateForm()" class="form form-email" :class='{error: sign.emptyUsername}' spellcheck="false" autocapitalize="off" :placeholder='getMyLanguage("login", "login.placeholder.email")'>
                        </ion-item>

                        <!-- PASSWORD -->
                        <ion-item lines="none">
                            <div class="enterPass">
                                <input v-model="sign.password" type="password" v-if="!showPass" @keyup.enter="validateForm()" class="form form-pass" :class='{error: sign.emptyPassword}' :placeholder='getMyLanguage("login", "login.placeholder.password")'>
                                <input v-model="sign.password" type="text" v-if="showPass" @keyup.enter="validateForm()" class="form form-pass" :class='{error: sign.emptyPassword}' :placeholder='getMyLanguage("login", "login.placeholder.password")'>

                                <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                                <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                            </div>
                        </ion-item>

                        <div class="remember-password">
                            <div class="btn-remember-password" @click="loginRemember.show = true">{{ getMyLanguage("login", "login.remember-password") }}</div>
                        </div>

                        <ion-item lines="none">
                            <ion-button class="btnPrimary btn-disable" v-if="sign.username.length <= 5 || sign.password.length <= 7">{{ getMyLanguage("login","login.btn.continue") }}</ion-button>
                            <ion-button @click="validateForm()" expand="block" class="btnPrimary" v-if="stepLogin.load === false && sign.username.length > 5 && sign.password.length > 7">{{ getMyLanguage("login", "login.btn.continue") }}</ion-button>
                            <div class="btn-primary btn-loading" v-if="stepLogin.load === true"></div>
                        </ion-item>

                        <div class="login-register">
                            <p class="or"><span>{{ getMyLanguage("login", "login.hello.or") }}</span></p>
                            
                            <a :href="`/${flagSelected}/platform/register`" class="btn-register" v-if="phaseCurrentRegister == 'Private'">
                                <span>{{ getMyLanguage("register", "register.title.interested") }}</span>
                            </a>
                            
                            <a :href="`/${flagSelected}/platform/register`" class="btn-register" v-if="phaseCurrentRegister != 'Private'">
                                <span>{{ getMyLanguage("login", "login.nav.createAccount") }}</span>
                            </a>
                        </div>
                    </ion-list>
                </form>
            </div>
        


            <!-- REMEMBER PASS -->
            <div class="form-login remember ion-padding" v-if="loginRemember.show">
                <!-- <ion-row>
                    <ion-col class="header">
                        <h3>{{ getMyLanguage("login", "login.remember-password") }}</h3>
                        <p>{{ getMyLanguage("login", "login.remember-password.msg") }}</p>
                    </ion-col>
                    <ion-col size="auto" class="selectLang">
                        <SelectLanguage />
                    </ion-col>
                </ion-row> -->

                <form>
                    <!-- EMAIL LOGIN -->
                    <ion-list v-if="!loginRemember.success && !loginRemember.error">
                        <ion-item lines="none">
                            <input v-model="emailRemember.email" type="email" class="form form-email" :class='{error: sign.emptyUsernameRemember}' spellcheck="false" autocapitalize="off" :placeholder='getMyLanguage("login", "login.remember-email-registered")'>
                        </ion-item>

                        <ion-item>
                            <ion-button class="btnPrimary btn-disable" v-if="emailRemember.email.length <= 5">{{ getMyLanguage("login","login.btn.rememberPass") }}</ion-button>
                            <ion-button @click="rememberPass()" expand="block" class="btnPrimary" v-if="stepLogin.load === false && emailRemember.email.length > 5">{{ getMyLanguage("login", "login.btn.rememberPass") }}</ion-button>
                            <div class="btn-primary btn-loading" v-if="stepLogin.load === true"></div>
                        </ion-item>
                    </ion-list>

                    <!-- SUCCESS REMEMBER -->
                    <ion-list v-if="loginRemember.success">
                        <ion-item>
                            <div class="box box-success">
                                <p>{{ getMyLanguage("login","login.remember-password.success") }}</p>
                            </div>
                        </ion-item>
                    </ion-list>
                    
                    <!-- ERROR REMEMBER -->
                    <ion-list v-if="loginRemember.error">
                        <ion-item>
                            <div class="box box-error">
                                <p>{{ getMyLanguage("login","login.remember-password.error") }}</p>
                            </div>
                        </ion-item>
                    </ion-list>
                </form>
            </div>
        </div>
    </div>
    
    <div class="overlayModal" v-if="dataSource.modal"></div>
</template>

<script>
    import {IonRow, IonItem, IonCol, IonList, IonButton, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import apiPlatform from '@/config/apiPlatform.js'

    export default {
        components: {
            IonRow, IonItem, IonCol, IonList, IonButton,
        },

        props:{
            dataSource: {}
        },

        data () {
            return {
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,

                flagSelected: {},
                showPass: false,

                stepLogin: {
                    'load': false,
                    'loadCode': false,
                    'statusEmailPass': true,
                    'statusCode': false
                },

                sign: {
                    "username": "",
                    "password": ""
                },

                codeConfirm: {},

                loginRemember: {
                    show: false,
                    success: false,
                    error: false
                },

                emailRemember: {
                    email: ""
                },

                phaseCurrentRegister: ""
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            resendCode(){
                window.navigator.vibrate(200);
            },

            validateForm(){
                this.stepLogin.load = true

                if ( this.sign.username == undefined || this.sign.username == "" ){
                    this.sign.emptyUsername = true
                    this.stepLogin.load = false
                } else if ( this.sign.password == undefined || this.sign.password == "" ){
                    this.sign.emptyUsername = false
                    this.sign.emptyPassword = true
                    this.stepLogin.load = false
                } else{
                    this.sign.emptyUsername = false
                    this.sign.emptyPassword = false
                    
                    this.loginPlatform()
                }
            },

            validateCode(){
                if ( this.codeConfirm._2fa == undefined || this.codeConfirm._2fa == "" ){
                    this.codeConfirm.empty2fa = true
                } else{
                    this.stepLogin.loadCode = true
                }
            },


            rememberPass(){
                this.stepLogin.load = true
                
                apiPlatform.post("/api/v1/platform/account/password/reset", this.emailRemember)
                .then(response => {
                    console.log(response)
                    this.loginRemember.success = true
                    this.stepLogin.load = false

                })
                .catch(error => {
                    console.log(error)
                    this.loginRemember.error = true
                    this.stepLogin.load = false
                })
            },

            loginPlatform(){
                const loginPlatform = this.sign;

                apiPlatform.post("/api/v1/platform/account/login", loginPlatform)
                .then(response => {
                    this.stepLogin.load = true

                    this.dataProfile = response.data
                    const aux = JSON.stringify(this.dataProfile.profile)
                    localStorage.setItem("profile",aux)

                    localStorage.setItem('accessToken',response.data.accessToken);
                    localStorage.setItem('codeLanguage',response.data.profile.account_language_alpha_2);
                    localStorage.setItem('flagLanguage',response.data.profile.account_language_flag);
                    

                    this.flagSelected = localStorage.getItem('codeLanguage')
                    
                    window.location.href = `${this.flagSelected}/${this.dataSource.slug}`
                })
                .catch(error => {
                    this.stepLogin.load = false
                    this.errorMessage = error.message;
                    this.openToast();
                    console.clear()
                    console.log(error.message);
                })
            },


            // loginCode(){
            //     const loginCode = this.codeConfirm;

            //     apiPlatform.post("/api/v1/platform/account/login", loginCode)
            //     .then(response => {
            //         this.registerId = response.data.id;
            //         window.location.href = `${this.flagSelected}/home/${response.data.id}`
            //     })
            //     .catch(error => {
            //         this.errorMessage = error.message;
            //         console.log(error.response.data)
            //     })
            // },

            async openToast() {
                const toast = await toastController
                    .create({
                    message: this.getMyLanguage("login","login.error.login-password"),
                    color: "danger",
                    duration: 2000
                    })
                return toast.present();
            },

            closeModal(){
                window.location.reload()
            }
        },


        async mounted () {
            this.flagUrl();

            setTimeout(() => {
                this.phaseCurrentRegister = localStorage.getItem("phaseCurrent")
            }, 2000);
        }
    }
</script>

<style scoped>
    .modal-login .modal-cont{max-height: 90vh;}
    .modal-login .modal-cont .close-modal{top: -15px; right: -5px; background-color: #eee;}
    .modal-login .form-login{padding: 1rem 1rem 1.5rem 1rem; border-radius: 16px 0 16px 16px;}

    .modal-login .login-register .or{margin: 2rem 0 .75rem 0; border-top: 2px solid #bbb; line-height: 20px; text-align: center;}
    .modal-login .login-register .or span{position: relative; top: -12px; display: inline-block; padding: 0 .5rem; background: #e2e2e2;}
    .modal-login .login-register .btn-register{display: flex; justify-content: center; align-items: center; --background: #f5f5f5; background: #f5f5f5; border: 1px solid #bbb; height: 40px; text-align: center; color: var(--color-primary); font-size: 14px; width: 100%; border-radius: 12px; cursor: pointer;}
</style>