<template>
    <ion-header class="header">
        <div class="header-row" :class="{develop: VUE_APP_DEVELOP == 'develop'}">
            <div class="logo-balances">
                <div class="logo-circle" @click="lnkWallets()">
                    <img src="@/assets/images/dne.svg">
                </div>
            </div>
            
            <div class="user">
                <div class="avatar" v-if="showContent">
                    <h4>
                        <!-- <span>{{ getMyLanguage("header", "header.welcome") }}</span> -->
                        {{dataProfile.profile_full_name}}
                        
                        <div class="dne"><i>DNE</i> <span>International</span></div>
                    </h4>
                    
                    <div class="photoUser">
                        <img src="@/assets/images/users/user.svg">
                    </div>
                </div>
            </div>

            <div class="selectLanguage">
                <SelectLanguage />
            </div>
        </div>

        <ValidatePhoneNumber />
    </ion-header>
</template>

<script>
    import {IonHeader} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";
    import SelectLanguage from "@/components/SelectLanguage.vue";
    import ValidatePhoneNumber from "@/components/ValidatePhoneNumber.vue";

    export default {
        components: {
            IonHeader,
            SelectLanguage,
            ValidatePhoneNumber
        },
        data () {
            return {
                VUE_APP_DEVELOP: process.env.VUE_APP_DEVELOP,
                
                flagSelected: {},
                showContent: false,
                dataAccount: []
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            lnkWallets(){
                window.location.href = `${this.flagSelected}/international/wallets/`
            }
        },

        async mounted () {
            this.flagUrl();            
            
            this.dataProfile = JSON.parse(localStorage.getItem("profile"))
            
            setTimeout(() => {
                if ( !this.dataProfile ){
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('profile');
                    localStorage.removeItem('phaseCurrent');
                    window.location.href = this.flagSelected + '/home/'
                }
            }, 100);

            this.showContent = true
        }
    }
</script>


<style scoped src="@/assets/css/Header.css" />