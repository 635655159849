<template>
   <div class="checkout">
      <div class="load-green" v-if="load"></div>

      <div class="box-error" v-if="!load && errorsGet.qrCode != false">
         <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
         <p>{{ getMyLanguage("box-error", "inactive-tool") }}</p>
      </div>

      <div class="send-content" v-if="dataWallets && !load && !errorsGet.qrCode">
         <div class="box">
         <!-- SELECT WALLET FROM -->
         <div class="select-wallet">
            <h3 class="title-secondary">
               {{ getMyLanguage("dnePay", "checkout.title") }}
               {{ dataWallets.asset_name }}
            </h3>

            <div class="form-group-radio" v-for="(walletsAccounts, indexAccount) in dataWallets.source_wallets" :key="indexAccount">
               <!-- <input type="radio" class="form-radio" name="selectWallet" :id="walletsAccounts.account_wallet_id" @click="walletFrom(walletsAccounts,dataWallets.asset_short)" :checked='(walletsAccounts.account_wallet_id === newQrCode.account_wallet_id)'> -->
               <input type="radio" class="form-radio" name="selectWallet" :id="walletsAccounts.account_wallet_id" :checked="walletsAccounts.account_wallet_id === newQrCode.account_wallet_id"/>
               <label :for="walletsAccounts.account_wallet_id" class="form-radio-label">
               
               <div>
                  <span>{{ walletsAccounts.wallet_name }}</span>
               </div>
               
               </label>
            </div>
         </div>

         <!-- VALUE CHECKOUT -->
         <div class="value-send">
            <label class="lbl-value">
               <div>
               <span>{{getMyLanguage("dnePay","checkout.description")}}</span>
               <!-- <div class="alert-value" v-if="flowQRCode.emptyDetails">Informe a descrição</div> -->
               </div>

               <textarea class="form" :class="{ error: flowQRCode.emptyDetails }" v-model="dataQRCode.Details" :placeholder='getMyLanguage("dnePay","simple-description")'></textarea>
            </label>

            <label class="lbl-value">
               <div>
               <span>{{ getMyLanguage("wallets", "modal-send.value") }}</span>
               <!-- <div class="alert-value" v-if="flowQRCode.alertValueMax">{{getMyLanguage("wallets","modal-send.maximum-value")}} {{this.send.amount}}</div>
                              <div class="alert-value" v-if="flowQRCode.alertValueMin">{{getMyLanguage("wallets","modal-send.minimum-value")}} {{this.send.amount}}</div> -->
               <!-- <div class="alert-value" v-if="flowQRCode.emptyAmount">Informe o valor</div> -->
               </div>

               <!-- <input type="number" class="form" v-model="newQrCode.value" @keyup="sendAmount()" @blur="sendAmountMax()" placeholder="0"> -->
               <input type="number" inputmode="decimal" class="form" :class="{ error: flowQRCode.emptyAmount }" v-model="dataQRCode.Amount" placeholder="0,00" />
            </label>
         </div>

         <!-- AMOUNT -->
         <div class="amount" :class="{ disable: !dataQRCode.Details || !dataQRCode.Amount }">
               <!-- <table>
                  <tr>
                     <td>{{getMyLanguage("wallets","modal-send.grossTotal")}}</td>
                     <td>{{newQrCode.gross.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                  </tr>
                  <tr v-if="newQrCode.withdrawalFee > 0">
                     <td>{{getMyLanguage("wallets","modal-send.fee")}} +{{newQrCode.withdrawalFee.toLocaleString("pt-PT", { maximumFractionDigits: 2 })}}%</td>
                     <td>{{newQrCode.feeValue.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                  </tr>
                  <tr v-if="newQrCode.withdrawalTax > 0">
                     <td>{{getMyLanguage("wallets","modal-send.tax")}} {{newQrCode.withdrawalTax}}%</td>
                     <td>{{newQrCode.taxValue.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                  </tr>
               </table> -->

            <div class="amount-value">
               <span>{{ getMyLanguage("wallets", "modal-send.netTotal") }}</span>

               <!-- <strong :class="newQrCode.assetShort" v-if='newQrCode.assetShort === "DNE"'><span>{{newQrCode.net.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</span></strong>
                           <strong :class="newQrCode.assetShort" v-if='newQrCode.assetShort === "EUR"'><span>{{newQrCode.net.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></strong> -->

               <strong class="DNE"><span>{{dataQRCode.Amount.toLocaleString("pt-PT", {maximumFractionDigits: 4,})}}</span></strong>
            </div>

            <div class="btn-primary btn-confirm" @click="generationQRCode()">{{getMyLanguage("dnePay","checkout.generate-qrCode")}}</div>
         </div>
         </div>
      </div>

      <!-- MODAL QR CODE -->
      <div class="modal modal-qrcode" :class="{ validatePhone: !dataProfile.profile_phone_number_verified }" v-if="modal.qrCode">
         <div class="modal-cont">
         <div class="close-modal" @click="modal.qrCode = false"></div>

         <div class="box">
            <qrcode-vue :value="qrCode.value" :size="qrCode.size" level="L" />
            <h2 class="priceDNE"><span>{{dataQRCode.Amount.toLocaleString("pt-PT", {maximumFractionDigits: 4,})}}</span></h2>
         </div>
         </div>
      </div>
      <div class="overlayModal" v-if="modal.qrCode"></div>
   </div>
</template>

<script>
import getMyFavouriteLanguage from "@/services/language.js";
import moment from "moment";
import QrcodeVue from "qrcode.vue";
import apiPay from "@/config/apiPay";

export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      flagSelected: {},
      dataProfile: "",
      load: true,

      dataWallets: [],

      modal: {
        qrCode: false,
      },

      qrCode: {
        value: "",
        size: 268,
      },

      dataQRCode: {
        Amount: 0,
      },

      flowQRCode: {
        emptyAmount: false,
        emptyDetails: false,
      },

      errorsPost: {
        qrCode: false,
      },

      errorsGet: {
        qrCode: false,
      },
    };
  },

  methods: {
    flagUrl() {
      const flagSelect = String(window.location.pathname).split("/")[1];
      this.flagSelected = flagSelect;
    },

    getMyLanguage(group, code) {
      return getMyFavouriteLanguage(group, code);
    },

    dateTime(value) {
      return moment(value).locale(this.flagSelected).format("ll");
    },

    dateTimeShort(value) {
      return moment(value).locale(this.flagSelected).format("DD/MM");
    },

    generationQRCode() {
      if (this.dataQRCode.Amount == 0 || !this.dataQRCode.Amount) {
        this.flowQRCode.emptyAmount = true;

        setTimeout(() => {
          this.flowQRCode.emptyAmount = false;
        }, 3000);
      }

      if (this.dataQRCode.Details === "") {
        this.flowQRCode.emptyDetails = true;

        setTimeout(() => {
          this.flowQRCode.emptyDetails = false;
        }, 3000);
      }

      if (this.dataQRCode.Amount > 0 && this.dataQRCode.Details != "") {
        this.qrCode.value = JSON.stringify(this.dataQRCode);
        this.modal.qrCode = true;
      }
    },
  },

  async mounted() {
    this.flagUrl();
    // const tokenValue = localStorage.getItem("accessToken");
    this.dataProfile = JSON.parse(localStorage.getItem("profile"));

    await apiPay
      .get("/api/v1/pay/wallet/wallets/DNE/payment")
      .then((response) => {
        this.dataWallet = response.data;

        (this.dataQRCode = {
          TransactionType: "Payment",
          OperationType: "Payment",
          AssetShort: this.dataWallet.AssetShort,
          CheckoutId: "",
          AccountWalletId: this.dataWallet.AccountWalletId,
          WalletAddress: this.dataWallet.WalletAddress,
          AccountWalletName: this.dataWallet.WalletName,
          MerchantName: this.dataProfile.profile_full_name,
          Details: "",
          Amount: '',
        }),
          setTimeout(() => {
            this.load = false;
          }, 1000);
      })
      .catch((error) => {
        this.errorsGet.qrCode = error.response.status;

        setTimeout(() => {
          this.load = false;
        }, 1000);

        // if ( error.response.status === 403 ) {
        //     this.showWallets = false
        //     window.location.href = `/${this.flagSelected}/account/consent`
        // }

        // if ( error.response.status === 409 ) {
        //     if ( error.response.data === "account_must_have_kyc" ){
        //         window.location.href = `/${this.flagSelected}/account/kyc`
        //     }

        //     if ( error.response.data === "account_must_have_compliance" ){
        //         window.location.href = `/${this.flagSelected}/account/compliance`
        //     }

        //     if ( error.response.data === "account_must_be_manualy_checked" ){
        //         window.location.href = `/${this.flagSelected}/account/pending`
        //     }
        // }

        // if( error.response.status === 401 || tokenValue.length > 0 ){
        //     localStorage.removeItem('accessToken');
        //     window.location.href = `/${this.flagSelected}/home`
        // }
      });
  },
};
</script>


<style scoped src="@/assets/css/Balances.css" />

<style scoped>
.send-content{-webkit-animation: showTopScale 0.4s alternate;}
.send-content .select-wallet{margin-bottom: 20px;}
.send-content .select-wallet .form-group-radio{margin-bottom: 10px;}
.send-content .select-wallet .form-group-radio .form-radio-label{background: #f5f5f5; width: 100%; padding: 10px 10px 10px 42px; border-radius: 12px;}
.send-content .select-wallet .form-group-radio .form-radio-label:before{left: 10px; top: 10px;}
.send-content .select-wallet .form-group-radio .form-radio:checked + label{background-color: #e9e9e9;}
.send-content .select-wallet .form-group-radio .form-radio-label div{width: 100%; display: flex; justify-content: space-between;}
.send-content .select-wallet .form-group-radio .form-radio-label div > span{font-size: 13px; font-weight: 600; color: var(--color-primary);}
.send-content .select-wallet .form-group-radio .form-radio-label div > strong{font-size: 12px; font-weight: 600; line-height: 16px; color: var(--color-secondary); text-align: right;}
.send-content .select-wallet .form-group-radio .form-radio-label div > strong small{display: block; font-size: 11px; font-weight: 400; color: var(--color-text-secondary);}
.send-content .select-wallet .form-group-radio .form-radio-label div > strong.DNE span{padding-right: 12px; background: url("~@/assets/images/icons/dne-symbol.svg") no-repeat center right; background-size: 10px auto;}

.send-content .amount{margin-bottom: 0;}

.send-content .amount.disable{filter: blur(2px);}

.modal .close-modal{top: -25px;}
.modal .modal-cont{text-align: center;}

.modal-qrcode canvas{padding: 20px; background: url('~@/assets/images/icons/scan-qrcode-visible.svg') no-repeat center; background-size: 100% auto;}
.modal-qrcode .priceDNE{display: flex; justify-content: center; margin: 0;}
.modal-qrcode .priceDNE span{font-family: var(--font-secondary-bold); font-size: 26px; line-height: 26px; color: var(--color-secondary); padding-right: 24px; background: url("~@/assets/images/icons/dne-symbol.svg") no-repeat right 6px; background-size: 20px auto;}
</style>