import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '@/pages/Home/Home.vue'
import Register from '@/pages/dneplatform/Account/Register.vue'
import RegisterSuccess from '@/pages/dneplatform/Account/RegisterSuccess.vue'
import HomePlatform from '@/pages/dneplatform/HomePlatform/HomePlatform.vue'
import Consent from '@/pages/dneplatform/Account/Consent.vue'
import Compliance from '@/pages/dneplatform/Account/Compliance.vue'
import ComplianceCompany from '@/pages/dneplatform/Account/ComplianceCompany.vue'
import ComplianceSuccess from '@/pages/dneplatform/Account/ComplianceSuccess.vue'
import BeneficialOwner from '@/pages/dneplatform/Account/BeneficialOwner.vue'
import ControllingPersonLegalEntities from '@/pages/dneplatform/Account/ControllingPersonLegalEntities.vue'
import Profile from '@/pages/dneplatform/Account/Profile.vue'
import ProfileSuccess from '@/pages/dneplatform/Account/ProfileSuccess.vue'
import Security from '@/pages/dneplatform/Security/Security.vue'

import DneBank from '@/pages/DneBank/DneBank.vue'
import DneMarket from '@/pages/DneMarket/DneMarket.vue'

// import DnePayActivities from '@/pages/DnePay/Activities/Activities.vue'
import DnePayMaintenance from '@/pages/DnePay/DnePayMaintenance.vue'
import DnePaySend from '@/pages/DnePay/Send/Send.vue'
import DnePayPay from '@/pages/DnePay/Pay/Pay.vue'
import DnePayReceive from '@/pages/DnePay/Receive/Receive.vue'
import DnePayCheckout from '@/pages/DnePay/Checkout/Checkout.vue'

import DneExchange from '@/pages/DneExchange/DneExchange.vue'

import KYC from '@/pages/DneInternational/Activity/KYC.vue'
import ShuftiSuccess from '@/pages/DneInternational/Activity/ShuftiSuccess.vue'
import Pending from '@/pages/DneInternational/Activity/Pending.vue'
import Token from '@/pages/DneInternational/Token/Token.vue'
import TokenPayment from '@/pages/DneInternational/Token/TokenPayment.vue'
import TokenPaymentSuccess from '@/pages/DneInternational/Token/TokenPaymentSuccess.vue'
import TokenPaymentSuccessId from '@/pages/DneInternational/Token/TokenPaymentSuccessId.vue'
import TokenPaymentError from '@/pages/DneInternational/Token/TokenPaymentError.vue'
import Wallets from '@/pages/DneInternational/Wallets/Wallets.vue'
import WalletStake from '@/pages/DneInternational/Wallets/WalletStake.vue'
import Vouchers from '@/pages/DneInternational/Vouchers/Vouchers.vue'
import Activity from '@/pages/DneInternational/Activity/Activity.vue'

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/en/home/' },
  { path: '/:lang/login/', redirect: '/en/home/' },
  { path: '/:lang/home/', name: 'Home', component: Home },
  { path: '/:lang/platform/register', name: 'Register', component: Register },
  { path: '/:lang/platform/register-success', name: 'RegisterSuccess', component: RegisterSuccess },
  { path: '/:lang/platform/home/', name: 'HomePlatform', component: HomePlatform },
  { path: '/:lang/account/consent/', name: 'Consent', component: Consent },
  { path: '/:lang/account/compliance/', name: 'Compliance', component: Compliance },
  { path: '/:lang/account/compliance-company/', name: 'ComplianceCompany', component: ComplianceCompany },
  { path: '/:lang/account/compliance-success/', name: 'ComplianceSuccess', component: ComplianceSuccess },
  { path: '/:lang/account/beneficial-owner/', name: 'BeneficialOwner', component: BeneficialOwner },
  { path: '/:lang/account/controlling-person-legal-entities/', name: 'ControllingPersonLegalEntities', component: ControllingPersonLegalEntities },
  { path: '/:lang/platform/profile/', name: 'Profile', component: Profile },
  { path: '/:lang/platform/profile-success/', name: 'ProfileSuccess', component: ProfileSuccess },
  { path: '/:lang/platform/security/', name: 'Security', component: Security },
  
  { path: '/:lang/dnebank/', name: 'DneBank', component: DneBank },
  { path: '/:lang/dnemarket/', name: 'DneMarket', component: DneMarket },
  
  { path: '/:lang/dnepay/activities', name: 'DnePayMaintenance', component: DnePayMaintenance },
  // { path: '/:lang/dnepay/activities', name: 'DnePayActivities', component: DnePayActivities },
  { path: '/:lang/dnepay/send', name: 'DnePaySend', component: DnePaySend },
  { path: '/:lang/dnepay/pay', name: 'DnePayPay', component: DnePayPay },
  { path: '/:lang/dnepay/receive', name: 'DnePayReceive', component: DnePayReceive },
  { path: '/:lang/dnepay/checkout', name: 'DnePayCheckout', component: DnePayCheckout },
  
  { path: '/:lang/dneexchange/', name: 'DneExchange', component: DneExchange },

  { path: '/:lang/account/kyc/', name: 'KYC', component: KYC },
  { path: '/:lang/account/shufti-success/', name: 'ShuftiSuccess', component: ShuftiSuccess },
  { path: '/:lang/account/pending/', name: 'Pending', component: Pending },
  { path: '/:lang/international/token/', name: 'Token', component: Token },
  { path: '/:lang/international/token-payment/', name: 'TokenPayment', component: TokenPayment },
  { path: '/:lang/international/token-payment-error/', name: 'TokenPaymentError', component: TokenPaymentError },
  { path: '/:lang/payment-success/', name: 'TokenPaymentSuccess', component: TokenPaymentSuccess },
  { path: '/:lang/payment-success/:registerId', name: 'TokenPaymentSuccessId', component: TokenPaymentSuccessId },
  { path: '/:lang/international/wallets/', name: 'Wallets', component: Wallets },
  { path: '/:lang/international/wallet-stake/', name: 'WalletStake', component: WalletStake },
  { path: '/:lang/international/Vouchers/', name: 'Vouchers', component: Vouchers },
  { path: '/:lang/international/activity/', name: 'Activity', component: Activity },


  // { path: '/login', component: () => import ('@/pages/Account/Login.vue') },
  // { path: '/register', component: () => import ('@/pages/Account/Register.vue') },
  // { path: '/home', component: () => import ('@/pages/Home/Home.vue') },
  // {
  //   path: '',
  //   redirect: '/folder/Inbox'
  // },
  // {
  //   path: '/folder/:id',
  //   component: () => import ('@/pages/Folder.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
