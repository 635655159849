<template>
    <div class="footer" :class="{openedMenu: menuFooter}">
        <div class="cont-footer">
            <div class="col-lnks lnks-right">
                <div @click="lnkHome()" class="lnk-footer">
                    <img src="@/assets/images/icons/btn-footer-home.svg">
                    <span>{{ getMyLanguage("footer", "footer.lnk.home") }}</span>
                </div>
            </div>


            <div class="col-open-menu">
                <div id="closeMenu" v-if="menuFooter" @click="menuFooter = false"></div>
                <div class="lnk-footer open-menu" id="openMenu" v-if="!menuFooter" @click="menuFooter = true">
                    <span>
                        <img src="@/assets/images/dne.svg">
                    </span>
                </div>
            </div>

            
            <div class="col-lnks lnks-left">            
                <!-- <div @click="lnkProfile()" class="lnk-footer">
                    <img src="@/assets/images/icons/btn-footer-profile.svg">
                    <span>{{ getMyLanguage("footer", "footer.lnk.profile") }}</span>
                </div> -->

                <div @click="lnkActivity()" class="lnk-footer">
                    <img src="@/assets/images/icons/btn-footer-activity.svg">
                    <span>{{ getMyLanguage("footer", "footer.lnk.activity") }}</span>
                </div>
            </div>
        </div>
    </div>

    <!-- MENU -->
    <div id="menu" v-if="menuFooter">
        <div class="contmenu ion-padding">
            <div class="menuNav">
                <ul class="box navbar-nav navbar-primary">
                    <div class="nav-link" @click="lnkToken()">
                        <img src="@/assets/images/menu/lnk-dne-token.svg">
                        <h6 class="title-primary">DNE <span>T</span>oken</h6>
                    </div>
                    <div class="nav-link" @click="lnkMarket()">
                        <img src="@/assets/images/menu/lnk-market.svg">
                        <h6 class="title-primary"><span>M</span>arket</h6>
                    </div>
                    <div class="nav-link" @click="lnkPay()">
                        <img src="@/assets/images/menu/lnk-payments.svg">
                        <h6 class="title-primary"><span>P</span>ay</h6>
                    </div>
                    <div class="nav-link" @click="lnkBank()">
                        <img src="@/assets/images/menu/lnk-bank.svg">
                        <h6 class="title-primary">{{getMyLanguage("dne-bank","dne-bank.title")}}</h6>
                    </div>
                    <div class="nav-link" @click="lnkExchange()">
                        <img src="@/assets/images/menu/lnk-exchange.svg">
                        <h6 class="title-primary"><span>E</span>xchange</h6>
                    </div>

                    <div class="nav-link nav-link-logout" @click="logoutPlatform()">
                        <img src="@/assets/images/menu/lnk-logout01.svg">
                        <h6 class="title-primary">{{ getMyLanguage("footer","footer.lnk.logout") }}</h6>
                    </div>
                </ul>

                <ul class="box navbar-nav navbar-secondary">
                    <div class="nav-link" @click="lnkSecurity()">
                        <img src="@/assets/images/menu/lnk-security.svg">
                        <h6>{{ getMyLanguage("footer", "footer.lnk.security") }}</h6>
                    </div>
                    <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/support`" target="_blank" class="nav-link">
                        <img src="@/assets/images/menu/lnk-support.svg">
                        <h6>{{ getMyLanguage("footer", "footer.lnk.support") }}</h6>
                    </a>
                    <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/terms-and-conditions`" target="_blank" class="nav-link">
                        <img src="@/assets/images/menu/lnk-terms-conditions.svg">
                        <h6>{{ getMyLanguage("footer", "footer.lnk.termsConditions") }}</h6>
                    </a>
                    <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/privacy`" target="_blank" class="nav-link">
                        <img src="@/assets/images/menu/lnk-privacy-policy.svg">
                        <h6>{{ getMyLanguage("footer", "footer.lnk.privacyPolicy") }}</h6>
                    </a>
                </ul>
            </div>
        </div>
    </div>

    <div id="overlayMenu"></div>


</template>

<script>
    // import {IonFooter, IonRow, IonCol} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    export default {
        components: {
            // IonFooter, IonRow, IonCol,
        },
        data () {
            return {
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                flagSelected: {},
                menuFooter: false
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            lnkHome(){
                window.location.href = `${this.flagSelected}/platform/home/`
            },
            lnkWallets(){
                window.location.href = `${this.flagSelected}/international/wallets/`
            },
            lnkActivity(){
                window.location.href = `${this.flagSelected}/international/activity/`
            },
            lnkProfile(){
                window.location.href = `${this.flagSelected}/platform/profile/`
            },
            lnkToken(){
                window.location.href = `${this.flagSelected}/international/token/`
            },

            
            logoutPlatform(){
                localStorage.removeItem('accessToken');
                localStorage.removeItem('profile');
                localStorage.removeItem('phaseCurrent');
                window.location.href = `${this.flagSelected}/home/`
            },

            
            lnkExchange(){
                window.location.href = `${this.flagSelected}/dneexchange/`
            },
            lnkBank(){
                window.location.href = `${this.flagSelected}/dnebank/`
            },
            lnkMarket(){
                window.location.href = `${this.flagSelected}/dnemarket/`
            },
            lnkPay(){
                window.location.href = `${this.flagSelected}/dnepay/activities`
            },
            lnkSecurity(){
                window.location.href = `${this.flagSelected}/platform/security/`
            },
        },

        async mounted () {
            this.flagUrl();
        }
    }
</script>


<style scoped src="@/assets/css/Footer.css" />

<style scoped>
    .col-lnks.lnks-left{justify-content: left; padding-left: 20px;}
    .col-lnks.lnks-right{justify-content: right; padding-right: 20px;}
</style>