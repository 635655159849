<template>
    <ion-app :scroll-events="true" class="wallets">
        <ion-page>
            <div class="header-step">
                <HeaderApp />
            </div>

            <ion-content class="wallets">
                <div class="content">

                    <div class="ion-padding">

                        <Balances />

                    </div>

                </div>
            </ion-content>

            <FooterApp />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import HeaderApp from '@/components/dneinternational/HeaderApp.vue';
    import Balances from '@/components/dneinternational/Balances.vue';
    import FooterApp from '@/components/dneinternational/FooterApp.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            HeaderApp,
            Balances,
            FooterApp
        },
        
        data () {
            return {
                flagSelected: {},
                TokenPay: {}
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            }
        },

        async mounted () {
            this.flagUrl();
        }
    }
</script>


<style scoped>
    ion-content.wallets{--padding-top: 20px;}
    .box-wallets{max-width: 550px; margin: 0 auto 30px;}
    .box-wallets .title-secondary{text-transform: uppercase; margin-bottom: 10px;}
    .box-wallets ion-row{border-bottom: 1px solid #ddd; padding: 5px 0;}
    .box-wallets ion-row:last-child{border-bottom: none;}
    /* .box-wallets ion-row ion-col.title-wallet{display: flex; align-items: center;} */
    .box-wallets .icon-title{display: flex; align-items: center;}
    .box-wallets .icon-wallet{width: 36px; height: 36px; margin-right: 10px; border-radius: 50%; background: var(--color-primary); display: flex; justify-content: center; align-items: center;}

    .box-wallets .title-wallet h5{font-size: 16px; color: var(--color-text-secondary); margin-bottom: 0;}
    .box-wallets .wallet-values{display: flex; justify-content: flex-end; align-items: center; flex-grow: initial; flex-direction: column;}
    .box-wallets .wallet-values strong{display: block; white-space: nowrap; width: 100%; text-align: right; font-size: 14px; color: var(--color-text-secondary);}
    .box-wallets .wallet-values small{display: block; white-space: nowrap; width: 100%; text-align: right; font-size: 12px;}

    .box-wallets .wallet-values.wallet-values-positive small{color: var(--color-primary);}
    .box-wallets .wallet-values.wallet-values-negative small{color: var(--color-pending);}

    .box-wallets .icon-wallet.icon-wallet-dne{background: var(--color-secondary);}
    .box-wallets .wallet-values.icon-wallet-values-dne strong{color: var(--color-secondary);}

</style>