<template>
    <ion-app :scroll-events="true" class="pending">
        <ion-page>
            <div class="header-step">
                <HeaderApp />
            </div>

            <ion-content class="pending-content">
                <div class="content">
                    
                    <div class="load-blue" v-if="!showContent"></div>

                    <!-- BOX ERROR -->
                    <div class="container ion-padding" v-if="showContent && (errors.error_404 || errors.error_general)">
                        <div class="box-error">
                            <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                            <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                            <div class="cod-error">{{ getMyLanguage("box-error",errors.error_msg) }}</div>
                        </div>
                    </div>

                    <!-- PENDING CONTENT -->
                    <div class="container ion-padding" v-if="showContent && (!errors.error_404 && !errors.error_general && !errors.error_blockPurchase)">
                        <h2 class="title-secondary">{{getMyLanguage("kyc","pending.title")}}</h2>
                        
                        <div class="box-pending">
                            <p>{{getMyLanguage("kyc","pending.msg")}}</p>
                        </div>
                    </div>
                </div>
            </ion-content>

            <FooterApp />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import HeaderApp from '@/components/dneinternational/HeaderApp.vue';
    import FooterApp from '@/components/dneinternational/FooterApp.vue';

    import apiInternational from '@/config/apiInternational.js';
    import { handleError, error404, errorGeneral, errorMsg, errorBlockPurchase } from '@/services/errorHandler.js'

    export default {
        components: {
            IonApp,IonPage, IonContent,
            HeaderApp,
            FooterApp
        },
        data () {
            return {
                flagSelected: {},
                showContent: false, 
                dataPending: {},

                errors: {
                    errorPost: false,
                    error_404: false,
                    error_general: false,
                    error_blockPurchase: false,
                    error_msg: ""
                }
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
        },


        async mounted () {
            this.flagUrl()

            await apiInternational.get('/api/v1/platform/account/international/kyc')
            .then(response => {
                this.dataPending = response.data

                this.showContent = true
            })
            .catch(error => {
                setTimeout(() => {
                    this.showContent = true
                }, 500);

                handleError(error, this.flagSelected);
                
                this.errors.error_404 = error404
                this.errors.error_general = errorGeneral
                this.errors.error_blockPurchase = errorBlockPurchase
                this.errors.error_msg = errorMsg
            })
        }
        
        // created () {
            
        // }
    }
</script>


<style scoped>
    ion-content.pending-content{--padding-top: 20px;}

    .box-pending{max-width: 500px; margin: 50px auto; background: url('~@/assets/images/bg-pending.svg') no-repeat; background-size: cover; border-radius: 12px !important; padding: 30px 16px; text-align: center;}
    .box-pending p{color: #fff;}
    .box-pending p:last-child{margin-bottom: 0;}
</style>