<template>
    <ion-app :scroll-events="true" class="token">
        <ion-page>
            <div class="header-step">
                <HeaderApp />
            </div>

            <ion-content class="itoken-content">
                <div class="content">
                    <!-- <Step /> -->

                    <div class="ion-padding">
                        <h1 class="title-primary">DNE <span>T</span>oken</h1>

                        <!-- <div class="success-purchase" v-for="(itemHistory,index) in dataHistory" :key="index"> -->
                        <div class="load-green" v-if="!showContent"></div>


                        <!-- SWAPIX -->
                        <div class="success-purchase success-swapix" v-if='showContent && dataPurchaseSepa.paymentType === "PIX" && dataPurchaseSepa.phasePurchaseStatus != "Cancelled"'>
                            <div class="header-box">
                                <p><strong>{{ getMyLanguage("activity", "activity.pending.payment.quantity") }}: </strong>{{dataPurchaseSepa.quantity}} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}</p>
                                <p><strong>{{ getMyLanguage("client-space","instructions.value") }}: </strong><span>{{(dataPurchaseSepa.finalPrice * dataPurchaseSepa.finalPriceAliquot).toLocaleString("pt-BR", {style:"currency", currency:"BRL"})}} </span> <small>/ {{dataPurchaseSepa.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</small></p>
                            </div>
                            
                            <div class="success-msg" v-html='getMyLanguage("client-space","client-space.success.swapix-txt")'></div>

                            <div class="qrcode-pix">
                                <qrcode-vue :value="qrCode.value" :size="qrCode.size" level="L" />
                            </div>
                            
                            <div class="list-iban">
                                <p><small>{{qrCode.value}}</small><span class="copy" @click='copyInstruction(qrCode.value)'></span></p>
                            </div>
                        </div>


                        <!-- SWIFT/SEPA -->
                        <div class="success-purchase" v-if='showContent && dataPurchaseSepa.paymentType === "SEPA/SWIFT" && dataPurchaseSepa.phasePurchaseStatus != "Cancelled"'>
                            <div class="success-msg" v-html='getMyLanguage("client-space","client-space.success.sepa-txt")'></div>

                            <h4>{{ getMyLanguage("client-space","instructions.title") }}</h4>

                            <div class="summary">                                
                                <div class="header-box">
                                    <p><strong>{{ getMyLanguage("activity", "activity.pending.payment.quantity") }}: </strong>{{dataPurchaseSepa.quantity}} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}</p>
                                    <p><strong>{{ getMyLanguage("client-space","instructions.value") }}: </strong>{{dataPurchaseSepa.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</p>
                                    <p><strong>{{ getMyLanguage("client-space","instructions.reference") }}: </strong>{{dataPurchaseSepa.reference}} <small>({{getMyLanguage("client-space","instructions.reference.msg")}})</small></p>
                                </div>                                

                                <div class="list-iban" v-for="(listIban, index) in dataPurchaseSepa.ibans" :key="index">
                                    <p>{{listIban.destination}} <span class="copy" @click="copyInstruction(listIban.destination)"></span></p>
                                    <p class="iban"><strong>{{ getMyLanguage("client-space","instructions.iban") }}: </strong>{{listIban.iban}} <span class="copy" @click="copyInstruction(listIban.iban)"></span></p>
                                    <p><strong>{{ getMyLanguage("client-space","instructions.swift") }}: </strong>{{listIban.swiftCode}} <span class="copy" @click="copyInstruction(listIban.swiftCode)"></span></p>
                                    <p class="reference"><strong>{{ getMyLanguage("client-space","instructions.reference") }}: </strong>{{dataPurchaseSepa.reference}} <small>({{getMyLanguage("client-space","instructions.reference.msg")}})</small> <span class="copy" @click="copyInstruction(dataPurchaseSepa.reference)"></span></p>
                                    <p class="value"><strong>{{ getMyLanguage("client-space","instructions.value") }}: </strong>{{dataPurchaseSepa.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}} <span class="copy" @click='copyInstruction(dataPurchaseSepa.finalPrice.toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"}))'></span></p>
                                </div>
                            </div>
                        </div>
                        
                        
                        <!-- CRYPTO -->
                        <div class="success-purchase" v-if='showContent && dataPurchaseSepa.paymentType === "Crypto" && dataPurchaseSepa.phasePurchaseStatus != "Cancelled"'>
                            <h4>{{ getMyLanguage("client-space","instructions.title") }}</h4>

                            <div class="summary">                                
                                <div class="header-box">
                                    <p><strong>{{ getMyLanguage("activity", "activity.pending.payment.quantity") }}: </strong>{{dataPurchaseSepa.quantity}} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}</p>
                                    <p><strong>{{ getMyLanguage("client-space","instructions.value") }}: </strong>{{dataPurchaseSepa.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</p>
                                </div>                                

                                <div class="list-iban" v-for="(listCrypto, index) in dataPurchaseSepa.addresses" :key="index">
                                    <p><strong>{{listCrypto.network}}</strong></p>
                                    <p>{{listCrypto.address}}<span class="copy" @click="copyInstruction(listCrypto.address)"></span></p>
                                    <p><strong>{{ getMyLanguage("client-space","instructions.value") }}: </strong><span class="value-crypto">{{(dataPurchaseSepa.finalPrice * dataPurchaseSepa.finalPriceAliquot).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT</span> <small>/ ({{dataPurchaseSepa.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}})</small> <span class="copy" @click='copyInstruction((dataPurchaseSepa.finalPrice * dataPurchaseSepa.finalPriceAliquot).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"}))'></span></p>
                                </div>
                            </div>
                        </div>
                            
                        <div class="msg-iban" v-if='dataPurchaseSepa.paymentType === "Credit Card"' v-html='getMyLanguage("client-space","credit-card.text")'></div>
                        <div class="msg-iban" v-if='dataPurchaseSepa.paymentType === "SEPA/SWIFT"' v-html='getMyLanguage("client-space","sepa_swift.text")'></div>
                        <div class="msg-iban" v-if='dataPurchaseSepa.paymentType === "Crypto"' v-html='getMyLanguage("client-space","crypto.text")'></div>

                    </div>
                </div>
            </ion-content>

            <FooterApp />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import apiInternational from '@/config/apiInternational.js';

    import HeaderApp from '@/components/dneinternational/HeaderApp.vue';
    import FooterApp from '@/components/dneinternational/FooterApp.vue';

    import QrcodeVue from 'qrcode.vue'

    export default {
        components: {
            IonApp,IonPage, IonContent,
            HeaderApp,
            FooterApp,
            QrcodeVue
        },
        data () {
            return {
                qrCode:{
                    value: "",
                    size: 220
                },

                flagSelected: {},
                showContent: false,
                dataPurchaseSepa: []
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            async copyInstruction(instruction) {
                try {
                    await navigator.clipboard.writeText(instruction);
                
                    const toast = await toastController
                        .create({
                            message: this.getMyLanguage("receive", "modal-receive.copied"),
                            color: "secondary",
                            duration: 2000,
                            position: 'middle',
                        })
                    return toast.present();
                }
                
                catch($e) {
                    window.alert(this.getMyLanguage("box-error","msg-error"))
                }
            }
        },

        async mounted () {
            this.flagUrl();

            const lastPurchaseSepa = await apiInternational.get(`/api/v1/ico/token_sale/purchases/${this.$route.params.registerId}`)
            this.dataPurchaseSepa = lastPurchaseSepa.data

            if ( this.dataPurchaseSepa.pixPayload != null ){
                this.qrCode.value = this.dataPurchaseSepa.pixPayload
            }

            this.showContent = true
        },
        
        // created () {

        // }
    }
</script>


<style scoped>
    .success-sepa-msg{text-align: center; margin-bottom: 30px;}

    .success-purchase{padding: 16px; border-radius: 12px; margin-bottom: 10px; background: url('~@/assets/images/bg-green.png') no-repeat; background-size: cover; -webkit-animation: showScale .4s alternate;}
    .success-purchase .success-msg{text-align: center; background: rgba(255,255,255,0.15); padding: 15px 15px 5px 15px; border-radius: 12px; margin-bottom: 20px;}
    .success-purchase h4{font-size: 20px; color: var(--color-text-tertiary); margin-bottom: 5px; text-align: center;}
    .success-purchase h6{color: var(--color-primary); text-align: center; font-size: 14px;}
    .success-purchase .qrcode-pix{display: flex; justify-content: center; margin: 20px auto 40px;}
    .success-purchase .qrcode-pix canvas{border-radius: 12px; padding: 10px; background: var(--color-text-tertiary);}

    .success-purchase.success-swapix .header-box{text-align: center; margin: 0 0 20px 0;}

    /* .success-purchase .summary{margin: 30px 0 0 0;}
    .success-purchase .summary p{margin-bottom: 0;}
    .success-purchase .summary p strong{color: var(--color-primary);} */
    
    .header-box{margin: 25px 0 15px 0;}
    .header-box p{color: var(--color-text-tertiary); margin-bottom: 0;}
    .header-box p{color: var(--color-text-tertiary);}
    .list-iban{background: var(--color-text-tertiary); padding: 12px 16px; border-radius: 12px; margin-bottom: 16px; text-transform: uppercase;}
    .list-iban:last-child{margin-bottom: 0;}
    .list-iban p{color: var(--color-primary); margin-bottom: 5px; padding-bottom: 5px; min-height: 30px; position: relative; border-bottom: 1px solid #eee; padding-right: 30px; min-height: 30px; overflow: hidden;}
    .list-iban p:not(.reference){display: flex; align-items: center;}
    .list-iban p.reference small{display: block; margin: 3px 0 0 0;}
    .list-iban p strong{display: inline-block; margin-right: 5px;}
    .list-iban p:last-child{border-bottom: none; margin-bottom: 0; padding-bottom: 0;}
    .list-iban .copy{position: absolute; right: 0; top: 0; display: block; width: 30px; height: 30px; background: url("~@/assets/images/icons/copy.svg") no-repeat center 4px #fff; background-size: 16px auto; transition: .3s; cursor: pointer;}
    .list-iban .copy:before{content: ''; width: 20px; height: 30px; position: absolute; left: -20px; top: 0; background: rgb(255,255,255); background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);}

    .msg-iban{margin: 20px 0; padding: 15px; border-radius: 6px; background: var(--color-text-tertiary);}
    .msg-iban p{display: block;}
    .msg-iban p:last-child{margin-bottom: 0;}

    @media (min-width: 992px){
        .msg-iban p{font-size: 14px;}
    }
</style>