<template>
    <ion-app :scroll-events="true" class="activity">
        <ion-page>
            <div class="header-step">
                <HeaderApp />
            </div>

            <ion-content class="activity">
                <div class="content">
                    
                    <div class="ion-padding">
                        <h1 class="title-secondary">{{ getMyLanguage("activity", "activity.title") }}</h1>
                        
                        <div class="load-blue" v-if="!showContent"></div>

                        <div class="box-error" v-if="errors.errorBlockPurchase">
                            <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                            <p>{{ getMyLanguage("box-error","block-purchase") }}</p>
                        </div>

                        <div class="box-error" v-if="showContent && (errors.error_404 || errors.error_general)">
                            <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                            <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                            <div class="cod-error">{{ getMyLanguage("box-error",errors.error_msg) }}</div>
                        </div>

                        <!-- ACTIVITY -->
                        <div class="box box-activity" v-if="showContent && dataHistory.length && !errors.errorBlockPurchase">

                            <div class="scroll-horizontal">
                                <table class="table-activity">
                                    <thead>
                                        <tr>
                                            <th>{{ getMyLanguage("activity", "activity.table.th.date") }}</th>
                                            <th>{{ getMyLanguage("activity", "activity.table.th.payment") }}</th>
                                            <th>{{ getMyLanguage("activity", "activity.table.th.status") }}</th>
                                            <th class="td-center">{{ getMyLanguage("activity", "activity.table.th.packages") }}</th>
                                            <th class="td-center">{{ getMyLanguage("activity", "activity.table.th.price") }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="activity-add" v-for="(itemHistory,index) in dataHistory" :key="index">
                                            <td>{{ dateTime(itemHistory.createdAt) }}</td>
                                            <td>{{ getMyLanguage("client-space","instructions.paymentType." + itemHistory.paymentType) }}</td>
                                            <td>
                                                <div class="purchaseStatus" :class="itemHistory.phasePurchaseStatus">
                                                    <span>{{ getMyLanguage("activity", itemHistory.phasePurchaseStatus) }}</span>
                                                    <div class="icon-activity paid" @click="changePayment(index)" v-if='itemHistory.phasePurchaseStatus == "Paid"'></div>
                                                    <div class="icon-activity pending" @click="changePayment(index)" v-if='itemHistory.phasePurchaseStatus == "PendingPayment"'></div>
                                                    <div class="icon-activity cancelled" @click="changePayment(index)" v-if='itemHistory.phasePurchaseStatus == "Cancelled"'></div>
                                                </div>
                                            </td>
                                            <td class="td-center">{{itemHistory.quantity}}</td>
                                            <td class="td-center">
                                                <div :class='{purchaseStatus: itemHistory.agreementId != null}'>
                                                    <span>{{itemHistory.finalPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span>
                                                    <div class="icon-activity invoice" @click='purchaseAgreement(itemHistory.agreementId)' v-if='itemHistory.agreementId != null'></div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                        <!-- FIRST TOKENS -->
                        <div class="box first-tokens" v-if="showContent && !errors.errorBlockPurchase">
                            <p>{{getMyLanguage("activity","activity.increase-text")}}</p>
                            <div class="btn-primary" @click="lnkToken()">{{ getMyLanguage("home", "home.content.banner.cta") }}</div>
                        </div>
                    </div>
                </div>
            </ion-content>

            <div class="modal modal-pending" v-if="pendingPay.modal">
                <div class="box cont-modal">
                    <div class="scroll-modal">
                        <div class="close-modal" @click='pendingPay.modal = false, uploadReceipt.File = null, uploadReceipt.resumeFile = false, pendingPay.reviewSuccess = false, validation.Hash = true'></div>
                    
                        <div class="row row-payment">
                            <div class="payment-content payment-pending" v-if="pendingPay.phasePurchaseStatus === 'PendingPayment'">
                                <h2 class="title-secondary">{{getMyLanguage("activity","activity.pending.payment.title")}}</h2>
                                <h6 class="payUp">{{getMyLanguage("activity","activity.pending.payment.payUp")}} {{ dateTime(pendingPay.payUpTo) }}</h6>
                                
                                <!-- BOX SEPA/SWIFT -->
                                <div class="box" v-if='pendingPay.paymentType === "SEPA/SWIFT"'>
                                    <div class="header-box">
                                        <p><strong>{{getMyLanguage("activity","activity.pending.payment.quantity")}}: </strong>{{ pendingPay.quantity }} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}</p>
                                        <p><strong>{{getMyLanguage("client-space", "instructions.value")}}: </strong>{{pendingPay.finalPrice.toLocaleString("pt-PT", {style: "currency",currency: "EUR",})}}</p>
                                        <p class="reference"><strong>{{getMyLanguage("client-space", "instructions.reference")}}: </strong>{{ pendingPay.reference }}<small> ({{getMyLanguage("client-space","instructions.reference.msg")}})</small></p>
                                    </div>

                                    <div class="list-iban" v-for="(listIban, index) in pendingPay.ibans" :key="index">
                                        <p>{{ listIban.destination }}<span class="copy" @click="copyInstruction(listIban.destination)"></span></p>
                                        <p class="iban"><strong>{{getMyLanguage("client-space", "instructions.iban")}}: </strong>{{ listIban.iban }}<span class="copy" @click="copyInstruction(listIban.iban)"></span></p>
                                        <p><strong>{{getMyLanguage("client-space", "instructions.swift")}}: </strong>{{ listIban.swiftCode }}<span class="copy" @click="copyInstruction(listIban.swiftCode)"></span></p>
                                        <p class="reference"><strong>{{getMyLanguage("client-space", "instructions.reference")}}: </strong>{{ pendingPay.reference }}<small> ({{getMyLanguage("client-space","instructions.reference.msg")}})</small><span class="copy" @click="copyInstruction(pendingPay.reference)"></span></p>
                                    </div>
                                </div>


                                <!-- BOX CREDIT CARD -->
                                <div class="box box-credit-card" v-if='pendingPay.paymentType === "Credit Card"' v-html='getMyLanguage("client-space","instructions.credit-card.pending")'>
                                </div>

                                
                                <!-- BOX CRYPTO -->
                                <div class="box" v-if='pendingPay.paymentType === "Crypto"'>
                                    <div class="header-box">
                                        <p><strong>{{getMyLanguage("activity","activity.pending.payment.quantity")}}: </strong>{{ pendingPay.quantity }} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}</p>
                                        <p><strong>{{getMyLanguage("client-space", "instructions.value")}}: </strong><span class="value-crypto">{{(pendingPay.finalPrice * pendingPay.finalPriceAliquot).toLocaleString("pt-PT", {minimumFractionDigits: "2", maximumFractionDigits: "5"})}} USDT</span> <small>/ {{pendingPay.finalPrice.toLocaleString("pt-PT", {style: "currency",currency: "EUR",})}}</small></p>
                                    </div>

                                    <div class="list-iban" v-for="(listCrypto, index) in pendingPay.addresses" :key="index">
                                        <p><strong>{{listCrypto.network}}</strong></p>
                                        <p>{{listCrypto.address}}<span class="copy" @click="copyInstruction(listCrypto.address)"></span></p>
                                    </div>
                                </div>


                                 <!-- BOX SWAPIX -->
                                <div class="box box-swapix" v-if='pendingPay.paymentType === "PIX"'>
                                    <div class="header-box">
                                        <p><strong>{{getMyLanguage("activity","activity.pending.payment.quantity")}}: </strong>{{ pendingPay.quantity }} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}</p>
                                        <p><strong>{{getMyLanguage("client-space", "instructions.value")}}: </strong><span class="value-crypto">{{(pendingPay.finalPrice * pendingPay.finalPriceAliquot).toLocaleString("pt-BR", {style: "currency",currency: "BRL",})}} </span> <small>/ {{pendingPay.finalPrice.toLocaleString("pt-PT", {style: "currency",currency: "EUR",})}}</small></p>
                                    </div>

                                    <div class="swapix-pending">
                                        <div class="qrcode-pix">
                                            <qrcode-vue :value="qrCode.value" :size="qrCode.size" level="L" />
                                        </div>
                                        
                                        <div class="list-iban">
                                            <p><small>{{qrCode.value}}</small><span class="copy" @click='copyInstruction(qrCode.value)'></span></p>
                                        </div>
                                    </div>
                                </div>

                                
                                <!-- SEND RECEIPT / REVIEW -->
                                <div class="send-receipt" :class="{hasReview: pendingPay.reviews.length}" v-if='pendingPay.paymentType != "PIX" && pendingPay.paymentType != "Credit Card"'>
                                    <div>
                                        <!-- UPLOAD -->
                                        <p v-if='pendingPay.paymentType === "SEPA/SWIFT"'><strong>{{getMyLanguage("client-space","instructions.receipt.txt")}}</strong></p>

                                        <div class="preview-file" v-if="uploadReceipt.resumeFile">
                                            <div class="preview-image" v-if="uploadReceipt.isImage">
                                                <div>
                                                    <img :src="uploadReceipt.previewURLImage" v-if="uploadReceipt.previewURLImage" />
                                                </div>
                                            </div>

                                            <div class="resume-file">
                                                <p><strong>Arquivo:</strong>&nbsp;{{ uploadReceipt.File.name }}</p>
                                                <p><strong>Tipo:</strong>&nbsp;{{ uploadReceipt.File.type }}</p>
                                                <p><strong>Tamanho:</strong>&nbsp;{{ formatFileSize(uploadReceipt.File ? uploadReceipt.File.size : 0) }}</p>
                                            </div>
                                        </div>

                                        <label for="LblUploadReceipt" class="btn-upload" :class="{fileSelected: uploadReceipt.File != null, error: validation.File === false}" v-if='pendingPay.paymentType === "SEPA/SWIFT"'>
                                            <span class="send">{{getMyLanguage("client-space","instructions.receipt.upload.select-file")}}</span>
                                            <span class="selected">{{getMyLanguage("client-space","instructions.receipt.upload.selected-file")}}</span>

                                            <input type="file" id="LblUploadReceipt" ref="fileReceipt" @change="uploadFileReceipt()">
                                        </label>


                                        <!-- HASH -->
                                        <label v-if='pendingPay.paymentType === "Crypto"'>
                                            <strong>{{getMyLanguage("client-space","instructions.receipt.hash-crypto")}}</strong>
                                            <input type="text" class="form" :class="{error: validation.Hash === false}" placeholder="Hash" v-model="review.Receipt">
                                            <div class="empty-error" v-if="validation.Hash === false">{{getMyLanguage("client-space","instructions.receipt.error.hash")}}</div>
                                        </label>
                                        
                                        <label>
                                            <strong>{{getMyLanguage("client-space","instructions.receipt.observations")}}</strong>
                                            <textarea class="form" :class="{error: validation.Observations === false}" v-model="review.Observations"></textarea>
                                            <div class="empty-error" v-if="validation.Observations === false">{{getMyLanguage("client-space","instructions.receipt.error.observations")}}</div>
                                        </label>

                                        <!-- <div class="btn-primary btn-disable" v-if='!validation.Hash'>{{getMyLanguage("client-space","instructions.receipt.send-hash")}}</div> -->
                                        <div class="btn-primary" v-if="!pendingPay.load" @click="validateFormReview()">{{getMyLanguage("client-space","instructions.receipt.send")}}</div>
                                        <div class="btn-primary load" v-if="pendingPay.load"></div>
                                        <p v-if="pendingPay.reviewSuccess" class="success">{{getMyLanguage("client-space","instructions.receipt.upload.success.txt")}}</p>
                                    </div>


                                    <!-- ERROR UPLOAD -->
                                    <div class="box-error" v-if="errorsPut.upload != false">
                                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                        <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                                        <div class="cod-error">
                                            Cód.: {{errorsPut.upload}}
                                        </div>
                                    </div>


                                    <!-- ERROR HASH -->
                                    <div class="box-error" v-if="errorsPost.hash != false">
                                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                        <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                                        <div class="cod-error">
                                            Cód.: {{errorsPost.hash}}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- PAYMENT CANCELLED -->
                            <div class="payment-content payment-cancelled" v-if="pendingPay.phasePurchaseStatus === 'Cancelled'" :class="{hasReview: pendingPay.reviews.length}">
                                <h2 class="title-secondary">{{getMyLanguage("client-space","activity.cancelled.payment.title")}}</h2>
                                <div class="box">
                                    <p><strong>{{getMyLanguage("client-space", "instructions.reference")}}: </strong>{{ pendingPay.reference }}</p>
                                    <p><strong>{{getMyLanguage("activity","activity.pending.payment.quantity")}}: </strong>{{ pendingPay.quantity }} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}</p>
                                    <p><strong>{{getMyLanguage("client-space", "instructions.value")}}: </strong>{{pendingPay.finalPrice.toLocaleString("pt-PT", {style: "currency",currency: "EUR",})}}</p>
                                    <p>&nbsp;</p>
                                    <p><strong>{{getMyLanguage("client-space", "instructions.date")}}: </strong>{{ dateTime(pendingPay.updatedAt) }}</p>
                                    <p><strong>{{getMyLanguage("client-space", "instructions.reason")}}: </strong>{{ pendingPay.phasePurchaseStatusReason }}</p>
                                </div>
                            </div>
                            
                            
                            <!-- PAYMENT PAID -->
                            <div class="payment-content payment-paid" v-if="pendingPay.phasePurchaseStatus === 'Paid'" :class="{hasReview: pendingPay.reviews.length}">
                                <h2 class="title-secondary">{{getMyLanguage("client-space","activity.paid.payment.title")}}</h2>
                                <div class="box">
                                    <p><strong>{{getMyLanguage("client-space", "instructions.reference")}}: </strong>{{ pendingPay.reference }}</p>
                                    <p><strong>{{getMyLanguage("activity","activity.pending.payment.quantity")}}: </strong>{{ pendingPay.quantity }} {{getMyLanguage("purchase-token","purchase-token.content.phase.packages")}}</p>
                                    <p><strong>{{getMyLanguage("client-space", "instructions.value")}}: </strong>{{pendingPay.finalPrice.toLocaleString("pt-PT", {style: "currency",currency: "EUR",})}}</p>
                                    <p>&nbsp;</p>
                                    <p><strong>{{getMyLanguage("client-space", "instructions.date")}}: </strong>{{ dateTime(pendingPay.updatedAt) }}</p>
                                    <p><strong>{{getMyLanguage("client-space", "instructions.reason")}}: </strong>{{ pendingPay.phasePurchaseStatusReason }}</p>
                                </div>
                            </div>


                            <!-- PAYMENT CANCELLED EXPIRED DATE -->
                            <div class="payment-content payment-cancelled expired-date" v-if="pendingPay.expired">
                                <h2 class="title-secondary">{{getMyLanguage("client-space","activity.cancelled.payment.title")}}</h2>
                                <div class="box">
                                    <p v-html='getMyLanguage("client-space","payment-cancelled.expired-date")'></p>
                                </div>
                            </div>


                            <!-- HISTORY -->
                            <div class="history" v-if="pendingPay.reviews.length">
                                <h2 class="title-secondary">History</h2>

                                <div class="box-history" v-for="(listHistory,index) in pendingPay.reviews" :key="index">
                                    <div class="scroll-horizontal">
                                        <p class="data-review">
                                            <span class="date">{{dateTime(listHistory.createdAt)}} ({{listHistory.accountName}})</span>
                                            <span class="status send">{{listHistory.reviewType}}</span>
                                        </p>
                                        <p>
                                            <span class="lnk" v-if='listHistory.attachment != "" && listHistory.attachmentType === "hash_tx"'>
                                                <a :href="`https://blockscan.com/address/${pendingPay.addresses[0].address}`" target="_blank">{{listHistory.attachment}}</a>
                                            </span>

                                            <!-- <span class="lnk" v-if='listHistory.attachment != "" && listHistory.attachmentType === "blob_uri"'>
                                                <a :href='listHistory.url' target="_blank">{{listHistory.fileName}}</a>
                                            </span> -->

                                            <span class="lnk" v-if='listHistory.attachment != "" && listHistory.attachmentType === "blob_uri"'>{{listHistory.fileName}}</span>
                                        </p>
                                        <p class="msg-review" v-if='listHistory.observations != null'>{{listHistory.observations}}</p>
                                    </div>
                                </div>
                            </div>


                            <!-- CANCEL PAYMENT -->
                            <div class="cancel-payment" v-if='pendingPay.phasePurchaseStatus != "Paid" && pendingPay.phasePurchaseStatus != "Cancelled" && !pendingPay.load'>
                                <button @click="cancelPurchase(pendingPay.phasePurchaseId)" class="btn-cancel-primary">{{ getMyLanguage("activity", "activity.pending.payment.btn.cancelPurchase") }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overlayModal" v-if="pendingPay.modal"></div>



            <!-- MODAL INVOICE -->
            <!-- <div class="modal modal-pending" v-if="modalInvoice">
                <div class="modal-cont">
                    <div class="close-modal" @click="modalInvoice = false"></div>
                    <div class="box">
                        <div class="cont-iframe">
                            <iframe :src='urlInvoice' frameborder="0"></iframe>
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- MODAL AGREEMENT -->
            <div class="modal modal-invoice" v-if="modalAgreement">
                <div class="modal-cont">
                    <div class="close-modal" @click="modalAgreement = false"></div>

                    <div class="box">
                        <div class="cont-iframe">
                            <iframe :src="urlAgreement" frameborder="0"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overlayModal" v-if="modalAgreement"></div>

            <FooterApp />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import HeaderApp from '@/components/dneinternational/HeaderApp.vue';
    import FooterApp from '@/components/dneinternational/FooterApp.vue';

    import QrcodeVue from 'qrcode.vue'
    import moment from 'moment';

    import apiInternational from '@/config/apiInternational.js';
    import { handleError, error404, errorGeneral, errorMsg, errorBlockPurchase } from '@/services/errorHandler.js'

    export default {
        components: {
            IonApp,IonPage, IonContent,
            HeaderApp,
            FooterApp,
            QrcodeVue
        },
        data () {
            return {
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,
                
                flagSelected: "en",
                dataHistory: [],
                dataPurchaseSepa: [],
                dataPendingPayment: null,
                showContent: false,
                dataProfile: "",

                errors: {
                    error_post: false,
                    error_404: false,
                    error_general: false,
                    error_blockPurchase: false,
                    error_msg: ""
                },

                errorsPut: {
                    upload: false
                },
                
                errorsPost: {
                    hash: false
                },

                pendingPay: {
                    modal: false,
                    load: false,
                    index: "",
                    reviewSuccess: false,
                    expired: true
                },

                qrCode:{
                    value: "",
                    size: 220
                },
                
                review: {},

                validation: {
                    hasReview: '',
                    Hash: '',
                    File: '',
                    Observations: ''
                },

                uploadReceipt: {
                    phasePurchaseId: "",
                    File: null,
                    selected: false,
                    load: false,
                    isImage: false,
                    resumeFile: false,
                    previewURLImage: ""
                },
                
                modalAgreement: false,
                urlAgreement: ""
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            lnkToken(){
                window.location.href = `${this.flagSelected}/international/token/`
            },

            dateTime(value) {
                return moment(value).locale(this.flagSelected).format('DD/MM');
            },

            goPayment(saleId, quantity){
                window.location.href = `${this.flagSelected}/token?phasePurchaseId=${saleId}&quantity=${quantity}`
            },

            changePayment(value) {
                this.pendingPay = this.dataHistory[value]
                this.pendingPay.index = value
                this.uploadReceipt.File = null
                this.uploadReceipt.selected = false

                this.review.PhasePurchaseId = this.pendingPay.phasePurchaseId
                this.review.Receipt = ""
                this.review.Observations = ""
                
                this.validation.Hash = ''
                this.validation.File = ''
                this.validation.Observations = ''

                if ( this.pendingPay.reviews.length ){
                    this.validation.hasReview = true

                    if ( this.pendingPay.paymentType === "SEPA/SWIFT" ){
                        this.validation.File = true
                    } else{
                        this.validation.Hash = true
                    }

                } else {
                    this.validation.hasReview = false
                    this.validation.Observations = ""
                }

                if ( this.pendingPay.paymentType === "PIX" ){
                    if ( this.pendingPay.pixPayload != null ){
                        this.qrCode.value = this.pendingPay.pixPayload
                    } else {
                        this.qrCode.value = ""
                    }
                }
                
                this.pendingPay.modal = true


                // CONDITION DATA
                this.pendingPay.expired = false
            },

            async copyInstruction(instruction) {
                try {
                    await navigator.clipboard.writeText(instruction);
                
                    const toast = await toastController
                        .create({
                            message: this.getMyLanguage("receive", "modal-receive.copied"),
                            color: "secondary",
                            duration: 2000,
                            position: 'middle',
                        })
                    return toast.present();
                }
                
                catch($e) {
                    window.alert(this.getMyLanguage("box-error","msg-error"))
                }
            },

            cancelPurchase(id){
                this.pendingPay.load = true

                apiInternational.delete(`/api/v1/ico/token_sale/cancel/${id}`)
                .then(response => {
                    this.registerId = response.data.id;
                    window.location.href = `/${this.flagSelected}/international/activity`
                })
                .catch(error => {
                    this.pendingPay.load = false
                    this.errorMessage = error.message;
                    console.error("There was an error!", JSON.stringify(error));                    
                    this.$toast.error(this.errorMessage);
                    setTimeout(this.$toast.clear, 3000)
                })
            },

            purchaseAgreement(value) {
                this.modalAgreement = true;
                this.tkAgreement = localStorage.getItem('accessToken')

                this.urlAgreement = `${this.VUE_APP_INTERNATIONAL_URL}en/account/token-auth?tk=${this.tkAgreement}&destiny=client-space/agreement/${value}`
            },

            uploadFileReceipt() {
                this.validation.File = true
                this.validation.Observations = true

                this.uploadReceipt.File = this.$refs.fileReceipt.files[0];
                console.log(this.uploadReceipt)

                if ( this.uploadReceipt.File != null ){
                    this.uploadReceipt.selected = "fileSelected"
                    
                    const fileURL = URL.createObjectURL(this.uploadReceipt.File);
                    this.uploadReceipt.previewURLImage = fileURL;
                    this.uploadReceipt.resumeFile = true
                    
                    setTimeout(() => {
                    URL.revokeObjectURL(fileURL);
                    }, 10000);
                }

                if (this.uploadReceipt.File.type.startsWith('image/')) {
                    this.uploadReceipt.isImage = true
                } else {
                    this.uploadReceipt.isImage = false
                }

                console.log(this.uploadReceipt.isImage)
            },

            formatFileSize(fileSize) {
                if (fileSize === 0) return '0 Bytes';
                const k = 1024;
                const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
                const i = Math.floor(Math.log(fileSize) / Math.log(k));
                return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
            },

            validateFormReview(){
                // HASH
                if ( (this.review.Receipt != null || this.review.Receipt != "") && !this.validation.hasReview && this.pendingPay.paymentType === "Crypto" ){
                    if ( this.review.Receipt.match(/^0x([A-Fa-f0-9]{64})$/) ) {
                        this.validation.Hash = true
                        this.validation.Observations = true
                    } else{
                        this.validation.Hash = false
                    }
                }

                // UPLOAD
                if ( !this.validation.hasReview && this.validation.File != true && this.pendingPay.paymentType === "SEPA/SWIFT" ){
                    if ( (this.uploadReceipt.File != null || this.uploadReceipt.File != "") ){
                        this.validation.File = false
                    }
                }


                // OBSERVATIONS
                if ( this.validation.hasReview ){
                    if ((((this.uploadReceipt.File != null || this.uploadReceipt.File != "") || (this.review.Receipt != null || this.review.Receipt != ""))) && this.review.Observations === ""){
                        this.validation.Observations = false
                    } else{
                        this.validation.Observations = true
                    }
                }


                // VALIDATED
                if ( (this.validation.Hash === true || this.validation.File === true) && this.validation.Observations != false ){
                    this.sendReview()
                }
            },

            sendReview(){
                this.pendingPay.load = true

                const formData = new FormData();
                formData.append('File', this.uploadReceipt.File);
                formData.append('PhasePurchaseId', this.review.PhasePurchaseId);
                formData.append('Receipt', this.review.Receipt);
                formData.append('Observations', this.review.Observations);
                // console.log(JSON.stringify(formData))
                const token = localStorage.getItem('accessToken');
                const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}` };
                apiInternational.put("/api/v1/ico/token_sale/purchase", formData, { headers })
                .then(response => {
                    console.log(response)
                    console.clear()

                    this.pendingPay.load = false
                    this.pendingPay.reviewSuccess = true
                    this.uploadReceipt.resumeFile = false

                    this.toastSuccessSendReview();

                    this.updatePurchases();
                    
                    setTimeout(() => {
                        this.pendingPay.reviewSuccess = false
                    }, 5000);
                })
                .catch(error => {
                    this.errorsPut.upload = error.response.data
                    this.errorsPut.upload = JSON.stringify(error.response.status)
                    
                    this.pendingPay.load = false
                })
            },

            async toastSuccessSendReview() {
                const toast = await toastController
                    .create({
                        message: this.getMyLanguage("client-space","instructions.receipt.upload.success.txt"),
                        color: "secondary",
                        duration: 2000,
                        position: 'middle',
                    })
                return toast.present();
            },

            async updatePurchases(){
                const updatePurchases = await apiInternational.get('api/v1/ico/token_sale/purchases')
                this.dataHistory = updatePurchases.data
                
                this.changePayment(this.pendingPay.index)
            }
        },


        async mounted () {
            this.flagUrl();

            this.dataProfile = JSON.parse(localStorage.getItem("profile"))
            
            await apiInternational.get("api/v1/ico/token_sale/purchases")
            .then((response) => {
                this.dataHistory = response.data;
                this.showContent = true
            })
            .catch((error) => {
                setTimeout(() => {
                    this.showContent = true
                }, 500);

                handleError(error, this.flagSelected);

                this.errors.error_404 = error404
                this.errors.error_general = errorGeneral
                this.errors.error_blockPurchase = errorBlockPurchase
                this.errors.error_msg = errorMsg
            });
            
            // this.dataHistoryPending = resumeHistory.data.filter(item => item.phasePurchaseStatus === "PendingPayment")[0]
            // this.dataHistoryPending = this.dataPendingPayment?.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))[0]
            
            // this.dataHistoryPending = resumeHistory.data.filter(item => item.phasePurchaseStatus === "PendingPayment")[0]
            // this.dataHistoryPending = this.dataHistoryPending?.sort((a, b) => (a.finalPrice > b.finalPrice ? -1 : 1))[0]

            // this.dataHistory.quantityTotal = this.dataHistory.quantity

            // this.showHistory = true
        }
    }
</script>


<style scoped>
    ion-content.activity{--padding-top: 20px;}

    .title-secondary{margin-bottom: 20px;}

    .first-tokens p{text-align: center; margin-bottom: 20px; font-size: 14px; font-weight: 600; color: var(--color-primary);}

    .box-activity{max-width: 550px; margin: 0 auto 50px; padding: 10px;}
    .box-activity .scroll-horizontal{padding-bottom: 0;}
    .box-activity .table-activity{border: none; font-size: 11px; color: var(--color-text-secondary); width: 100%;}
    .box-activity .table-activity thead{border-bottom: 1px solid var(--color-tertiary); line-height: 26px; text-align: left;}
    .box-activity .table-activity .td-center{text-align: center;}
    .box-activity .table-activity .td-right{text-align: right;}
    .box-activity .table-activity td{padding: 8px 6px; border-bottom: 1px solid #eee; position: relative; white-space: nowrap; text-align: center;}
    .box-activity .table-activity tbody tr{line-height: 13px;}
    .box-activity .table-activity tbody tr:last-child td{border-bottom: 0; padding-bottom: 0;}
    .box-activity .table-activity tbody td .purchaseStatus.purchase-pending-payment,
    .box-activity .table-activity tbody td.purchase-created,
    .box-activity .table-activity tbody td.purchase-pending-stake{color: var(--color-pending);}

    .row-payment{max-width: 550px; margin: 0 auto 30px;}
    .row-payment h6{font-size: 18px; text-align: center; color: var(--color-pending); margin-bottom: 10px;}

    .row-payment .payment-pending .box{background: var(--color-pending); border-radius: 12px; margin-bottom: 20px;}
    .row-payment .payment-paid .box{background: var(--color-secondary); border-radius: 12px; margin-bottom: 20px;}
    .row-payment .payment-cancelled .box{background: var(--color-cancel); border-radius: 12px; margin-bottom: 20px; text-align: center;}
    .row-payment .box p{margin-bottom: 0; color: var(--color-text-tertiary);}
    .row-payment .btn-primary{margin-top: 30px;}
    /* .row-payment .box .header-box{margin-bottom: 15px;} */
    .row-payment .box .header-box .subtitle{border-top: 2px solid rgba(255,255,255,0.4); margin: 15px 0 10px 0; padding: 10px 0 3px 0; position: relative;}
    .row-payment .box .header-box .subtitle:before{content: ''; display: block; position: absolute; left: 0; bottom: 0; border-right: 6px solid transparent; border-left: 6px solid transparent; border-top: 6px solid var(--color-text-tertiary); width: 0; height: 0;}
    .row-payment .box .header-box p.reference{margin-bottom: 15px;}
    .row-payment .box .header-box p.reference small{display: block;}
    .row-payment .box .list-iban{background: var(--color-text-tertiary); padding: 10px 5px 10px 10px; border-radius: 12px; margin-bottom: 16px; text-transform: uppercase;}
    .row-payment .box .list-iban:last-child{margin-bottom: 0;}
    .row-payment .box .list-iban p{color: var(--color-primary); margin-bottom: 5px; padding-bottom: 5px; position: relative; border-bottom: 1px solid #eee; padding-right: 30px; min-height: 30px; overflow: hidden;}
    .row-payment .box .list-iban p:not(.reference){display: flex; align-items: center;}
    .row-payment .box .list-iban p:last-child{margin-bottom: 0; border-bottom: none;}
    .row-payment .box .list-iban p.reference{padding-top: 5px; border-bottom: none; margin-bottom: 0; padding-bottom: 0;}
    .row-payment .box .list-iban p.reference small{display: block; margin-left: 5px; line-height: 12px;}
    .row-payment .box .list-iban p strong{display: inline-block; margin-right: 5px;}
    .row-payment .box .list-iban .copy{position: absolute; right: 0; top: 0; display: block; width: 30px; height: 30px; background: url("~@/assets/images/icons/copy.svg") no-repeat center 4px #fff; background-size: 16px auto; transition: .3s; cursor: pointer;}
    .row-payment .box .list-iban .copy:before{content: ''; width: 20px; height: 30px; position: absolute; left: -20px; top: 0; background: rgb(255,255,255); background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);}
    
    @media (min-width: 992px){
        .row-payment .box .list-iban p{display: flex; align-items: center; line-height: 16px; margin-bottom: 3px;}
        .row-payment .box .copy:hover{transform: scale3d(1.2, 1.2, 1.2)}
    }
    
     @media (max-width: 992px){
        .row-payment .box .list-iban p{font-size: 12px; line-height: 14px;}
        .row-payment .box .list-iban p.reference{padding-top: 3px; line-height: 15px;}
        .row-payment .box .list-iban p.reference small{margin: 3px 0 0 0;}
    }


    .row-payment .box-cancelled{background: var(--color-cancel); border-radius: 12px;}
    .row-payment .box-cancelled p{margin-bottom: 0; color: var(--color-text-tertiary);}
    
    .row-payment .box-paid{background: var(--color-secondary); border-radius: 12px;}
    .row-payment .box-paid p{margin-bottom: 0; color: var(--color-text-tertiary);}

    .purchaseStatus{position: relative; padding-right: 20px;}
    .purchaseStatus span{display: block; height: 30px; line-height: 30px;}

    .icon-activity{position: absolute; z-index: 20; left: 0; top: 0; width: 100%; height: 30px; background-size: 16px !important; cursor: pointer;}
    .icon-activity.paid{background: url('~@/assets/images/icons/view-paid.svg') no-repeat center right;}
    .icon-activity.pending{background: url('~@/assets/images/icons/view-pending.svg') no-repeat center right;}
    .icon-activity.cancelled{background: url('~@/assets/images/icons/view-cancelled.svg') no-repeat center right;}
    .icon-activity.invoice{background: url('~@/assets/images/icons/view-invoice.svg') no-repeat center right;}

    .send-receipt{padding: 16px 12px; border: 1px solid #ddd; background: #f5f5f5; border-radius: 12px; text-align: center; margin-bottom: 30px;}
    .send-receipt label{display: block; margin-bottom: 20px; font-size: 13px; line-height: 17px;}
    .send-receipt .form{background: #fff; border: 1px solid #ccc; margin: 5px 0;}
    .send-receipt .form.error{border: 1px solid var(--color-cancel);}
    .send-receipt textarea.form{height: 80px;}
    .send-receipt .upload-receipt{display: flex; flex-direction: column; align-items: center;}
    .send-receipt .upload-receipt label{display: flex; align-items: center; justify-content: center; width: 100%; height: 50px; margin-bottom: 20px; border-radius: 12px; background: var(--color-primary); color: var(--color-text-tertiary);}
    .send-receipt .upload-receipt .btn-primary{max-width: 180px;}
    .send-receipt .empty-error{font-size: 12px; line-height: 20px; color: var(--color-cancel); margin-top: -5px; margin-bottom: 15px;}

    .preview-image{display: flex; justify-content: center;}
    .preview-image div{display: flex; justify-content: center; align-items: center; background: #eee; border: 2px dashed #ccc; padding: 10px; width: 200px; height: 200px;}
    .preview-image div img{max-width: 180px; max-height: 180px;}
    
    .resume-file{margin: 10px 0 30px; padding: 10px 12px; background: #fff; border-radius: 10px;}
    .resume-file .error{background: var(--color-pending); color: var(--color-text-tertiary); font-family: var(--font-primary-semibold); text-align: center; border-radius: 6px; padding: 10px; margin-bottom: 10px;}
    .resume-file p{position: relative; font-size: 12px; line-height: 20px; margin-bottom: 0; text-align: left !important; overflow: hidden; white-space: nowrap;}
    .resume-file p:not(:last-child){padding-bottom: 3px; margin-bottom: 3px; border-bottom: 1px solid #ddd;}
    .resume-file p:after{content: ''; display: block; width: 50px; height: 20px; position: absolute; right: 0; top: 0; background: rgb(255,255,255); background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);}

    .history{background: #f5f5f5; width: 100%; padding: 20px 10px; margin-bottom: 60px; border-radius: 12px;}
    .history .title-secondary{z-index: 20; color: var(--color-primary); font-size: 24px; line-height: 30px; padding-bottom: 5px;}
    .history .box-history{margin-bottom: 15px; border-radius: 12px; padding: 16px 6px 6px 6px; background: var(--color-text-tertiary); -webkit-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -moz-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);}
    .history .box-history .scroll-horizontal{}
    .history p{margin-bottom: 0;}
    .history .data-review{display: flex; flex-direction: column; align-items: flex-start; margin-bottom: 5px; padding: 0 5px;}
    .history .data-review span{display: block; font-size: 13px; line-height: 20px; text-decoration: none; white-space: nowrap;}
    .history .data-review span.date{font-weight: 600;}
    .history .data-review span.status{font-size: 12px; color: var(--color-text-tertiary); border-radius: 6px; padding: 0 8px; margin: 5px 0; background: #f5f5f5;}
    .history .data-review span.approved{background: var(--color-secondary);}
    .history .data-review span.send{background: var(--color-primary);}
    .history .data-review span.pending{background: var(--color-pending);}
    .history .lnk{display: flex; justify-content: flex-start; padding: 0 5px; font-size: 13px; font-weight: 600; text-decoration: underline; color: var(--color-secondary); overflow: hidden;}
    .history .lnk a{display: flex; justify-content: flex-end; align-items: center; max-width: 100%; padding-right: 17px; font-size: 11px; font-weight: 600; text-decoration: underline; color: var(--color-secondary); position: relative; white-space: nowrap; overflow: hidden;}
    .history .lnk a:before{content: ''; display: block; position: absolute; z-index: 10; top: 50%; right: 0; transform: translateY(-50%); width: 30px; height: 20px; background: rgb(255,255,255); background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);}
    .history .lnk a:after{content: ''; display: block; position: absolute; z-index: 20; top: 50%; right: 0; transform: translateY(-50%); width: 17px; height: 20px; background: url('~@/assets/images/icons/link.svg') no-repeat center right; background-size: 10px;}
    .history .msg-review{padding: 5px 8px; margin: 10px 0 0 0; font-size: 14px; line-height: 20px; text-align: left; background: #f5f5f5; border-radius: 6px;}
    .history .btn-approve{font-size: 13px; padding: 0 10px; line-height: 30px !important; height: 30px; margin-left: 10px;}
    .history .box-send-review{margin-top: 50px; padding: 16px; background: #fff; border-radius: 16px;}
    .history .box-send-review .bts{display: flex; justify-content: center;}

    .btn-cancel-primary{background: var(--color-text-tertiary); color: var(--color-cancel); border: 1px solid var(--color-cancel);}

    .box-swapix{margin-bottom: 60px !important;}
    .box-swapix .header-box{text-align: center;}
    .swapix-pending{display: flex; flex-direction: column; align-items: center;}
    .swapix-pending .qrcode-pix{border-radius: 12px; padding: 10px; height: 240px; background: var(--color-text-tertiary); margin: 20px 0 40px;}
    .swapix-pending .list-iban{max-width: 100%; text-transform: initial; margin-bottom: 20px;}
    .swapix-pending .list-iban p{color: var(--color-text-primary); padding-bottom: 0;}
    .swapix-pending .list-iban p small{display: block; max-width: 100%; font-size: 8px; line-height: 12px;}
    .swapix-pending .list-iban p .copy{width: 100% !important; height: 100% !important; background: none !important;}
    .swapix-pending .list-iban p .copy:before{left: auto; right: 30px; top: 0; height: 100%;}
    .swapix-pending .list-iban p .copy:after{content: ''; display: block; width: 30px; height: 100%; background: url('~@/assets/images/icons/copy.svg') no-repeat center var(--color-text-tertiary); background-size: 16px auto; position: absolute; right: 0; top: 0;}
    

    .box-credit-card{text-align: center; color: var(--color-text-tertiary);}
    


    .box .cont-iframe{
        -webkit-overflow-scrolling: touch !important;
        height: 83vh;
    }

    .cont-iframe iframe{width: 100%; height: 83vh; overflow: auto !important;}

</style>