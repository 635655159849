<template>
    <ion-app :scroll-events="true" class="construction">
        <ion-page>
            <div class="header-step">
                <HeaderApp />
            </div>

            <ion-content class="dne-pay">
                <div class="content">
                    
                    <div class="ion-padding">
                        
                        <!-- <div class="load-blue" v-if="!showContent"></div> -->

                        <!-- <div class="ion-padding" v-if="errorsGet.aaaaaaa != false">
                            <div class="box-error">
                                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                            </div>
                        </div> -->


                        <!-- MENU ACTIONS -->
                        <div class="menu-actions">
                            <div class="scroll">
                                <div class="btn btn-activities" @click="lnkActivities">
                                    <div><img src="~@/assets/images/icons/dnepay-activities.svg"></div>
                                    <span>{{getMyLanguage("dnePay","activities.title")}}</span>
                                </div>
                                <div class="btn btn-send" @click="lnkSend">
                                    <div><img src="~@/assets/images/icons/dnepay-send.svg"></div>
                                    <span>{{getMyLanguage("dnePay","send.title")}}</span>
                                </div>
                                <div class="btn btn-pay" @click="lnkPay">
                                    <div><img src="~@/assets/images/icons/dnepay-pay.svg"></div>
                                    <span>{{getMyLanguage("dnePay","pay.title")}}</span>
                                </div>
                                <div class="btn btn-receive" @click="lnkReceive">
                                    <div><img src="~@/assets/images/icons/dnepay-receive.svg"></div>
                                    <span>{{getMyLanguage("dnePay","receive.title")}}</span>
                                </div>
                                <div class="btn btn-checkout active" @click="lnkCheckout">
                                    <div><img src="~@/assets/images/icons/dnepay-checkout.svg"></div>
                                    <span>{{getMyLanguage("dnePay","checkout.title")}}</span>
                                </div>
                            </div>
                        </div>

                        <Checkout />

                    </div>
                </div>
            </ion-content>
            <FooterApp />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import HeaderApp from '@/components/dnepay/HeaderApp.vue';
    import Checkout from '@/components/dnepay/Checkout.vue';
    import FooterApp from '@/components/dneplatform/FooterApp.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            HeaderApp,
            Checkout,
            FooterApp
        },
        data () {
            return {
                flagSelected: {},
                showContent: false,
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            lnkActivities(){
                window.location.href = `${this.flagSelected}/dnepay/activities/`
            },

            lnkSend(){
                window.location.href = `${this.flagSelected}/dnepay/send/`
            },

            lnkPay(){
                window.location.href = `${this.flagSelected}/dnepay/pay/`
            },

            lnkReceive(){
                window.location.href = `${this.flagSelected}/dnepay/receive/`
            },

            lnkCheckout(){
                window.location.href = `${this.flagSelected}/dnepay/checkout/`
            }
        },


        async mounted () {
            this.flagUrl()
        }
    }
</script>

<style scoped src="@/assets/css/dne-pay.css" />