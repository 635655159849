<template>
    <ion-app :scroll-events="true" class="permission">
        <ion-page>
            <div class="header-step">
                <HeaderApp />
            </div>

            <ion-content class="ion-padding permission-content">
                <div class="content">
                    <div class="load-blue" v-if="!showContent"></div>

                    <div class="box-error" v-if="showContent && (errors.error_404 || errors.error_general)">
                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                        <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
                        <div class="cod-error">{{ getMyLanguage("box-error",errors.error_msg) }}</div>
                    </div>
                    
                    <div class="permission cont" v-if="showContent && (!errors.error_404 && !errors.error_general && !errors.error_blockPurchase)">
                        <div class="box">
                            <h2 class="title-primary">{{getMyLanguage("permission","consent.title")}}</h2>
                            <p v-html='getMyLanguage("permission","consent.text-01")'></p>
                            <p v-html='getMyLanguage("permission","consent.text-02")'></p>

                            <div class="bts-privacy-terms">
                                <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/privacy`" class="lnk-terms" target="_blank">{{getMyLanguage("permission","consent.lnk-privacy")}}</a>
                                <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/terms-and-conditions`" class="lnk-terms" target="_blank">{{getMyLanguage("permission","consent.lnk-terms")}}</a>
                            </div>
                        </div>

                        <!-- LOGIN -->
                        <label class="lbl-pass-consent">{{getMyLanguage("permission","consent.lbl.password")}}</label>
                        <div class="enterPass">
                            <input v-model="sign.password" type="password" v-if="!showPass" class="form form-pass" :class='{error: sign.emptyPassword}' :placeholder='getMyLanguage("login", "login.placeholder.password")'>
                            <input v-model="sign.password" type="text" v-if="showPass" class="form form-pass" :class='{error: sign.emptyPassword}' :placeholder='getMyLanguage("login", "login.placeholder.password")'>

                            <img src="@/assets/images/icons/no-view.svg" class="eyePass" v-if="!showPass" @click="showPass = true" />
                            <img src="@/assets/images/icons/view.svg" class="eyePass" v-if="showPass" @click="showPass = false" />
                        </div>
                        
                        <div class="bts">
                            <div class="btn-primary" @click="loginConsent()" v-if="!stepLogin.load && sign.password.length >= 8">{{getMyLanguage("permission","consent.btn-authorize")}}</div>
                            <div class="btn-primary btn-disable" v-if="sign.password.length < 8">{{getMyLanguage("permission","consent.btn-authorize")}}</div>
                            <div class="btn-primary btn-loading" v-if="stepLogin.load">{{getMyLanguage("permission","consent.btn-authorize")}}</div>

                            <!-- <div class="btn-cancel" @click="noAuthorize()" v-if="!stepLogin.load">{{getMyLanguage("permission","consent.btn-notauthorize")}}</div> -->
                        </div>
                    </div>
                </div>
            </ion-content>
            <FooterApp />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import HeaderApp from '@/components/dneplatform/HeaderApp.vue';
    import FooterApp from '@/components/dneplatform/FooterApp.vue';

    import apiPlatform from '@/config/apiPlatform.js';

    // import { handleError, error404, errorGeneral, errorMsg, errorBlockPurchase } from '@/services/errorHandler.js'

    export default {
        components: {
            IonApp,IonPage, IonContent,
            HeaderApp,
            FooterApp
        },
        data () {
            return {
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                flagSelected: {},
                showPass: false,
                showContent: false, 
                dataProfile: "",

                errors: {
                    errorPost: false,
                    error_404: false,
                    error_general: false,
                    error_blockPurchase: false,
                    error_msg: ""
                },

                stepLogin: {
                    'load': false,
                    'loadCode': false,
                    'statusEmailPass': true,
                    'statusCode': false
                },
                sign: {
                    "scope": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    "password": ""
                },
                destiny: "",

                errorsGet: {
                    dataProfile: false
                }
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },


            loginConsent(){
                this.stepLogin.load = true
                const loginConsent = this.sign;

                apiPlatform.post("/api/v1/platform/consent/ask", loginConsent)
                .then(response => {
                    const responseData = response.data.accessToken

                    localStorage.removeItem('accessToken');
                    localStorage.setItem('accessToken',responseData);

                    // window.location.href = this.destiny
                    window.location.href = `/${this.flagSelected}/international/activity/`
                })
                .catch(error => {
                    this.stepLogin.load = false
                    this.errorMessage = error.message;
                    this.openToast();
                })
            },


            noAuthorize(){
                window.location.href = `${this.flagSelected}/home/`
            },

            async openToast() {
                const toast = await toastController
                    .create({
                    message: this.getMyLanguage("permission","consent.error-password"),
                    color: "danger",
                    duration: 2000
                    })
                return toast.present();
            },
        },


        async mounted () {
            this.flagUrl()

            this.dataProfile = JSON.parse(localStorage.getItem("profile"))

            // apiPlatform.get('/api/v1/platform/account/profile/')
            // .then(response => {
            //     this.dataProfile = response.data
            // })
            // .catch(error => {
            //     this.errorsGet.dataProfile = error.response.status
            // })

            // const urlParams = new URLSearchParams(window.location.search);
            // const urlScope = urlParams.get('scope')
            // const urlDestiny = urlParams.get('destiny');
            
            // this.sign.scope = "3fa85f64-5717-4562-b3fc-2c963f66afa6"
            // this.destiny = urlDestiny

            this.showContent = true
        }
    }
</script>


<style scoped>
    .load-blue{width: 80px; height: 80px;}
    .content{padding-top: 30px;}
    .content .box p{font-size: 13px;}
    
    .bts-privacy-terms{width: 100%; margin-top: 20px; display: flex; flex-wrap: wrap;}
    .bts-privacy-terms .lnk-terms{background: #eee; line-height: 30px; padding: 0 10px; margin: 0 10px 10px 0; border-radius: 5px; font-size: 13px; cursor: pointer; white-space: nowrap;}
    
    .content .lbl-pass-consent{display: block; margin: 30px 0 5px; font-size: 13px;}
    .content .bts{display: flex; flex-direction: column; margin-bottom: 30px;}
    .content .bts .btn-primary{margin-bottom: 20px;}
    .content .bts .btn-cancel{display: inline-block; margin: 0 auto; height: 30px; line-height: 30px; font-size: 13px; color: var(--color-cancel);}

    .content .btn-loading{background: url("~@/assets/images/icons/loading-secondary.svg") no-repeat center; background-size: auto 100%;}
</style>