<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <!-- HEADER -->
            <ion-header class="header" v-if="showContent">
                <div class="header-row" :class="{develop: VUE_APP_DEVELOP == 'develop'}">
                    <div class="logo-balances">
                        <div class="logo-circle">
                            <img src="@/assets/images/dne.svg">
                        </div>
                    </div>
                    
                    <div class="login-register">
                        <h2 class="title-secondary" v-if="phaseCurrentType == 'Private'">{{ getMyLanguage("register","register.title.interested") }}</h2>
                        <h2 class="title-secondary" v-if="phaseCurrentType != 'Private'">{{ getMyLanguage("register","register.title") }}</h2>
                    </div>

                    <div class="selectLanguage">
                        <SelectLanguage />
                    </div>
                </div>
            </ion-header>


            <!-- CONTENT -->
            <ion-content class="ion-padding">
                <div id="confirmEmail">
                    <section class="confirm-email">
                        <div class="load-green load-200" v-if="!showContent"></div>
                        
                        <!-- ERROR RESTRICTED -->
                        <div class="container" v-if="showContent && errorsPost.country_pep">
                            <div class="box-error">
                                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                <p v-if="newRegister.person.pep" v-html='getMyLanguage("box-error","not_allowed_restricted_country_pep-pep")'></p>
                                <p v-if="!newRegister.person.pep" v-html='getMyLanguage("box-error","not_allowed_restricted_country_pep-country")'></p>
                                <!-- <div class="cod-error">
                                    Cód.: {{errorsGet.dataInvite}}
                                </div> -->
                            </div>
                        </div>

                        
                        <!-- BOX REGISTRATION -->
                        <div class="container" v-if='showContent && !errorsPost.newRegister'>
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <div class="box-confirm-email">
                                        <div class="box-registration" :class="{dropListOpened: (dropListOpened.languageCompany || dropListOpened.countryCompany || dropListOpened.ddiCompany || dropListOpened.languagePerson || dropListOpened.countryPerson || dropListOpened.ddiPerson)}">
                                            <div class="formBox">
                                                <p class="welcome" v-if="phaseCurrentType == 'Private'"><span>{{getMyLanguage("register","register.title.interested.msg")}}</span></p>
                                                <p class="welcome" v-if="phaseCurrentType != 'Private'"><span>{{getMyLanguage("register","register.welcome")}}</span></p>

                                                <div class="check-type-code">
                                                    <div class="form-group-radio">
                                                        <input type="radio" id="typeSingular" :value="true" name="radioFlowTypeCode" class="form-radio" @click='flow.typeCode = "account_type_person"' checked >
                                                        <label for="typeSingular" class="form-radio-label">
                                                            <span>{{ getMyLanguage("register","register.type-code.singular") }}</span>
                                                        </label>

                                                        <input type="radio" id="typeCollective" :value="false" name="radioFlowTypeCode" class="form-radio" @click='flow.typeCode = "account_type_company"' >
                                                        <label for="typeCollective" class="form-radio-label">
                                                            <span>{{ getMyLanguage("register","register.type-code.collective") }}</span>
                                                        </label>
                                                    </div>
                                                </div>

                                                <!-- INVITE COMPANY -->
                                                <div class="invite-company" v-if='flow.typeCode === "account_type_company"'>
                                                    <div class="anc anc-company" id="!company"></div>

                                                    <h3>{{getMyLanguage("purchase-token","purchase-token.content.box-register.subtitle.companyData")}}</h3>
                                                    <label class="formFullName">
                                                        <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.companyName")}}</span>
                                                        <input v-model="newRegister.company.fullName" type="text" class="form" :class='{error: errorForm.companyInvite.fullName}' @keydown="errorForm.companyInvite.fullName = false" maxlength="80" autocomplete="off" >
                                                    </label>
                                                    <p class="msg-validate-form msg-error anime" v-if='errorForm.companyInvite.fullName'>{{getMyLanguage("errors","errors.nameError")}}</p>
                                                    

                                                    <!-- EMAIL COMPANY -->
                                                    <label class="formYourEmail">
                                                        <span class="ph">{{getMyLanguage("purchase-token","purchase-token.content.box-register.placeholder.companyEmail")}}</span>
                                                        <input v-model="validateEmail.company.val" onpaste="return false;" @blur='regexEmail("company")' @keyup='checkEmail("company")' type="email" class="form formYourEmail" :class='{error: (errorForm.companyInvite.email || errorsPost.newRegister === "account_activate_email_already_exists" || validateEmail.company.regex === false)}' @keydown="errorForm.companyInvite.email = false" maxlength="60" autocomplete="off">
                                                    </label>
                                                    <p class="msg-validate-form msg-error anime" :class="{invalidated: validateEmail.company.regex}" v-if='validateEmail.company.regex === false || errorForm.companyInvite.email'>{{getMyLanguage("errors","errors.emailError")}}</p>
                                                    
                                                    <label class="formYourEmail">
                                                        <span class="ph">{{getMyLanguage("client-space","client-space.account.lbl.repeatEmail")}}</span>
                                                        <input v-model="newRegister.company.email" onpaste="return false;" @keyup='checkEmail("company")' type="email" id="email" class="form formYourEmail" :class='{error: (errorForm.companyInvite.email || errorsPost.newRegister === "account_activate_email_already_exists"), warning: validateEmail.company.status === false}' maxlength="60" autocomplete="off">
                                                    </label>
                                                    <p class="msg-validate-form msg-warning anime" :class="{validated: validateEmail.company.status, invalidated: validateEmail.company.status}" v-if='validateEmail.company.status === false'>{{getMyLanguage("errors","errors.emailRepeatError")}}</p>
                                                    <p class="msg-validate-form msg-warning anime" :class="{validated: validateEmail.company.status, invalidated: validateEmail.company.status}" v-if='errorsPost.newRegister === "account_activate_email_already_exists"'>{{getMyLanguage("errors","errors.emailRegistered")}}</p>


                                                    <!-- LANGUAGE COMPANY -->
                                                    <div class="autocomplete formLanguage" :class="{opened: dropListOpened.languageCompany}">
                                                        <div class="openAutocomplete" v-if="!listLanguagesCompanyStatus" @click="dropListLanguagesCompany(true)"></div>
                                                        <div class="closeAutocomplete closeDropListCountry" v-if="listLanguagesCompanyStatus" v-on:click="dropListLanguagesCompany(false)"></div>

                                                        <label>
                                                            <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.language")}}</span>
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedLanguageCompanyFlag}/flags`" class="flag-selected" v-if="selectedLanguageCompanyFlag != false">
                                                            <input type="text" id="languageCompanyID" class="form" :class="{selected: selectedLanguageCompanyFlag != false, error: errorForm.companyInvite.languageId}" @focus="dropListLanguagesCompany(true)" v-on:keyup="filterListLanguageCompany" @blur="dropListLanguagesCompany(false)" @keydown.esc="dropListLanguagesCompany(false)" :value="selectedLanguageCompany.name" :title='getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.language")' autocomplete="off">
                                                        </label>
                                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.companyInvite.languageId'>{{getMyLanguage("errors","errors.language")}}</p>

                                                        <ul id="drop-list-company" v-if="listLanguagesCompanyStatus">
                                                            <li v-for="(itemList,index) in dataLanguage" :key="index" v-on:click="(newRegister.company.languageId = itemList.id),(selectedLanguageCompany.name = itemList.name),(selectedLanguageCompanyFlag = itemList.flag), (dropListLanguagesCompany(false)), errorForm.companyInvite.languageId = false">
                                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.flag}/flags`">
                                                                <a>{{itemList.name}}</a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <!-- COUNTRY COMPANY -->
                                                    <div class="autocomplete formResidenceCountry" :class="{opened: dropListOpened.countryCompany}">
                                                        <div class="openAutocomplete" v-if="!listCountryCompanyStatus" @click="dropListCountryCompany(true)"></div>
                                                        <div class="closeAutocomplete closeDropListCountry" v-if="listCountryCompanyStatus" v-on:click="dropListCountryCompany(false)"></div>

                                                        <label>
                                                            <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.companyCountry")}}</span>
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlagCompany}/flags`" class="flag-selected" v-if="selectedCountryFlagCompany != false">
                                                            <input type="text" id="residenceCompanyCountry" class="form" :class="{selected: selectedCountryFlagCompany != false, error: errorForm.companyInvite.countryId}" @focus="dropListCountryCompany(true)" @blur="dropListCountryCompany(false)" @keydown.esc="dropListCountryCompany(false)" v-on:keyup="filterListCountryCompany" :value="selectedCountryCompany.name" :title='getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.residence")' autocomplete="off">
                                                        </label>
                                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.companyInvite.countryId'>{{getMyLanguage("errors","errors.residenceError")}}</p>

                                                        <ul id="drop-list" v-if="listCountryCompanyStatus">
                                                            <li v-for="(itemListCompany,index) in dataCountry" :key="index" v-on:click="(newRegister.company.countryId = itemListCompany.Id), (newRegister.company.phoneCountryId = itemListCompany.Id), (this.changeMaskCompany()), (selectedCountryFlagCompany = itemListCompany.Flag), (selectedCountryDDICompany.countrySelected = true), (selectedCountryDDICompany.active = 'active'), (selectedCountryDDICompany.phoneMask = itemListCompany.PhoneMask), (selectedCountryDDICompany.ddi = itemListCompany.DDI), (selectedCountryDDICompany.flag = itemListCompany.Flag), (selectedCountryCompany.name = itemListCompany.Name), (dropListCountryCompany(false)), errorForm.companyInvite.countryId = false">
                                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemListCompany.Flag}/flags`">
                                                                <a>{{itemListCompany.Name}}</a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <!-- PHONE COMPANY -->
                                                    <div class="formPhone" :class='{opened: dropListOpened.ddiCompany, active: selectedCountryDDICompany.active, error: (errorsPost.newRegister === "account_activate_phone_already_exists" || errorForm.companyInvite.phoneNumber)}'>
                                                        <label>
                                                            <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.companyPhone")}}</span>
                                                        </label>

                                                        <div class="d-flex">
                                                            <div class="select-ddi" id="selectDDICompany" v-if="selectedCountryDDICompany.countrySelected && selectedCountryDDICompany.changeMask">
                                                                <div class="open-select-ddi" @click="dropListDDICompany(true)"></div>
                                                                <div class="close-select-ddi" v-if="selectedCountryDDICompany.openSelect" @click="dropListDDICompany(false)"></div>
                                                                
                                                                <div class="selected-ddi">
                                                                    <div class="flag">
                                                                        <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDICompany.flag}/flags`" v-if="selectedCountryDDICompany.flag != ''">
                                                                    </div>
                                                                    <span class="ddi">+{{selectedCountryDDICompany.ddi}}</span>
                                                                </div>

                                                                <ul class="list-ddi" v-if="selectedCountryDDICompany.openSelect">
                                                                    <div class="scroll">
                                                                        <li v-for="(ddiList,index) in dataCountry" :key="index" @click='(newRegister.company.phoneCountryId = ddiList.Id), (selectedCountryDDICompany.phoneMask = ddiList.PhoneMask), (this.changeMaskCompany()), (selectedCountryDDICompany.ddi = ddiList.DDI), (selectedCountryDDICompany.flag = ddiList.Flag), (dropListDDICompany(false)), (newRegister.company.phoneNumber = "")'>
                                                                            <div class="flag">
                                                                                <img class="anime" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${ddiList.Flag}/flags`">
                                                                            </div>
                                                                            <span class="name-country">{{ddiList.Name}}</span>
                                                                            <span class="ddi" >(+{{ddiList.DDI}})</span>
                                                                        </li>
                                                                    </div>
                                                                </ul>
                                                            </div>

                                                            <label v-if="selectedCountryDDICompany.changeMask">
                                                                <input v-model="validatePhone.company.val" id="phoneNumberCompany" type="text" class="form" v-mask="selectedCountryDDICompany.phoneMask" @keydown="errorForm.companyInvite.phoneNumber = false" @blur='regexPhone("company"), selectedCountryDDICompany.openSelect = false' maxlength="25" autocomplete="off" >
                                                            </label>
                                                        </div>

                                                        <div class="form load-phone" v-if="!selectedCountryDDICompany.changeMask"></div>
                                                    </div>
                                                    <p class="msg-validate-form msg-error anime" v-if='validatePhone.company.regex === false || errorForm.companyInvite.phoneNumber'>{{getMyLanguage("errors","errors.phoneError")}}</p>
                                                    <p class="msg-validate-form msg-error anime" v-if='errorsPost.newRegister === "account_activate_phone_already_exists"'>{{getMyLanguage("errors","errors.phoneRegistered")}}</p>

                                                    <label class="formTaxNumber">
                                                        <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.companyTaxNumber")}}</span>
                                                        <input v-model="newRegister.company.taxNumber" type="text" class="form" :class="{error: errorForm.companyInvite.taxNumber}" @keydown="errorForm.companyInvite.taxNumber = false" maxlength="80" autocomplete="off" >
                                                    </label>
                                                    <p class="msg-validate-form msg-error anime" v-if='errorForm.companyInvite.taxNumber'>{{getMyLanguage("errors","errors.taxNumber")}}</p>


                                                    <!-- ERRORS COMPANY -->
                                                    <!-- <div class="msgError errorsCompany">
                                                        <span class="nameError">{{ getMyLanguage("errors", "errors.nameError") }}</span>
                                                        <span class="emailError">{{ getMyLanguage("errors", "errors.emailError") }}</span>
                                                        <span class="residenceError">{{ getMyLanguage("errors", "errors.residenceError") }}</span>
                                                        <span class="phoneError">{{ getMyLanguage("errors", "errors.phoneError") }}</span>
                                                        <span class="taxNumberError">{{ getMyLanguage("errors", "errors.taxNumber") }}</span>
                                                        <span class="email_existe" v-if='errorsPost.newRegister === "account_activate_email_already_exists"'>{{ getMyLanguage("box-error", "account_activate_email_already_exists")}}</span>
                                                        <span class="phone_existe" v-if='errorsPost.newRegister === "account_activate_phone_already_exists"'>{{ getMyLanguage("box-error", "account_activate_phone_already_exists")}}</span>
                                                    </div> -->
                                                </div>


                                                <!-- INVITE PERSON -->
                                                <div class="invite-person">
                                                    <div class="anc anc-company" id="!person"></div>

                                                    <h3 v-if='flow.typeCode === "account_type_company"'>{{getMyLanguage("purchase-token","purchase-token.content.box-register.subtitle.partnerData")}}</h3>

                                                    <div class="check-pep">
                                                        <p>{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.check-pep")}}</p>

                                                        <div class="form-group-radio">
                                                            <input type="radio" v-model="newRegister.person.pep" id="pepTrue" :value="true" name="radioPEP" class="form-radio" @click='newRegister.person.pep = true' :checked='newRegister.person.pep' >
                                                            <label for="pepTrue" class="form-radio-label">
                                                                <span>{{getMyLanguage("client-space","client-space.account.modal.btn-yes")}}</span>
                                                            </label>

                                                            <input type="radio" v-model="newRegister.person.pep" id="pepFalse" :value="false" name="radioPEP" class="form-radio" @click="newRegister.person.pep = false" :checked='!newRegister.person.pep' >
                                                            <label for="pepFalse" class="form-radio-label">
                                                                <span>{{getMyLanguage("client-space","client-space.account.modal.btn-no")}}</span>
                                                            </label>
                                                        </div>

                                                        <p class="alert" v-if="newRegister.person.pep">{{getMyLanguage("purchase-token","purchase-token.content.box-register.alert.check-pep")}}</p>
                                                    </div>

                                                    <label class="formFullName">
                                                        <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.fullname")}}</span>
                                                        <input v-model="newRegister.person.fullName" type="text" class="form" :class="{error: errorForm.personInvite.fullName}" @keydown="errorForm.personInvite.fullName = false" maxlength="80" autocomplete="off" >
                                                    </label>
                                                    <p class="msg-validate-form msg-error anime" v-if='errorForm.personInvite.fullName'>{{getMyLanguage("errors","errors.nameError")}}</p>


                                                    <!-- EMAIL -->
                                                    <label class="formYourEmail">
                                                        <span class="ph">{{getMyLanguage("client-space","client-space.account.lbl.email")}}</span>
                                                        <input v-model="validateEmail.person.val" onpaste="return false;" @blur='regexEmail("person")' @keyup='checkEmail("person")' @keydown="errorForm.personInvite.email = false" type="email" class="form formYourEmail" :class='{error: (errorForm.personInvite.email || errorsPost.newRegister === "account_activate_email_already_exists" || validateEmail.person.regex === false)}' maxlength="60" autocomplete="off">
                                                    </label>
                                                    <p class="msg-validate-form msg-error anime" :class="{invalidated: validateEmail.person.regex}" v-if='validateEmail.person.regex === false || errorForm.personInvite.email'>{{getMyLanguage("errors","errors.emailError")}}</p>

                                                    <label class="formYourEmail">
                                                        <span class="ph">{{getMyLanguage("client-space","client-space.account.lbl.repeatEmail")}}</span>
                                                        <input v-model="newRegister.person.email" onpaste="return false;" @keyup='checkEmail("person")' type="email" id="email" class="form formYourEmail" :class='{error: (errorForm.personInvite.email || errorsPost.newRegister === "account_activate_email_already_exists")}' maxlength="60" autocomplete="off">
                                                    </label>
                                                    
                                                    <p class="msg-validate-form msg-warning anime" :class="{validated: validateEmail.person.status, invalidated: validateEmail.person.status}" v-if='validateEmail.person.status === false'>{{getMyLanguage("errors","errors.emailRepeatError")}}</p>
                                                    <p class="msg-validate-form msg-warning anime" :class="{validated: validateEmail.person.status, invalidated: validateEmail.person.status}" v-if='errorsPost.newRegister === "account_activate_email_already_exists"'>{{getMyLanguage("errors","errors.emailRegistered")}}</p>


                                                    <!-- LANGUAGE -->
                                                    <div class="autocomplete formLanguage" :class="{opened: dropListOpened.languagePerson}">
                                                        <div class="openAutocomplete" v-if="!listLanguagesStatus" @click="dropListLanguages(true)"></div>
                                                        <div class="closeAutocomplete closeDropListCountry" v-if="listLanguagesStatus" v-on:click="dropListLanguages(false)"></div>

                                                        <label>
                                                            <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.language")}}</span>
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedLanguageFlag}/flags`" class="flag-selected" v-if="selectedLanguageFlag != false">
                                                            <input type="text" id="languageID" class="form" :class="{selected: selectedLanguageFlag != false, error: errorForm.personInvite.languageId}" @focus="dropListLanguages(true)" v-on:keyup="filterListLanguage" @blur="dropListLanguages(false)" @keydown.esc="dropListLanguages(false)" :value="selectedLanguage.name" :title='getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.language")' autocomplete="off">
                                                        </label>
                                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.personInvite.languageId'>{{getMyLanguage("errors","errors.language")}}</p>

                                                        <ul id="drop-list" v-if="listLanguagesStatus">
                                                            <li v-for="(itemList,index) in dataLanguage" :key="index" v-on:click="(newRegister.person.languageId = itemList.id),(selectedLanguage.name = itemList.name),(selectedLanguageFlag = itemList.flag), (dropListLanguages(false)), errorForm.personInvite.languageId = false">
                                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.flag}/flags`">
                                                                <a>{{itemList.name}}</a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <!-- COUNTRY -->
                                                    <div class="autocomplete formResidenceCountry" :class="{opened: dropListOpened.countryPerson}">
                                                        <div class="openAutocomplete" v-if="!listCountryStatus" @click="dropListCountry(true)"></div>
                                                        <div class="closeAutocomplete closeDropListCountry" v-if="listCountryStatus" v-on:click="dropListCountry(false)"></div>

                                                        <label>
                                                            <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.residence")}}</span>
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlag}/flags`" class="flag-selected" v-if="selectedCountryFlag != false">
                                                            <input type="text" id="residenceCountry" class="form" :class="{selected: selectedCountryFlag != false, error: errorForm.personInvite.countryId}" @focus="dropListCountry(true)" @blur="dropListCountry(false)" @keydown.esc="dropListCountry(false)" v-on:keyup="filterListCountry" :value="selectedCountry.name" :title='getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.residence")' autocomplete="off">
                                                        </label>
                                                        <p class="msg-validate-form msg-error anime" v-if='errorForm.personInvite.countryId'>{{getMyLanguage("errors","errors.residenceError")}}</p>

                                                        <ul id="drop-list" v-if="listCountryStatus">
                                                            <li v-for="(itemList,index) in dataCountry" :key="index" v-on:click="(newRegister.person.countryId = itemList.Id), (newRegister.person.phoneCountryId = itemList.Id), (this.changeMask()), (selectedCountryFlag = itemList.Flag), (selectedCountryDDI.countrySelected = true), (selectedCountryDDI.active = 'active'), (selectedCountryDDI.phoneMask = itemList.PhoneMask), (selectedCountryDDI.ddi = itemList.DDI), (selectedCountryDDI.flag = itemList.Flag), (selectedCountry.name = itemList.Name), (dropListCountry(false)), errorForm.personInvite.countryId = false">
                                                                <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.Flag}/flags`">
                                                                <a>{{itemList.Name}}</a>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <!-- PHONE -->
                                                    <div class="formPhone" :class='{opened: dropListOpened.ddiPerson, active: selectedCountryDDI.active, error: (errorsPost.newRegister === "account_activate_phone_already_exists" || errorForm.personInvite.phoneNumber)}'>
                                                        <label>
                                                            <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.phone")}}</span>
                                                        </label>
                                                        
                                                        <div class="d-flex">
                                                            <div class="select-ddi" v-if="selectedCountryDDI.countrySelected && selectedCountryDDI.changeMask">
                                                                <div class="open-select-ddi" @click="dropListDDI(true)"></div>
                                                                <div class="close-select-ddi" v-if="selectedCountryDDI.openSelect" @click="dropListDDI(false)"></div>
                                                                
                                                                <div class="selected-ddi">
                                                                    <div class="flag">
                                                                        <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryDDI.flag}/flags`" v-if="selectedCountryDDI.flag != ''">
                                                                    </div>
                                                                    <span class="ddi">+{{selectedCountryDDI.ddi}}</span>
                                                                </div>
                                                                
                                                                <ul class="list-ddi" v-if="selectedCountryDDI.openSelect">
                                                                    <div class="scroll">
                                                                        <li v-for="(ddiList,index) in dataCountry" :key="index" @click='(newRegister.person.phoneCountryId = ddiList.Id), (selectedCountryDDI.phoneMask = ddiList.PhoneMask), (this.changeMask()), (selectedCountryDDI.ddi = ddiList.DDI), (selectedCountryDDI.flag = ddiList.Flag), (dropListDDI(false)), (newRegister.person.phoneNumber = "")'>
                                                                            <div class="flag">
                                                                                <img class="anime" :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${ddiList.Flag}/flags`">
                                                                            </div>
                                                                            <span class="name-country">{{ddiList.Name}}</span>
                                                                            <span class="ddi" >(+{{ddiList.DDI}})</span>
                                                                        </li>
                                                                    </div>
                                                                </ul>
                                                            </div>

                                                            <label v-if="selectedCountryDDI.changeMask">
                                                                <input v-model="validatePhone.person.val" id="phoneNumber" type="text" class="form" v-mask="selectedCountryDDI.phoneMask" @keydown="errorForm.personInvite.phoneNumber = false" @blur='regexPhone("person")' maxlength="25" autocomplete="off" >
                                                            </label>
                                                        </div>

                                                        <div class="form load-phone" v-if="!selectedCountryDDI.changeMask"></div>
                                                    </div>
                                                    <p class="msg-validate-form msg-error regex-error anime" v-if='validatePhone.person.regex === false || errorForm.personInvite.phoneNumber'>{{getMyLanguage("errors","errors.phoneError")}}</p>
                                                    <p class="msg-validate-form msg-error anime" v-if='errorsPost.newRegister === "account_activate_phone_already_exists"'>{{getMyLanguage("errors","errors.phoneRegistered")}}</p>


                                                    <!-- TAXNUMBER -->
                                                    <label class="formTaxNumber">
                                                        <span class="ph">{{getMyLanguage("purchase-token", "purchase-token.content.box-register.placeholder.personTaxNumber")}}</span>
                                                        <input v-model="newRegister.person.taxNumber" type="text" class="form" maxlength="80" autocomplete="off" >
                                                    </label>
                                                    

                                                    <!-- ACCEPTS -->
                                                    <div class="accepts">
                                                        <div class="form-group-check">
                                                            <input type="checkbox" id="acceptTerms" class="form-check" v-model="accept.terms">
                                                            <label for="acceptTerms" class="form-check-label" :class="{error: errorForm.acceptsInvite.acceptTerms}"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms") }}</small></label>
                                                            <a :href="`/${flagSelected}/terms-and-conditions`" target="_blank" class="lnk-terms-group-check">{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms.lnk") }}</a>
                                                        </div>
                                                        
                                                        <div class="form-group-check">
                                                            <input type="checkbox" id="acceptNotify" class="form-check" v-model="accept.notify">
                                                            <label for="acceptNotify" class="form-check-label" :class="{error: errorForm.acceptsInvite.acceptNotify}"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-notify") }}</small></label>
                                                        </div>
                                                        
                                                        <div class="form-group-check">
                                                            <input type="checkbox" id="acceptGDPR" class="form-check" v-model="accept.gdpr">
                                                            <label for="acceptGDPR" class="form-check-label" :class="{error: errorForm.acceptsInvite.acceptGdpr}"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-gdpr-ecosistema") }}</small></label>
                                                        </div>
                                                    </div>

                                                    <div class="box-error" v-if="errorsPost.general != false">
                                                        <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                                        <p>{{ getMyLanguage("box-error","general-error") }}</p>
                                                        <div class="cod-error">
                                                            Cód.: {{errorsPost.general}}
                                                        </div>
                                                    </div>


                                                    <div class="check-referrer" v-if="!hideBoxReferrer">
                                                        <p>{{getMyLanguage("purchase-token", "purchase-token.content.box-register.has-referrer")}}</p>

                                                        <div class="form-group-radio">
                                                            <input type="radio" v-model="hasReferrer" id="refTrue" :value="true" name="radioReferrer" class="form-radio" @click='hasReferrer = true' :checked='hasReferrer' >
                                                            <label for="refTrue" class="form-radio-label">
                                                                <span>{{getMyLanguage("client-space","client-space.account.modal.btn-yes")}}</span>
                                                            </label>

                                                            <input type="radio" v-model="hasReferrer" id="refFalse" :value="false" name="radioReferrer" class="form-radio" @click="hasReferrer = false" :checked='!hasReferrer' >
                                                            <label for="refFalse" class="form-radio-label">
                                                                <span>{{getMyLanguage("client-space","client-space.account.modal.btn-no")}}</span>
                                                            </label>
                                                        </div>

                                                        <label v-if="hasReferrer">
                                                            <span class="ph">{{getMyLanguage("purchase-token","purchase-token.content.box-register.placeholder.has-referrer")}}</span>
                                                            <input type="text" class="form" :class="{error: errorForm.personInvite.fullName}" @keydown="errorForm.personInvite.fullName = false" v-model="referrerId">
                                                        </label>
                                                        <p class="msg-validate-form msg-error anime" v-if='hasReferrer && errorForm.codReferrerId'>{{getMyLanguage("errors","errors.referrer")}}</p>
                                                    </div>


                                                    <div id="btnRegister" v-if="!accept.terms || !accept.notify || !accept.gdpr" @click="validationFormInvite()" class="btn-primary btn-disable">{{ getMyLanguage("register","register.btn-register") }}</div>
                                                    <div id="btnRegister" v-if="accept.terms && accept.notify && accept.gdpr && !load && !errorsPost.general" @click="validationFormInvite()" class="btn-primary">{{ getMyLanguage("register","register.btn-register") }}</div>
                                                    <div class="btn-primary load" v-if="load"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </ion-content>

            <!-- <FooterApp /> -->

            <!-- FOOTER -->
            <div class="footer" :class="{openedMenu: menuFooter}" v-if="showContent">
                <div class="cont-footer">
                    <div class="col-lnks lnks-right">
                        <div @click="loginHome()" class="lnk-footer">
                            <img src="@/assets/images/icons/btn-footer-home.svg">
                            <span>{{ getMyLanguage("footer", "footer.lnk.home") }}</span>
                        </div>
                    </div>

                    <div class="col-open-menu">
                        <div id="closeMenu" v-if="menuFooter" @click="menuFooter = false"></div>
                        <div class="lnk-footer open-menu" id="openMenu" v-if="!menuFooter" @click="menuFooter = true">
                            <span>
                                <img src="@/assets/images/dne.svg">
                            </span>
                        </div>
                    </div>
                    
                    <div class="col-lnks lnks-left">            
                        <div @click="loginHome()" class="lnk-footer">
                            <img src="@/assets/images/icons/btn-footer-activity.svg">
                            <span>{{ getMyLanguage("footer", "footer.lnk.activity") }}</span>
                        </div>
                    </div>
                </div>
            </div>


            <!-- MENU -->
            <div id="menu" v-if="menuFooter">
                <div class="contmenu ion-padding">
                    <div class="menuNav">
                        <ul class="box navbar-nav navbar-primary">
                            <div class="nav-link" @click="loginHome()">
                                <img src="@/assets/images/menu/lnk-dne-token.svg">
                                <h6 class="title-primary">DNE <span>T</span>oken</h6>
                            </div>

                            <div class="nav-link" @click="loginHome()">
                                <img src="@/assets/images/menu/lnk-market.svg">
                                <h6 class="title-primary"><span>M</span>arket</h6>
                            </div>

                            <div class="nav-link" @click="loginHome()">
                                <img src="@/assets/images/menu/lnk-payments.svg">
                                <h6 class="title-primary"><span>P</span>ay</h6>
                            </div>

                            <div class="nav-link" @click="loginHome()">
                                <img src="@/assets/images/menu/lnk-bank.svg">
                                <h6 class="title-primary">{{getMyLanguage("dne-bank","dne-bank.title")}}</h6>
                            </div>

                            <div class="nav-link" @click="loginHome()">
                                <img src="@/assets/images/menu/lnk-exchange.svg">
                                <h6 class="title-primary"><span>E</span>xchange</h6>
                            </div>
                        </ul>

                        <ul class="box navbar-nav navbar-secondary">
                            <div class="nav-link" @click="loginHome()">
                                <img src="@/assets/images/menu/lnk-security.svg">
                                <h6>{{ getMyLanguage("footer", "footer.lnk.security") }}</h6>
                            </div>

                            <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/support`" target="_blank" class="nav-link">
                                <img src="@/assets/images/menu/lnk-support.svg">
                                <h6>{{ getMyLanguage("footer", "footer.lnk.support") }}</h6>
                            </a>

                            <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/terms-and-conditions`" target="_blank" class="nav-link">
                                <img src="@/assets/images/menu/lnk-terms-conditions.svg">
                                <h6>{{ getMyLanguage("footer", "footer.lnk.termsConditions") }}</h6>
                            </a>

                            <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/privacy`" target="_blank" class="nav-link">
                                <img src="@/assets/images/menu/lnk-privacy-policy.svg">
                                <h6>{{ getMyLanguage("footer", "footer.lnk.privacyPolicy") }}</h6>
                            </a>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="overlayMenu"></div>
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonHeader, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";
    
    import SelectLanguage from "@/components/SelectLanguage.vue";
    // import FooterApp from '@/components/dneplatform/FooterApp.vue';

    import {mask} from 'vue-the-mask'

    import apiPlatform from '@/config/apiPlatform';
    import apiInternational from '@/config/apiInternational';
    // import { handleError, error404, errorGeneral, errorMsg, errorBlockPurchase } from '@/services/errorHandler.js'

    export default {
        components: {
            IonApp, IonPage, IonHeader, IonContent,
            SelectLanguage,
            // FooterApp
        },

        directives: {mask},

        data () {
            return {
                VUE_APP_DEVELOP: process.env.VUE_APP_DEVELOP,

                showContent: false,
                load: false,

                errorsGet: {
                    dataInvite: false
                },
                errorsPost: {
                    newRegister: false,
                    country_pep: false,
                    general: false
                },

                validateEmail: {
                    company: {
                        regex: '',
                        status: '',
                        val: ''
                    },

                    person: {
                        regex: '',
                        status: '',
                        val: ''
                    }
                },
                
                validatePhone: {
                    company: {
                        regex: '',
                        status: '',
                        val: ''
                    },

                    person: {
                        regex: '',
                        status: '',
                        val: ''
                    }
                },

                flow: {
                    typeCode: "account_type_person",
                },

                dataCountry: [],
                dataLanguage: [],
                
                flagSelected: "en",
                showFlag: false,
                showFlagCompany: false,

                listLanguagesStatus: false,
                selectedLanguage: {},
                selectedLanguageFlag: false,
                
                listLanguagesCompanyStatus: false,
                selectedLanguageCompany: {},
                selectedLanguageCompanyFlag: false,

                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryFlag: false,
                
                listCountryCompanyStatus: false,
                selectedCountryCompany: {},
                selectedCountryFlagCompany: false,
                
                selectedCountryDDICompany: {
                    changeMask: true,
                    phoneMask: '',
                    countrySelected: false,
                    flag: ''
                },

                selectedCountryDDI: {
                    changeMask: true,
                    phoneMask: '',
                    countrySelected: false,
                    flag: ''
                },

                dropListOpened: {
                    languageCompany: false,
                    countryCompany: false,
                    ddiCompany: false,

                    languagePerson: false,
                    countryPerson: false,
                    ddiPerson: false
                },

                newRegister: {
                    person: {
                        pep: false,
                        fullName: "",
                        email: "",
                        countryId: "",
                        languageId: "",
                        phoneCountryId: "",
                        phoneNumber: "",
                        taxNumber: ""
                    },
                    company: {
                        pep: false,
                        fullName: "",
                        email: "",
                        countryId: "",
                        languageId: "",
                        phoneCountryId: "",
                        phoneNumber: "",
                        taxNumber: ""
                    }
                },                

                errorForm: {
                    companyInvite: {},
                    personInvite: {},
                    acceptsInvite: {
                        acceptTerms: false,
                        acceptNotify: false,
                        acceptGdpr: false
                    },

                    company: false,
                    person: false,
                    accepts: false,
                    codReferrerId: false
                },

                accept: {
                    terms: false,
                    notify: false,
                    gdpr: false
                },

                resendLoad: false,

                phaseCurrentType: "",
                hideBoxReferrer: false,
                hasReferrer: false,
                referrerId: "",
                urlPost: "",

                menuFooter: false
            }
        },


        async mounted(){
            const urlParams = new URLSearchParams(window.location.search);
            this.referrerId = urlParams.get('referrer')
            
            if ( this.referrerId ){
                this.hideBoxReferrer = true
            }

            
            // PHASE CURRENT
            await apiInternational.get('api/v2/ico/phase/current')
            .then(response => {
                localStorage.setItem("phaseCurrent",response.data.phase_type)
                this.phaseCurrentType = response.data.phase_type
            })
            .catch(error => {
                console.log(error)
                this.errorsGet.dataInvite = error.response.data
            })

            
            // LANGUAGE
            await apiPlatform.get('/api/v1/platform/language')
            .then(response => {
                this.dataLanguage = response.data
            })
            .catch(error => {
                this.errorsGet.dataInvite = error.response.data
            })
            

            // COUNTRY
            await apiPlatform.get('api/v1/platform/country')
            .then(response => {
                this.dataCountry = response.data
            })
            .catch(error => {
                this.errorsGet.dataInvite = error.response.data
            })


            // URL POST
            if ( localStorage.getItem("phaseCurrent") == "Private" ){
                if ( this.referrerId || this.hasReferrer ){
                    this.urlPost = `/api/v2/platform/account/lead?referrer_account_id=${this.referrerId}`
                } else {
                    this.urlPost = "/api/v2/platform/account/lead"
                }
            } else {
                if ( this.referrerId || this.hasReferrer ){
                    this.urlPost = `/api/v2/platform/account/register?referrer_account_id=${this.referrerId}`
                } else {
                    this.urlPost = "/api/v2/platform/account/register"
                }
            }

            setTimeout(() => {
                this.showContent = true
            }, 500);
        },


        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            regexEmail(emailCheck){
                const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                if ( emailCheck === "company"){
                    if (regex.test(this.validateEmail.company.val)) {
                        this.validateEmail.company.regex = true
                    } else{
                        this.validateEmail.company.regex = false
                    }
                } else if ( emailCheck === "person"){
                    if (regex.test(this.validateEmail.person.val)) {
                        this.validateEmail.person.regex = true
                    } else{
                        this.validateEmail.person.regex = false
                    }
                }
            },

            checkEmail(emailCheck){
                if ( emailCheck === "company" ){
                    if ( this.validateEmail.company.val === this.newRegister.company.email ){
                        this.validateEmail.company.status = true
                    } else{
                        this.validateEmail.company.status = false
                    }
                } else if ( emailCheck === "person" ){
                    if ( this.validateEmail.person.val === this.newRegister.person.email ){
                        this.validateEmail.person.status = true
                    } else{
                        this.validateEmail.person.status = false
                    }
                }
            },

            regexPhone(phoneCheck){
                const regex = /^\+[1-9]\d{8,14}$/;

                if ( phoneCheck === "company"){
                    let phoneE164Company = this.selectedCountryDDICompany.ddi + this.validatePhone.company.val
                    phoneE164Company = "+" + phoneE164Company.replace(/[^0-9]/g,'')
                    
                    if (regex.test(phoneE164Company)) {
                        this.validatePhone.company.regex = true
                    } else{
                        this.validatePhone.company.regex = false
                    }
                } else if ( phoneCheck === "person"){
                    let phoneE164Person = this.selectedCountryDDI.ddi + this.validatePhone.person.val
                    phoneE164Person = "+" + phoneE164Person.replace(/[^0-9]/g,'')
                    
                    if (regex.test(phoneE164Person)) {
                        this.validatePhone.person.regex = true
                    } else{
                        this.validatePhone.person.regex = false
                    }
                }
            },

            changeFlagLanguage(value){
                this.showFlag = false
                this.newRegister.person.languageId = value

                this.showFlag = true
            },

            changeMaskCompany(){
                this.selectedCountryDDICompany.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDICompany.changeMask = true

                    const maskPhone = this.selectedCountryDDICompany.phoneMask
                    
                    this.selectedCountryDDICompany.phoneMask = maskPhone.replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#")

                    this.selectedCountryDDICompany.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumberCompany').focus()
                }, 1050);
            },

            changeMask(){
                this.selectedCountryDDI.changeMask = false

                setTimeout(() => {
                    this.selectedCountryDDI.changeMask = true

                    const maskPhone = this.selectedCountryDDI.phoneMask
                    
                    this.selectedCountryDDI.phoneMask = maskPhone.replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#").replace("0", "#")

                    this.selectedCountryDDI.changeMask = true
                }, 1000);

                setTimeout(() => {
                    document.getElementById('phoneNumber').focus()
                }, 1050);
            },

            dropListCountryCompany(status){
                setTimeout(() => {
                    this.listCountryCompanyStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryCompany = true
                        document.getElementById('residenceCompanyCountry').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryCompany = false
                }
            },
            
            dropListCountry(status){
                setTimeout(() => {
                    this.listCountryStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.countryPerson = true
                        document.getElementById('residenceCountry').focus()
                    }, 10);
                } else {
                    this.dropListOpened.countryPerson = false
                }
            },
            
            dropListLanguages(status){
                setTimeout(() => {
                    this.listLanguagesStatus = status
                }, 250)

                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.languagePerson = true
                        document.getElementById('languageID').focus()
                    }, 10);
                } else {
                    this.dropListOpened.languagePerson = false
                }
            },
            
            dropListLanguagesCompany(status){
                setTimeout(() => {
                    this.listLanguagesCompanyStatus = status
                }, 250);

                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.languageCompany = true
                        document.getElementById('languageCompanyID').focus()
                    }, 10);
                } else {
                    this.dropListOpened.languageCompany = false
                }
            },
            
            dropListDDICompany(status){
                console.log("aaa")
                setTimeout(() => {
                    this.selectedCountryDDICompany.openSelect = status
                }, 250);

                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.ddiCompany = true
                    }, 10);
                } else {
                    this.dropListOpened.ddiCompany = false
                }
            },
            
            dropListDDI(status){
                setTimeout(() => {
                    this.selectedCountryDDI.openSelect = status
                }, 250);

                if ( status ){
                    setTimeout(() => {
                        this.dropListOpened.ddiPerson = true
                    }, 10);
                } else {
                    this.dropListOpened.ddiPerson = false
                }
            },

            filterListLanguageCompany() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("languageCompanyID");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list-company");
                const li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }

                // var input, filter, ul, li, a, i, txtValue;
                // input = document.getElementById("languageCompanyID");
                // filter = input.value.toUpperCase();
                // ul = document.getElementById("drop-list-company");
                // li = ul.getElementsByTagName("li");
                // for (i = 0; i < li.length; i++) {
                // a = li[i].getElementsByTagName("a")[0];
                // txtValue = a.textContent || a.innerText;
                // if (txtValue.toUpperCase().indexOf(filter) > -1) {
                //         li[i].style.display = "";
                // } else {
                //         li[i].style.display = "none";
                // }
                // }
            },

            filterListLanguage() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("languageID");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }

                // var input, filter, ul, li, a, i, txtValue;
                // input = document.getElementById("languageID");
                // filter = input.value.toUpperCase();
                // ul = document.getElementById("drop-list");
                // li = ul.getElementsByTagName("li");
                // for (i = 0; i < li.length; i++) {
                // a = li[i].getElementsByTagName("a")[0];
                // txtValue = a.textContent || a.innerText;
                // if (txtValue.toUpperCase().indexOf(filter) > -1) {
                //         li[i].style.display = "";
                // } else {
                //         li[i].style.display = "none";
                // }
                // }
            },

            filterListCountryCompany() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("residenceCompanyCountry");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }


                // var input, filter, ul, li, a, i, txtValue;
                // input = document.getElementById("residenceCompanyCountry");
                // filter = input.value.toUpperCase();
                // ul = document.getElementById("drop-list");
                // li = ul.getElementsByTagName("li");
                // for (i = 0; i < li.length; i++) {
                // a = li[i].getElementsByTagName("a")[0];
                // txtValue = a.textContent || a.innerText;
                // if (txtValue.toUpperCase().indexOf(filter) > -1) {
                //         li[i].style.display = "";
                // } else {
                //         li[i].style.display = "none";
                // }
                // }
            },
            
            filterListCountry() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("residenceCountry");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }


                // var input, filter, ul, li, a, i, txtValue;
                // input = document.getElementById("residenceCountry");
                // filter = input.value.toUpperCase();
                // ul = document.getElementById("drop-list");
                // li = ul.getElementsByTagName("li");
                // for (i = 0; i < li.length; i++) {
                // a = li[i].getElementsByTagName("a")[0];
                // txtValue = a.textContent || a.innerText;
                // if (txtValue.toUpperCase().indexOf(filter) > -1) {
                //         li[i].style.display = "";
                // } else {
                //         li[i].style.display = "none";
                // }
                // }
            },


            validationFormInvite(){

                // VALIDATION COMPLIANCE
                if ( this.flow.typeCode === "account_type_company" ){

                    // COMPANY NAME
                    if ( this.newRegister.company.fullName != "" ){
                        let companyName = this.newRegister.company.fullName
                        companyName = companyName.trim().split(' ').length
                        
                        if ( companyName < 2 ){
                            this.errorForm.companyInvite.fullName = true
                        } else {
                            this.errorForm.companyInvite.fullName = false
                        }
                    } else {
                        this.errorForm.companyInvite.fullName = true
                    }


                    // COMPANY EMAIL
                    if ( this.newRegister.company.email === "" || !this.validateEmail.company.regex || !this.validateEmail.company.status ){
                        this.errorForm.companyInvite.email = true
                    } else {
                        this.errorForm.companyInvite.email = false
                    }


                    // COMPANY LANGUAGE
                    if ( this.newRegister.company.languageId === "" ){
                        this.errorForm.companyInvite.languageId = true
                    } else {
                        this.errorForm.companyInvite.languageId = false
                    }


                    // COMPANY COUNTRY
                    if ( this.newRegister.company.countryId === "" ){
                        this.errorForm.companyInvite.countryId = true
                    } else {
                        this.errorForm.companyInvite.countryId = false
                    }


                    // COMPANY PHONE
                    if ( this.validatePhone.company.val === "" ){
                        this.errorForm.companyInvite.phoneNumber = true
                    } else if ( this.validatePhone.company.val.length < this.selectedCountryDDICompany.phoneMask.length ) {
                        this.errorForm.companyInvite.phoneNumber = true
                    } else {
                        this.errorForm.companyInvite.phoneNumber = false
                    }

                    // COMPANY TAXNUMBER
                    if ( this.newRegister.company.taxNumber ==="" ){
                        this.errorForm.companyInvite.taxNumber = true
                    } else {
                        this.errorForm.companyInvite.taxNumber = false
                    }


                    // CKECK VALIDATION COMPLIANCE
                    if ( this.errorForm.companyInvite.fullName || this.errorForm.companyInvite.email || this.errorForm.companyInvite.languageId || this.errorForm.companyInvite.countryId || this.errorForm.companyInvite.phoneNumber || this.errorForm.companyInvite.taxNumber ){
                        // window.location.href = "#!company"
                        this.errorForm.company = true
                    } else {
                        this.errorForm.company = false
                    }
                }



                
                // PERSON NAME
                if ( this.newRegister.person.fullName != "" ){
                    let personName = this.newRegister.person.fullName
                    personName = personName.trim().split(' ').length
                    
                    if ( personName < 2 ){
                        this.errorForm.personInvite.fullName = true
                    } else {
                        this.errorForm.personInvite.fullName = false
                    }
                } else {
                    this.errorForm.personInvite.fullName = true
                }


                // EMAIL
                if ( this.newRegister.person.email === "" || !this.validateEmail.person.regex || !this.validateEmail.person.status ){
                    this.errorForm.personInvite.email = true
                } else {
                    this.errorForm.personInvite.email = false
                }


                // LANGUAGE
                if ( this.newRegister.person.languageId === "" ){
                    this.errorForm.personInvite.languageId = true
                } else {
                    this.errorForm.personInvite.languageId = false
                }


                // COUNTRY
                if ( this.newRegister.person.countryId === "" ){
                    this.errorForm.personInvite.countryId = true
                } else {
                    this.errorForm.personInvite.countryId = false
                }


                // PHONE
                if ( this.validatePhone.person.val === "" ){
                    this.errorForm.personInvite.phoneNumber = true
                } else if ( this.validatePhone.person.val.length < this.selectedCountryDDI.phoneMask.length ) {
                    this.errorForm.personInvite.phoneNumber = true
                } else {
                    this.errorForm.personInvite.phoneNumber = false
                }


                // TAXNUMBER
                // if ( this.newRegister.person.taxNumber ==="" ){
                //     this.errorForm.personInvite.taxNumber = true
                // } else {
                //     this.errorForm.personInvite.taxNumber = false
                // }


                // ACCEPTS
                if ( !this.accept.terms ){
                    this.errorForm.acceptsInvite.acceptTerms = true
                } else{
                    this.errorForm.acceptsInvite.acceptTerms = false
                }
                
                if ( !this.accept.notify ){
                    this.errorForm.acceptsInvite.acceptNotify = true
                } else{
                    this.errorForm.acceptsInvite.acceptNotify = false
                }
                
                if ( !this.accept.gdpr ){
                    this.errorForm.acceptsInvite.acceptGdpr = true
                } else{
                    this.errorForm.acceptsInvite.acceptGdpr = false
                }

                if ( this.errorForm.acceptsInvite.acceptTerms || this.errorForm.acceptsInvite.acceptNotify || this.errorForm.acceptsInvite.acceptGdpr ){
                    this.errorForm.accepts = true
                } else {
                    this.errorForm.accepts = false
                }


                // CKECK VALIDATION COMPLIANCE
                if ( !this.errorForm.company && (this.errorForm.personInvite.fullName || this.errorForm.personInvite.email || this.errorForm.personInvite.languageId || this.errorForm.personInvite.countryId || this.errorForm.personInvite.phoneNumber) ){
                    // window.location.href = "#!person"
                    this.errorForm.person = true
                } else {
                    this.errorForm.person = false
                }

                
                // REFERRER ID
                if ( this.hasReferrer && !this.referrerId ){
                    this.errorForm.codReferrerId = true
                } else {
                    this.errorForm.codReferrerId = false
                }


                // LAST CKECK
                if ( !this.errorForm.company && !this.errorForm.accepts && !this.errorForm.person ){
                    this.register()
                }
            },

            
            register(){
                this.load = true
                
                if ( this.flow.typeCode === "account_type_company" ){
                    const dataPhoneCompany = this.validatePhone.company.val
                    this.newRegister.company.phoneNumber = dataPhoneCompany.replace(/[^0-9]/g,'')
                }
                
                const dataPhonePerson = this.validatePhone.person.val
                this.newRegister.person.phoneNumber = dataPhonePerson.replace(/[^0-9]/g,'')


                if ( this.flow.typeCode === "account_type_person" ){
                    delete this.newRegister.company;
                }

                apiPlatform.post(this.urlPost, this.newRegister)
                .then(response => {
                    this.registerId = response.data.id;
                    const languageRedirect = String(window.location.pathname).split("/")[1];

                    window.location.href = `/${languageRedirect}/platform/register-success/?phase=${this.phaseCurrentType}`
                })
                .catch(error => {
                    console.log(error.response.data)

                    if ( error.response.data === "account_activate_email_already_exists" || error.response.data === "account_activate_phone_already_exists" ) {
                        this.errorsPost.newRegister = error.response.data
                    } else if ( error.response.data === "not_allowed_restricted_country_pep" ) {
                        this.errorsPost.country_pep = true
                    } else{
                        this.errorsPost.general = error.response.status

                        setTimeout(() => {
                            this.errorsPost.general = false
                        }, 4000);
                    }

                    console.log(this.errorsPost)

                    setTimeout(() => {
                        this.load = false
                    }, 500);
                })
            },


            loginHome(){
                window.location.href = `/${this.flagSelected}/home`
            }
        }
    }
</script>


<style scoped src="@/assets/css/Header.css" />
<style scoped src="@/assets/css/Register.css" />
<style scoped src="@/assets/css/Footer.css" />