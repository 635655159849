<template>
    <div class="receive-content">
        <div class="load-green" v-if="load"></div>

        <div v-if="showWallets && !load">
            <h2 class="title-secondary">QR Code</h2>
            <div class="box qrcode">
                <qrcode-vue :value="qrCode.value" :size="qrCode.size" level="L" />
            </div>

            <h2 class="title-secondary">{{getMyLanguage("dnePay","wallet")}}</h2>
            <div class="box wallet">
                <h3 class="wallet-title">Spot</h3>
                <p class="wallet-address">
                    {{dataQRCode.wallet_address}}
                    <span class="copy" @click='copyAddress(dataQRCode.wallet_address)'></span>
                </p>
                
                <div class="btn-primary" @click='shareWallet()'>{{getMyLanguage("dnePay","receive.share")}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {toastController} from '@ionic/vue';

    import getMyFavouriteLanguage from "@/services/language.js";

    import { Share } from '@capacitor/share';

    import QrcodeVue from 'qrcode.vue'
    import apiPay from '@/config/apiPay.js';
    
    export default {
        components: {
            QrcodeVue
        },
        data () {
            return {
                VUE_APP_INTERNATIONAL_URL: process.env.VUE_APP_INTERNATIONAL_URL,

                showWallets: false,
                load: true,

                qrCode:{
                    value: "",
                    size: 200
                },

                flagSelected: {},
                dataProfile: "",

                dataWallet: {},
                dataQRCode: {},

                // dataWallet: {
                //     account_wallet_type: "Transfer",
                //     wallet_can_deposit: true,
                //     asset_short: "DNE",
                //     account_wallet_id: "65s8d4f65s4df564sd56f4s56d4f56s",
                //     wallet_address: "ggdfdfgdfgdf64g56df4g65d456f4g56df4g654d56f4g6df",
                //     wallet_name: "Henryson`s DNE Wallet"
                // },

                // dataWallet: {
                //     TransactionType: "Payment",
                //     OperationType: "Payment",
                //     AssetShort: "DNE",
                //     CheckoutId: "as1d456as564d6a5s4d6a6s4d",
                //     AccountWalletId: "65s8d4f65s4df564sd56f4s56d4f56s",
                //     WalletAddress: "ggdfdfgdfgdf64g56df4g65d456f4g56df4g654d56f4g6df",
                //     AccountWalletName: "Tilt DNE Market",
                //     MerchantName: "Tilt Informatica",
                //     Details: [
                //         {
                //             name: "Mouse Pad Razer",
                //             value: 24.99
                //         },
                //         {
                //             name: "HD External SSD 2TB Toshiba",
                //             value: 129.99
                //         },
                //     ],
                //     Amount: 154.98
                // },

                dataShare: {
                    title: 'Jorge Cabral (DNE Token)',
                    text: 'Address: 3513515315313518131358135186135135153153135181',
                    url: 'https://dne.international',
                    dialogTitle: 'Digital New Economy',
                }
                
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            async copyAddress(addressWallet) {
                try {
                await navigator.clipboard.writeText(addressWallet);
                    this.openToastCopy()
                }
                
                catch($e) {
                    this.openToastNoCopy()
                }
            },

            async openToastCopy() {
                const toast = await toastController
                    .create({
                        message: this.getMyLanguage("receive", "modal-receive.copied"),
                        color: "secondary",
                        duration: 2000,
                        position: 'middle',
                    })
                return toast.present();
            },            
            
            async openToastNocopy() {
                const toast = await toastController
                    .create({
                        message: "Error",
                        color: "primary",
                        duration: 2000
                    })
                return toast.present();
            },

            async shareWallet(){
                await Share.share({
                    title: this.dataShare.title,
                    text: this.dataShare.text,
                    url: this.dataShare.url,
                    dialogTitle: this.dataShare.dialogTitle,
                });

                console.log(this.dataShare)
            }
        },

        async mounted () {
            this.flagUrl()
            // const tokenValue = localStorage.getItem('accessToken')
            this.dataProfile = JSON.parse(localStorage.getItem("profile"))

            await apiPay.get('/api/v1/pay/wallet/balances/DNE')
            .then(response => {
                this.dataWallet = response.data.source_wallets.filter(spot => spot.account_wallet_type_code === "spot")[0]
                console.log(this.dataWallet)

                this.dataQRCode.wallet_can_deposit = this.dataWallet.wallet_can_deposit
                this.dataQRCode.asset_short = "DNE"
                this.dataQRCode.account_wallet_id = this.dataWallet.account_wallet_id
                this.dataQRCode.wallet_address = this.dataWallet.wallet_address
                this.dataQRCode.wallet_name = this.dataWallet.wallet_name

                // dataWallet: {
                //     account_wallet_type: "Transfer",
                //     wallet_can_deposit: true,
                //     asset_short: "DNE",
                //     account_wallet_id: "65s8d4f65s4df564sd56f4s56d4f56s",
                //     wallet_address: "ggdfdfgdfgdf64g56df4g65d456f4g56df4g654d56f4g6df",
                //     wallet_name: "Henryson`s DNE Wallet"
                // },
                
                this.qrCode.value = JSON.stringify(this.dataWallet)


                // SHARE
                this.dataShare.title = this.dataWallet.wallet_name
                this.dataShare.text = `Address: ${this.dataWallet.wallet_address}`
                this.dataShare.url = this.VUE_APP_INTERNATIONAL_URL
                this.dataShare.dialogTitle = 'Digital New Economy'
                
                
                setTimeout(() => {
                    this.showWallets = true
                    this.load = false
                }, 1000);
            })
            // .catch(error => {
            //     if ( error.response.status === 403 ) {
            //         this.showWallets = false
            //         window.location.href = `/${this.flagSelected}/account/consent`
            //     }
                
            //     if ( error.response.status === 409 ) {
            //         if ( error.response.data === "account_must_have_kyc" ){
            //             window.location.href = `/${this.flagSelected}/account/kyc`
            //         }

            //         if ( error.response.data === "account_must_have_compliance" ){
            //             window.location.href = `/${this.flagSelected}/account/compliance`
            //         }

            //         if ( error.response.data === "account_must_be_manualy_checked" ){
            //             window.location.href = `/${this.flagSelected}/account/pending`
            //         }
            //     }
                
            //     if( error.response.status === 401 || tokenValue.length > 0 ){
            //         localStorage.removeItem('accessToken');
            //         window.location.href = `/${this.flagSelected}/home`
            //     }
            // })
        }
    }
</script>

<style scoped>
    .wallet{margin-bottom: 40px;}
    .wallet .wallet-title{font-size: 20px; color: var(--color-primary);}
    .wallet .wallet-address{position: relative; font-size: 14px; line-height: 34px; font-weight: 600; white-space: nowrap; overflow: hidden; margin-bottom: 30px;}
    .wallet .wallet-address .copy{position: absolute; right: 0; top: 0; width: 34px; height: 34px; background: url('~@/assets/images/icons/copy.svg') no-repeat center right var(--color-text-tertiary); background-size: auto 80%;}
    .wallet .btn-primary:after{display: none;}

    .qrcode{margin: 0 auto 30px; width: 272px; display: flex; justify-content: center;}
</style>

<style scoped src="@/assets/css/Balances.css" />
