
import { IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
// import { useRoute } from 'vue-router';
// import { archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';

export default defineComponent({
   name: 'App',
   components: {
      IonRouterOutlet,
   }
});
