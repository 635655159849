<template>
    <ion-app :scroll-events="true" class="home">
        <ion-page>
            <!-- HEADER -->
            <ion-header class="header" v-if="showContent">
                <div class="header-row" :class="{develop: VUE_APP_DEVELOP == 'develop'}">
                    <div class="logo-balances">
                        <div class="logo-circle">
                            <img src="@/assets/images/dne.svg">
                        </div>
                    </div>
                    
                    <div class="login-register">
                        <h2 class="title-secondary">{{ getMyLanguage("confirm-email","success-title") }}</h2>
                    </div>

                    <div class="selectLanguage">
                        <SelectLanguage />
                    </div>
                </div>
            </ion-header>


            <!-- CONTENT -->
            <ion-content class="ion-padding">
                <section class="confirm-email">
                    <div class="load-green load-200" v-if="!showContent"></div>
                    
                    <div class="container" v-if='showContent'>
                        <div class="row">
                            <div class="col-12 col-lg-6">

                                <!-- SUCCESS LEAD -->
                                <div class="box" v-if="success == 'Private'">
                                    <div class="icon-confirm">
                                        <img src="~@/assets/images/icons/register-success.svg">
                                    </div>

                                    <div class="box-success">
                                        <p>{{ getMyLanguage("confirm-email", "register-lead-success") }}</p>
                                    </div>
                                </div>


                                <!-- SUCCESS REGISTER -->
                                <div class="box" v-if="success != 'Private'">
                                    <div class="icon-confirm">
                                        <img src="~@/assets/images/icons/register-success.svg">
                                    </div>

                                    <div class="box-success">
                                        <p>{{ getMyLanguage("confirm-email", "register-success") }}</p>
                                    </div>
                                    
                                    <div class="msg msg-attention">
                                        <a :href="`/${flagSelected}/home`" class="btn-primary" v-html='getMyLanguage("confirm-email","cta-create-account")'></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </ion-content>

            <FooterApp />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonHeader, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";
    
    import SelectLanguage from "@/components/SelectLanguage.vue";
    import FooterApp from '@/components/dneplatform/FooterApp.vue';

    export default {
        components: {
            IonApp, IonPage, IonHeader, IonContent,
            SelectLanguage,
            FooterApp
        },

        data () {
            return {
                VUE_APP_DEVELOP: process.env.VUE_APP_DEVELOP,
                success: "",
                showContent: false
            }
        },

        async mounted(){
            const urlParams = new URLSearchParams(window.location.search);
            this.success = urlParams.get('phase')

            setTimeout(() => {
                this.showContent = true
            }, 2000);
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            }
        }
    }
</script>


<style scoped src="@/assets/css/Header.css" />

<style scoped>
    .box .icon-confirm{width: 40%; margin: 0 auto 30px;}
    .box .box-success{background: var(--color-primary); margin: 0;}
    .box .btn-primary{font-size: 16px; line-height: 20px; width: 90%; height: 70px; margin: -10px auto 0;}
    .box .btn-primary:after{display: none;}
</style>
