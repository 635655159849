<template>
    <ion-app :scroll-events="true" class="wallets">
        <ion-page>
            <div class="header-step">
                <HeaderApp />
            </div>

            <ion-content class="wallets">
                <div class="content">
                    <div class="ion-padding">
                        <div class="load-blue" v-if="!showContent"></div>
                
                        <div class="wallet-stake" v-if="showContent">
                            <h2 class="title-primary"><span>W</span>allet Stake</h2>

                            <!-- GENERATE WALLET -->
                            <div class="box box-wallet" v-if="!flow.error">
                                <p class="alert">{{ getMyLanguage("purchase-token","wallet-stake.alert") }}</p>

                                <div class="load-green load-200" v-if="flow.load"></div>
                                
                                <div class="btn-primary" v-if="!flow.generated && !flow.load" @click="generateWallet()">{{ getMyLanguage("purchase-token","wallet-stake.btn-generate-wallet") }}</div>

                                <div class="my-wallets" v-if="flow.generated && !flow.load">
                                    <p><strong>{{ getMyLanguage("purchase-token","wallet-stake.public-key") }}</strong></p>
                                    <div class="wallet"><small>{{flow.keys.public}}</small><span class="copy" @click="copyWallet(flow.keys.public,flow.copied.public = true)"></span></div>

                                    <p><strong>{{ getMyLanguage("purchase-token","wallet-stake.secret-key") }}</strong></p>
                                    <div class="wallet"><small>{{flow.keys.secret}}</small><span class="copy" @click="copyWallet(flow.keys.secret,flow.copied.secret = true)"></span></div>
                                </div>

                                <div class="accept" v-if="flow.generated && !flow.load">
                                    <div class="form-group-check">
                                        <input type="checkbox" id="confirm" class="form-check" v-model="flow.confirm">
                                        <label for="confirm" class="form-check-label">{{ getMyLanguage("purchase-token","wallet-stake.certify") }}</label>
                                    </div>
                                </div>

                                <div class="no-copied" v-if="flow.confirm && (!flow.copied.public || !flow.copied.secret)">
                                    <p v-if="!flow.copied.public">{{ getMyLanguage("purchase-token","wallet-stake.no-copied.public") }}</p>
                                    <p v-if="!flow.copied.secret">{{ getMyLanguage("purchase-token","wallet-stake.no-copied.secret") }}</p>
                                </div>

                                <div class="btn-primary btn-disable" v-if="flow.generated && !flow.confirm && !flow.load">{{ getMyLanguage("purchase-token","wallet-stake.continue") }}</div>
                                <div class="btn-primary" v-if="flow.generated && flow.confirm && flow.copied.public && flow.copied.secret && !flow.load" @click="saveWallet()">{{ getMyLanguage("purchase-token","wallet-stake.continue") }}</div>
                            </div>


                            <!-- ERROR -->
                            <div class="box-error" v-if="flow.error">
                                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                

                <!-- MODAL 2FA -->
                <div class="modal modal-2fa" v-if="modal2FA.show">
                    <div class="modal-cont">
                        <div class="modal-close" @click="modal2FA.show = false"></div>
                        <div class="box">
                            <div class="modal-scroll">

                                <!-- SMS CODE -->
                                <div class="sms-code" v-if="!flowSMS.success">
                                    <h3 class="title-secondary">{{getMyLanguage("compliance","compliance.modal2fa.title")}}</h3>
                                    <div class="load" v-if="flowSMS.load"></div>

                                    <p v-if="!flowSMS.load">{{getMyLanguage("compliance","compliance.modal2fa.inf")}}</p>
                                    <div class="sms-code-cont" v-if="!flowSMS.load">
                                        <!-- <div class="sms-confirm" v-if="flowSMS.smsCodeShow && !flowSMS.load"> -->
                                        <div class="sms-confirm">
                                            <input type="text" class="form form-smsCode" v-model="codeSMS.smsCode" v-mask="'000000'" placeholder="______" autocomplete="one-time-code">
                                            <div class="confirm btn-disable" v-if="codeSMS.smsCode.length < 6">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                            <div class="confirm" v-if="codeSMS.smsCode.length === 6" @click="validateCodeSMS()">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                        </div>
                                        
                                        <div class="alert" v-if="flowSMS.smsCodeShow && !flowSMS.load">{{getMyLanguage("wallets","modal-send-code.msg-sms")}} +{{$store.state.profile.profile_country_ddi}} {{$store.state.profile.profile_phone_number}}</div>
                                        <div class="load" v-if="flowSMS.smsCodeShow && flowSMS.load"></div>
                                    </div>

                                    <div class="resend" v-if="flowSMS.smsCodeShow && !flowSMS.load">
                                        <div class="btn-resend resend-load" v-if="!flowSMS.btnResendSMS">{{getMyLanguage("wallets","modal-send-code.btn-resend-in")}} {{flowSMS.countDownResendCode}}s</div>
                                        <div class="btn-resend" v-if="flowSMS.btnResendSMS" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-resend")}}</div>
                                    </div>
                                </div>

                                <!-- SUCCESS -->
                                <div class="success" v-if="flowSMS.success && !flowSMS.load">
                                    <h3 class="title-secondary">{{getMyLanguage("compliance","compliance.modal2fa.boxSuccess.title")}}</h3>
                                    
                                    <div class="box-success">
                                        <p>{{getMyLanguage("compliance","compliance.modal2fa.boxSuccess.msg")}}</p>
                                        <a :href="`/${flagSelected}/international/token`" class="btn-primary">{{getMyLanguage("compliance","compliance.cta-success")}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ion-content>

            <FooterApp />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";
    
    import apiInternational from '@/config/apiInternational.js'
    import apiPay from '@/config/apiPay.js'
    import { handleError } from '@/services/errorHandler.js'

    import HeaderApp from '@/components/dneinternational/HeaderApp.vue';
    import FooterApp from '@/components/dneinternational/FooterApp.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            HeaderApp,
            FooterApp
        },
        data () {
            return {
                load: false,
                showContent: false,
                flagSelected: "",

                flow: {
                    generated: false,
                    load: false,
                    confirm: false,
                    copied: {
                        public: false,
                        secret: false
                    },
                    keys: {
                        public: "",
                        secret: ""
                    },
                    error: false
                },

                saveWalletStake: {
                    asset_short: "DNE",
                    name: "DNE External Wallet Stake",
                    address: "",
                    memo: "",
                    _2fa_token: 0
                },

                codeSMS: {
                    smsCode: ''
                },

                modal2FA: {
                    show: false,
                    load: false,
                    send: false
                },

                flowSMS: {
                    load: true,
                    smsCodeShow: false,
                    btnResendSMS: false,
                    countDownResendCode: 0,
                    success: false
                }
            }
        },

        async mounted () {
            this.flagUrl()

            await apiInternational.get('api/v1/international/compliance/wallet/stake')
            .then(response => {
                console.log(response.data)
                
                setTimeout(() => {
                    this.showContent = true
                }, 1000);
            })
            .catch(error => {
                handleError(error, this.flagSelected);                
            })
        },

        methods: {
            loginStatus(){
                if ( localStorage.getItem('accessToken') === undefined || localStorage.getItem('accessToken') === null){
                window.location.href = '/'
                }
            },

            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            async generateWallet(){
                this.flow.load = true

                const keyGenerated = await apiPay.get('api/v2/pay/wallet/address/stake/random')
                this.flow.keys.public = keyGenerated.data.pub
                this.flow.keys.secret = keyGenerated.data.pk
                
                this.saveWalletStake.address = keyGenerated.data.pub

                // const StellarSdk = require("stellar-sdk");
                
                // const pair = StellarSdk.Keypair.random();
                // this.flow.keys.public = pair.publicKey()
                // this.flow.keys.secret = pair.secret()

                // this.saveWalletStake.address = pair.publicKey()

                setTimeout(() => {
                    this.flow.generated = true
                    this.flow.load = false
                }, 1000);
            },


            async copyWallet(value) {
                try {
                    await navigator.clipboard.writeText(value);
                
                    const toast = await toastController
                        .create({
                            message: this.getMyLanguage("receive", "modal-receive.copied"),
                            color: "secondary",
                            duration: 2000,
                            position: 'middle',
                        })
                    return toast.present();
                }
                
                catch($e) {
                    window.alert(this.getMyLanguage("box-error","msg-error"))
                }

                // try {
                //     await navigator.clipboard.writeText(value);
                //     this.$toast.success(this.getMyLanguage("client-space","instructions.copied"));
                //     setTimeout(this.$toast.clear, 3000)

                // }
                
                // catch($e) {
                //     this.$toast.success(this.getMyLanguage("client-space","instructions.nocopied"));
                //     setTimeout(this.$toast.clear, 3000)
                // }
            },


            async getCodeSend(){
                this.flowSMS.load = true
                this.modal2FA.show = true

                apiInternational.post('',this.codeSMS.smsCode)
                .then(response => {
                    console.log(response)

                    this.flowSMS.smsCodeShow = false
                    
                    setTimeout(() => {
                        this.flowSMS.smsCodeShow = true
                        this.flowSMS.load = false
                        this.flowSMS.btnResendSMS = false
                        this.flowSMS.countDownResendCode = 10
                        this.countDownflowSMS()
                    }, 1000);
                })
                .catch(error => {
                    this.$toast.error(this.getMyLanguage("compliance","compliance.toast.error.codigo"))
                    console.log(error)

                    this.flowSMS.load = false
                })

                // TEMP
                // setTimeout(() => {
                //     this.flowSMS.load = false
                // }, 500);
                
                // this.flowSMS.smsCodeShow = true
                // this.flowSMS.btnResendSMS = false
                // this.flowSMS.countDownResendCode = 10
                // this.countDownflowSMS()
            },


            countDownflowSMS() {

                if(this.flowSMS.countDownResendCode > 0) {
                    setTimeout(() => {
                        this.flowSMS.countDownResendCode -= 1
                        this.countDownflowSMS()
                    }, 1000)
                } else{
                    this.flowSMS.btnResendSMS = true
                }
            },


            async validateCodeSMS(){
                this.flowSMS.load = true
                
                await apiInternational.post('', this.codeSMS)
                .then(response => {
                    console.log(response)

                    setTimeout(() => {
                        this.flowSMS.success = true
                        this.flowSMS.load = false
                        this.errors.invalidCode = false

                        this.saveWallet()
                    }, 1000);
                })
                .catch(error => {
                    this.$toast.error(this.getMyLanguage("client-space","client-space.account.lbl.phone.validate-error"));
                    setTimeout(this.$toast.clear, 6000)

                    console.log(error)
                    
                    this.flowSMS.load = false

                    setTimeout(() => {
                        this.errors.flow = false
                    }, 5000);
                })
            },


            saveWallet(){
                this.flow.load = true

                apiPay.post('/api/v2/pay/wallet/address/trust/stake', this.saveWalletStake)
                .then(response => {
                    console.log(response.data)

                    setTimeout(() => {
                        window.location.href = `/${this.flagSelected}/international/token`
                    }, 1000);
                })
                .catch(error => {
                    console.log(error)

                    setTimeout(() => {
                        this.flow.load = false
                        this.flow.error = true
                    }, 1000);
                })
            }
        }
    }
</script>


<style scoped>
    ion-content.wallets{--padding-top: 20px;}
    
    .box-wallet{display: flex; flex-direction: column; align-items: center; margin: 0 auto 40px; max-width: 600px; overflow-x: auto; background: var(--color-text-tertiary); border-radius: 12px; padding: 16px; -webkit-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); -moz-box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1); box-shadow: 0px 8px 20px 0px rgba(0,0,0,0.1);}
    /* .box-wallet .load{display: block; width: 100px; height: 100px; margin: 10px auto; background: url('~@/assets/images/icons/loading-secondary.svg') no-repeat center; background-size: 100% auto;} */
    
    .my-wallets{width: 100%; max-width: 500px;}
    .my-wallets p{margin-bottom: 2px; color: var(--color-primary);}
    .my-wallets .wallet{ position: relative; background: #eee; border-radius: 6px; padding: 12px 40px 12px 12px; margin-bottom: 30px;}
    .my-wallets .wallet small{display: block; font-size: 12px; line-height: 16px; word-break: break-all;}
    .my-wallets .wallet .copy{display: block; position: absolute; left: 0; top: 0; width: 100%; height: 100%; background: none;}
    .my-wallets .wallet .copy:after{content: ''; display: block; width: 30px; height: 100%; background: url('~@/assets/images/icons/copy.svg') no-repeat center; background-size: 16px auto; position: absolute; right: 0; top: 0;}
    
    .accept{max-width: 500px; margin-bottom: 30px;}
    .accept .form-check-label{font-size: 13px; line-height: 16px;}
    
    .no-copied{background: rgba(235, 104, 52, 0.15); border-radius: 12px; padding: 10px 15px; margin-bottom: 30px;}
    .no-copied p{font-size: 13px; font-weight: 600; color: var(--color-cancel); line-height: 16px;}
    .no-copied p:last-child{margin-bottom: 0;}

    .alert{max-width: 500px; position: relative; background: url('~@/assets/images/icons/alert.svg') no-repeat center 15px rgba(235, 104, 52, 0.15); background-size: auto 40px; padding: 60px 15px 15px; margin: 0 auto 30px; border-radius: 12px; text-align: center;}

    /* SMS CODE */
    .modal-2fa .modal-scroll{max-height: 80vh !important;}

    .sms-code{display: flex; flex-direction: column; justify-content: center; align-items: center; margin-bottom: 10px;}
    .sms-code .title-secondary + p{margin-bottom: 20px;}
    .sms-code .load{margin: 22px; width: 140px; height: 140px;}
    .sms-code .sms-confirm{display: flex; align-items: center; width: 100%; margin-bottom: 20px;}
    .sms-code .sms-confirm .form{margin-bottom: 0; width: 206px; border-radius: 12px 0 0 12px; border-right: 0; font-size: 18px; font-family: var(--font-primary-semibold); padding: 0 0 0 20px; letter-spacing: 16px;}
    
    @media (max-width: 374px){
        .sms-code .sms-confirm .form{width: 160px; padding-left: 15px; letter-spacing: 9px;}
    }
    
    .sms-code .sms-confirm .form.form-smsCode{background: #eee; border: none;}
    .sms-code .sms-confirm .form.form-smsCode:focus{box-shadow: none;}

    .confirm{display: block; background: var(--color-secondary); width: 90px; height: 44px; line-height: 44px; font-size: 14px; color: var(--color-text-tertiary); text-align: center; border-radius: 0 12px 12px 0; cursor: pointer;}
    .confirm.btn-disable{filter: blur(2px);}
    

    .sms-code .sms-confirm.errorCode .form{border: 1px solid var(--color-cancel); border-right: none;}
    .sms-code .sms-confirm.errorCode .confirm{border: 1px solid var(--color-cancel); border-left: none;}

    .sms-code .invalid-code{width: 100%; font-size: 75%; color: var(--color-cancel);}
        
    .sms-code .alert{font-size: 11px; line-height: 11px; text-align: center; width: 100%; margin-top: 8px;}
    .sms-code .resend{display: flex; justify-content: center; width: 100%; padding-top: 25px; margin-top: 25px; border-top: 1px solid #eee;}
    .sms-code .resend.btn-resend{ padding: 0 15px; border-radius: 6px; background: #ddd; font-size: 12px; line-height: 30px; cursor: pointer;}
    .sms-code .resend.btn-resend.resend-load{background: #eee;}

    .success .box-success{display: flex; flex-direction: column; align-items: center; max-width: 400px; margin: 30px auto 10px;}
    .success .box-success p{text-align: center;}
    .success .box-success .btn-primary{background: var(--color-text-tertiary); color: var(--color-secondary); margin-top: 20px;}
    .success .box-success .btn-primary:after{display: none;}

</style>