let error404 = false;
let errorGeneral = false;
let errorMsg = false;
let errorBlockPurchase = false

export function handleError(error, language) {

    const tokenValue = localStorage.getItem('accessToken')    

    // if ( error.response.status == 401 && !tokenValue ){
    if ( error.response.status == 401 ){
        errorGeneral = true
        errorMsg = "msg-error-401"

        localStorage.removeItem('accessToken');
        localStorage.removeItem('profile');
        localStorage.removeItem('phaseCurrent');
        window.location.href = '/' + language + "/home"
    }
    
    else if ( error.response.status == 403 ) {
        errorGeneral = true
        errorMsg = "msg-error-403"

        this.showWallets = false
        window.location.href = '/' + language + "/account/consent"
    }

    else if ( error.response.status == 404 ) {
        error404 = true;
        errorMsg = "msg-error-404"
    }
    
    else if ( error.response.status == 409 ) {
        if ( error.response.data == "account_must_have_kyc" ){
            window.location.href = '/' + language + "/account/kyc"
        }
        
        if ( error.response.data == "account_must_have_compliance" ){
            window.location.href = '/' + language + "/account/compliance"
        }
        
        if ( error.response.data == "account_must_have_company_compliance" ) {
            window.location.href = "/" + language + "/account/compliance-company";
        }

        if ( error.response.data == "account_already_have_wallet_stake" ){
            window.location.href = "/" + language + "/international/token";
            // this.$router.push({ name: 'ClientSpacePurchase', params: { lang: this.$route.params.lang } })
        }

        if ( error.response.data == "account_must_be_manualy_checked" ){
            window.location.href = '/' + language + "/account/pending"
        }
        
        if ( error.response.data == "account_must_have_beneficial_owner" &&  this.$store.state.profile.account_type == "Person" ) {
            window.location.href = '/' + language + "/account/beneficial-owner"
            // this.$router.push({ name: 'BeneficialOwner', params: { lang: this.$route.params.lang } })
        }
        
        if ( error.response.data == "account_must_have_company_beneficial_owner" &&  this.$store.state.profile.account_type == "Company" ) {
            window.location.href = '/' + language + "/account/controlling-person-legal-entities"
            // this.$router.push({ name: 'ControllingPersonLegalEntities', params: { lang: this.$route.params.lang } })
        }
        
        if (error.response.data == "wrong_account_type") {
            errorBlockPurchase = true
            errorMsg = "block-purchase"
        }
        
        if (error.response.data == "account_must_have_wallet_stake") {
            window.location.href = '/' + language + "/international/wallet-stake"
            // this.$router.push({ name: 'ClientSpaceWalletStake', params: { lang: this.$route.params.lang } })
        }
    }

    else {
        errorGeneral = true
        errorMsg = "msg-error-general"
    }
}

export { error404, errorGeneral, errorMsg, errorBlockPurchase };