/* eslint-disable */

import en from '@/locales/en.json';
import es from '@/locales/es.json';
import pt from '@/locales/pt.json';
import ar from '@/locales/ar.json';
import ru from '@/locales/ru.json';

export default function getMyFavouriteLanguage (group, code){
    
    const language = String(window.location.pathname).split("/")[1];

    let lang = [];
    
    if(language === "en"){
        lang = en;
    }
    else if(language === "es"){
        lang = es;
    }
    else if(language === "pt"){
        lang = pt;
    }
    else if(language === "ar"){
        lang = ar;
    }
    else if(language === "ru"){
        lang = ru;
    }
    else{
        lang = en;
    }

    return lang.filter((item) => item.group === group).filter((item) => item.code === code).map(item => item.value).join();
}

/* eslint-disable */