<template>
    <div class="balances">
        <div v-if="showContent && errors.error_blockPurchase">
            <h2 class="title-secondary">{{getMyLanguage("wallets","wallets.title")}}</h2>
            
            <div class="box-error">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error",errors.error_msg) }}</p>
            </div>
        </div>

        <div v-if="showContent && (errors.error_404 || errors.error_general)">
            <h2 class="title-secondary">{{getMyLanguage("wallets","wallets.title")}}</h2>

            <div class="box-error">
                <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                <div class="cod-error">{{ getMyLanguage("box-error",errors.error_msg) }}</div>
            </div>
        </div>

        <div v-if="showContent && (!errors.error_404 && !errors.error_general && !errors.error_blockPurchase)">
            <div v-for="(wallets,indexWallet) in dataWallets" :key="indexWallet">

                <h2 class="title-secondary" v-if='wallets.wallets[0].accountWalletType === "Commission"'>{{getMyLanguage("wallets","box-balances.assetShort.title.commissions")}}</h2>
                <h2 class="title-secondary" v-else>{{getMyLanguage("wallets","wallets.title")}}</h2>
                
                <div class="box box-balances" v-if="!showWallets">
                    <div class="load"></div>
                </div>
                
                <div class="box box-balances" v-if="showWallets">
                    <table class="table-balances">
                        <tr v-for="(accountWallet,indexList) in wallets.wallets" :key="indexList" :class="accountWallet.accountWalletTypeCode">
                            <td class="title">
                                <div class="title-balance">
                                    <i :class='accountWallet.assetShort'>{{accountWallet.asset_symbol}}</i>
                                    <span>{{accountWallet.assetShort}}<small>{{accountWallet.accountWalletType}}</small></span>
                                </div>
                            </td>
                            
                            <td>
                                <span class="balance-value" :class="accountWallet.assetShort" v-if='accountWallet.assetShort === "DNE"'>
                                    <span>{{accountWallet.walletBalance.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</span>
                                    <small>&asymp; {{accountWallet.walletBalancePrice.toLocaleString("pt-PT", {style:"currency", currency:accountWallet.assetShort})}}</small>
                                </span>

                                <span class="balance-value" :class="accountWallet.assetShort" v-else>
                                    <span>{{accountWallet.walletBalance.toLocaleString("pt-PT", {style:"currency", currency:accountWallet.assetShort})}}</span>
                                </span>
                            </td>
                            
                            <td v-if="accountWallet.walletCanWithdrawal"><div class="btn-balances btn-send" @click="(openSend(accountWallet.assetShort, accountWallet.accountWalletTypeCode)), (modal.load = true)"></div></td>
                            <td v-if="!accountWallet.walletCanWithdrawal"><div class="btn-balances btn-send btn-disable"></div></td>
                            
                            <td v-if='accountWallet.walletCanDeposit && accountWallet.assetShort === "DNE"'><div class="btn-balances btn-receive" @click="(openReceive(accountWallet.assetShort, accountWallet.accountWalletTypeCode)), (modal.receive = true)"></div></td>
                            <td v-if='!accountWallet.walletCanDeposit && accountWallet.assetShort === "DNE"'><div class="btn-balances btn-receive btn-disable"></div></td>
                            
                            <td><div class="btn-balances btn-history" @click="(openHistory(accountWallet.accountWalletId)), (modal.load = true)"></div></td>
                        </tr>

                        <tr class="total DNE" v-if='wallets.assetShort === "DNE"'>
                            <td colspan="6">
                                <strong>
                                    <small>{{getMyLanguage("wallets","box-balances.total")}}</small>
                                    <span>{{wallets.amount.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</span>
                                </strong>
                                <small>&asymp; {{wallets.amountPrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</small>
                            </td>
                        </tr>
                        
                        <tr class="total" :class="wallets.assetShort" v-if='wallets.assetShort != "DNE"'>
                            <td colspan="6">
                                {{getMyLanguage("wallets","box-balances.total")}}<strong>{{wallets.amount.toLocaleString("pt-PT", {style:"currency", currency:wallets.assetShort})}}</strong>
                            </td>
                        </tr>
                    </table>
                    <div class="overlayPreModal" v-if="modal.load"></div>


                    <!-- BTS BALANCES -->
                    <div class="bts-balances" v-if='wallets.assetShort === "DNE"'>
                        <div class="btn-primary btn-send" @click='(openSend("DNE", "spot")), (modal.send = true)'><span>{{getMyLanguage("wallets","box-balances.bts.btn-send")}}</span></div>
                        <div class="btn-primary btn-receive" @click='(openReceive("DNE", "spot")), (modal.receive = true)'><span>{{getMyLanguage("wallets","box-balances.bts.btn-receive")}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        


        <!-- MODAL SEND -->
        <div class="modal modal-smaller modal-send" v-if="modal.send">
            <div class="cont-modal">
                <div class="close-modal" v-if="!destinations.show" @click="modal.send = false"></div>
                <div class="box">
                    <div class="scroll-modal">
                        <div class="select-currency" v-if="dataWallets.lenght > 1">
                            <div class="anime" :class="{active: listSelectCurrency.assetShort === sendModal.assetShort}" :data-short="listSelectCurrency.assetShort" v-for="(listSelectCurrency,listSelect) in dataWallets" :key="listSelect" @click="openSend(listSelectCurrency.assetShort, listSelectCurrency.accountWalletTypeCode)"><span>{{listSelectCurrency.assetShort}}</span></div>
                        </div>

                        <div class="load extra-margin" v-if="flowSend.load"></div>

                        <!-- DNE -->
                        <div class="select-entities-dne" v-if='sendModal.assetShort === "DNE" && !flowSend.load'>
                            <div class="entity bank" @click="send.destinyId = 12345"><i>B</i><span>Bank</span></div>
                            <div class="entity market" @click="send.destinyId = 54321"><i>M</i><span>Market</span></div>
                            <div class="entity pay disable"><i>P</i><span>Pay</span></div>
                            <div class="entity add" @click='registerNewDestiny("crypto", sendModal.assetShort)'><i></i><span>{{getMyLanguage("wallets","modal-send.btn-add")}}</span></div>
                        </div>

                        <div class="send-box" v-if="!flowSend.load">
                            <h3 class="title-secondary">{{getMyLanguage("wallets","modal-send.title")}} <small>{{sendModal.assetName}}</small></h3>

                            <div class="block-send">
                                <div class="balance-available" v-if="sendModal.wallets != null">
                                    <span>{{getMyLanguage("wallets","modal-send.balance-available")}}: </span>
                                    <strong class="dneToken" v-if='sendModal.assetShort === "DNE"'>
                                        <span>{{sendModal.balanceAvailable}}</span>
                                        <small>&asymp; {{sendModal.balanceAvailablePrice.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</small>
                                    </strong>
                                    
                                    <strong class="dneToken" v-if='sendModal.assetShort === "EUR"'>
                                        <span>{{sendModal.balanceAvailable.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span>
                                    </strong>

                                    <strong v-if='sendModal.assetType === "euro"'><span>{{sendModal.balanceAvailable.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></strong>
                                </div>

                                <div class="destiny" v-if="sendModal.wallets != null">                                    
                                    <div class="bts-open-destinations" v-if="!destinations.show && !flowSend.destinySelected">
                                        <div class="btn-primary openDestinations btn-full" @click="destinations.show = true"><span>{{getMyLanguage("wallets","modal-send.select-destiny")}}</span></div>
                                    </div>

                                    <div class="destinySelected" v-if="flowSend.destinySelected" @click="destinations.show = true">
                                        <label>{{getMyLanguage("wallets","modal-send.destiny")}}</label>
                                        <div class="destinyOption active">
                                            <i></i>
                                            <p>{{sendModal.walletName}} <small>{{sendModal.walletAddress}}</small></p>
                                        </div>
                                    </div>

                                    <div class="destinations box" v-if="destinations.show">
                                        <div class="close-modal" @click="destinations.show = false"></div>
                                        <div class="scroll-vertical">
                                            <div class="destinyOption" :class="{active: listDestiny.accountWalletId === send.destinyWalletId}" v-for="(listDestiny, index) in sendModal.wallets" :key="index" @click="(sendDestinyName(listDestiny.accountWalletId)),(destinations.show = false)">
                                                <i></i>
                                                <p>{{listDestiny.walletName}} <small>{{listDestiny.walletAddress}}</small></p>
                                            </div>
                                        </div>

                                        <div class="add">
                                            <div class="btn-add" @click="registerNewDestiny(sendModal.assetType, sendModal.assetShort)"><span>{{getMyLanguage("wallets","modal-send.add-new-destiny")}}</span></div>
                                        </div>
                                    </div>
                                    <div class="overlayDestinations" v-if="destinations.show"></div>

                                    <label v-if="sendModal.withdrawalReceipt" for="receipt" class="btn-upload" :class="{fileSelected: send.receipt != null}">
                                        <span class="send">{{getMyLanguage("wallets","modal-send.sendReceipt")}}</span>
                                        <span class="selected">{{getMyLanguage("wallets","modal-send.selectedFile")}}</span>
                                        <input type="file" accept="image/*" id="receipt" ref="file" @change="uploadFileWithdraw">
                                    </label>
                                </div>

                                <div class="value-send" v-if="sendModal.wallets != null">
                                    <label class="lbl-value">
                                        <div>
                                            <span>{{getMyLanguage("wallets","modal-send.value")}}</span>
                                            <div class="alert-value" v-if="flowSend.alertValueMax">{{getMyLanguage("wallets","modal-send.maximum-value")}} {{this.send.amount}}</div>
                                            <div class="alert-value" v-if="flowSend.alertValueMin">{{getMyLanguage("wallets","modal-send.minimum-value")}} {{this.send.amount}}</div>
                                        </div>
                                        
                                        <input type="number" class="form" v-model="sendModal.value" @keyup="sendAmount()" @blur="sendAmountMax()" placeholder="0">
                                    </label>
                                </div>

                                <ul class="guide guide-withdraw" v-if="!flowSend.showBtnConfirm || !flowSend.destinySelected || flowSend.uploadReceipt">
                                    <li v-if="!flowSend.destinySelected">{{getMyLanguage("wallets","modal-send.guide.destiny")}}</li>
                                    <li v-if="flowSend.uploadReceipt">{{getMyLanguage("wallets","modal-send.guide.receipt")}}</li>
                                    <li v-if="!flowSend.showBtnConfirm">{{getMyLanguage("wallets","modal-send.guide.value")}}</li>
                                </ul>

                                <div class="amount" :class="{pending: (!flowSend.showBtnConfirm || !flowSend.destinySelected || flowSend.uploadReceipt)}" v-if="sendModal.wallets != null">
                                    <table>
                                        <tr>
                                            <td>{{getMyLanguage("wallets","modal-send.grossTotal")}}</td>
                                            <td>{{sendModal.gross.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                        </tr>
                                        <tr v-if="sendModal.withdrawalFee > 0">
                                            <td>{{getMyLanguage("wallets","modal-send.fee")}} +{{sendModal.withdrawalFee.toLocaleString("pt-PT", { maximumFractionDigits: 2 })}}%</td>
                                            <td>{{sendModal.feeValue.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                        </tr>
                                        <tr v-if="sendModal.withdrawalTax > 0">
                                            <td>{{getMyLanguage("wallets","modal-send.tax")}} {{sendModal.withdrawalTax}}%</td>
                                            <td>{{sendModal.taxValue.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                                        </tr>
                                    </table>
                                    
                                    <div class="amount-value">
                                        <span>{{getMyLanguage("wallets","modal-send.netTotal")}}</span>

                                        <strong :class="sendModal.assetShort" v-if='sendModal.assetShort === "DNE"'><span>{{sendModal.net.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</span></strong>
                                        <strong :class="sendModal.assetShort" v-if='sendModal.assetShort === "EUR"'><span>{{sendModal.net.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></strong>
                                    </div>

                                    <div class="btn-primary btn-confirm btn-disable" v-if='!flowSend.showBtnConfirm || !flowSend.destinySelected || flowSend.uploadReceipt'>{{getMyLanguage("wallets","modal-send.review-withdrawal")}}</div>
                                    <div class="btn-primary btn-confirm" v-if="flowSend.showBtnConfirm && flowSend.destinySelected != false && !flowSend.uploadReceipt" @click="reviewWithdrawal()">{{getMyLanguage("wallets","modal-send.review-withdrawal")}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="modal.send"></div>



        <!-- MODAL SEND CONFIRM -->
        <div class="modal modal-smaller modal-send-confirm" v-if="modal.reviewWithdrawal">
            <div class="modal-cont">
                <div class="close-modal" @click="modal.reviewWithdrawal = false"></div>
                <div class="box">
                    <div class="scroll-modal">
                        <div class="send-dne">
                            <h3 class="title-secondary">{{getMyLanguage("wallets","modal-send-confirm.title")}}</h3>

                            <div class="block-send">

                                <!-- RESUME WITHDRAW -->
                                <div class="resume" v-if="!errorsPost.withdraw && !flowSend.successWithdrawn">
                                    <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.asset")}}: </strong><span>{{}}{{getMyLanguage("wallets","modal-send.asset." + sendModal.assetType)}}</span></div>
                                    <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.destiny")}}: </strong><span>{{sendModal.walletName}}</span></div>
                                    <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.amount")}}: </strong><span>{{send.amount.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}}</span></div>
                                    <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.value")}}: </strong><span>{{sendModal.net.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}} <small>(+{{sendModal.withdrawalFee.toLocaleString("pt-PT", { maximumFractionDigits: 2 })}}% > Fee {{sendModal.feeValue.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}})</small></span></div>
                                </div>


                                <!-- SMS CODE -->
                                <div class="sms-code" v-if="!errorsPost.withdraw && !flowSend.successWithdrawn">
                                    
                                    <div class="btn-primary getCode" v-if="!flowSend.smsCodeShow && !flowSend.load" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-generate")}}</div>
                                    <div class="load" v-if="!flowSend.smsCodeShow && flowSend.load"></div>

                                    <div class="sms-confirm" v-if="flowSend.smsCodeShow && !flowSend.load">
                                        <div class="tooltip-error" v-if="flowSend.errorCode">{{getMyLanguage("wallets","modal-send-code-error")}}</div>
                                        <input type="text" class="form form-smsCode" :class="{error: flowSend.errorCode}" v-model="send.smsCode" v-mask="'######'" placeholder="______">
                                        <div class="confirm btn-disable" v-if="send.smsCode.length < 6">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                        <div class="confirm" v-if="send.smsCode.length === 6" @click="confirmWithdraw()">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                    </div>
                                    
                                    <div class="alert" v-if="flowSend.smsCodeShow && !flowSend.load">{{getMyLanguage("wallets","modal-send-code.msg-sms")}} {{dataProfile.profile_phone_country_ddi}} {{dataProfile.profile_phone_number}}</div>
                                    <div class="load" v-if="flowSend.smsCodeShow && flowSend.load"></div>

                                    <div class="resend" v-if="flowSend.smsCodeShow && !flowSend.load">
                                        <div class="btn-resend resend-load" v-if="!flowSend.btnResendSMS">{{getMyLanguage("wallets","modal-send-code.btn-resend-in")}} {{flowSend.countDownResendCode}}s</div>
                                        <div class="btn-resend" v-if="flowSend.btnResendSMS" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-resend")}}</div>
                                    </div>
                                </div>


                                <!-- SUCCESS WITHDRAW -->
                                <div class="success" v-if="flowSend.successWithdrawn">
                                    <h2 class="title-secondary">{{getMyLanguage("wallets","modal-send-success.title")}}</h2>
                                    <p>{{getMyLanguage("wallets","modal-send-success.msg")}}</p>

                                    <div class="resume">
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.asset")}}: </strong><span>{{}}{{getMyLanguage("wallets","modal-send.asset." + sendModal.assetType)}}</span></div>
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.destiny")}}: </strong><span>{{sendModal.walletName}}</span></div>
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.amount")}}: </strong><span>{{send.amount.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}}</span></div>
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.value")}}: </strong><span>{{sendModal.net.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}} <small>(+{{sendModal.withdrawalFee.toLocaleString("pt-PT", { maximumFractionDigits: 2 })}}% > {{getMyLanguage("wallets","modal-send.fee")}} {{sendModal.feeValue.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}})</small></span></div>
                                    </div>
                                </div>


                                <!-- ERROR WITHDRAW -->
                                <div class="error" v-if="errorsPost.withdraw">
                                    <h2 class="title-secondary">{{getMyLanguage("wallets","modal-send-error.title")}}</h2>
                                    <p>{{getMyLanguage("wallets","modal-send-error.msg")}}</p>
                                    
                                    <div class="resume">
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.asset")}}: </strong><span>{{}}{{getMyLanguage("wallets","modal-send.asset." + sendModal.assetType)}}</span></div>
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.destiny")}}: </strong><span>{{sendModal.walletName}}</span></div>
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.amount")}}: </strong><span>{{send.amount.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}}</span></div>
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.value")}}: </strong><span>{{sendModal.net.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}} <small>(+{{sendModal.withdrawalFee.toLocaleString("pt-PT", { maximumFractionDigits: 2 })}}% > {{getMyLanguage("wallets","modal-send.fee")}} {{sendModal.feeValue.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}})</small></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="modal.reviewWithdrawal"></div>



        <!-- MODAL NEW DESTINY -->
        <div class="modal modal-smaller modal-newDestiny" v-if="modal.newDestiny">
            <div class="modal-cont">
                <div class="close-modal" @click="(modal.newDestiny = false), (modal.send = true)"></div>
                <div class="box">
                    <div class="scroll-modal">
                        <div class="newDestiny" v-if="!flowNewDestiny.successRegister && !flowNewDestiny.errorRegister">
                            <h2 class="title-secondary">
                                {{getMyLanguage("wallets","modal-new-destiny.title")}}
                                <small>{{getMyLanguage("wallets","modal-new-destiny.type." + flowNewDestiny.type)}}</small>
                            </h2>
                            
                            <div class="asset">
                                <span>{{getMyLanguage("wallets","modal-new-destiny.currency")}}: </span>
                                <strong>{{newDestiny.asset}}</strong>
                            </div>

                            <label>
                                {{getMyLanguage("wallets","modal-new-destiny.name-wallet")}}
                                <input type="text" class="form" v-model="newDestiny.name">
                            </label>
                            
                            <label>
                                {{getMyLanguage("wallets","modal-new-destiny." + flowNewDestiny.type + ".address")}}
                                <input type="text" class="form" v-model="newDestiny.address">
                            </label>
                            
                            <label>
                                {{getMyLanguage("wallets","modal-new-destiny." + flowNewDestiny.type + ".memo")}}
                                <input type="text" class="form" v-model="newDestiny.memo">
                            </label>

                            <label for="receiptNewDestiny" v-if='flowNewDestiny.type != "crypto"' class="btn-upload" :class="{fileSelected: newDestiny.NIBProof != null}">
                                <span class="send">{{getMyLanguage("wallets","modal-new-destiny.sendProof")}}</span>
                                <span class="selected">{{getMyLanguage("wallets","modal-new-destiny.fileSelected")}}</span>
                                <input type="file" accept="image/*" id="receiptNewDestiny" ref="fileDestiny" @change="uploadFileNewDestiny">
                            </label>
                        </div>

                        <ul class="guide guide-destiny" v-if="newDestiny.name.length <= 2 || newDestiny.address.length <= 2">
                            <li v-if="newDestiny.name.length <= 2">{{getMyLanguage("wallets","modal-new-destiny.guide.name")}}</li>
                            <li v-if="newDestiny.address.length <= 2">{{getMyLanguage("wallets","modal-new-destiny.guide.address")}}</li>
                        </ul>


                        <!-- SMS CODE -->
                        <div class="sms-code" v-if="!flowNewDestiny.successRegister && !flowNewDestiny.errorRegister">

                            <div class="btn-primary getCode btn-disable" v-if="(this.newDestiny.name.length <= 2 || this.newDestiny.address.length <= 2) && !flowNewDestiny.smsCodeShow && !flowNewDestiny.load">{{getMyLanguage("wallets","modal-send-code.btn-generate")}}</div>
                            <div class="btn-primary getCode" v-if="(this.newDestiny.name.length > 2 && this.newDestiny.address.length > 2) && !flowNewDestiny.smsCodeShow && !flowNewDestiny.load" @click="getCodeNewDestiny()">{{getMyLanguage("wallets","modal-send-code.btn-generate")}}</div>
                            <div class="load" v-if="!flowNewDestiny.smsCodeShow && flowNewDestiny.load"></div>

                            <div class="sms-confirm" v-if="flowNewDestiny.smsCodeShow && !flowNewDestiny.load">
                                <div class="tooltip-error" v-if="flowNewDestiny.errorCode">{{getMyLanguage("wallets","modal-send-code-error")}}</div>
                                <input type="text" class="form" :class="{error: flowNewDestiny.errorCode}" v-model="newDestiny.smsCode" v-mask="'######'" placeholder="______">
                                <div class="confirm btn-disable" v-if="newDestiny.smsCode.length < 6">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                <div class="confirm" v-if="newDestiny.smsCode.length === 6" @click="confirmNewDestiny()">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                            </div>
                            <div class="alert" v-if="flowNewDestiny.smsCodeShow && !flowNewDestiny.load">{{getMyLanguage("wallets","modal-send-code.msg-sms")}} {{dataProfile.profile_phone_country_ddi}} {{dataProfile.profile_phone_number}}</div>
                            <div class="load" v-if="flowNewDestiny.smsCodeShow && flowNewDestiny.load"></div>

                            <div class="resend" v-if="flowNewDestiny.smsCodeShow && !flowNewDestiny.load">
                                <div class="btn-resend resend-load" v-if="!flowNewDestiny.btnResendSMS">{{getMyLanguage("wallets","modal-send-code.btn-resend-in")}} {{flowNewDestiny.countDownResendCode}}s</div>
                                <div class="btn-resend" v-if="flowNewDestiny.btnResendSMS" @click="getCodeNewDestiny()">{{getMyLanguage("wallets","modal-send-code.btn-resend")}}</div>
                            </div>
                        </div>


                        <!-- SUCCESS REGISTER DESTINY -->
                        <div class="success" v-if="flowNewDestiny.successRegister">
                            <h2 class="title-secondary">{{getMyLanguage("wallets","modal-new-destiny.success.title")}}</h2>
                            <p>{{getMyLanguage("wallets","modal-new-destiny.success.msg")}}</p>

                            <div class="resume">
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny.currency")}}: </strong><span>{{newDestiny.asset}}</span></div>
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny.name-wallet")}}: </strong><span>{{newDestiny.name}}</span></div>
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny." + flowNewDestiny.type + ".address")}}: </strong><span>{{newDestiny.address}}</span></div>
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny." + flowNewDestiny.type + ".memo")}}: </strong><span>{{newDestiny.memo}}</span></div>
                            </div>
                        </div>


                        <!-- ERROR REGISTER DESTINY -->
                        <div class="error" v-if="flowNewDestiny.errorRegister">
                            <h2 class="title-secondary">{{getMyLanguage("wallets","modal-new-destiny.error.title")}}</h2>
                            <p>{{getMyLanguage("wallets","modal-new-destiny.error.msg")}}</p>

                            <div class="resume">
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny.currency")}}: </strong><span>{{newDestiny.asset}}</span></div>
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny.name-wallet")}}: </strong><span>{{newDestiny.name}}</span></div>
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny." + flowNewDestiny.type + ".address")}}: </strong><span>{{newDestiny.address}}</span></div>
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny." + flowNewDestiny.type + ".memo")}}: </strong><span>{{newDestiny.memo}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="modal.newDestiny"></div>
        

        
        <!-- MODAL RECEIVE -->
        <div class="modal modal-smaller modal-receive" v-if="modal.receive">
            <div class="modal-cont">
                <div class="close-modal" @click="modal.receive = false"></div>
                <div class="box">
                    <div class="scroll-modal">

                        <div class="load extra-margin" v-if="flowReceive.load"></div>

                        <div class="receive-box" v-if="!flowReceive.load">
                            <h2 class="title-secondary">{{getMyLanguage("receive","modal-receive.title")}}</h2>

                            <div class="block-receive">
                                <div class="box-qrcode">
                                    <qrcode-vue :value="qrCode.value" :size="qrCode.size" level="L" />
                                </div>

                                <div class="receive">
                                    <table>
                                        <tr>
                                            <td colspan="2">
                                                <div class="copy" @click="copyReceive(receiveModal.walletAddress)">
                                                    <strong>{{getMyLanguage("receive","modal-receive.address")}}: </strong>
                                                    <small>{{receiveModal.walletAddress}}</small>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="receiveModal.walletAddressExtra">
                                            <td colspan="2">
                                                <div class="copy" @click="copyReceive(receiveModal.walletAddressExtra)">
                                                    <strong>{{getMyLanguage("receive","modal-receive.extra")}}: </strong>
                                                    <small>{{receiveModal.walletAddressExtra}}</small>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>{{getMyLanguage("receive","modal-receive.walletType")}}: </strong></td>
                                            <td>{{receiveModal.accountWalletType}}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{{getMyLanguage("receive","modal-receive.asset")}}: </strong></td>
                                            <td><span>{{receiveModal.assetShort}}</span> <small class="asset-type">({{receiveModal.assetType}})</small></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="modal.receive"></div>



        <!-- MODAL HISTORY -->
        <div class="modal modal-smaller modal-history" v-if="modal.history">
            <div class="modal-cont">
                <div class="close-modal" v-if="!historyModalDetails.show" @click="modal.history = false"></div>
                <div class="box" v-if="!historyModalDetails.show">
                    <div class="scroll-modal">
                        <h2 class="title-secondary">{{getMyLanguage("history","modal-history.title")}}</h2>
                        
                        <table class="table-activities" v-if="historyModal.length > 0">
                            <tr class="tr" v-for="(listHistory, index) in historyModal" :key="index" @click="detailsHistory(index)">
                                <td class="td date">{{ dateTimeShort(listHistory.transaction_created_at) }}</td>
                                <td class="td description">
                                    <strong v-if='listHistory.operation_type === "Fee"'>{{getMyLanguage("history","modal-history.transactionType." + listHistory.transaction_type)}} {{getMyLanguage("history","modal-history.operationType." + listHistory.operation_type)}}</strong>
                                    <strong v-if='listHistory.operation_type != "Fee"'>{{getMyLanguage("history","modal-history.operationType." + listHistory.operation_type)}} {{listHistory.operation_account_name}}</strong>
                                    
                                    <small v-if="listHistory.stakeEnd != null">{{getMyLanguage("history","modal-history.available-in")}} {{ dateTime(listHistory.stakeEnd)}}</small>
                                </td>
                                <td class="td" :class='{output: listHistory.amount < 0}'>
                                    <span>{{listHistory.quantity.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}} {{listHistory.asset_short}}</span>
                                    <small>{{listHistory.amount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</small>
                                </td>
                            </tr>
                        </table>

                        <p v-if="historyModal.length == 0" class="history-empty">{{getMyLanguage("history","modal-history.details.empty")}}</p>
                    </div>
                </div>

                <div class="details-history" v-if="historyModalDetails.show">
                    <div class="details-cont">
                        <div class="close-modal" @click="historyModalDetails.show = false"></div>
                        
                        <div class="scroll-modal">
                            <h4 class="title-secondary">{{getMyLanguage("history","modal-history.details.title")}}</h4>
                        
                            <div class="box-history">
                                <table>
                                    <tr v-if='historyModalDetails.operation_account_name != ""'>
                                        <td><strong>{{getMyLanguage("history","modal-history.details.name")}}:</strong></td>
                                        <td>{{historyModalDetails.operation_account_name}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{getMyLanguage("history","modal-history.details.date")}}:</strong></td>
                                        <td>{{dateTime(historyModalDetails.transaction_created_at)}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{getMyLanguage("history","modal-history.details.transaction")}}:</strong></td>
                                        <td>{{historyModalDetails.transaction_type}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{getMyLanguage("history","modal-history.details.operation")}}:</strong></td>
                                        <td>{{historyModalDetails.operation_type}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{getMyLanguage("history","modal-history.details.source")}}:</strong></td>
                                        <td>{{historyModalDetails.source_wallet_address}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{getMyLanguage("history","modal-history.details.destiny")}}:</strong></td>
                                        <td>{{historyModalDetails.destiny_wallet_address}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{getMyLanguage("history","modal-history.details.asset")}}:</strong></td>
                                        <td>{{historyModalDetails.asset_name}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>{{getMyLanguage("history","modal-history.details.value")}}:</strong></td>
                                        <td class="value" :class='{output: historyModalDetails.amount < 0}'>
                                            <span>{{historyModalDetails.quantity.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}} {{historyModalDetails.asset_short}}</span>
                                            <small>{{historyModalDetails.amount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</small>
                                        </td>
                                    </tr>
                                    <tr v-if="historyModalDetails.stakeEnd != null">
                                        <td>{{getMyLanguage("history","modal-history.details.stake")}}:</td>
                                        <td>
                                            {{ dateTime(historyModalDetails.stakeEnd)}}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="modal.history"></div>

    </div>
</template>

<script>
    import {toastController} from '@ionic/vue';

    import getMyFavouriteLanguage from "@/services/language.js";

    import QrcodeVue from 'qrcode.vue'

    import apiInternational from '@/config/apiInternational.js';
    import { handleError, error404, errorGeneral, errorMsg, errorBlockPurchase } from '@/services/errorHandler.js'

    import axios from 'axios';
    import moment from 'moment';
    import {mask} from 'vue-the-mask'

    export default {
        directives: {mask},
        components: {
            QrcodeVue
        },
        data () {
            return {
                VUE_APP_INTERNATIONAL_API: process.env.VUE_APP_INTERNATIONAL_API,

                qrCode:{
                    value: "",
                    size: 120
                },

                flagSelected: {},
                dataProfile: "",
                showContent: false,
                showWallets: false,
                dataWallets: [],

                errors: {
                    errorPost: false,
                    error_404: false,
                    error_general: false,
                    error_blockPurchase: false,
                    error_msg: ""
                },

                modal: {
                    load: false,
                    send: false,
                    reviewWithdrawal: false,
                    newDestiny: false,
                    receive: false,
                    history: false
                },

                sendModal: [],

                send: {
                    receipt: null,
                    sourceWalletId: '',
                    assetId: '',
                    amount: '',
                    smsCode: '',
                    destinyWalletId: ''
                },

                flowSend:{
                    load: false,
                    assetShort: '',
                    destinySelected: false,
                    uploadReceipt: false,
                    smsCodeShow: false,
                    errorCode: false,
                    successWithdrawn: false,
                    errorWithdrawn: false,
                    alertValue: false,
                    showBtnConfirm: false,
                    showBtnConfirmSMSCode: false,
                    countDownResendCode: 0
                },

                flowNewDestiny:{
                    load: false,
                    smsCodeShow: false,
                    errorCode: false,
                    successRegister: false,
                    errorRegister: false,
                    showBtnGenerateCode: false,
                    showBtnConfirm: false,
                    showBtnConfirmSMSCode: false,
                    countDownResendCode: 0
                },

                receiveModal:[],

                flowReceive:{
                    load: false
                },

                historyModal: [],
                historyModalDetails: {
                    show: false
                },

                newDestiny: {
                    NIBProof: null,
                    memo: ""
                },

                destinations:{
                    show: false,
                },

                errorsPost: {
                    destiny: "",
                    withdraw: ""
                }
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            dateTime(value) {
                return moment(value).locale(this.flagSelected).format('ll');
            },

            dateTimeShort(value) {
                return moment(value).locale(this.flagSelected).format('DD/MM');
            },

            async openSend(short, type){
                this.flowSend.load = true

                const resumeSendModal = await apiInternational.get(`/api/v1/pay/wallet/asset/${short}/${type}`)
                this.sendModal = resumeSendModal.data

                this.modal.load = false
                this.modal.send = true

                this.sendModal.value = ""
                this.sendModal.feeValue = 0
                this.sendModal.taxValue = 0
                this.sendModal.gross = 0
                this.sendModal.net = 0

                this.send.sourceWalletId = this.sendModal.accountWalletId
                this.send.receipt = null
                this.send.destinyWalletId = ''
                this.send.assetId = this.sendModal.assetId
                this.send.amount = ''
                this.send.smsCode = ''

                this.flowSend.destinySelected = false
                this.flowSend.uploadReceipt = false
                this.flowSend.smsCodeShow = false
                this.flowSend.showBtnConfirm = false
                this.flowSend.load = false
                this.flowSend.successWithdrawn = false
                this.flowSend.errorWithdrawn = false

                if( this.sendModal.withdrawalReceipt === true ){
                    this.flowSend.uploadReceipt = true
                }
            },

            sendDestinyName(value){
                this.flowSend.destinySelected = true
                this.sendModal.walletName = this.sendModal.wallets.filter(destiny => destiny.accountWalletId === value)[0].walletName
                this.sendModal.walletAddress = this.sendModal.wallets.filter(destiny => destiny.accountWalletId === value)[0].walletAddress
                this.send.destinyWalletId = value
            },

            sendAmount(){
                this.sendModal.feeValue = this.sendModal.value * this.sendModal.withdrawalFee / 100
                this.sendModal.taxValue = this.sendModal.value * this.sendModal.withdrawalTax / 100
                this.sendModal.net = this.sendModal.value + this.sendModal.feeValue + this.sendModal.taxValue
                this.sendModal.gross = this.sendModal.value
                this.send.amount = this.sendModal.value

                if( this.sendModal.value < this.sendModal.minWithdrawal || this.sendModal.value === "" )
                {
                    this.flowSend.showBtnConfirm = false
                }else{
                    this.flowSend.showBtnConfirm = true
                }
            },

            sendAmountMax(){
                setTimeout(() => {

                    if( this.sendModal.net >= this.sendModal.balanceAvailable ){
                        this.flowSend.alertValueMax = true

                        this.sendModal.value = this.sendModal.balanceAvailable - (this.sendModal.balanceAvailable * this.sendModal.withdrawalFee / 100) - (this.sendModal.balanceAvailable * this.sendModal.withdrawalTax / 100)
                        this.sendModal.feeValue = this.sendModal.balanceAvailable * this.sendModal.withdrawalFee / 100
                        this.sendModal.taxValue = this.sendModal.balanceAvailable * this.sendModal.withdrawalTax / 100
                        this.sendModal.net = this.sendModal.value + this.sendModal.feeValue + this.sendModal.taxValue
                        this.sendModal.gross = this.sendModal.value

                        this.send.amount = this.sendModal.value

                        setTimeout(() => {
                            this.flowSend.alertValueMax = false
                        }, 2000);
                    }
                }, 1);


                if(this.sendModal.value < this.sendModal.minWithdrawal){
                    this.flowSend.alertValueMin = true

                    this.sendModal.value = this.sendModal.minWithdrawal

                    this.sendModal.feeValue = this.sendModal.minWithdrawal * this.sendModal.withdrawalFee / 100
                    this.sendModal.taxValue = this.sendModal.minWithdrawal * this.sendModal.withdrawalTax / 100
                    this.sendModal.net = this.sendModal.minWithdrawal + this.sendModal.feeValue + this.sendModal.taxValue
                    this.sendModal.gross = this.sendModal.minWithdrawal + this.sendModal.feeValue + this.sendModal.taxValue
                    this.send.amount = this.sendModal.value
                    this.flowSend.showBtnConfirm = true

                    setTimeout(() => {
                        this.flowSend.alertValueMin = false
                    }, 2000);
                }
            },

            reviewWithdrawal(){
                // console.log(this.send)
                this.modal.send = false
                this.modal.reviewWithdrawal = true
            },

            async getCodeSend(){
                this.flowSend.load = true

                const formData = new FormData();
                //formData.append('Receipt', this.send.receipt);
                //formData.append('SMSCode', this.send.smsCode);
                formData.append('AssetId', this.send.assetId);
                formData.append('SourceWalletId', this.send.sourceWalletId);
                formData.append('DestinyWalletId', this.send.destinyWalletId);
                formData.append('Amount', this.send.amount);

                const token = localStorage.getItem('accessToken');
                const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}` };
                axios.post(`${this.VUE_APP_INTERNATIONAL_API}api/v1/pay/wallet/withdrawal/otp`, formData, { headers })
                .then(response => {
                    console.log(response)

                    this.flowSend.smsCodeShow = false
                    
                    setTimeout(() => {
                        this.flowSend.smsCodeShow = true
                        this.flowSend.load = false
                        this.flowSend.btnResendSMS = false
                        this.flowSend.countDownResendCode = 10
                        this.countDownFlowSend()
                    }, 1000);
                })
                .catch(error => {
                    if ( error.response.status === 400 ){
                        this.errorsPost.withdraw = error.response.data
                        this.send.smsCode = ""
                    } else{
                        this.errorsPost.withdraw = error.response.status
                    }

                    this.flowSend.load = false
                })
            },

            countDownFlowSend() {

                if(this.flowSend.countDownResendCode > 0) {
                    setTimeout(() => {
                        this.flowSend.countDownResendCode -= 1
                        this.countDownFlowSend()
                    }, 1000)
                } else{
                    this.flowSend.btnResendSMS = true
                }
            },

            uploadFileWithdraw() {
                this.send.receipt = this.$refs.file.files[0];
                this.flowSend.uploadReceipt = false
            },

            confirmWithdraw(){
                this.flowSend.load = true

                const formData = new FormData();
                formData.append('Receipt', this.send.receipt);
                formData.append('SMSCode', this.send.smsCode);
                formData.append('AssetId', this.send.assetId);
                formData.append('SourceWalletId', this.send.sourceWalletId);
                formData.append('DestinyWalletId', this.send.destinyWalletId);
                formData.append('Amount', this.send.amount);

                const token = localStorage.getItem('accessToken');
                const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}` };
                axios.post(`${this.VUE_APP_INTERNATIONAL_API}api/v1/pay/wallet/withdrawal`, formData, { headers })
                .then(response => {
                    this.registerId = response.data.id;
                    
                    this.flowSend.load = false
                    this.flowSend.successWithdrawn = true

                    this.updateBalance()
                })
                .catch(error => {
                    this.flowSend.load = false

                    if ( error.response.status === 400 ){
                        if ( error.response.data === "security_otp_payload_type_invalid" ){
                            this.flowSend.errorCode = true
                            this.send.smsCode = ""
                    
                            setTimeout(() => {
                                this.flowSend.errorCode = false
                            }, 4000);
                        } else{
                            this.errorsPost.withdraw = error.response.status
                        }
                    } else{
                        this.errorsPost.withdraw = error.response.status
                        console.log(error)
                    }
                })
            },

            async updateBalance(){
                const resumeWallets = await apiInternational.get('/api/v1/pay/wallet/balances')
                this.dataWallets = resumeWallets.data
            },
            
            
            getCodeNewDestiny(){
                this.flowNewDestiny.smsCodeShow = false
                this.flowNewDestiny.load = true
                
                const formData = new FormData();
                // formData.append('NIBProof', this.newDestiny.NIBProof);
                // formData.append('SMSCode', this.newDestiny.smsCode);
                formData.append('Name', this.newDestiny.name);
                formData.append('Address', this.newDestiny.address);
                formData.append('Memo', this.newDestiny.memo);

                const token = localStorage.getItem('accessToken');
                const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}` };
                axios.post(`${this.VUE_APP_INTERNATIONAL_API}api/v1/pay/wallet/${this.sendModal.assetType}/${this.sendModal.assetShort}/otp`, formData, { headers })
                .then(response => {
                    console.log(response)

                    this.flowNewDestiny.smsCodeShow = false
                    
                    setTimeout(() => {
                        this.flowNewDestiny.smsCodeShow = true
                        this.flowNewDestiny.load = false
                        this.flowNewDestiny.btnResendSMS = false
                        this.flowNewDestiny.countDownResendCode = 10
                        this.countDownFlowNewDestiny()
                        
                        // window.location.href = "#sms-destiny"
                    }, 1000);
                })
                .catch(error => {
                    if ( error.response.status === 400 ){
                        this.errorsPost.destiny = error.response.data
                    } else{
                        this.errorsPost.destiny = error.response.status
                    }

                    this.flowNewDestiny.load = false
                })
            },

            countDownFlowNewDestiny() {

                if(this.flowNewDestiny.countDownResendCode > 0) {
                    setTimeout(() => {
                        this.flowNewDestiny.countDownResendCode -= 1
                        this.countDownFlowNewDestiny()
                    }, 1000)
                } else{
                    this.flowNewDestiny.btnResendSMS = true
                }
            },

            registerNewDestiny(type, assetShort){
                this.flowNewDestiny.smsCodeShow = false
                this.flowNewDestiny.successRegister = false
                this.flowNewDestiny.errorRegister = false

                this.newDestiny = {
                    name: "",
                    address: "",
                    memo: "",
                    smsCode: ""
                }

                this.modal.newDestiny = true
                this.modal.send = false
                
                this.flowNewDestiny.type = type
                this.newDestiny.asset = assetShort
            },


            uploadFileNewDestiny() {
                this.newDestiny.NIBProof = this.$refs.fileDestiny.files[0];
                console.log(this.newDestiny.NIBProof)
            },

            confirmNewDestiny(){
                this.flowNewDestiny.load = true

                const formData = new FormData();
                formData.append('NIBProof', this.newDestiny.NIBProof);
                formData.append('SMSCode', this.newDestiny.smsCode);
                formData.append('Name', this.newDestiny.name);
                formData.append('Address', this.newDestiny.address);
                formData.append('Memo', this.newDestiny.memo);

                const token = localStorage.getItem('accessToken');
                const headers = { 'Content-Type': 'multipart/form-data', 'Authorization': `bearer ${token}` };
                axios.post(`${this.VUE_APP_INTERNATIONAL_API}api/v1/pay/wallet/${this.sendModal.assetType}/${this.sendModal.assetShort}`, formData, { headers })
                .then(response => {
                    this.registerId = response.data.id;
                    
                    this.flowNewDestiny.load = false
                    this.flowNewDestiny.successRegister = true

                    this.updateSendModal(this.sendModal.assetShort, this.sendModal.accountWalletTypeCode);
                })
                .catch(error => {
                    this.flowNewDestiny.load = false

                    if ( error.response.status === 400 ){
                        if ( error.response.data === "security_otp_payload_type_invalid" ){
                            this.flowNewDestiny.errorCode = true
                            this.newDestiny.smsCode = ""
                    
                            setTimeout(() => {
                                this.flowNewDestiny.errorCode = false
                            }, 4000);
                        } else{
                            this.flowNewDestiny.errorRegister = true
                        }
                    } else{
                        this.flowNewDestiny.errorRegister = true
                        console.log(error)
                    }
                })
            },


            async updateSendModal(short, type){
                const resumeSendModal = await apiInternational.get(`/api/v1/pay/wallet/asset/${short}/${type}`)
                this.sendModal = resumeSendModal.data

                this.sendModal.walletName = this.sendModal.walletName + " (" + this.sendModal.walletAddress + ")"
                this.sendModal.feeValue = 0
                this.sendModal.withdrawalTax = 0
                this.sendModal.taxValue = 0
                this.sendModal.gross = 0
                this.sendModal.net = 0

                this.send.sourceWalletId = this.sendModal.accountWalletId
                this.send.assetId = this.sendModal.assetId
                
                this.send.amount = 0
                this.sendModal.net = 0
                this.flowSend.smsCodeShow = false
                this.send.smsCode = ''
            },


            async openReceive(short, type){
                this.flowReceive.load = true

                const resumeReceiveModal = await apiInternational.get(`/api/v1/pay/wallet/deposit/${short}/${type}`)
                this.receiveModal = resumeReceiveModal.data

                this.qrCode.value = this.receiveModal.walletAddress

                this.flowReceive.load = false
            },

            async copyReceive(urlShare) {
                try {
                await navigator.clipboard.writeText(urlShare);
                    this.openToastCopy()
                }
                
                catch($e) {
                    this.openToastNoCopy()
                }
            },

            async openToastCopy() {
                const toast = await toastController
                    .create({
                        message: this.getMyLanguage("receive", "modal-receive.copied"),
                        color: "secondary",
                        duration: 2000,
                        position: 'middle',
                    })
                return toast.present();
            },
            
            
            async openToastNocopy() {
                const toast = await toastController
                    .create({
                        message: "Error",
                        color: "primary",
                        duration: 3000
                    })
                return toast.present();
            },


            async openHistory(id){
                const resumeHistoryModal = await apiInternational.get(`/api/v1/pay/wallet/transactions/${id}`)
                this.historyModal = resumeHistoryModal.data
                // console.log(this.historyModal)
                
                this.modal.load = false
                this.modal.history = true
            },

            detailsHistory(value){
                this.historyModalDetails = this.historyModal[value]
                this.historyModalDetails.show = true
            },
        },

        async mounted () {
            this.flagUrl()

            this.dataProfile = JSON.parse(localStorage.getItem("profile"))
            
            await apiInternational.get('/api/v1/pay/wallet/balances')
            .then(response => {
                this.dataWallets = response.data
                
                setTimeout(() => {
                    this.showWallets = true
                    this.showContent = true
                    this.load = true
                }, 1000);
            })
            .catch(error => {
                setTimeout(() => {
                    this.showContent = true
                }, 500);

                handleError(error, this.flagSelected);
                
                this.errors.error_404 = error404
                this.errors.error_general = errorGeneral
                this.errors.error_blockPurchase = errorBlockPurchase
                this.errors.error_msg = errorMsg
            })
        }
    }
</script>

<style scoped src="@/assets/css/Balances.css" />