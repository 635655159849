<template>
    <ion-app :scroll-events="true" class="token">
        <ion-page>
            <div class="header-step">
                <HeaderApp />
            </div>

            <ion-content class="token-payment">
                <!-- <Step /> -->

                <div class="ion-padding">
                    <div class="content">
                        <h1 class="title-primary">DNE <span>T</span>oken</h1>

                        <div class="buy-content">
                            <div class="box summary">
                                <h6>{{ getMyLanguage("summary", "summary.title") }}</h6>
                                <p>{{ getMyLanguage("summary", "summary.originalPrice") }}: <strong>180 DNE Token > 90 €</strong></p>
                                <p>{{ getMyLanguage("summary", "summary.promotionPrice") }}: <strong>81 € (5% OFF) + 20% bonus</strong></p>
                                <p>{{ getMyLanguage("summary", "summary.stake") }}: <strong>12 {{ getMyLanguage("summary", "summary.stake.months") }}</strong></p>
                                <p>{{ getMyLanguage("summary", "summary.total") }}: <strong>216 DNE Token</strong></p>
                            </div>

                            <form>
                                <label>
                                    {{ getMyLanguage("tokenPayment", "tokenPayment.label.cardHolder") }}
                                    <input type="text" class="form" id="cardholder" v-model="dataPurchase.cardHolder" required>
                                </label>
                                
                                <label>
                                    {{ getMyLanguage("tokenPayment", "tokenPayment.label.cardNumber") }}
                                    <input type="text" class="form" id="card-number" v-model="dataPurchase.cardNumber" required>
                                </label>

                                <div class="expiry-cvv">                            
                                    <label class="expiry">
                                        {{ getMyLanguage("tokenPayment", "tokenPayment.label.expiry") }}
                                        <input type="text" class="form" id="expiry" v-model="dataPurchase.expiry" required>
                                    </label>
                                    
                                    <label class="cvv">
                                        {{ getMyLanguage("tokenPayment", "tokenPayment.label.cvv") }}
                                        <input type="text" class="form" id="cvv" v-model="dataPurchase.cvv" required>
                                    </label>
                                </div>

                                <button @click="validateFormPay()" type="submit" expand="block" class="btn-primary" v-if="stepBuy.load === false">{{ getMyLanguage("tokenPayment", "tokenPayment.btn.pay") }} 81€</button>
                                <div class="btn-primary btn-loading" v-if="stepBuy.load === true"></div>


                                <!-- <ion-list class="ion-padding">
                                    <ion-item>
                                        <ion-label position="floating" color="primary">Cardholder name</ion-label>
                                        <ion-input v-model="TokenPay.cardHolder" type="text" spellcheck="false" autocapitalize="off" required></ion-input>
                                    </ion-item>
                                    <ion-item>
                                        <ion-label position="floating" color="primary">Card number</ion-label>
                                        <ion-input v-model="TokenPay.cardNumber" type="text" spellcheck="false" autocapitalize="off" required></ion-input>
                                    </ion-item>
                                    <ion-item>
                                        <ion-label position="floating" color="primary">Expiry</ion-label>
                                        <ion-input v-model="TokenPay.expiry" type="text" spellcheck="false" autocapitalize="off" required></ion-input>
                                    </ion-item>
                                    <ion-item>
                                        <ion-label position="floating" color="primary">CVV</ion-label>
                                        <ion-input v-model="TokenPay.cvv" type="text" spellcheck="false" autocapitalize="off" required></ion-input>
                                    </ion-item>
                                </ion-list>

                                <ion-row class="ion-padding">
                                    <ion-col>
                                        <ion-input v-model="TokenPay.accountId" type="hidden"></ion-input>
                                        <button @click="validateFormPay()" type="submit" expand="block" class="btn-primary">Continue</button>
                                    </ion-col>
                                </ion-row> -->
                                <!-- {{TokenPay}} -->
                                
                                
                                <div class="obsBuy">
                                    <h6>{{ getMyLanguage("tokenPayment", "tokenPayment.obs.title") }}</h6>
                                    <p>{{ getMyLanguage("tokenPayment", "tokenPayment.obs.msg") }}</p>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </ion-content>

            <FooterApp />
        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import HeaderApp from '@/components/dneinternational/HeaderApp.vue';
    // import Step from '@/components/dneplatform/Step.vue';
    import FooterApp from '@/components/dneinternational/FooterApp.vue';

    export default {
        components: {
            IonApp,IonPage, IonContent,
            HeaderApp,
            // Step,
            FooterApp
        },
        data () {
            return {
                flagSelected: {},
                dataPurchase: {},
                stepBuy: {
                    'load': true
                }
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
            
            validateFormPay(){
                window.location.href = `${this.flagSelected}/token-payment-success`
            }
        },


        async mounted () {
            this.flagUrl();
        }
        
        // created () {
            
        // }
    }
</script>


<style scoped>
    .summary{}
    .summary h6{font-size: 20px; margin-bottom: 10px; color: var(--color-primary);}
    .summary p{margin-bottom: 0;}
    .summary p strong{color: var(--color-secondary);}

    .buy-content{margin-bottom: 30px;}
   
    form{background: var(--color-primary); border-radius: 12px; padding: 16px;}
    form label{display: block; color: var(--color-text-tertiary);}
    form .form{height: 40px; line-height: 40px;}
    form .expiry-cvv{display: flex; justify-content: space-between; align-items: flex-start;}
    form .expiry-cvv .expiry{width: 50%;}
    form .expiry-cvv .cvv{width: 30%;}
    form .btn-primary{margin-top: 20px;}
    
    form .obsBuy{margin-top: 40px; padding-top: 30px; border-top: 1px solid rgba(255,255,255,0.2);}
    form .obsBuy h6{font-size: 16px; color: var(--color-text-tertiary); margin: 0 0 8px 0;}
    form .obsBuy p{font-size: 12px; color: var(--color-text-tertiary);}

    /* <div class="obsBuy">
            <p>Observation</p>
            <p><small>For your safety, DNE Ecosystem does not save your credit card details.</small></p>
        </div> */
</style>