<template>
    <div class="balances">
        <h2 class="title-primary">Vouchers</h2>

        <div class="box box-vouchers" v-if="!showVouchers">
            <div class="load"></div>
        </div>
        
        <div class="box box-vouchers" v-if="showVouchers">
            <table class="table-vouchers">
                <tr>
                    <td class="voucher"><strong>1/4</strong> Bónus</td>
                    <td class="value"><span>180</span><small>90 €</small></td>
                    <td><div class="btn-voucher btn-copy-send" @click="copyVoucher()"></div></td>
                    <td><div class="btn-voucher btn-activate" @click="activateVoucher()"></div></td>
                </tr>
                <tr>
                    <td class="voucher"><strong>3/4</strong> Revenda</td>
                    <td class="value"><span>1800</span><small>900 €</small></td>
                    <td><div class="btn-voucher btn-copy-send" @click="copyVoucher()"></div></td>
                    <td><div class="btn-voucher btn-activate" @click="activateVoucher()"></div></td>
                </tr>
                <tr>
                    <td class="voucher"><strong>9/4</strong> Bónus</td>
                    <td class="value"><span>180</span><small>90 €</small></td>
                    <td><div class="btn-voucher btn-copy-send" @click="copyVoucher()"></div></td>
                    <td><div class="btn-voucher btn-activate" @click="activateVoucher()"></div></td>
                </tr>
                <tr>
                    <td class="voucher"><strong>6/4</strong> Ativação</td>
                    <td class="value output"><span>-180</span><small>90 €</small></td>
                    <td><div class="btn-voucher btn-copy-send btn-disable"></div></td>
                    <td><div class="btn-voucher btn-history"></div></td>
                </tr>

                <tr class="total">
                    <td colspan="6">
                        {{getMyLanguage("wallets","box-balances.total")}}
                        <strong>1980</strong>
                        <small>990 €</small>
                    </td>
                </tr>
            </table>


            <div class="overlayPreModal" v-if="modal.load" @click="modal.load = false"></div>
        </div>
           


        <!-- MODAL ACTIVATE -->
        <div class="modal modal-activate" v-if="modal.modalActivate">
            <div class="cont-modal">
                <div class="close-modal" @click="modal.modalActivate = false"></div>
                <div class="box">
                    <div class="activate-box">

                        <!-- ACTIVATE INITIAL -->
                        <div class="activate-initial" v-if="!modal.load && modal.activate.initialActivate">
                            <h3 class="title-secondary">Ativação Voucher</h3>
                            <div class="box">
                                <table>
                                    <tr>
                                        <td><strong>Tipo:</strong></td>
                                        <td>Bónus <small>(não gera bónus)</small></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Qtde:</strong></td>
                                        <td>180 DNE Token <small>(90€)</small></td>
                                    </tr>
                                </table>
                            </div>

                            <label>
                                Destino
                                <input type="text" class="form" placeholder="E-mail">
                            </label>

                            <div class="btn-primary" @click="verifyEmail()">Continuar</div>
                        </div>

                        <!-- ACTIVATE REVIEW -->
                        <div class="activate-review" v-if="!modal.load && modal.activate.reviewActivate">
                            <h3 class="title-secondary">Confirmar</h3>
                            <div class="form-group-check">
                                <input type="checkbox" id="acceptTerms" class="form-check" v-model="sendVoucher.accept">
                                <label for="acceptTerms" class="form-check-label"><small>Confirma a ativação do voucher #123456789?</small></label>
                            </div>


                            <!-- SMS CODE -->
                            <div class="load" v-if="flowSendVoucher.load"></div>

                            <div class="sms-code" v-if="sendVoucher.accept && !flowSendVoucher.load">
                                <div class="sms-confirm">
                                    <input type="text" class="form" v-model="sendVoucher.smsCode" placeholder="SMS code">
                                    <div class="confirm" @click="confirmActivateVoucher()">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                </div>
                                
                                <div class="alert">{{getMyLanguage("wallets","modal-send-code.msg-sms")}} 999****999</div>

                                <div class="resend">
                                    <div class="btn-resend" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-resend")}}</div>
                                </div>
                            </div>

                            <div class="btn-cancel-primary" v-if="!sendVoucher.accept" @click="modal.modalActivate = false">Cancelar</div>
                        </div>


                        <!-- SUCCESS ACTIVATE VOUCHER -->
                        <div class="activate-success" v-if="!modal.load && modal.activate.successActivate">
                            <h2 class="title-secondary">{{getMyLanguage("wallets","modal-send-success.title")}}</h2>
                            <p>{{getMyLanguage("wallets","modal-send-success.msg")}}</p>
                        </div>


                        <!-- ERROR ACTIVATE VOUCHER -->
                        <div class="activate-error" v-if="!modal.load && modal.activate.errorActivate">
                            <h2 class="title-secondary">{{getMyLanguage("wallets","modal-send-error.title")}}</h2>
                            <p>{{getMyLanguage("wallets","modal-send-error.msg")}}</p>
                        </div>


                        <div class="load extra-margin" v-if="modal.load"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="modal.modalActivate" @click="modal.modalActivate = false"></div>



        <!-- MODAL HISTORY -->
        <!-- <div class="modal modal-send" v-if="modal.history">
            <div class="cont-modal">
                <div class="close-modal" @click="modal.history = false"></div>
                <div class="box">
                    <div class="scroll-modal">
                        <h2 class="title-secondary">{{getMyLanguage("history","modal-history.title")}}</h2>
                        
                        <table class="table-activities">
                            <tr class="tr" v-for="(listHistory, index) in historyModal" :key="index">
                                <td class="td date">{{ dateTimeShort(listHistory.transaction_created_at) }}</td>
                                <td class="td description"><strong>{{listHistory.transaction_type}}</strong><small>{{getMyLanguage("history","modal-history.available-in")}} {{ dateTime(listHistory.stakeEnd)}}</small></td>
                                <td class="td" :class='{output: listHistory.amount < 0}'>
                                    <span>{{listHistory.quantity}} {{listHistory.asset_short}}</span>
                                    <small>{{listHistory.amount}} €</small>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="modal.history" @click="modal.history = false"></div> -->
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";
    
    import moment from 'moment';

    export default {
        data () {
            return {
                flagSelected: {},
                showVouchers: true,
                
                modal: {
                    load: false,
                    modalActivate: false,
                    activate: {
                        initialActivate: false,
                        reviewActivate: false,
                        errorActivate: false,
                        successActivate: false
                    }
                },

                sendVoucher: {
                    accept: false
                },

                flowSendVoucher:{
                    load: false,
                    assetShort: '',
                    successWithdrawn: false,
                    errorWithdrawn: false,
                    alertValue: false
                },
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            dateTime(value) {
                return moment(value).locale(this.flagSelected).format('ll');
            },

            dateTimeShort(value) {
                return moment(value).locale(this.flagSelected).format('DD/MM');
            },

            activateVoucher(){
                this.modal.load = true
                this.modal.modalActivate = true
                this.modal.activate.initialActivate = true
                this.modal.activate.reviewActivate = false
                this.modal.activate.errorActivate = false
                this.modal.activate.successActivate = false

                this.sendVoucher.accept = false

                setTimeout(() => {
                    this.modal.load = false
                }, 1000);
            },

            verifyEmail(){
                this.modal.load = true
                this.modal.activate.initialActivate = false
                this.modal.activate.reviewActivate = true

                setTimeout(() => {
                    this.modal.load = false
                }, 1000);
            },

            confirmActivateVoucher(){
                this.modal.load = true
                this.modal.activate.reviewActivate = false
                this.modal.activate.successActivate = true

                setTimeout(() => {
                    this.modal.load = false
                }, 1000);
            },

            getCodeSend(){
                // post para o getCode

                // then
                
                this.flowSendVoucher.smsCodeShow = false
                this.flowSendVoucher.load = true
                
                setTimeout(() => {
                    this.flowSendVoucher.smsCodeShow = true
                    this.flowSendVoucher.load = false
                }, 1000);
            },

            copyVoucher(){
                console.log("")
            },
            



            // reviewWithdrawal(){
            //     this.modal.send = false
            //     this.modal.reviewWithdrawal = true
            // },

            // confirmTransfer(){
            //     this.flowSend.load = true

            //     api.post("/api/v1/pay/wallet/withdrawal/", this.send)
            //     .then(response => {
            //         this.registerId = response.data.id;
                    
            //         this.flowSend.load = false
            //         this.flowSend.successWithdrawn = true

            //         this.updateBalance()
            //     })
            //     .catch(error => {
            //         this.flowSend.successWithdrawn = true
            //         this.flowSend.load = false
            //         console.log(error)
            //     })
            // },
            
            getCode(){
                this.flowNewDestiny.smsCodeShow = false
                this.flowNewDestiny.load = true
                
                setTimeout(() => {
                    this.flowNewDestiny.smsCodeShow = true
                    this.flowNewDestiny.load = false
                }, 1000);
            },


            // async openHistory(id){
            //     const resumeHistoryModal = await api.get(`/api/v1/pay/wallet/transactions/${id}`)
            //     this.historyModal = resumeHistoryModal.data
                
            //     this.modal.load = false
            //     this.modal.history = true
            // },
        },

        async mounted () {
            this.flagUrl()
            
            // const resumeWallets = await api.get('/api/v1/pay/wallet/balances')
            // this.dataWallets = resumeWallets.data

            // setTimeout(() => {
            //     this.showVouchers = true
            // }, 1000);
        }
    }
</script>


<style scoped src="@/assets/css/Vouchers.css" />
