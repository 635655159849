<template>
    <ion-app :scroll-events="true" class="permission">
        <ion-page>
            <div class="header-step">
                <HeaderApp />
            </div>

            <ion-content class="ion-padding permission-content">
                <div class="content">
                    <div class="load-blue" v-if="!showContent"></div>

                    <div class="container ion-padding" v-if="showContent && (errors.error_404 || errors.error_general)">
                        <div class="box-error">
                            <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                            <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                            <div class="cod-error">{{ getMyLanguage("box-error",errors.error_msg) }}</div>
                        </div>
                    </div>


                    <div class="container" v-if="showContent && (!errors.error_404 && !errors.error_general && !errors.error_blockPurchase)">
                        <h2 class="title-secondary">Establishing of the controlling person of operating legal entities and partnerships both not quoted on the stock exchange</h2>
                        
                        <div class="box-beneficial">
                            <div class="box-registration">
                                <div class="formBox">
                                    <p>(for operating legal entities and partnerships that are contracting partner as well as analogously for operating legal entities and partnerships that are beneficial owners)</p>
                                    <div class="partner">
                                        <div>
                                            <h3 class="subtitle">Contracting partner:</h3>
                                            <p>DNE International Sagl</p>
                                            <p>CHE-409.547.100 / Switzerland</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="box-registration">
                                <div class="formBox">
                                    <h3>Pursuant to the anti-money laundering legislation, the contracting partner(s) hereby declare(s) (tick the appropriate box):</h3>

                                    <div class="form-group-radio">
                                        <input type="radio" id="box1" v-model="signature.legislation" value="Legislation 01" name="radioBoxLegislation" class="form-radio" @click='signature.legislation = "Legislation 01"' :checked='signature.legislation == "Legislation 01"' >
                                        <label for="box1" class="form-radio-label">
                                            the person(s) listed below is / are holding 25 % or more of the contracting partner’s shares (capital shares or voting rights); or
                                        </label>

                                        <input type="radio" id="box2" v-model="signature.legislation" value="Legislation 02" name="radioBoxLegislation" class="form-radio" @click='signature.legislation = "Legislation 02"' :checked='signature.legislation == "Legislation 02"' >
                                        <label for="box2" class="form-radio-label">
                                            if there are no capital shares or voting rights of 25 % or more, that the following person(s) listed below is / are controlling the contracting partner in other ways; or
                                        </label>
                                        
                                        <input type="radio" id="box3" v-model="signature.legislation" value="Legislation 03" name="radioBoxLegislation" class="form-radio" @click='signature.legislation = "Legislation 03"' :checked='signature.legislation == "Legislation 03"' >
                                        <label for="box3" class="form-radio-label last">
                                            in case no person(s) exist(s) who exercise(s) control over the contracting partner in a different capacity, the contracting partner hereby declares that the person(s) listed below is / are the managing director(s).
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="box-registration">
                                <div class="anc" id="error"></div>

                                <div class="formBox">
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="formName">
                                                First name(s), last name(s) / entity:
                                                <input type="text" class="form" :class="{error: errorsForm.fullName}" v-model="signature.fullName" v-on:input="signatureName()" @keyup='validateError("fullName")' :placeholder='getMyLanguage("compliance","compliance.placeholder.fullName")'>
                                                <small class="error" v-if="errorsForm.fullName">{{getMyLanguage("compliance","compliance.error.comparative")}} {{dataAccount.company.fullName}}</small>
                                            </label>
                                        </div>

                                        <div class="col-12">
                                            <label class="formAddress last">
                                                Actual address of domicile / registered office (incl. country):
                                                <input type="text" class="form" :class="{error: errorsForm.fullAddress}" v-model="signature.fullAddress" @keyup='validateError("fullAddress")' :placeholder='getMyLanguage("compliance","compliance.placeholder.fullAddress")'>
                                                <small class="error" v-if="errorsForm.fullAddress">{{getMyLanguage("compliance","compliance.error.address-empty")}}</small>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="box-registration">
                                <div class="formBox">
                                    <h3>Fiduciary holding of assets</h3>
                                    <p>Is a third person the beneficial owner of the assets booked under the above relationship?</p>
                                    <div class="form-group-radio">
                                        <input type="radio" id="boxNo" v-model="signature.fiduciary" value="No" name="radioBoxRelationship" class="form-radio" @click='signature.fiduciary = "No"' :checked='signature.fiduciary == "No"' >
                                        <label for="boxNo" class="form-radio-label">
                                            No.
                                        </label>

                                        <input type="radio" id="boxYes" v-model="signature.fiduciary" value="Yes" name="radioBoxRelationship" class="form-radio" @click='signature.fiduciary = "Yes"' :checked='signature.fiduciary == "Yes"' >
                                        <label for="boxYes" class="form-radio-label last">
                                            Yes. &rarr; The relevant information regarding the beneficial owner has to be obtained by filling in a separate form A.
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="box-registration">
                                <div class="formBox signature">
                                    <h3 class="subtitle">The contracting partner(s) hereby undertake(s) to automatically inform the bank of any changes.</h3>

                                     <label class="formDateToday">
                                        Date:
                                        <span>{{dateTime()}}</span>
                                    </label>
                                
                                    <label class="formSignature">
                                        Signature(s):
                                        <span v-html="signature.fullName"></span>
                                    </label>

                                    <p class="alert">It is a criminal offence to deliberately provide false information on this form (Article 251 of the Swiss Criminal Code, document forgery).</p>
                                </div>
                            </div>

                            <div class="bt-registration">
                                <div class="btn-primary" @click='validateForm()'>{{ getMyLanguage("client-space", "instructions.receipt.send") }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ion-content>
            <FooterApp />
        </ion-page>


         <!-- MODAL 2FA -->
        <div class="modal modal-2fa" v-if="modalSMS.show">
            <div class="cont-modal">
                <div class="close-modal" @click="modalSMS.show = false"></div>
                <div class="box">
                    <div class="scroll-modal">

                        <!-- SMS CODE -->
                        <div class="sms-code" v-if="!flowSMS.success">
                            <h3 class="title-secondary">{{getMyLanguage("compliance","compliance.modal2fa.title")}}</h3>
                            <div class="load" v-if="flowSMS.load"></div>

                            <p v-if="!flowSMS.load">{{getMyLanguage("compliance","compliance.modal2fa.inf")}}</p>
                            <div class="sms-code-cont" v-if="!flowSMS.load">
                                <!-- <div class="sms-confirm" v-if="flowSMS.smsCodeShow && !flowSMS.load"> -->
                                <div class="sms-confirm">
                                    <input type="text" class="form form-smsCode" v-model="codeSMS.smsCode" maxlength="6" placeholder="______" autocomplete="one-time-code">
                                    <div class="confirm btn-disable" v-if="codeSMS.smsCode.length < 6">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                    <div class="confirm" v-if="codeSMS.smsCode.length === 6" @click="validateCodeSMS()">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                </div>
                                
                                <div class="alert" v-if="flowSMS.smsCodeShow && !flowSMS.load">{{getMyLanguage("wallets","modal-send-code.msg-sms")}} +{{$store.state.profile.profile_country_ddi}} {{$store.state.profile.profile_phone_number}}</div>
                                <div class="load" v-if="flowSMS.smsCodeShow && flowSMS.load"></div>
                            </div>

                            <div class="resend" v-if="flowSMS.smsCodeShow && !flowSMS.load">
                                <div class="btn-resend resend-load" v-if="!flowSMS.btnResendSMS">{{getMyLanguage("wallets","modal-send-code.btn-resend-in")}} {{flowSMS.countDownResendCode}}s</div>
                                <div class="btn-resend" v-if="flowSMS.btnResendSMS" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-resend")}}</div>
                            </div>
                        </div>

                        <!-- SUCCESS -->
                        <div class="success" v-if="flowSMS.success && !flowSMS.load">
                            <h3 class="title-secondary">{{getMyLanguage("compliance","compliance.modal2fa.boxSuccess.title")}}</h3>
                            
                            <div class="box-success">
                                <p>{{getMyLanguage("compliance","compliance.modal2fa.boxSuccess.msg")}}</p>
                                <a :href="`/${flagSelected}/client-space-purchase`" class="btn-primary">{{getMyLanguage("compliance","compliance.cta-success")}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="overlayModal" v-if="modalSMS.show"></div>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent, toastController} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import HeaderApp from '@/components/dneplatform/HeaderApp.vue';
    import FooterApp from '@/components/dneplatform/FooterApp.vue';

    import moment from 'moment';

    // import apiPlatform from '@/config/apiPlatform.js';
    import apiInternational from '@/config/apiInternational.js';

    import { handleError, error404, errorGeneral, errorMsg, errorBlockPurchase } from '@/services/errorHandler.js'

    export default {
        components: {
            IonApp,IonPage, IonContent,
            HeaderApp,
            FooterApp
        },
        
        data(){
            return{
                showContent: false,
                flagSelected: {},
                load: false,
                dataAccount: {},

                errors: {
                    errorPost: false,
                    error_404: false,
                    error_general: false,
                    error_blockPurchase: false,
                    error_msg: ""
                },

                signature: {
                    legislation: "Legislation 01",
                    fullName: "",
                    fullAddress: "",
                    fiduciary: "No",
                    smsCode: 0
                },

                errorsForm: {
                    fullName: false,
                    fullAddress: false
                },

                errorsGet: {
                    dataAccount: false
                },

                errorsPost: {
                    signature: false
                },
                
                codeSMS: {
                    legislation: "",
                    fullName: "",
                    fullAddress: "",
                    fiduciary: "",
                    smsCode: ''
                },
                
                // dataCountry: [],
                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryFlag: false,

                modalSMS: {
                    show: false,
                    load: false,
                    send: false
                },

                flowSMS: {
                    load: true,
                    smsCodeShow: false,
                    btnResendSMS: false,
                    countDownResendCode: 0,
                    success: false
                }
            }
        }, 

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            dateTime(value) {
                return moment(value).locale(this.flagSelected).format('ll');
            },

            signatureName(){
                const nameSignature = this.signature.fullName;
                const nameSignatureLowercase = nameSignature.toLowerCase();
                const words = nameSignatureLowercase.split(' ');
                const wordsUppercase = words.map(words => words.charAt(0).toUpperCase() + words.slice(1));
                const nameSignatureFinished = wordsUppercase.join(' ');
                this.signature.fullName = nameSignatureFinished;
            },

            listCountry(status){
                setTimeout(() => {
                    this.listCountryStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        document.getElementById('issuerCountry').focus()
                    }, 10);
                }
            },

            filterListCountry() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("issuerCountry");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            validateError(){
                if ( this.signature.fullName.toLowerCase() == this.dataAccount.company.fullName.toLowerCase() ){
                    this.errorsForm.fullName = false
                }

                if ( this.signature.fullAddress != "" ){
                    this.errorsForm.fullAddress = false
                }
            },

            validateForm(){
                if ( this.signature.fullName.toLowerCase() != this.dataAccount.company.fullName.toLowerCase() || this.signature.fullName == "" || this.signature.fullName == null ){
                    this.errorsForm.fullName = true

                    const errorElement = document.getElementById("error");
                    errorElement.scrollIntoView();
                } else {
                    this.errorsForm.fullName = false
                }

                if ( this.signature.fullAddress == "" || this.signature.fullAddress == null ){
                    this.errorsForm.fullAddress = true

                    const errorElement = document.getElementById("error");
                    errorElement.scrollIntoView();
                } else {
                    this.errorsForm.fullAddress = false
                }


                if ( !this.errorsForm.fullName && !this.errorsForm.fullAddress && !this.errorsForm.nationality ){
                    this.saveBO()

                    // this.modalSMS.show = true
                    // this.getCodeSend()
                }
            },

            saveBO(){
                apiInternational.post('api/v1/international/compliance/beneficial_owner', this.signature)
                .then(response => {
                    console.log(response)
                    this.toastSave("secondary",this.getMyLanguage('compliance','compliance.title-success'))

                    setTimeout(() => {
                        window.location.href = `/${this.flagSelected}/international/token`
                    }, 1000);
                })
                .catch(error => {
                    console.log(error)
                    this.toastSave("danger",this.getMyLanguage('box-error','msg-error'))
                })
            },

            async toastSave(value,txt) {
                const toast = await toastController.create({
                    message: txt,
                    color: value,txt,
                    duration: 3000,
                    position: 'middle',
                })
                return toast.present();
            },

            // async getCodeSend(){
            //     this.flowSMS.load = true

            //     apiInternational.post('/api/v1/******',this.signature)
            //     .then(response => {
            //         console.log(response)

            //         this.flowSMS.smsCodeShow = false
                    
            //         setTimeout(() => {
            //             this.flowSMS.smsCodeShow = true
            //             this.flowSMS.load = false
            //             this.flowSMS.btnResendSMS = false
            //             this.flowSMS.countDownResendCode = 10
            //             this.countDownflowSMS()
            //             this.codeSMS.legislation = this.signature.legislation
            //             this.codeSMS.fullName = this.signature.fullName
            //             this.codeSMS.fullAddress = this.signature.fullAddress
            //             this.codeSMS.fiduciary = this.signature.fiduciary
            //         }, 1000);
            //     })
            //     .catch(error => {
            //         this.$toast.error(this.getMyLanguage("compliance","compliance.toast.error.codigo"))
            //         console.log(error)

            //         this.flowSMS.load = false
            //     })

            //     // TEMP
            //     // setTimeout(() => {
            //     //     this.flowSMS.load = false
            //     // }, 500);
                
            //     // this.flowSMS.smsCodeShow = true
            //     // this.flowSMS.btnResendSMS = false
            //     // this.flowSMS.countDownResendCode = 10
            //     // this.countDownflowSMS()
            // },

            // countDownflowSMS() {

            //     if(this.flowSMS.countDownResendCode > 0) {
            //         setTimeout(() => {
            //             this.flowSMS.countDownResendCode -= 1
            //             this.countDownflowSMS()
            //         }, 1000)
            //     } else{
            //         this.flowSMS.btnResendSMS = true
            //     }
            // },


            // async validateCodeSMS(){
            //     this.flowSMS.load = true
                
            //     await apiInternational.post('/api/v1/platform/account/international/beneficial_owner', this.codeSMS)
            //     .then(response => {
            //         console.log(response)
            //         // console.clear()

            //         setTimeout(() => {
            //             this.flowSMS.success = true
            //             this.flowSMS.load = false
            //             this.errors.invalidCode = false
            //         }, 1000);
            //     })
            //     .catch(error => {
            //         this.$toast.error(this.getMyLanguage("client-space","client-space.account.lbl.phone.validate-error"));
            //         setTimeout(this.$toast.clear, 6000)

            //         console.log(error)
                    
            //         this.flowSMS.load = false

            //         setTimeout(() => {
            //             this.errors.flow = false
            //         }, 5000);
            //     })
            // }
        },


        async mounted(){
            this.flagUrl();

            this.showContent = true

            await apiInternational.get('/api/v1/international/compliance/company')
            .then(response => {
                this.dataAccount = response.data
                console.log(this.dataAccount)
                this.showContent = true
            })
            .catch(error => {
                setTimeout(() => {
                    this.showContent = true
                }, 500);

                handleError(error, this.flagSelected);
                
                this.errors.error_404 = error404
                this.errors.error_general = errorGeneral
                this.errors.error_blockPurchase = errorBlockPurchase
                this.errors.error_msg = errorMsg
            })

            
            // const resumeClientCountry = await apiPlatform.get('api/v1/platform/country')
            // this.dataCountry = resumeClientCountry.data
        }
    }
</script>


<style scoped>
    .msg{padding: 16px; margin-bottom: 20px; background: url('~@/assets/images/bg-25.svg') no-repeat; background-size: cover; border-radius: 12px; text-align: center; font-size: 13px; font-family: var(--font-primary-semibold);}
    .msg.msg-pending{position: relative; z-index: 20; background: url('~@/assets/images/bg-pending.svg') no-repeat; background-size: cover; color: var(--color-text-tertiary);}
    .msg.msg-success{background: rgba(74,174,87,0.1); color: var(--color-primary);}
    .msg p{margin: 0;}
    .msg h3{font-size: 28px;}

    .box-registration{width: 100%; border-radius: 12px; background: var(--color-text-tertiary); margin-bottom: 30px;}
    .box-registration label{font-size: 13px;}
    .box-registration label.required input{border: 1px solid var(--color-cancel) !important;}
    .box-registration label small.error{color: var(--color-cancel);}
    
    .bt-registration{margin-bottom: 30px;}

    .cont{position: absolute; right: 12px; top: 16px; font-size: 10px;}

    .autocomplete .openAutocomplete{top: 20px; bottom: auto;}

    .formBox{padding: 20px 15px;}
    .formBox h3{font-size: 16px; color: var(--color-primary); margin: 0 0 20px 0;}
    .formBox h3.subtitle{display: block; padding-bottom: 7px; position: relative;}
    .formBox h3.subtitle:after{content: ''; display: block; width: 240px; height: 4px; background: #eee; position: absolute; left: 0; bottom: 0;}
    .formBox label:not(.form-check-label), .formBox .autocomplete{position: relative; margin-bottom: 15px; display: block;}
    .formBox label:not(.form-check-label) span, .formBox .autocomplete span{display: block; padding-left: 40px;}
    .formBox label:not(.form-check-label) span:before, .formBox .autocomplete span:before{content: ''; display: block; width: 16px; height: 16px; background-size: 16px auto; position: absolute; left: 12px;}
    .formBox label.noIcon .form{padding-left: 15px;}
    .formBox label.noIcon span:before{display: none;}
    .formBox label span img{width: 30px; margin-right: 10px;}
    .formBox label:not(.noIcon) span img{margin-left: -30px; position: relative; z-index: 10;}
    .formBox input, .formBox select{margin-bottom: 0;}
    .formBox .formDisabled{border-color: #eee; color: #999; cursor: not-allowed; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; display: flex !important; align-items: center;}
    .formBox .formDisabled span{line-height: 14px; padding-left: 0 !important;}
    .formBox .formDisabled small{font-size: 12px; line-height: 12px;}
    
    .formBox .d-flex{justify-content: space-between; align-items: center;}
    .formBox .btn-primary{padding: 0 20px;}

    .modal-noConfirm h2{color: var(--color-pending); margin-bottom: 25px;}
    .modal-noConfirm label{display: block; margin-bottom: 5px; font-size: 12px;}
    .modal-noConfirm .form{height: 80px; line-height: 20px; padding: 10px 14px;}
    .box-success{text-align: center;}

    .errorsForm{max-width: 400px; background: rgba(216, 25, 25, 0.1); padding: 15px 20px; margin-bottom: 30px; border-radius: 5px;}
    .errorsForm p{color: var(--color-cancel); font-size: 14px;}
    .errorsForm p:not(:first-child){margin-bottom: 0;}

    .signature label{text-align: center;}
    .signature label span{padding-left: 0 !important; margin-top: 5px;}
    .signature label span:before{display: none}
    .signature label.formDateToday{margin-bottom: 20px;}
    .signature label.formSignature span{font-family: var(--font-signature); font-size: 20px; line-height: 24px; padding: 5px 0; font-weight: 600; color: blue; border-bottom: 2px solid #eee;}

    .btn-primary.load{background: url(~@/assets/images/icons/loading-white.svg) no-repeat center var(--color-secondary); background-size: auto 80%;}
    .btn-primary.load:after{display: none;}

    #btnRegister, .btn-primary.load{margin-bottom: 40px;}
</style>