<template>
    <ion-app :scroll-events="true" class="home-logged">
        <ion-page>

            <!-- LOAD -->
            <div class="load-home" v-if="load">
                <div class="load-green"><span></span></div>
            </div>


            <!-- HEADER -->
            <ion-header class="header" v-if="showContent">
                <div class="header-row" :class="{develop: VUE_APP_DEVELOP == 'develop'}">
                    <div class="logo-balances">
                        <div class="logo-circle">
                            <img src="@/assets/images/dne.svg">
                        </div>
                    </div>
                    
                    <div class="login-register">
                        <p>
                            <strong @click="loginHome('platform/home')">{{ getMyLanguage("login", "login.hello.msg") }}</strong>
                            &nbsp;{{ getMyLanguage("login", "login.hello.or") }}&nbsp;
                            <strong @click="lnkRegister()">{{ getMyLanguage("login", "login.hello.register") }}</strong>
                        </p>
                    </div>

                    <div class="selectLanguage">
                        <SelectLanguage />
                    </div>
                </div>
            </ion-header>


            <!-- CONTENT -->
            <ion-content class="ion-padding">
                <div class="content" v-if="showContent">
                    <div class="banner-home">
                        <div class="cont-banner">
                            <h2 class="title-primary">DNE <span>T</span>oken</h2>
                            <p>{{ getMyLanguage("home", "home.content.banner") }}</p>
                            <div @click="loginHome('international/token')" class="btn-primary">{{ getMyLanguage("home", "home.content.banner.cta") }}</div>
                        </div>
                        <img src="@/assets/images/home/banner-dne-token-purchase.jpg">
                    </div>


                    <div class="home-content">
                        <div @click="loginHome('international/activity')" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-dne-token.svg">
                                <h4><span>I</span>nternational</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>
                        
                        <div @click="loginHome('dnemarket')" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-market.svg">
                                <h4><span>M</span>arket</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>

                        <div @click="loginHome('dnepay/activities')" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-pay.svg">
                                <h4><span>P</span>ay</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>
                        
                        <div @click="loginHome('dnebank')" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-bank.svg">
                                <h4>{{getMyLanguage("dne-bank","dne-bank.title")}}</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>

                         <div @click="loginHome('dneexchange')" class="lnk-home">
                            <div class="cont-lnk">
                                <img src="@/assets/images/home/home-exchange.svg">
                                <h4><span>E</span>xchange</h4>
                            </div>
                            <img src="@/assets/images/home/lnk.svg" class="size-cicle">
                        </div>
                    </div>

                    <div class="client-referer-invite">
                        <div class="cont">
                            <h4>{{getMyLanguage("client-space","client-space.bonus-invite-title")}}</h4>
                            <p>{{getMyLanguage("client-space","client-space.bonus-invite-txt")}}</p>
                            <div class="btn-secondary btn-url-copy" @click="loginHome('platform/home')">{{getMyLanguage("client-space","client-space.bonus-invite-cta")}}</div>
                        </div>
                    </div>
                </div>
            </ion-content>


            <!-- FOOTER -->
            <div class="footer" :class="{openedMenu: menuFooter}" v-if="showContent">
                <div class="cont-footer">
                    <div class="col-lnks lnks-right">
                        <div @click="loginHome('platform/home')" class="lnk-footer">
                            <img src="@/assets/images/icons/btn-footer-home.svg">
                            <span>{{ getMyLanguage("footer", "footer.lnk.home") }}</span>
                        </div>
                    </div>

                    <div class="col-open-menu">
                        <div id="closeMenu" v-if="menuFooter" @click="menuFooter = false"></div>
                        <div class="lnk-footer open-menu" id="openMenu" v-if="!menuFooter" @click="menuFooter = true">
                            <span>
                                <img src="@/assets/images/dne.svg">
                            </span>
                        </div>
                    </div>
                    
                    <div class="col-lnks lnks-left">            
                        <div @click="loginHome('international/activity')" class="lnk-footer">
                            <img src="@/assets/images/icons/btn-footer-activity.svg">
                            <span>{{ getMyLanguage("footer", "footer.lnk.activity") }}</span>
                        </div>
                    </div>
                </div>
            </div>


            <!-- MENU -->
            <div id="menu" v-if="menuFooter">
                <div class="contmenu ion-padding">
                    <div class="menuNav">
                        <ul class="box navbar-nav navbar-primary">
                            <div class="nav-link" @click="loginHome('international/token')">
                                <img src="@/assets/images/menu/lnk-dne-token.svg">
                                <h6 class="title-primary">DNE <span>T</span>oken</h6>
                            </div>

                            <div class="nav-link" @click="loginHome('dnemarket')">
                                <img src="@/assets/images/menu/lnk-market.svg">
                                <h6 class="title-primary"><span>M</span>arket</h6>
                            </div>

                            <div class="nav-link" @click="loginHome('dnepay/activities')">
                                <img src="@/assets/images/menu/lnk-payments.svg">
                                <h6 class="title-primary"><span>P</span>ay</h6>
                            </div>

                            <div class="nav-link" @click="loginHome('dnebank')">
                                <img src="@/assets/images/menu/lnk-bank.svg">
                                <h6 class="title-primary">{{getMyLanguage("dne-bank","dne-bank.title")}}</h6>
                            </div>

                            <div class="nav-link" @click="loginHome('dneexchange')">
                                <img src="@/assets/images/menu/lnk-exchange.svg">
                                <h6 class="title-primary"><span>E</span>xchange</h6>
                            </div>
                        </ul>

                        <ul class="box navbar-nav navbar-secondary">
                            <div class="nav-link" @click="loginHome('platform/security')">
                                <img src="@/assets/images/menu/lnk-security.svg">
                                <h6>{{ getMyLanguage("footer", "footer.lnk.security") }}</h6>
                            </div>

                            <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/support`" target="_blank" class="nav-link">
                                <img src="@/assets/images/menu/lnk-support.svg">
                                <h6>{{ getMyLanguage("footer", "footer.lnk.support") }}</h6>
                            </a>

                            <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/terms-and-conditions`" target="_blank" class="nav-link">
                                <img src="@/assets/images/menu/lnk-terms-conditions.svg">
                                <h6>{{ getMyLanguage("footer", "footer.lnk.termsConditions") }}</h6>
                            </a>

                            <a :href="`${VUE_APP_PLATFORM_URL}${flagSelected}/privacy`" target="_blank" class="nav-link">
                                <img src="@/assets/images/menu/lnk-privacy-policy.svg">
                                <h6>{{ getMyLanguage("footer", "footer.lnk.privacyPolicy") }}</h6>
                            </a>
                        </ul>
                    </div>
                </div>
            </div>

            <div id="overlayMenu"></div>


            <!-- LOGIN -->
            <ModalLogin :dataSource="login" />

        </ion-page>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonHeader, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";
    
    import SelectLanguage from "@/components/SelectLanguage.vue";
    import ModalLogin from "@/components/ModalLogin.vue";

    import apiInternational from '@/config/apiInternational';

    export default {
        components: {
            IonApp, IonPage, IonHeader, IonContent,
            SelectLanguage,
            ModalLogin
        },

        data () {
            return {
                VUE_APP_PLATFORM_URL: process.env.VUE_APP_PLATFORM_URL,
                VUE_APP_DEVELOP: process.env.VUE_APP_DEVELOP,
                flagSelected: "en",
                showContent: false,
                load: true,
                menuFooter: false,

                login: {
                    modal: false,
                    slug: ""
                }
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            loginHome(link){
                this.login.slug = link
                this.login.modal = true
            },

            lnkRegister(){
                window.location.href = `/${this.flagSelected}/platform/register`
            }
        },

        async mounted () {
            this.flagUrl();

            const resumePhase = await apiInternational.get('api/v2/ico/phase/current')
            localStorage.setItem("phaseCurrent",resumePhase.data.phase_type)

            setTimeout(() => {
                this.showContent = true
            }, 500);
            
            setTimeout(() => {
                this.load = false
            }, 3000);
        },
    }
</script>

<style scoped src="@/assets/css/Header.css" />
<style scoped src="@/assets/css/Footer.css" />

<style scoped>
    ion-content{--background: none;}

    .load-home{position: fixed; z-index: 999; top: 0; left: 0; width: 100%; height: 100%;}
    .load-home:after{content: ""; display: block; position: absolute; z-index: 50; top: 0; left: 0; width: 100%; height: 100%; opacity: 0; background: url("@/assets/images/bg-mobile.png") no-repeat center; background-size: cover; -webkit-animation: bgLoadHome 2.8s alternate;}
    .load-home .load-green{width: 180px; height: 180px; margin: 0; position: absolute; z-index: 90; top: 50%; left: 50%; transform: scale(1.2) translate(-42%, -42%); opacity: 0; -webkit-animation: loading 2.8s alternate;}
    .load-home .load-green span{display: block !important; width: 100%; height: 100%; background: url("@/assets/images/dne.svg") no-repeat center; background-size: 60px auto;}

    
    @keyframes bgLoadHome {
        0% {opacity: 1; background-size: 100% 250%;}
        80% {opacity: 1; background-size: 100% 100%;}
        100% {opacity: 0; background-size: cover;}
    }

    @keyframes loading {
        0% {transform: scale(1) translate(-50%, -50%); opacity: 1;}
        60% {transform: scale(1) translate(-50%, -50%); opacity: 1;}
        100% {transform: scale(1.2) translate(-42%, -42%); opacity: 0;}
    }


    /* FOOTER */
    .col-lnks.lnks-left{justify-content: left; padding-left: 20px;}
    .col-lnks.lnks-right{justify-content: right; padding-right: 20px;}
</style>