<template>
    <div class="balances">

        <div class="load-green" v-if="sendModal.load"></div>

        <div class="box-error" v-if="errorsGet.sendModal != false">
            <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
            <p>{{ getMyLanguage("box-error","msg-error") }}</p>
        </div>

        <div class="send-content" v-if="dataWallets && !sendModal.load && !errorsGet.sendModal">
            <div class="box">  

                <!-- SELECT WALLET FROM -->
                <div class="select-wallet">
                    <!-- <div v-for="(walletsFrom, index) in dataWallets" :key="index"> -->
                        <h3 class="title-secondary">{{getMyLanguage("dnePay","send.title")}} {{dataWallets.asset_name}}</h3>
                        <div class="form-group-radio" v-for="(walletsAccounts, indexAccount) in dataWallets.source_wallets" :key="indexAccount">
                            <input type="radio" class="form-radio" name="selectWallet" :id="walletsAccounts.account_wallet_id" @click="walletFrom(walletsAccounts,dataWallets.asset_short)" :checked='(walletsAccounts.account_wallet_id === sendModal.account_wallet_id)'>
                            <label :for="walletsAccounts.account_wallet_id" class="form-radio-label">
                                <div>
                                    <span>{{walletsAccounts.wallet_name}}</span>
                                    
                                    <strong v-if='dataWallets.asset_short != "DNE"' :class="dataWallets.asset_short">
                                        {{walletsAccounts.amount.toLocaleString("pt-PT", {style:"currency", currency:dataWallets.asset_short})}}
                                    </strong>
                                    
                                    <strong v-if='dataWallets.asset_short === "DNE"' :class="dataWallets.asset_short">
                                        <span>{{walletsAccounts.balance.toLocaleString("pt-PT", {maximumFractionDigits: 4})}}</span>
                                        <small>{{walletsAccounts.amount.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</small>
                                    </strong>
                                </div>
                            </label>
                        </div>
                    <!-- </div> -->
                </div>


                <!-- DESTINY -->
                <div class="destiny">
                    <div class="bts-open-destinations" v-if="!destinations.show && !flowSend.destinySelected">
                        <div class="btn-primary openDestinations" @click="destinations.show = true">
                            <span>{{getMyLanguage("wallets","modal-send.select-destiny")}}</span>
                        </div>

                        <div class="btn-scan" @click="scanQRCode = true"></div>
                    </div>
                    
                    <div class="destinySelected" v-if="flowSend.destinySelected" @click="destinations.show = true">
                        <label>{{getMyLanguage("wallets","modal-send.destiny")}}</label>
                        <div class="destinyOption active">
                            <i></i>
                            <p>{{sendModal.wallet_name}} <small>{{sendModal.wallet_address}}</small></p>
                        </div>
                    </div>

                    <div class="destinations box" :class="{validatePhone: !dataProfile.profile_phone_number_verified}" v-if="destinations.show">
                        <div class="close-modal" v-if='modal.status != "success"' @click="destinations.show = false"></div>
                        <div class="close-modal" v-if='modal.status == "success"' @click="lnkActivities()"></div>
                        <div class="scroll-vertical">
                            <div class="destinyOption" :class="{active: listDestiny.WalletId === send.destinyWalletId}" v-for="(listDestiny, index) in sendModal.destinationsWallets" :key="index" @click="(sendDestinyName(listDestiny.account_wallet_id)),(destinations.show = false)">
                                <i></i>
                                <p>{{listDestiny.wallet_name}} <small>{{listDestiny.wallet_address}}</small></p>
                            </div>

                            <div class="box no-wallet-found" v-if="!sendModal.destinationsWallets.length">{{getMyLanguage("wallets","modal-send.no-wallet-found")}}</div>
                        </div>

                        <div class="add">
                            <div class="btn-add" @click="registerNewDestiny(sendModal.assetType, sendModal.assetShort)"><span>{{getMyLanguage("wallets","modal-send.add-new-destiny")}}</span></div>
                        </div>
                    </div>

                    <div class="overlayDestinations" v-if="destinations.show"></div>
                </div>


                <!-- VALUE SEND -->
                <div class="value-send">
                    <label class="lbl-value">
                        <div>
                            <span>{{getMyLanguage("wallets","modal-send.value")}}</span>
                            <div class="alert-value" v-if="flowSend.alertValueMax">{{getMyLanguage("wallets","modal-send.maximum-value")}} {{this.send.amount}}</div>
                            <div class="alert-value" v-if="flowSend.alertValueMin">{{getMyLanguage("wallets","modal-send.minimum-value")}} {{this.send.amount}}</div>
                        </div>

                        <input type="number" class="form" v-model="sendModal.value" @keyup="sendAmount()" @blur="sendAmountMax()" placeholder="0">
                    </label>
                </div>
                

                <!-- AMOUNT -->
                <div class="amount">
                    <table>
                        <tr>
                            <td>{{getMyLanguage("wallets","modal-send.grossTotal")}}</td>
                            <td>{{sendModal.gross.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                        </tr>
                        <tr v-if="sendModal.withdrawalFee > 0">
                            <td>{{getMyLanguage("wallets","modal-send.fee")}} +{{sendModal.withdrawalFee.toLocaleString("pt-PT", { maximumFractionDigits: 2 })}}%</td>
                            <td>{{sendModal.feeValue.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                        </tr>
                        <tr v-if="sendModal.withdrawalTax > 0">
                            <td>{{getMyLanguage("wallets","modal-send.tax")}} {{sendModal.withdrawalTax}}%</td>
                            <td>{{sendModal.taxValue.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}</td>
                        </tr>
                    </table>
                    
                    <div class="amount-value">
                        <span>{{getMyLanguage("wallets","modal-send.netTotal")}}</span>

                        <strong :class="sendModal.assetShort" v-if='sendModal.assetShort === "DNE"'><span>{{sendModal.net.toLocaleString("pt-PT", { maximumFractionDigits: 4 })}}</span></strong>
                        <strong :class="sendModal.assetShort" v-if='sendModal.assetShort === "EUR"'><span>{{sendModal.net.toLocaleString("pt-PT", {style:"currency", currency:"EUR"})}}</span></strong>
                    </div>

                    <div class="btn-primary btn-confirm btn-disable" v-if='!flowSend.showBtnConfirm || !flowSend.destinySelected || flowSend.uploadReceipt'>{{getMyLanguage("dnePay","review")}}</div>
                    <div class="btn-primary btn-confirm" v-if="flowSend.showBtnConfirm && flowSend.destinySelected != false && !flowSend.uploadReceipt" @click="reviewWithdrawal()">{{getMyLanguage("dnePay","review")}}</div>
                </div>
            </div>
        </div>
        



        <!-- MODAL SEND CONFIRM -->
        <div class="modal modal-send-confirm" :class="{validatePhone: !dataProfile.profile_phone_number_verified}" v-if="modal.reviewWithdrawal">
            <div class="modal-cont">
                <div class="close-modal" v-if='!flowNewDestiny.successRegister && errorsPost.withdraw != "400"' @click="modal.reviewWithdrawal = false"></div>
                <div class="close-modal" v-if='flowNewDestiny.successRegister || errorsPost.withdraw == "400"' @click="modal.reviewWithdrawal = false"></div>
                <div class="close-modal" v-if='flowSend.successWithdrawn' @click="successWithdrawn()"></div>
                <div class="box">
                    <div class="scroll-modal">
                        <div class="send-dne">
                            <h3 class="title-secondary">{{getMyLanguage("wallets","modal-send-confirm.title")}}</h3>

                            <div class="block-send">
                                <!-- RESUME WITHDRAW -->
                                <div class="resume" v-if="!errorsPost.withdraw && !flowSend.successWithdrawn">
                                    <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.asset")}}: </strong><span>{{}}{{getMyLanguage("wallets","modal-send.asset." + sendModal.assetType)}}</span></div>
                                    <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.destiny")}}: </strong><span>{{sendModal.wallet_name}}</span></div>
                                    <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.amount")}}: </strong><span>{{send.amount.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}}</span></div>
                                    <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.value")}}: </strong><span>{{sendModal.net.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}} <small>(+{{sendModal.withdrawalFee.toLocaleString("pt-PT", { maximumFractionDigits: 2 })}}% > Fee {{sendModal.feeValue.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}})</small></span></div>
                                </div>

                                <!-- SMS CODE -->
                                <div class="sms-code" v-if="!errorsPost.withdraw && !flowSend.successWithdrawn">
                                    <div class="btn-primary getCode" v-if="!flowSend._2fa_tokenShow && !flowSend.load" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-generate")}}</div>
                                    <div class="load" v-if="!flowSend._2fa_tokenShow && flowSend.load"></div>

                                    <div class="sms-confirm" v-if="flowSend._2fa_tokenShow && !flowSend.load">
                                        <input type="text" class="form form-_2fa_token" inputmode="numeric" v-model="send._2fa_token" v-mask="'######'" placeholder="______">
                                        <div class="confirm btn-disable" v-if="send._2fa_token.length < 6">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                        <div class="confirm" v-if="send._2fa_token.length === 6" @click="confirmWithdraw(sendModal.account_wallet_id,send.destinyWalletId,sendModal.assetShort,sendModal.value)">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                    </div>
                                    
                                    <div class="alert" v-if="flowSend._2fa_tokenShow && !flowSend.load">{{getMyLanguage("wallets","modal-send-code.msg-sms")}} {{dataProfile.profile_phone_country_ddi}} {{dataProfile.profile_phone_number}}</div>
                                    <div class="load" v-if="flowSend._2fa_tokenShow && flowSend.load"></div>

                                    <div class="resend" v-if="flowSend._2fa_tokenShow && !flowSend.load">
                                        <div class="btn-resend resend-load" v-if="!flowSend.btnResendSMS">{{getMyLanguage("wallets","modal-send-code.btn-resend-in")}} {{flowSend.countDownResendCode}}s</div>
                                        <div class="btn-resend" v-if="flowSend.btnResendSMS" @click="getCodeSend()">{{getMyLanguage("wallets","modal-send-code.btn-resend")}}</div>
                                    </div>
                                </div>

                                <!-- SUCCESS WITHDRAW -->
                                <div class="success" v-if="flowSend.successWithdrawn">
                                    <h2 class="title-secondary">{{getMyLanguage("wallets","modal-send-success.title")}}</h2>
                                    <p>{{getMyLanguage("wallets","modal-send-success.msg")}}</p>

                                    <div class="resume">
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.asset")}}: </strong><span>{{}}{{getMyLanguage("wallets","modal-send.asset." + sendModal.assetType)}}</span></div>
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.destiny")}}: </strong><span>{{sendModal.wallet_name}}</span></div>
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.amount")}}: </strong><span>{{send.amount.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}}</span></div>
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.value")}}: </strong><span>{{sendModal.net.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}} <small>(+{{sendModal.withdrawalFee.toLocaleString("pt-PT", { maximumFractionDigits: 2 })}}% > {{getMyLanguage("wallets","modal-send.fee")}} {{sendModal.feeValue.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}})</small></span></div>
                                    </div>
                                </div>


                                <!-- ERROR WITHDRAW -->
                                <div class="error" v-if="errorsPost.withdraw">
                                    <h2 class="title-secondary">{{getMyLanguage("wallets","modal-send-error.title")}}</h2>
                                    <p>{{getMyLanguage("wallets","modal-send-error.msg")}}</p>
                                    
                                    <div class="resume">
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.asset")}}: </strong><span>{{}}{{getMyLanguage("wallets","modal-send.asset." + sendModal.assetType)}}</span></div>
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.destiny")}}: </strong><span>{{sendModal.wallet_name}}</span></div>
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.amount")}}: </strong><span>{{send.amount.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}}</span></div>
                                        <div><strong>{{getMyLanguage("wallets","modal-send-confirm.resume.value")}}: </strong><span>{{sendModal.net.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}} <small>(+{{sendModal.withdrawalFee.toLocaleString("pt-PT", { maximumFractionDigits: 2 })}}% > {{getMyLanguage("wallets","modal-send.fee")}} {{sendModal.feeValue.toLocaleString("pt-PT", { minimumFractionDigits: 2, maximumFractionDigits: 4 })}})</small></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="overlayModal" v-if="modal.reviewWithdrawal"></div>



        <!-- MODAL NEW DESTINY -->
        <div class="modal modal-newDestiny" :class="{validatePhone: !dataProfile.profile_phone_number_verified}" v-if="modal.newDestiny">
            <div class="modal-cont">
                <div class="close-modal" @click="(modal.newDestiny = false), (modal.send = true)"></div>
                <div class="box">
                    <div class="scroll-modal">
                        <div class="newDestiny" v-if="!flowNewDestiny.successRegister && !flowNewDestiny.errorRegister">
                            <h2 class="title-secondary">
                                {{getMyLanguage("wallets","modal-new-destiny.title")}}
                                <small>{{getMyLanguage("wallets","modal-new-destiny.type." + flowNewDestiny.type)}}</small>
                            </h2>
                            
                            <div class="asset">
                                <span>{{getMyLanguage("wallets","modal-new-destiny.currency")}}: </span>
                                <strong>{{newDestiny.asset_short}}</strong>
                            </div>

                            <label>
                                {{getMyLanguage("wallets","modal-new-destiny.name-wallet")}}
                                <input type="text" class="form" v-model="newDestiny.name">
                            </label>
                            
                            <label>
                                {{getMyLanguage("wallets","modal-new-destiny." + flowNewDestiny.type + ".address")}}
                                <input type="text" class="form" v-model="newDestiny.address">
                            </label>
                            
                            <label>
                                {{getMyLanguage("wallets","modal-new-destiny." + flowNewDestiny.type + ".memo")}}
                                <input type="text" class="form" v-model="newDestiny.memo">
                            </label>
                        </div>


                        <!-- SMS CODE -->
                        <div class="sms-code" v-if="!flowNewDestiny.successRegister && !flowNewDestiny.errorRegister">

                            <div class="btn-primary getCode btn-disable" v-if="(this.newDestiny.name.length <= 2 || this.newDestiny.address.length <= 2) && !flowNewDestiny._2fa_tokenShow && !flowNewDestiny.load">{{getMyLanguage("wallets","modal-send-code.btn-generate")}}</div>
                            <div class="btn-primary getCode" v-if="(this.newDestiny.name.length > 2 && this.newDestiny.address.length > 2) && !flowNewDestiny._2fa_tokenShow && !flowNewDestiny.load" @click="getCodeNewDestiny()">{{getMyLanguage("wallets","modal-send-code.btn-generate")}}</div>
                            <div class="load" v-if="!flowNewDestiny._2fa_tokenShow && flowNewDestiny.load"></div>

                            <div class="sms-confirm" v-if="flowNewDestiny._2fa_tokenShow && !flowNewDestiny.load">
                                <input type="text" class="form" inputmode="numeric" v-model="newDestiny._2fa_token" v-mask="'######'" placeholder="______">
                                <div class="confirm btn-disable" v-if="newDestiny._2fa_token.length < 6">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                                <div class="confirm" v-if="newDestiny._2fa_token.length === 6" @click="confirmNewDestiny()">{{getMyLanguage("wallets","modal-send-code.btn-confirm")}}</div>
                            </div>
                            <div class="alert" v-if="flowNewDestiny._2fa_tokenShow && !flowNewDestiny.load">{{getMyLanguage("wallets","modal-send-code.msg-sms")}} {{dataProfile.profile_phone_country_ddi}} {{dataProfile.profile_phone_number}}</div>
                            <div class="load" v-if="flowNewDestiny._2fa_tokenShow && flowNewDestiny.load"></div>

                            <div class="resend" v-if="flowNewDestiny._2fa_tokenShow && !flowNewDestiny.load">
                                <div class="btn-resend resend-load" v-if="!flowNewDestiny.btnResendSMS">{{getMyLanguage("wallets","modal-send-code.btn-resend-in")}} {{flowNewDestiny.countDownResendCode}}s</div>
                                <div class="btn-resend" v-if="flowNewDestiny.btnResendSMS" @click="getCodeNewDestiny()">{{getMyLanguage("wallets","modal-send-code.btn-resend")}}</div>
                            </div>
                        </div>

                        <!-- SUCCESS REGISTER DESTINY -->
                        <div class="success" v-if="flowNewDestiny.successRegister">
                            <h2 class="title-secondary">{{getMyLanguage("wallets","modal-new-destiny.success.title")}}</h2>
                            <p>{{getMyLanguage("wallets","modal-new-destiny.success.msg")}}</p>

                            <div class="resume">
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny.currency")}}: </strong><span>{{newDestiny.asset_short}}</span></div>
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny.name-wallet")}}: </strong><span>{{newDestiny.name}}</span></div>
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny." + flowNewDestiny.type + ".address")}}: </strong><span>{{newDestiny.address}}</span></div>
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny." + flowNewDestiny.type + ".memo")}}: </strong><span>{{newDestiny.memo}}</span></div>
                            </div>
                        </div>

                        <!-- ERROR REGISTER DESTINY -->
                        <div class="error" v-if="flowNewDestiny.errorRegister">
                            <h2 class="title-secondary">{{getMyLanguage("wallets","modal-new-destiny.error.title")}}</h2>
                            <p>{{getMyLanguage("wallets","modal-new-destiny.error.msg")}}</p>

                            <div class="resume">
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny.currency")}}: </strong><span>{{newDestiny.asset_short}}</span></div>
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny.name-wallet")}}: </strong><span>{{newDestiny.name}}</span></div>
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny." + flowNewDestiny.type + ".address")}}: </strong><span>{{newDestiny.address}}</span></div>
                                <div><strong>{{getMyLanguage("wallets","modal-new-destiny." + flowNewDestiny.type + ".memo")}}: </strong><span>{{newDestiny.memo}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="overlayModal" v-if="modal.newDestiny" @click="(modal.newDestiny = false), (modal.send = true)"></div> -->
        <div class="overlayModal" v-if="modal.newDestiny"></div>


        <!-- QRCODE NEW DESTINY -->
        <div class="modal-scan" :class="{load: loadCam, validatePhone: !dataProfile.profile_phone_number_verified}" v-if="scanQRCode && !error">
            <div class="header-scan">
                <div class="instructions">Faça a leitura do QR Code da carteira de destino.</div>
                <div class="close-scan" @click="scanQRCode = false"></div>
            </div>

            <qrcode-stream @decode="decode" @init="onInit"></qrcode-stream>
        </div>
        
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";
    import { QrcodeStream } from 'qrcode-reader-vue3'
    import moment from 'moment';
    import {mask} from 'vue-the-mask'
    import apiPay from '@/config/apiPay';
    import { handleError } from '@/services/errorHandler.js'

    import {toastController} from '@ionic/vue';

    export default {
        directives: {mask},
        components:{
            QrcodeStream
        },
        data () {
            return {
                flagSelected: {},
                dataProfile: "",
                showWallets: false,

                parameters: {
                    asset_short: '',
                    account_id: ''
                },

                dataWallets: [],
                
                modal: {
                    load: false,
                    send: false,
                    reviewWithdrawal: false,
                    newDestiny: false,
                    receive: false,
                    history: false
                },

                walletFormSelected: "",
                sendModal: {
                    load: true,
                    value: "",
                    assetType: "",
                    feeValue: 0,
                    taxValue: 0,
                    gross: 0,
                    net: 0,
                    withdrawalFee: 0,
                    withdrawalTax: 0,
                    destinationsWallets: {}
                },            

                send: {
                    receipt: null,
                    sourceWalletId: '',
                    assetId: '',
                    amount: '',
                    _2fa_token: '',
                    destinyWalletId: ''
                },

                flowSend:{
                    load: false,
                    assetShort: '',
                    destinySelected: false,
                    uploadReceipt: false,
                    _2fa_tokenShow: false,
                    successWithdrawn: false,
                    errorWithdrawn: false,
                    alertValue: false,
                    showBtnConfirm: false,
                    showBtnConfirm_2fa_token: false,
                    countDownResendCode: 0
                },

                flowNewDestiny:{
                    load: false,
                    _2fa_tokenShow: false,
                    successRegister: false,
                    errorRegister: false,
                    showBtnGenerateCode: false,
                    showBtnConfirm: false,
                    showBtnConfirm_2fa_token: false,
                    countDownResendCode: 0
                },

                loadCam: true,

                scanQRCode: false,
                dataResume: "",
                error: false,

                receiveModal:[],

                flowReceive:{
                    load: false
                },

                historyModal: [],
                historyModalDetails: {
                    show: false
                },

                newDestiny: {},

                destinations:{
                    show: false,
                },

                errorsPost: {
                    withdraw: ""
                },

                errorsGet: {
                    sendModal: false
                }
            }
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            redirect(){
                window.location.href = `${this.flagSelected}/dnepay/activities`
            },

            dateTime(value) {
                return moment(value).locale(this.flagSelected).format('ll');
            },

            dateTimeShort(value) {
                return moment(value).locale(this.flagSelected).format('DD/MM');
            },

            startWalletLoad(){
                const urlParams = new URLSearchParams(window.location.search);
                this.parameters.asset_short = urlParams.get('asset-short')
                this.parameters.account_wallet_id = urlParams.get('account-wallet-id')
                
                if ( !this.parameters.asset_short ){
                    this.parameters.asset_short = "DNE"
                }

                this.walletsLoad()
            },

            async walletsLoad(){
                await apiPay.get(`/api/v1/pay/wallet/balances/${this.parameters.asset_short}`)
                .then(response => {
                    this.dataWallets = response.data
                    this.dataWallets.source_wallets = this.dataWallets.source_wallets.filter(canWithdrawal => canWithdrawal.wallet_can_withdrawal === true)
                    // console.log(this.dataWallets.source_wallets)
                    // this.dataWallets.source_wallets = response.data.source_wallets.filter(canWithdrawal => canWithdrawal.wallet_can_withdrawal === true)
                    
                    setTimeout(() => {
                        this.showWallets = true
                        this.showContent = true

                        if (this.parameters.account_wallet_id){
                            this.sendModal.account_wallet_id = this.parameters.account_wallet_id
                            this.sendModal.balanceAvailable = this.dataWallets.source_wallets.filter(walletId => walletId.account_wallet_id === this.parameters.account_wallet_id)[0].balance
                            
                        } else{
                            this.sendModal.account_wallet_id = this.dataWallets.source_wallets[0].account_wallet_id
                            this.sendModal.balanceAvailable = this.dataWallets.source_wallets[0].quantity
                        }

                        // this.sendModal.assetShort = this.dataWallets.asset_short
                        this.sendModal.assetShort = this.parameters.asset_short
                        this.sendModal.feeValue = 0
                        this.sendModal.taxValue = 0
                        this.sendModal.withdrawalTax = 0
                        this.sendModal.gross = 0
                        this.sendModal.net = 0
                        this.sendModal.value = 0
                        this.sendModal.assetType = this.dataWallets.asset_type
                        
                        this.flowSend.destinySelected = false
                        this.sendModal.wallet_name = ""
                        this.sendModal.wallet_address = ""
                        this.send.destinyWalletId = ""

                        this.sendModal.destinationsWallets = this.dataWallets.destiny_wallets
                        
                        this.sendModal.load = false

                        // TEMP
                        // if (this.sendModal.assetShort == "DNE"){
                        //     this.sendModal.assetType = 'crypto'
                        // } else{
                        //     this.sendModal.assetType = 'fiat'
                        // }
                        
                    }, 100);
                })
                .catch(error => {
                    handleError(error, this.flagSelected);
                })
            },

            walletFrom(wallet,short){
                this.sendModal.account_wallet_id = wallet.account_wallet_id
                this.sendModal.assetShort = short
                this.sendModal.balanceAvailable = wallet.quantity
                this.sendModal.feeValue = 0
                this.sendModal.taxValue = 0
                this.sendModal.withdrawalTax = 0
                this.sendModal.net = 0
                this.sendModal.value = 0

                // TEMP
                if (this.sendModal.assetShort == "DNE"){
                    this.sendModal.assetType = 'crypto'
                } else{
                    this.sendModal.assetType = 'fiat'
                }
            },

            async destinationsLoad(){
                await apiPay.get(`/api/v1/pay/wallet/balances/${this.parameters.asset_short}`)
                .then(response => {
                    this.sendModal.destinationsWallets = response.data.wallets
                })
                .catch(error => {
                    this.errorsGet.sendModal = error.response.status
                })
            },

            sendDestinyName(value){
                this.flowSend.destinySelected = true
                this.sendModal.wallet_name = this.sendModal.destinationsWallets.filter(destiny => destiny.account_wallet_id === value)[0].wallet_name
                this.sendModal.wallet_address = this.sendModal.destinationsWallets.filter(destiny => destiny.account_wallet_id === value)[0].wallet_address
                this.send.destinyWalletId = value
            },

            sendAmount(){
                this.sendModal.feeValue = this.sendModal.value * this.sendModal.withdrawalFee / 100
                this.sendModal.taxValue = this.sendModal.value * this.sendModal.withdrawalTax / 100
                this.sendModal.net = this.sendModal.value + this.sendModal.feeValue + this.sendModal.taxValue
                this.sendModal.gross = this.sendModal.value
                this.send.amount = this.sendModal.value

                this.sendModal.minWithdrawal = 30

                if( this.sendModal.value < this.sendModal.minWithdrawal || this.sendModal.value === "" )
                {
                    this.flowSend.showBtnConfirm = false
                }else{
                    this.flowSend.showBtnConfirm = true
                }
            },

            sendAmountMax(){
                setTimeout(() => {

                    if( this.sendModal.net >= this.sendModal.balanceAvailable ){
                        this.flowSend.alertValueMax = true

                        this.sendModal.value = this.sendModal.balanceAvailable - (this.sendModal.balanceAvailable * this.sendModal.withdrawalFee / 100) - (this.sendModal.balanceAvailable * this.sendModal.withdrawalTax / 100)
                        this.sendModal.feeValue = this.sendModal.balanceAvailable * this.sendModal.withdrawalFee / 100
                        this.sendModal.taxValue = this.sendModal.balanceAvailable * this.sendModal.withdrawalTax / 100
                        this.sendModal.net = this.sendModal.value + this.sendModal.feeValue + this.sendModal.taxValue
                        this.sendModal.gross = this.sendModal.value

                        this.send.amount = this.sendModal.value

                        setTimeout(() => {
                            this.flowSend.alertValueMax = false
                        }, 2000);
                    }
                }, 1);



                if(this.sendModal.value < this.sendModal.minWithdrawal){
                    this.flowSend.alertValueMin = true

                    this.sendModal.value = this.sendModal.minWithdrawal

                    this.sendModal.feeValue = this.sendModal.minWithdrawal * this.sendModal.withdrawalFee / 100
                    this.sendModal.taxValue = this.sendModal.minWithdrawal * this.sendModal.withdrawalTax / 100
                    this.sendModal.net = this.sendModal.minWithdrawal + this.sendModal.feeValue + this.sendModal.taxValue
                    this.sendModal.gross = this.sendModal.minWithdrawal + this.sendModal.feeValue + this.sendModal.taxValue
                    this.send.amount = this.sendModal.value
                    this.flowSend.showBtnConfirm = true

                    setTimeout(() => {
                        this.flowSend.alertValueMin = false
                    }, 2000);
                }
            },

            reviewWithdrawal(){
                this.modal.send = false
                this.modal.reviewWithdrawal = true
                this.flowSend.load = false
            },

            getCodeSend(){
                // post para o getCode

                // then
                
                this.flowSend._2fa_tokenShow = false
                this.flowSend.load = true
                
                setTimeout(() => {
                    this.flowSend._2fa_tokenShow = true
                    this.flowSend.load = false
                    this.flowSend.btnResendSMS = false
                    this.flowSend.countDownResendCode = 10
                    this.countDownFlowSend()
                }, 1000);

                
            },

            countDownFlowSend() {

                if(this.flowSend.countDownResendCode > 0) {
                    setTimeout(() => {
                        this.flowSend.countDownResendCode -= 1
                        this.countDownFlowSend()
                    }, 1000)
                } else{
                    this.flowSend.btnResendSMS = true
                }
            },

            uploadFileWithdraw() {
                this.send.receipt = this.$refs.file.files[0];
                this.flowSend.uploadReceipt = false
                // console.log(this.send.receipt)
            },

            confirmWithdraw(source_account_wallet_id, destiny_account_wallet_id, asset_short, quantity){
                // console.log(source_account_wallet_id)
                // console.log(destiny_account_wallet_id)
                // console.log(asset_short)
                // console.log(quantity)
                this.flowSend.load = true

                apiPay.post('/api/v1/pay/tx/withdraw', {"source_account_wallet_id": source_account_wallet_id, "destiny_account_wallet_id": destiny_account_wallet_id, "asset_short": asset_short, "quantity": quantity})
                .then(response => {
                    this.registerId = response.data.id
                    this.flowSend.load = false
                    this.flowSend.successWithdrawn = true

                    // this.updateBalance()
                })
                .catch(error => {
                    this.errorsPost.withdraw = error.response.status
                    this.flowSend.load = false
                })
            },

            async updateBalance(){
                const resumeWallets = await apiPay.get(`/api/v1/pay/wallet/balances/${this.parameters.asset_short}`)
                this.dataWallets = resumeWallets.data
            },

            successWithdrawn(){
                this.sendModal.feeValue = 0
                this.sendModal.taxValue = 0
                this.sendModal.withdrawalTax = 0
                this.sendModal.gross = 0
                this.sendModal.net = 0
                this.sendModal.value = 0

                this.flowSend.destinySelected = false
                this.sendModal.wallet_name = ""
                this.sendModal.wallet_address = ""
                this.send.destinyWalletId = ""

                this.modal.reviewWithdrawal = false
            },
            
            
            getCodeNewDestiny(){
                // post para o getCode

                // then
                
                this.flowNewDestiny._2fa_tokenShow = false
                this.flowNewDestiny.load = true
                
                setTimeout(() => {
                    this.flowNewDestiny._2fa_tokenShow = true
                    this.flowNewDestiny.load = false
                    this.flowNewDestiny.btnResendSMS = false
                    this.flowNewDestiny.countDownResendCode = 10
                    this.countDownFlowNewDestiny()
                }, 1000);
            },

            countDownFlowNewDestiny() {

                if(this.flowNewDestiny.countDownResendCode > 0) {
                    setTimeout(() => {
                        this.flowNewDestiny.countDownResendCode -= 1
                        this.countDownFlowNewDestiny()
                    }, 1000)
                } else{
                    this.flowNewDestiny.btnResendSMS = true
                }
            },

            registerNewDestiny(type, assetShort){
                this.flowNewDestiny._2fa_tokenShow = false
                this.flowNewDestiny.successRegister = false
                this.flowNewDestiny.errorRegister = false
                // this.flowNewDestiny.showBtnConfirm = false

                if (this.dataResume){
                    this.newDestiny = {
                        name: this.dataResume.wallet_name,
                        address: this.dataResume.wallet_address,
                        memo: "",
                        _2fa_token: ""
                    }
                } else{
                    this.newDestiny = {
                        name: "",
                        address: "",
                        memo: "",
                        _2fa_token: ""
                    }
                }


                this.modal.newDestiny = true
                this.modal.send = false
                
                // this.flowNewDestiny.type = type
                this.newDestiny.asset_short = assetShort
                
                // TEMP
                console.log(type)
                if (assetShort == "DNE"){
                    this.flowNewDestiny.type = 'crypto'
                } else{
                    this.flowNewDestiny.type = 'fiat'
                }
            },


            confirmNewDestiny(){
                console.log(this.newDestiny)
                
                apiPay.post('/api/v1/pay/wallet/address/trust', this.newDestiny)
                .then(response => {
                    this.registerId = response.data.id
                    this.flowNewDestiny.load = false
                    this.flowNewDestiny.successRegister = true

                    this.destinationsLoad()
                })
                .catch(error => {
                    this.flowNewDestiny.load = false
                    this.flowNewDestiny.errorRegister = true
                    console.log(error)
                })
            },


            decode(decodeString){
                this.scanQRCode = false

                this.dataResume = JSON.parse(decodeString)

                if ( this.dataResume.account_wallet_id == null || !this.dataResume.wallet_can_deposit ){
                    this.errorToastQrCode()
                } else {
                    if ( this.dataWallets.destiny_wallets.filter(hasAddress => hasAddress.wallet_address == this.dataResume.wallet_address)[0] != undefined ){
                        this.sendDestinyName(this.dataWallets.destiny_wallets.filter(hasAddress => hasAddress.wallet_address == this.dataResume.wallet_address)[0].account_wallet_id)
                        this.destinations.show = false
                    } else{
                        this.registerNewDestiny(this.sendModal.assetType, this.dataResume.asset_short)
                    }
                }
            },

            async onInit (promise) {
                setTimeout(() => {
                    this.loadCam = false
                }, 1000);

                setTimeout(() => {
                    if ( this.dataResume == "" && this.scanQRCode ){
                        this.errorToastQrCode()
                        this.scanQRCode = false
                    }
                }, 30000);

                try {
                    await promise
                } catch (error) {
                    if (error.name === 'NotAllowedError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.NotAllowedError")
                    } else if (error.name === 'NotFoundError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.NotFoundError")
                    } else if (error.name === 'NotSupportedError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.NotSupportedError")
                    } else if (error.name === 'NotReadableError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.NotReadableError")
                    } else if (error.name === 'OverconstrainedError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.OverconstrainedError")
                    } else if (error.name === 'StreamApiNotSupportedError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.StreamApiNotSupportedError")
                    } else if (error.name === 'InsecureContextError') {
                        this.error = this.getMyLanguage("dnePay","pay.scan.error.InsecureContextError")
                    } else {
                        this.error = `ERROR: Camera error (${error.name})`;
                    }
                }
            },

            async errorToastQrCode() {
                const toast = await toastController
                    .create({
                        message: this.getMyLanguage("dnePay","send.toast-qrcode-error"),
                        color: "warning",
                        duration: 6000,
                    })
                return toast.present();
            },
        },

        async mounted () {
            this.flagUrl()
            this.startWalletLoad()
            
            this.dataProfile = JSON.parse(localStorage.getItem("profile"))


            // this.openSend("EUR","commission")
        }
    }
</script>


<style scoped src="@/assets/css/Balances.css" />

<style scoped>
    .send-content{-webkit-animation: showTopScale .4s alternate;}
    .send-content .select-wallet{margin-bottom: 20px;}
    .send-content .select-wallet .form-group-radio{margin-bottom: 10px;}
    .send-content .select-wallet .form-group-radio .form-radio-label{background: #f5f5f5; width: 100%; padding: 10px 10px 10px 42px; border-radius: 12px;}
    .send-content .select-wallet .form-group-radio .form-radio-label:before{left: 10px; top: 10px;}
    .send-content .select-wallet .form-group-radio .form-radio:checked+label{background-color: #e9e9e9;}
    .send-content .select-wallet .form-group-radio .form-radio-label div{width: 100%; display: flex; justify-content: space-between;}
    .send-content .select-wallet .form-group-radio .form-radio-label div > span{font-size: 13px; font-weight: 600; color: var(--color-primary);}
    .send-content .select-wallet .form-group-radio .form-radio-label div > strong{font-size: 12px; font-weight: 600; line-height: 16px; color: var(--color-secondary); text-align: right;}
    .send-content .select-wallet .form-group-radio .form-radio-label div > strong small{display: block; font-size: 11px; font-weight: 400; color: var(--color-text-secondary)}
    .send-content .select-wallet .form-group-radio .form-radio-label div > strong.DNE span{padding-right: 12px; background: url('~@/assets/images/icons/dne-symbol.svg') no-repeat center right; background-size: 10px auto;}

    .send-content .amount{margin-bottom: 0;}
</style>