<template>
    <ion-app :scroll-events="true" class="permission">
        <ion-page>
            <div class="header-step">
                <HeaderApp />
            </div>

            <ion-content class="ion-padding permission-content">
                <div class="content">
                    <div class="load-blue" v-if="!showContent"></div>

                    <div class="container ion-padding" v-if="showContent && (errors.error_404 || errors.error_general)">
                        <div class="box-error">
                            <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                            <p>{{ getMyLanguage("box-error","msg-error") }}</p>
                            <div class="cod-error">{{ getMyLanguage("box-error",errors.error_msg) }}</div>
                        </div>
                    </div>

                    <div class="container" v-if="showContent && (!errors.error_404 && !errors.error_general && !errors.error_blockPurchase)">
                        <h2 class="title-secondary">{{getMyLanguage("compliance","compliance.title")}}</h2>
                        
                        <div class="box-compliance">
                            <div class="msg msg-success" v-html='getMyLanguage("compliance","compliance.msg")'></div>
                            

                            <!-- PERSONAL INFORMATION -->
                            <div class="box-registration" id="errorAnc">
                                <div class="formBox">

                                    <h3 class="subtitle">{{getMyLanguage("compliance","compliance.dnePlatform.information")}}</h3>
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <label class="formFullName">
                                                {{getMyLanguage("compliance","compliance.lbl.fullName")}}
                                                <span class="form formDisabled">{{dataAccount.fullName}}</span>
                                            </label>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="formYourEmail">
                                                {{getMyLanguage("compliance","compliance.lbl.email")}}
                                                <span class="form formDisabled">{{dataAccount.email}}</span>
                                            </label>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="formPhone">
                                                {{getMyLanguage("compliance","compliance.lbl.phone")}}
                                                <span class="form formDisabled">{{dataAccount.phoneNumber}}</span>
                                            </label>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="formResidenceCountry">
                                                {{getMyLanguage("compliance","compliance.lbl.country")}}
                                                <span class="form formDisabled">
                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${dataAccount.countryFlag}/flags`" class="flag">
                                                    {{dataAccount.countryName}}
                                                </span>
                                            </label>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="formTaxNumber" :class="{required: errorsValidate.taxNumber}">
                                                {{getMyLanguage("compliance","compliance.lbl.taxNumber")}}
                                                <input type="text" class="form" :placeholder='getMyLanguage("compliance","compliance.lbl.taxNumberDoc")' v-model="dataCompliance.taxNumber" @blur="errorsValidate.taxNumber = false">
                                                <small class="error" v-if="errorsValidate.taxNumber">{{ getMyLanguage("errors","errors.required") }}</small>
                                            </label>
                                        </div>
                                        

                                        <!-- ISSUER COUNTRY -->
                                        <div class="col-12 col-lg-6">
                                            <div class="autocomplete formIssuerCountry">
                                                <label :class="{required: errorsValidate.taxNumberIssuerCountry}">
                                                    {{getMyLanguage("compliance","compliance.lbl.IssuerCountry")}}

                                                    <div class="openAutocomplete" v-if="!listCountryStatus" @click="listCountry(true)"></div>
                                                    <div class="closeAutocomplete closeDropListCountry" v-if="listCountryStatus" @click="listCountry(false)"></div>

                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlag}/flags`" class="flag-selected" v-if="selectedCountryFlag != false && selectedCountryFlag != null">
                                                    <input type="text" id="issuerCountry" class="form" :class="{selected: selectedCountryFlag != false}" @keyup="filterListCountry" @blur="listCountry(false)" :value="selectedCountry.name" :placeholder='getMyLanguage("compliance","compliance.lbl.selectCountry")' :title='getMyLanguage("compliance","compliance.lbl.IssuerCountry")' autocomplete="off">

                                                    <ul id="drop-list" v-if="listCountryStatus">
                                                        <li v-for="(itemList,index) in dataCountry" :key="index" @click="changeTaxnumberIssuerCountry(dataCompliance.taxNumberIssuerCountry = itemList.Id, selectedCountry.name = itemList.Name, selectedCountryFlag = itemList.Flag)">
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.Flag}/flags`">
                                                            <a>{{itemList.Name}}</a>
                                                        </li>
                                                    </ul>

                                                    <small class="error" v-if="errorsValidate.taxNumberIssuerCountry">{{ getMyLanguage("errors","errors.residenceError") }}</small>
                                                </label>
                                            </div>
                                        </div>
                                        <!-- <div class="col-12 col-lg-6">
                                            <div class="autocomplete formIssuerCountry">
                                                <label :class="{required: errorsValidate.taxNumberIssuerCountry}">
                                                    {{getMyLanguage("compliance","compliance.lbl.IssuerCountry")}}

                                                    <div class="openAutocomplete" v-if="!listCountryStatus" @click="listCountryStatus = true"></div>
                                                    <div class="closeAutocomplete closeDropListCountry" v-if="listCountryStatus" @click="listCountryStatus = false"></div>

                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${selectedCountryFlag/flags`" class="flag-selected" v-if="selectedCountryFlag != false">
                                                    <input type="text" id="issuerCountry" class="form" :class="{selected: selectedCountryFlag != false}"  @keyup="filterListCountry" :value="selectedCountry.name" :placeholder='getMyLanguage("compliance","compliance.lbl.selectCountry")' :title='getMyLanguage("compliance","compliance.lbl.IssuerCountry")' autocomplete="off">

                                                    <ul id="drop-list" v-if="listCountryStatus">
                                                        <li v-for="(itemList,index) in dataCountry" :key="index" @click="changeTaxnumberIssuerCountry(dataCompliance.taxNumberIssuerCountry = itemList.id, selectedCountry.name = itemList.name, selectedCountryFlag = itemList.flag)">
                                                            <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${itemList.flag/flags`">
                                                            <a>{{itemList.name}}</a>
                                                        </li>
                                                    </ul>
                                                </label>
                                            </div>
                                        </div> -->
                                        
                                    </div>
                                </div>
                            </div>


                            <!-- IDENTIFICATION DATA -->
                            <div class="box-registration identification-data">
                                <div class="formBox">

                                    <h3 class="subtitle">{{getMyLanguage("compliance","compliance.additionalData")}}</h3>        
                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <label class="formIdNumber">
                                                <span>{{getMyLanguage("compliance","compliance.lbl." + dataAccount.documentType)}}</span>
                                                <span class="form formDisabled">{{dataAccount.documentNumber}}</span>
                                            </label>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="formExpirationDate">
                                                {{getMyLanguage("compliance", "compliance.lbl.validUntil")}}
                                                <span class="form formDisabled">{{ dateTime(dataAccount.documentValidThru) }}</span>
                                            </label>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="formCountry">
                                                {{getMyLanguage("compliance","compliance.lbl.issuing-country")}}
                                                <span class="form formDisabled">
                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${dataAccount.documentIssuerFlag}/flags`" class="flag" v-if="dataAccount.documentIssuerFlag != false && dataAccount.documentIssuerFlag != null">
                                                    {{dataAccount.documentIssuer}}
                                                </span>
                                            </label>
                                        </div>
                                        
                                        <div class="col-12 col-lg-6">
                                            <label class="formPersonDOB">
                                                {{getMyLanguage("profile", "profile.label.birthDate")}}
                                                <span class="form formDisabled">{{ dateTime(dataAccount.dob) }}</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <!-- ADDRESS -->
                            <div class="box-registration address-compliance">
                                <div class="formBox">

                                    <h3 class="subtitle">{{getMyLanguage("compliance","compliance.address")}}</h3>

                                    <div class="row">
                                        <div class="col-12 col-lg-6">
                                            <label class="formAddress01 noIcon">
                                                {{getMyLanguage("compliance","compliance.address.address01")}}
                                                <span class="form formDisabled">
                                                    {{dataAccount.address.address1}}
                                                </span>
                                            </label>
                                        </div>
                                        
                                        <div class="col-12 col-lg-6">
                                            <label class="formAddress02 noIcon">
                                                {{getMyLanguage("compliance","compliance.address.address02")}}
                                                <span class="form formDisabled">
                                                    {{dataAccount.address.address2}}
                                                </span>
                                            </label>
                                        </div>

                                        <div class="col-12 col-lg-6">
                                            <label class="formPostalCode noIcon">
                                                {{getMyLanguage("compliance","compliance.address.postalCode")}}
                                                <span class="form formDisabled">
                                                    {{dataAccount.address.postalCode}}
                                                </span>
                                            </label>
                                        </div>
                                        
                                        <div class="col-12 col-lg-6">
                                            <label class="formCity noIcon">
                                                {{getMyLanguage("compliance","compliance.address.city")}}
                                                <span class="form formDisabled">
                                                    {{dataAccount.address.city}}
                                                </span>
                                            </label>
                                        </div>
                                        
                                        <div class="col-12 col-lg-6">
                                            <label class="formStateProvince noIcon">
                                                {{getMyLanguage("compliance","compliance.address.district")}}
                                                <span class="form formDisabled">
                                                    {{dataAccount.address.district}}
                                                </span>
                                            </label>
                                        </div>
                                        
                                        <div class="col-12 col-lg-6">
                                            <label class="formCountry noIcon">
                                                {{getMyLanguage("compliance","compliance.address.country")}}
                                                <span class="form formDisabled">
                                                    <img :src="`https://imagedelivery.net/-5Lr2B91XIIGgp5YOFIoTg/${dataAccount.address.countryFlag}/flags`" class="flag">
                                                    {{dataAccount.address.country}}
                                                </span>
                                            </label>
                                        </div>

                                        <div class="col-12">
                                            <label class="additional-address-data noIcon">
                                                {{getMyLanguage("compliance","compliance.lbl.additional-address")}}
                                                <span class="cont">{{dataCompliance.addressAdditionalInfo.length}}/250</span>
                                                <textarea class="form" v-model="dataCompliance.addressAdditionalInfo" maxlength="250" :placeholder='getMyLanguage("compliance","compliance.placeholder.additional-address")'></textarea>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div>
                                <div class="msgSupport" v-if='dataCompliance.wrongData != ""'>
                                    <p><strong>{{getMyLanguage("compliance","compliance.modal.noConfirm.title")}}</strong></p>
                                    <p>{{dataCompliance.wrongData}}</p>
                                </div>

                                <div class="accepts" v-if="!errorsPost.dataCompliance">
                                    <div class="form-group-check">
                                        <input type="checkbox" id="acceptTermsLG" class="form-check" v-model="accept.terms">
                                        <label for="acceptTermsLG" class="form-check-label" :class="{error: errorsValidate.acceptTerms}" @click="errorsValidate.acceptTerms = false"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms") }}</small></label>
                                        <a :href="`/${flagSelected}/terms-and-conditions`" target="_blank" class="lnk-terms-group-check">{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-terms.lnk") }}</a>
                                    </div>
                                    
                                    <div class="form-group-check">
                                        <input type="checkbox" id="acceptNotify" class="form-check" v-model="accept.notify">
                                        <label for="acceptNotify" class="form-check-label" :class="{error: errorsValidate.acceptNotify}" @click="errorsValidate.acceptNotify = false"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-notify") }}</small></label>
                                    </div>
                                    
                                    <div class="form-group-check">
                                        <input type="checkbox" id="acceptGDPR" class="form-check" v-model="accept.gdpr">
                                        <label for="acceptGDPR" class="form-check-label" :class="{error: errorsValidate.acceptGdpr}" @click="errorsValidate.acceptGdpr = false"><small>{{ getMyLanguage("purchase-token", "purchase-token.content.box-register.accept-gdpr") }}</small></label>
                                    </div>
                                </div>

                                <div class="errorsForm" v-if="errorsValidate.taxNumber || errorsValidate.taxNumberIssuerCountry || errorsValidate.acceptTerms || errorsValidate.acceptNotify || errorsValidate.acceptGdpr ">
                                    <div>
                                        <p><strong>{{getMyLanguage("compliance","compliance.errorValidateForm")}}</strong></p>
                                        <p v-if="errorsValidate.taxNumber"><small>- {{ getMyLanguage("compliance","compliance.errorTaxNumber") }}</small></p>
                                        <p v-if="errorsValidate.taxNumberIssuerCountry"><small>- {{ getMyLanguage("compliance","compliance.errorIssuerCountry") }}</small></p>
                                        <p v-if="errorsValidate.acceptTerms || errorsValidate.acceptNotify || errorsValidate.acceptGdpr"><small>- {{ getMyLanguage("compliance","compliance.errorAccepts") }}</small></p>
                                    </div>
                                </div>

                                <div class="box-error" v-if="errorsPost.dataCompliance != false">
                                    <h3 class="title-secondary">{{ getMyLanguage("box-error", "title-error") }}</h3>
                                    <p>{{ getMyLanguage("box-error", "msg-error") }}</p>
                                    <div class="cod-error">
                                        Cód.: {{errorsPost.dataCompliance}}
                                    </div>
                                </div>

                                <div class="row" v-if="errorsPost.dataCompliance === false">
                                    <div id="btnRegister" v-if="!accept.terms || !accept.notify || !accept.gdpr" @click="validateForm()" class="btn-primary btn-disable">{{ getMyLanguage("client-space", "instructions.receipt.send") }}</div>
                                    <div id="btnRegister" v-if="accept.terms && accept.notify && accept.gdpr && !load" @click="validateForm()" class="btn-primary">{{ getMyLanguage("client-space", "instructions.receipt.send") }}</div>

                                    <div class="btn-primary load" v-if="load"></div>
                                    
                                    <div class="btn-cancel-compliance">
                                        <div class="btn" @click="modalNoConfirm.modal = true">{{getMyLanguage("compliance","compliance.btn-cancel")}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ion-content>
            <FooterApp />
        </ion-page>


        <!-- MODAL CANCEL COMPLIANCE -->
        <div class="modal modal-noConfirm" v-if="modalNoConfirm.modal">
            <div class="cont-modal">
                <div class="close-modal" @click="modalNoConfirm.modal = false"></div>
                <div class="box">
                    <div class="scroll-modal">

                        <!-- FORM -->
                        <div class="form-content" v-if="!modalNoConfirm.send">
                            <h2 class="title-secondary">{{getMyLanguage("compliance","compliance.modal.noConfirm.title")}}</h2>

                            <label>{{getMyLanguage("compliance","compliance.modal.noConfirm.description-error")}}</label>
                            <textarea class="form" v-model="dataCompliance.wrongData"></textarea>

                            <div class="btnModal">
                                <div class="btn-primary" @click="modalNoConfirm.send = true">{{getMyLanguage("compliance","compliance.modal.noConfirm.btn-send")}}</div>
                            </div>
                        </div>


                        <!-- SUCCESS -->
                        <div class="success" v-if="modalNoConfirm.send">
                            <h2 class="title-secondary">{{getMyLanguage("compliance","compliance.modal.noConfirm.success.title")}}</h2>
                            <div class="box-success">
                                <p v-html='getMyLanguage("compliance","compliance.modal.noConfirm.success.msg")'></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="overlayModal" v-if="modalNoConfirm.modal"></div>
    </ion-app>
</template>

<script>
    import {IonApp, IonPage, IonContent} from '@ionic/vue';
    import getMyFavouriteLanguage from "@/services/language.js";

    import HeaderApp from '@/components/dneplatform/HeaderApp.vue';
    import FooterApp from '@/components/dneplatform/FooterApp.vue';

    import moment from 'moment';

    import apiPlatform from '@/config/apiPlatform.js';
    import apiInternational from '@/config/apiInternational.js';

    import { handleError, error404, errorGeneral, errorMsg, errorBlockPurchase } from '@/services/errorHandler.js'

    export default {
        components: {
            IonApp,IonPage, IonContent,
            HeaderApp,
            FooterApp
        },
        data () {
            return {
                flagSelected: {},
                showContent: false,
                load: false,
                dataAccount: {},

                errors: {
                    errorPost: false,
                    error_404: false,
                    error_general: false,
                    error_blockPurchase: false,
                    error_msg: ""
                },

                errorsGet: {
                    dataAccount: false
                },
                errorsPost: {
                    dataCompliance: false
                },


                accept: {
                    terms: false,
                    notify: false,
                    gdpr: false
                },
                dataCompliance: {
                    wrongData: "",
                    addressAdditionalInfo: "",
                    taxNumberIssuerCountry: ""
                },

                dataCountry: [],
                listCountryStatus: false,
                selectedCountry: {},
                selectedCountryFlag: false,

                modalNoConfirm: {
                    modal: false,
                    load: false,
                    send: false
                },

                errorsValidate: {
                    taxNumber: false,
                    taxNumberIssuerCountry: false
                },

                noConfirm: {
                    error: "Address error",
                    msg: ""
                }
            }
        },
        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            dateTime(value) {
                return moment(value).locale(this.flagSelected).format('ll');
            },

            listCountry(status){
                setTimeout(() => {
                    this.listCountryStatus = status
                }, 250)
                
                if ( status ){
                    setTimeout(() => {
                        document.getElementById('issuerCountry').focus()
                    }, 10);
                }
            },

            filterListCountry() {
                let a = '';
                let i = '';
                let txtValue = '';
                const inputList = document.getElementById("issuerCountry");
                const filter = inputList.value.toUpperCase();
                const ul = document.getElementById("drop-list");
                const li = ul.getElementsByTagName("li");
                for (i = 0; i < li.length; i++) {
                    a = li[i].getElementsByTagName("a")[0];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                            li[i].style.display = "";
                    } else {
                            li[i].style.display = "none";
                    }
                }
            },

            changeTaxnumberIssuerCountry(id,name,flag){
                this.dataCompliance.taxNumberIssuerCountry = id
                this.selectedCountry.name = name
                this.selectedCountryFlag = flag
                this.listCountryStatus = false
            },

            validateForm(){
                if ( this.dataCompliance.taxNumber === "" ){
                    this.errorsValidate.taxNumber = true
                    document.getElementById("errorAnc").scrollIntoView();
                } else{
                    this.errorsValidate.taxNumber = false
                }
                
                if ( this.dataCompliance.taxNumberIssuerCountry === null ){
                    this.errorsValidate.taxNumberIssuerCountry = true
                    document.getElementById("errorAnc").scrollIntoView();
                } else{
                    this.errorsValidate.taxNumberIssuerCountry = false
                }

                if ( !this.accept.terms ){
                    this.errorsValidate.acceptTerms = true
                } else{
                    this.errorsValidate.acceptTerms = false
                }
                
                if ( !this.accept.notify ){
                    this.errorsValidate.acceptNotify = true
                } else{
                    this.errorsValidate.acceptNotify = false
                }
                
                if ( !this.accept.gdpr ){
                    this.errorsValidate.acceptGdpr = true
                } else{
                    this.errorsValidate.acceptGdpr = false
                }
                
                if ( !this.errorsValidate.taxNumber && !this.errorsValidate.taxNumberIssuerCountry && !this.errorsValidate.acceptTerms && !this.errorsValidate.acceptNotify && !this.errorsValidate.acceptGdpr ) {
                    this.register()
                }
            },

            register(){
                this.load = true
                const compliance = this.dataCompliance;

                apiInternational.post('/api/v1/platform/account/international/compliance', compliance)
                .then(response => {
                    this.registerId = response.data.id;
                    window.location.href = `/${this.flagSelected}/account/compliance-success`
                })
                .catch(error => {
                    // this.errorMessage = error.message;

                    this.errorsPost.dataCompliance = error.response.status

                    setTimeout(() => {
                        this.load = false
                    }, 500);

                    setTimeout(() => {
                        this.errorsPost.dataCompliance = false
                    }, 5000);
                })
            },

            // register(){
            //     this.load = true
            //     const compliance = this.dataCompliance.additional;

            //     apiInternational.post('/api/v1/platform/account/international/compliance', compliance)
            //     .then(response => {
            //         this.registerId = response.data.id;
            //         window.location.href = `/${this.flagSelected}/account/compliance-success`
            //     })
            //     .catch(error => {
            //         this.errorMessage = error.message;
            //         this.errorsPost.dataCompliance = error.response.data

            //         setTimeout(() => {
            //             this.load = false
            //         }, 500);
            //     })
            // },

            noConfirmRegister(){
                this.modalNoConfirm.send = true
            }
        },


        async mounted () {
            this.flagUrl()

            await apiInternational.get('/api/v1/platform/account/international/compliance')
            .then(response => {
                this.dataAccount = response.data
                this.dataCompliance.taxNumber = this.dataAccount.taxNumber

                this.dataCompliance.taxNumberIssuerCountry = this.dataAccount.taxNumberIssuerCountryId
                this.selectedCountry.name = this.dataAccount.taxNumberIssuerCountry
                this.selectedCountryFlag = this.dataAccount.taxNumberIssuerCountryFlag

                this.dataCompliance.wrongData = this.dataAccount.wrongData

                if ( this.dataAccount.address.addressAdditionalInfo === null ){
                    this.dataCompliance.addressAdditionalInfo = ""
                } else{
                    this.dataCompliance.addressAdditionalInfo = this.dataAccount.address.addressAdditionalInfo
                }
            })
            .catch(error => {
                setTimeout(() => {
                    this.showContent = true
                }, 500);

                handleError(error, this.flagSelected);
                
                this.errors.error_404 = error404
                this.errors.error_general = errorGeneral
                this.errors.error_blockPurchase = errorBlockPurchase
                this.errors.error_msg = errorMsg
            })

            const resumeClientCountry = await apiPlatform.get('api/v1/platform/country')
            this.dataCountry = resumeClientCountry.data

            this.showContent = true
        }
    }
</script>


<style scoped>
    .msg{padding: 16px; margin-bottom: 20px; background: url('~@/assets/images/bg-25.svg') no-repeat; background-size: cover; border-radius: 12px; text-align: center; font-size: 13px; font-family: var(--font-primary-semibold);}
    .msg.msg-pending{position: relative; z-index: 20; background: url('~@/assets/images/bg-pending.svg') no-repeat; background-size: cover; color: var(--color-text-tertiary);}
    .msg.msg-success{background: rgba(74,174,87,0.1); color: var(--color-primary);}
    .msg p{margin: 0;}
    .msg h3{font-size: 28px;}

    .box-registration{width: 100%; border-radius: 12px; background: var(--color-text-tertiary); margin-top: 30px;}
    .box-registration label{font-size: 13px;}
    .box-registration label.required input{border: 1px solid var(--color-cancel) !important;}
    .box-registration label small.error{color: var(--color-cancel);}

    .box-registration .headerBox{background: var(--color-secondary); padding: 15px 10px; text-align: center; position: relative;}
    .box-registration .headerBox p{color: var(--color-text-tertiary); font-family: var(--font-primary-semibold); margin: 0;}
    .box-registration .headerBox p span{opacity: .8;}
    .box-registration .headerBox:after{content: ''; display: block; position: absolute; left: 50%; bottom: -10px; transform: translateX(-50%); width: 0; height: 0; border-left: 15px solid transparent; border-right: 15px solid transparent; border-top: 10px solid var(--color-secondary);}
    
    .box-registration.address-compliance{padding: 1rem 0; border-radius: 12px;}

    .box-registration.address-compliance.address-input{background: rgba(235, 103, 51 ,0.1);}
    .box-registration.address-compliance.address-input .formBox{padding: 0;}
    .box-registration.address-compliance.address-input h3.subtitle{margin-bottom: 3px;}
    .box-registration.address-compliance.address-input h3.subtitle:after{background: var(--color-pending);}
    .box-registration.address-compliance.address-input .msg-alert{color: var(--color-pending); font-family: var(--font-primary-semibold); margin-bottom: 25px;}

    .box-registration.address-compliance .additional-address-data{padding: 12px; margin-bottom: 0 !important; background: rgba(235, 103, 51 ,0.1); border-radius: 12px; margin-top: 30px;}
    .box-registration.address-compliance .additional-address-data .form{height: 100px; padding: 8px 12px; margin: 10px 0 0 0; line-height: 20px;}

    .cont{position: absolute; right: 12px; top: 16px; font-size: 10px;}
    
    .type-account{display: flex; flex-direction: column; align-items: baseline; margin-top: 10px; padding: 1rem; background: #e5e5e5; border-radius: 12px;}
    .type-account .formGroups{display: flex;}
    .type-account .formGroups .form-group-radio{margin: 0 30px 0 0;}
    .type-account .formGroups .form-group-radio:last-child{margin-right: 0;}
    .type-account .formGroups .form-group-radio label{font-family: var(--font-primary-semibold); margin-bottom: 0 !important;}
    .type-account .formGroups .form-group-radio label small{font-size: 13px;}

    .identification-data label{margin-bottom: 20px !important;}
    .identification-data .lbl-type-account{margin: 0 0 10px 0 !important; font-family: var(--font-primary-regular);}

    .msgSupport{background: rgba(235, 104, 52, 0.8); padding: 15px 20px; margin: 30px 0; border-radius: 5px; font-size: 13px;}
    .msgSupport p{color: var(--color-text-tertiary);}
    .msgSupport p:not(:first-child){margin-bottom: 0;}
    
    .accepts{margin: 30px 0; padding: 5px 10px; background: rgba(255,255,255,0.3); border-radius: 6px;}
    .accepts .form-group-check .form-check+label.error:before{background: var(--color-cancel);}

    .formBox{padding: 20px 15px;}
    .formBox h3{font-size: 16px; color: var(--color-primary); margin: 0 0 20px 0;}
    .formBox h3.subtitle{display: block; padding-bottom: 7px; position: relative;}
    .formBox h3.subtitle:after{content: ''; display: block; width: 240px; height: 4px; background: #eee; position: absolute; left: 0; bottom: 0;}
    .formBox label:not(.form-check-label), .formBox .autocomplete{position: relative; margin-bottom: 15px; display: block;}
    .formBox label:not(.form-check-label) span, .formBox .autocomplete span{display: block; padding-left: 40px;}
    .formBox label:not(.form-check-label) span:before, .formBox .autocomplete span:before{content: ''; display: block; width: 16px; height: 16px; background-size: 16px auto; position: absolute; left: 12px;}
    .formBox label.noIcon .form{padding-left: 15px;}
    .formBox label.noIcon span:before{display: none;}
    .formBox label span img{width: 30px; margin-right: 10px;}
    .formBox label:not(.noIcon) span img{margin-left: -30px; position: relative; z-index: 10;}
    .formBox input, .formBox select{margin-bottom: 0;}
    .formBox .formDisabled{border-color: #eee; color: #999; cursor: not-allowed; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; display: flex !important; align-items: center;}
    .formBox .formDisabled span{line-height: 14px; padding-left: 0 !important;}
    .formBox .formDisabled small{font-size: 12px; line-height: 12px;}
    
    .formBox .formFullName .form:before{background: url(~@/assets/images/icons/form-register-fullname.svg) no-repeat center;}
    .formBox .formYourEmail .form:before{background: url(~@/assets/images/icons/form-register-your-email.svg) no-repeat center;}
    .formBox .formResidenceCountry .form:before{background: url(~@/assets/images/icons/form-register-residence-country.svg) no-repeat center;}
    .formBox .formPhone .form:before{background: url(~@/assets/images/icons/form-register-phone.svg) no-repeat center;}

    .formBox .formIdNumber span:first-child{padding-left: 0;}
    .formBox .formIdNumber .form{padding-left: 40px;}
    .formBox .formIdNumber .form:before{background: url(~@/assets/images/icons/form-register-idnumber.svg) no-repeat center;}
    
    .formBox .formExpirationDate .form{padding-left: 40px;}
    .formBox .formExpirationDate .form:before{background: url(~@/assets/images/icons/form-register-expiration.svg) no-repeat center;}
    
    .formBox .formCountry .form{padding-left: 40px;}
    .formBox .formCountry .form:before{background: url(~@/assets/images/icons/form-register-residence-country.svg) no-repeat center;}
    
    .formBox .formPersonDOB .form{padding-left: 40px;}
    .formBox .formPersonDOB .form:before{background: url(~@/assets/images/icons/form-register-dob.svg) no-repeat center;}
    
    .formBox .formTaxNumber .form{padding-left: 40px; border: 1px solid #ccc;}
    .formBox .formTaxNumber:before{content: ''; display: block; position: absolute; z-index: 10; bottom: 12px; left: 12px; width: 16px; height: 16px; background: url(~@/assets/images/icons/form-register-taxnumber.svg) no-repeat center; background-size: 16px auto;}

    .formBox .formIssuerCountry .form{padding-left: 40px; border: 1px solid #ccc;}
    .formBox .formIssuerCountry:before{content: ''; display: block; position: absolute; z-index: 10; bottom: 12px; left: 12px; width: 16px; height: 16px; background: url(~@/assets/images/icons/form-register-residence-country.svg) no-repeat center; background-size: 16px auto;}
    
    .formBox .formGender .form{padding-left: 40px;}
    .formBox .formGender .form:before{background: url(~@/assets/images/icons/form-register-gender.svg) no-repeat center;}

    .formBox .formAddress .form{padding: .75rem 1rem .75rem 40px; line-height: 22px; height: 72px; margin-bottom: 0;}
    .formBox .formAddress .form:before{background: url(~@/assets/images/icons/form-register-address.svg) no-repeat center; top: 43px !important; transform: translateY(0) !important; height: 33px !important;}
    
    .formBox .d-flex{justify-content: space-between; align-items: center;}
    .formBox .btn-primary{padding: 0 20px;}

    .modal-noConfirm h2{color: var(--color-pending); margin-bottom: 25px;}
    .modal-noConfirm label{display: block; margin-bottom: 5px; font-size: 12px;}
    .modal-noConfirm .form{height: 80px; line-height: 20px; padding: 10px 14px;}
    .box-success{text-align: center;}

    .errorsForm{max-width: 400px; background: rgba(216, 25, 25, 0.1); padding: 15px 20px; margin-bottom: 30px; border-radius: 5px;}
    .errorsForm p{color: var(--color-cancel); font-size: 14px;}
    .errorsForm p:not(:first-child){margin-bottom: 0;}

    .btn-primary.load{background: url(~@/assets/images/icons/loading-white.svg) no-repeat center var(--color-secondary); background-size: auto 80%;}
    .btn-primary.load:after{display: none;}

    #btnRegister, .btn-primary.load{margin-bottom: 40px;}

    .btn-cancel-compliance{display: flex; justify-content: center; align-items: center; width: 100%; margin-bottom: 50px;}
    .btn-cancel-compliance .btn{font-size: 14px; color: var(--color-cancel); padding: 0 20px; height: 40px; line-height: 40px; border-radius: 12px; background: rgba(0,0,0,0.05); cursor: pointer;}
</style>